// Modified by: Mohit Tiwari
// On: 2024-06-07
// Reason: Removed changes for isInitialMount(committed on 10 May,2024), as it blocks search and exit survey funcitonality

// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props.listCount)

import React, { useEffect, useRef } from 'react';
import { ChangeEvent, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';

import Loader from 'components/Loader/Loader';
import './ManageSurvey.scss';
import { getCall, putCall } from 'utils/ApiCallActions';
import { InsightLabels } from 'labels';

interface CurrentState {
    languageData: LanguageState
}

const SurveyList = (props: any) => {
    const isInitialMount = useRef(true);

    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const [errorCount, setErrorCount] = useState({
        errorCount: [0]
    });

    const [list, setList] = useState({
        flag: false
    })

    const [exitSurveyList, setExitSurveyList] = useState({
        data: [],
        dataFetched: false,
        isOpen: false,
        selectedState: null,
        selectedValue: 'Select Exit Survey',
        checkedState: false,
        stateFromAPI: false,
        stateCodeFromAPI: null
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            // To perform search on list
            // if(props.firstTimeLoad === false) {
            //     isInitialMount.current = true;
            // }

            // isInitialMount.current is used to avoid multiple api callls
            if (isInitialMount.current) {
                if (fetched_details.languageData.languageData) {
                    setList((prevState: any) => {
                        return {
                            ...prevState,
                            flag: true
                        }
                    })
                    
                    if(props.callFrom !== 'search' ) {
                        setExitSurveyList((prevState: any) => {
                            return {
                                ...prevState,
                                data: [],
                                dataFetched: false,
                                isOpen: false,
                                selectedState: null,
                                selectedValue: 'Select Exit Survey',
                                checkedState: false,
                                stateFromAPI: false,
                                stateCodeFromAPI: null
                            }
                        })
                        getExitSurveyList();
                    }
                    
                }
                isInitialMount.current = false;

                setTimeout(() => {
                    isInitialMount.current = true;
                }, 1000)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [props.listData])


   



    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            setList((prevState: any) => {
                return {
                    ...prevState,
                    flag: false
                }
            })
            props.searchList(e.target.value)
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleDelete = (data: string) => {
        props.deleteSentiment(data)
    }

    const handleShare = (title: string, code: string) => {
        props.shareSentiment(title, code)
    }

    const changeStatus = (code: string, title: string) => {
        try {
            let flagState = false;
            if (exitSurveyList.stateCodeFromAPI === code) {
                flagState = exitSurveyList.stateFromAPI
            }
            setExitSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    selectedState: code,
                    selectedValue: title,
                    isOpen: false,
                    checkedState: flagState
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    };

    const toggleDropDown = (event: boolean) => {
        setExitSurveyList((prevState: any) => {
            return {
                ...prevState,
                isOpen: event,
            };
        });
    };

    const getExitSurveyList = () => {
        try {
            let params = {
                search_text: "",
                time_zone: timeZone,
                sort_by: 'last_updated_on',
                order: 'desc',
                page_index: 1,
                page_size: 9999,
            };

            getCall(params, 'SENTIMENT_LIST', '').then((data: any) => {
                if (data?.result === 'success') {
                    apiCatchError(0, 0);
                    getExitSurveyState(data.data.sentiments)
                } else {
                    setExitSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            data: [],
                            dataFetched: true
                        }
                    })
                    apiCatchError(0, 1);
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getExitSurveyState = (dataList: any) => {
        try {
            const params = {};
            getCall(params, 'EXIT_SURVEY', '').then((data: any) => {

                let sentimentData: any = [];


                if (data.result === 'success') {

                    sentimentData = dataList.filter((item: any) => {
                        if (item.sentimentCode === data.data.sentiment.sentimentCode) {
                            return item;
                        }
                    });
                    setExitSurveyList((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: true,
                            selectedState: sentimentData.length ? sentimentData[0].sentimentCode : null,
                            selectedValue: sentimentData.length ? sentimentData[0].sentimenTitle : exitSurveyList.selectedValue,
                            data: dataList,
                            checkedState: data.data.exitSentiment ? true : false,
                            stateFromAPI: data.data.exitSentiment ? true : false,
                            stateCodeFromAPI: sentimentData.length ? sentimentData[0].sentimentCode : null
                        }
                    })

                } else {

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    // useEffect(() => {
    //     getExitSurveyList();
    // }, [])



    const setExitSurveyState = (event: any) => {
        try {
            setExitSurveyList((prevState: any) => {
                return {
                    ...prevState,
                    checkedState: event.target.checked
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const saveExitSurvey = () => {
        try {
            let params = {
                "exitSentiment": exitSurveyList.checkedState ? true : null,
                "sentiment": {
                    "sentimentCode": exitSurveyList.selectedState
                }
            }
            putCall(params, 'EXIT_SURVEY', '').then((data: any) => {
                if (data.result === 'success') {
                    toast.success(insightsLbls.successfullyUpdated, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);
                } else if (data.result === 'error') {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            toast.dismiss();
                        }, 5000);
                    }
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const discardExitSurvey = () => {
        setExitSurveyList((prevState: any) => {
            return {
                ...prevState,
                data: [],
                dataFetched: false,
                isOpen: false,
                selectedState: null,
                selectedValue: 'Select Exit Survey',
                checkedState: false,
                stateFromAPI: false,
                stateCodeFromAPI: null
            }
        })
        getExitSurveyList();

    }


    return (
        <>

            <div id="category_main_div" className="displayFlex">
                <div className="categoryDiv">
                    <div className="categoryList-div" id="tags_main_div">
                        <div className="searchDiv">
                            <p className="displayFlex marginTop-10">
                                <input type="text"
                                    className="searchIcon form-control round-input searchInput-box"
                                    placeholder={insightsLbls.searchSurvey}
                                    autoComplete="off"
                                    onChange={(event) => onSearchText(event)}
                                    disabled={(props.apiError === 1 || (props.firstTimeLoad && props.listCount === 0)) ? true : false} />
                                <button className="createCat-button" onClick={() => props.dispatchClick()}>
                                    <i className="fa fa-plus font20" aria-hidden="true"></i>
                                </button>
                            </p>
                        </div>
                        <div className="categoryMain-div">

                            {
                                list.flag 
                                    ? (
                                        props.apiError === 0 ?
                                            (
                                                <>
                                                    {
                                                        props.listCount ?
                                                            (
                                                                <>
                                                                    {
                                                                        props.listData.map((data: any, index: number) => {
                                                                            return (


                                                                                <div className="row category-card highlightCard" key={index}>
                                                                                    <div className="flex-100 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                                                                        <div className="cat-content-div col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10" onClick={() => props.dispatchEditSurvey(data)}>
                                                                                            <p className="nomargin fontW600 textOverflow cat-info marginBottom-0">
                                                                                                {data.sentimenTitle}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="app-actions-div col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 paddingLR-0">
                                                                                            <div className="catEdit-optn">
                                                                                                <div className="optnsContainer displayFlex alignCenter marginTop-10">
                                                                                                    <i className="fa fa-external-link editCard-icon margin8" onClick={() => handleShare(data.sentimenTitle, data.sentimentCode)}></i>
                                                                                                    <i className="fa fa-trash editCard-icon margin8" aria-hidden="true" onClick={() => handleDelete(data.sentimentCode)}></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="alignCenter flex-100 font12">
                                                                                        <div className="col-lg-7 textLeft alignCenter paddingRight-5 paddingLeft-0">
                                                                                            <div className="alignCenter">
                                                                                                <span className="lastUpdatedText">{insightsLbls.lastUpdated}:&nbsp;</span>
                                                                                                <span className="font600 lastUpdated">{data.lastUpdatedOn}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-5 textLeft alignCenter displayFlex paddingRight-5 paddingLeft-0">
                                                                                            {data.isGlobal && (
                                                                                                <>
                                                                                                    <div className="alignCenter globalChip marginRight-15" >
                                                                                                        {insightsLbls.global}
                                                                                                    </div> &nbsp;
                                                                                                </>
                                                                                            )}

                                                                                            <div className="alignCenter questionCount">
                                                                                                {insightsLbls.questions}:&nbsp;<span className="font600">{data.questionCount}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            )}
                                                </>
                                            )
                                            :
                                            (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.serviceNotAvailable}
                                                </div>
                                            )
                                    )
                                    :
                                    (<div className="minHeight400">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>)
                            }
                        </div>
                    </div>
                </div>

                {/* Sentiment Instructions */}
                <div id="sentiment_instructions" className="width100">
                    <div className="exitSurvey-div">


                        {exitSurveyList.dataFetched ? (
                            errorCount.errorCount[0] === 0 ? (
                                !exitSurveyList.data.length ? (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                            <span className="marginLeft-30 font14">{insightsLbls.enableExitSurvey}</span>
                                            <input type="checkbox"
                                                className="custom-control-input chk checkbox"
                                                id="customControlAutosizing1"
                                                name="chk-box"
                                                value="delete"
                                                checked={exitSurveyList.checkedState}
                                                onClick={(event) => setExitSurveyState(event)} />
                                            <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                        </label>
                                        <p className="exitSurveyWarning-msg">({insightsLbls.exitSurveyWarningMsg})</p>

                                        <Dropdown id="survey-filter-dropdown" show={exitSurveyList.isOpen} onToggle={(event) => toggleDropDown(event)} className="marginTop-30">

                                            <Dropdown.Toggle className={"btn btn-lg btn-outline-primar bootstrapDCWorkaround2 source-but-state-filter buttonPositionStateFilter"}>
                                                <span>{exitSurveyList.selectedValue}</span>
                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (exitSurveyList.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                            </Dropdown.Toggle>

                                            {/* dropdown-menu-state-filter userList-dropdown  */}
                                            <Dropdown.Menu className={"dropdown-menu source-menu-state-filter"}>
                                                {
                                                    exitSurveyList.data.map((state: any, index: number) => {
                                                        return (
                                                            <div className="dropdown-status-menu" key={index}>
                                                                <button onClick={() => changeStatus(state.sentimentCode, state.sentimenTitle)} id={"status_" + state.key}
                                                                    className={"pointer dropdown-menu-item statusBtn " + (exitSurveyList.selectedState === state.sentimentCode ? "active-button" : "")}>
                                                                    {state.sentimenTitle}
                                                                </button>
                                                            </div>
                                                        );
                                                    })

                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <div className="displayFlowRoot  marginTop-30">
                                            <span className='floatRight'>
                                                <button className="btn modal-save-btn marginRight-10" onClick={() => saveExitSurvey()}>{insightsLbls.done}</button>
                                                <button className="submit-btn btn greyBtn disableClrBtn modal-cancel-btn" onClick={() => discardExitSurvey()}>{insightsLbls.discard}</button>
                                            </span>
                                        </div>
                                    </>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight150">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className="sentimentInstruction">
                        <div>{insightsLbls.click} <span className="createCat-button createCat-button-span"><i className="fa fa-plus font20" aria-hidden="true"></i></span>
                            {insightsLbls.iconToCreateNewSurvey}</div>
                        <div>{insightsLbls.clickToEditSurvey}</div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default React.memo(SurveyList);