
//Modified by: Saurabh Garg
//on: 25-Jan-23
//Reason: adding tutorial guide info in guide list and corresponding message in playmode


import React, { useEffect, useCallback } from 'react';
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { Form, FormGroup, FormControl, ButtonToolbar, SplitButton, Dropdown, FormLabel, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import withRouter from "hoc/withRouter";
import * as ActionTypes from "store/Actions/ActionTypes";
import { Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import { RetryApi } from "utils/GlobalConstants";
import qs from 'querystring';
import { useErrorBoundary } from 'react-error-boundary';

import moment from 'moment';


import { AppsState, EventSourceState, DatesState, LanguageState } from 'store/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import manageGoalsReducer from "store/Reducers/Goal/manageGoals";
import { withReducer } from 'store/withReducer';
import { ManageGoalState } from 'store/Reducers/Goal/manageGoals';
import * as manageGoalActions from "store/Actions/index";
import { getCall, postCall, putCall } from 'utils/ApiCallActions';




import './GoalForm.scss';


import EventSource from 'components/EventSource/EventSource';
import Select from 'react-select';
import Maintenance from 'components/Maintenance/Maintenance';
import Loader from 'components/Loader/Loader';
import CommonModal from 'components/CommonModal/CommonModal';

// import CreatableSelect from 'react-select/creatable';


import { InsightLabels } from 'labels';


interface CurrentState {
    apps: AppsState,
    eventSource: EventSourceState,
    goal: ManageGoalState,
    dates: DatesState,
    languageData: LanguageState
}

const GoalForm = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    let email_id = localStorage.getItem("EMAIL_ID");

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const today = new Date();
    const startDate = moment(today).add(30, 'm').toDate();
    const endDate = moment(today).add(1470, 'm').toDate();

    let fetched_details = useSelector((state: CurrentState) => state);

    let playMode = []

    if(process.env.REACT_APP_WESTPAC === 'false'){
        playMode = [
            { key: "guideme", value: "GuideMe" },
            { key: "showme", value: "ShowMe" },
            { key: "doitforme", value: "DoItForMe" },
            { key: "tooltip", value: "ToolTip"}
        ]
    } else {
        playMode = [
            { key: "guideme", value: "GuideMe" },
            { key: "tooltip", value: "ToolTip"}
        ]
    }

    const [goalData, setGoalData] = useState({
        pageArray: [[]],
        loaderFlag: false,
        setGlobal: false,
        goalTitle: '',
        labelData: [],
        duplicateFlag: false,
        requestFlag: false,
        goalCode: '',
        saveBtnClicked: false,
        goalTitleError: false
    })


    const [errorCount, setErrorCount] = useState({
        errorCount: [0],
        showMaintenance_flag: false
    });


    const [goalDate, setGoalDate] = useState({
        startDate: startDate,
        endDate: endDate,
        endDateDisabled: false,
        goalDateSetFlag: false,
        startDateInvalid: false,
        endDateInvalid: false,
    })

    const [playCount, setPlayCount] = useState({
        maxLimit: 10000,
        playCountError: false,
        errorMsg: ''
    })

    const [goalGuideList, setGoalGuideList] = useState({
        dataFetched: false,
        data: [],
        firstTimeload: true,
        selectedGuides: [],
        guideListValidationFlag: true,
        maxGuideCount: 10,
    })

    const [goalTooltipList, setGoalTooltipList] = useState({
        dataFetched: false,
        data: [],
        firstTimeload: true,
        selectedtips: [],
        tooltipListValidationFlag: true,
        maxTooltipCount: 10,
    })


    const [goalPayload, setGoalPayload] = useState({
        goalTitle: '',
        goalDescription: '',
        goalType: 'guide_goal',
        goalStartTimestamp: moment(startDate).format("YYYY-MM-DD") + '_' + moment(startDate).format("HH:mm:ss"),
        goalEndTimestamp: moment(endDate).format("YYYY-MM-DD") + '_' + moment(endDate).format("HH:mm:ss"),
        goalGuideList: [],
        goalPlayTypes: [],
        eventSourceGoals: [],
        count: 1,
        editFlag: false,
        saveBtnDisabled: true,
        isPublishedDisabled: true,
        goalCode: '',
        publishForEditFlag: false,
        isPublished: 0,
        formDataLoad: false,
        selectTooltip: false,
        goalTooltipList: [],
        guideListForTooltip: [],
        playbuttonDisabled: false,
    })


    const [publishModal, setPublishModal] = useState({
        open: false,
        goalCode: "",
    })

    const [notificationModal, setNotificationModal] = useState({
        open: false,
    })

    const [publishValidationModal, setPublishValidationModal] = useState({
        open: false,
        goalCode: "",
        errorMsg: '',
        errorSubMsg: []
    })

    const [eventSourceSwitchFlag, setEventSourceSwitchFlag] = useState(false)

    const [formLoader, setFormLoader] = useState(false)


    // const eventSourceValuesTooltips = props.formData.length !== 0?
    //                             props.formData.goalPayloadObject['event_sources'].join('&event_source=')
    //                             :fetched_details.eventSource.eventSourceGoalsTooltips;

    // const eventSourceValuesGuides = props.formData.length !== 0?
    // props.formData.goalPayloadObject['event_sources'].join('&event_source=')
    // :fetched_details.eventSource.eventSourceGoalsTooltips;

    // Object.keys(props.formData).length ?             //Edit(is data available from api )
    //                             (
    //                              Array.isArray(props.formData.goalPayloadObject['event_sources']) ? 

    //                                 props.formData.goalPayloadObject['event_sources'].join('&event_source=') 
    //                                 : props.formData.goalPayloadObject['event_sources'] 
    //                              )
    //                             : 'player' //New Goal

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            getGuideList('');
            getTooltipList('')
            // setEventSourceSwitchFlag(true);
            if (Object.keys(props.formData).length) {
                let guideIDs: any = [];
                let guides: any = [];
                let tooltipIDs: any = [];
                let tooltips: any = [];
                let onlyTutorialGuides: boolean = false;

                if (props.formData.goalPayloadObject['play_types'].includes('tooltip')) {
                    for (let item of props.formData.goalPayloadObject['guides']) {
                        guideIDs.push(item['guideID'])
                        tooltips.push({ value: item['stepID'], label: item['guideTitle'], id: item['guideTitle'], guideId: item['guideID'] })
                        tooltipIDs.push(item['stepID'])
                    }
                } else {
                    for (let [index, item] of props.formData.goalPayloadObject['guides'].entries()) {
                        let label;
                        label = item['guideType'] === 'tutorial' ? item['guideTitle'] + ' (Tutorial guide)' : item['guideTitle'];

                    guideIDs.push(item['guideID']);
                    guides.push({ value: item['guideTitle'], label: label, id: item['guideID'], guideType: item['guideType'] })

                        if (index === 0) {
                            item['guideType'] === 'tutorial' ? (onlyTutorialGuides = true) : (onlyTutorialGuides = false);
                        } else {
                            if (onlyTutorialGuides === true) {
                                item['guideType'] === 'tutorial' ? (onlyTutorialGuides = true) : (onlyTutorialGuides = false);
                            }
                        }
                    }
                }

                setGoalPayload((prevState: any) => {
                    return {
                        ...prevState,
                        formDataLoad: true,
                        goalTitle: props.formData['goalTitle'],
                        goalDescription: props.formData['goalDescription'],
                        goalType: props.formData['goalType'],
                        goalGuideList: props.formData.goalPayloadObject['play_types'].includes('tooltip') ? [] : [...guideIDs],
                        eventSourceGoals: Array.isArray(props.formData.goalPayloadObject['event_sources']) ? props.formData.goalPayloadObject['event_sources'].join('&event_source=') : props.formData.goalPayloadObject['event_sources'],
                        goalPlayTypes: props.formData.goalPayloadObject['play_types'],
                        selectTooltip: props.formData.goalPayloadObject['play_types'].includes('tooltip'),
                        count: props.formData.goalPayloadObject['count'],
                        goalCode: props.formData['goalCode'],
                        isPublishedDisabled: false,
                        saveBtnDisabled: true,
                        editFlag: true,
                        publishForEditFlag: true,
                        isPublished: props.formData['isPublished'],
                        goalEndTimestamp: moment(new Date(props.formData['goalEndTimestamp'])).format("YYYY-MM-DD") + '_' + moment(new Date(props.formData['goalEndTimestamp'])).format("HH:mm:ss"),
                        goalStartTimestamp: moment(new Date(props.formData['goalStartTimestamp'])).format("YYYY-MM-DD") + '_' + moment(new Date(props.formData['goalStartTimestamp'])).format("HH:mm:ss"),
                        goalTooltipList: props.formData.goalPayloadObject['play_types'].includes('tooltip') ? [...tooltipIDs] : [],
                        guideListForTooltip: props.formData.goalPayloadObject['play_types'].includes('tooltip') ? [...guideIDs] : [],
                        playbuttonDisabled: onlyTutorialGuides,
                    }
                })

                setGoalDate((prevState: any) => {
                    return {
                        ...prevState,
                        startDate: new Date(props.formData['goalStartTimestamp']),
                        endDate: new Date(props.formData['goalEndTimestamp']),
                        endDateDisabled: false,
                        goalDateSetFlag: false,
                        startDateInvalid: false,
                        endDateInvalid: false,
                    }
                })

                if (props.formData.goalPayloadObject['play_types'].includes('tooltip')) {
                    setGoalTooltipList((prevState: any) => {
                        return {
                            ...prevState,
                            selectedtips: [...tooltips],
                        }
                    })

                } else {
                    setGoalGuideList((prevState: any) => {
                        return {
                            ...prevState,
                            selectedGuides: [...guides],
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.languageData.languageData]);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideList = (apiErrorFlag?: string) => {
        let params = {
            search_text: encodeURIComponent(''),
            app_code: fetched_details.apps.appCode,
            page_index: 1,
            page_size: 99999,
        };


        manageGoalActions.getManageGoalData(
            params,
            "GOAL_GUIDE_LIST",
            ActionTypes.SET_GOAL_GUIDE_LIST,
            apiErrorFlag ?? '',
            goalGuideList.firstTimeload
        ).then((res: any) => dispatch(res));
    }

    const getTooltipList = (apiErrorFlag?: string) => {
        let params = {
            search_text: encodeURIComponent(''),
            app_code: fetched_details.apps.appCode,
            page_index: 1,
            page_size: 99999,
        };


        manageGoalActions.getManageGoalDataToolTip(
            params,
            "GOAL_TOOLTIP_LIST",
            ActionTypes.SET_GOAL_TOOLTIP_LIST,
            apiErrorFlag ?? '',
            goalGuideList.firstTimeload
        ).then((res: any) => dispatch(res));
    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // UseEffect for date, app, filters change
    // useEffect(() => {
    //     if (fetched_details?.dates.source === 'component') {
    //         if (location.pathname === fetched_details?.dates.url) {
    //             getGuideList();
    //         }
    //     }

    // }, [fetched_details?.eventSource?.eventSourceGuides,
    // fetched_details?.dates.fdate,
    // fetched_details?.dates.tdate,
    // fetched_details?.dates.excludeFlag,
    // fetched_details?.dates.dateSelection,
    // fetched_details?.dates.source,
    // fetched_details?.dates?.appId,
    // fetched_details?.dates?.url,
    // fetched_details?.dates?.realTimeCheck,
    // fetched_details?.dates?.realTime_tdate])

    // Goal Guide List
    useEffect(() => {
        try {
            if (
                fetched_details?.goal?.goalGuideList?.data !== undefined &&
                fetched_details?.goal?.goalGuideList?.result === "success"
            ) {

                let guideList: any = [];

                for (let item of fetched_details?.goal?.goalGuideList?.data.data) {
                    let label;
                    label = item.guide_type === 'tutorial' ? item['guide_title'] + ' (Tutorial guide)' : item['guide_title'];
                    guideList.push({ value: item['guide_title'], label: label, id: item['guide_id'], guideType: item['guide_type'] })
                }

                setGoalGuideList((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        data: [...guideList]
                    }
                })
                apiCatchError(0, 0);

            } else if (fetched_details?.goal?.goalGuideList?.result === 'retry') {
                getGuideList('retry');
            } else if (fetched_details?.goal?.goalGuideList?.result === "error") {
                setGoalGuideList((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                    }
                })
                apiCatchError(0, 1);

                }
            } catch (error) {
                showBoundary(error)
            }

        }, [fetched_details?.goal?.goalGuideList])

    //Goal Tooltip List
    useEffect(() => {
        try {
            if (
                fetched_details?.goal?.goalTooltipList?.data !== undefined &&
                fetched_details?.goal?.goalTooltipList?.result === "success"
            ) {

                let tooltipList: any = [];

                for (let item of fetched_details?.goal?.goalTooltipList?.data.data) {
                    // tooltipList.push({ value: item['step_title'], label: item['step_title'], id: item['step_id'], guideId: item['guide_id'] })
                    tooltipList.push({ value: item['step_id'], label: item['step_title'], id: item['step_title'], guideId: item['guide_id'] })
                }

                setGoalTooltipList((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        data: [...tooltipList]
                    }
                })
                apiCatchError(0, 0);

            } else if (fetched_details?.goal?.goalTooltipList?.result === 'retry') {
                getTooltipList('retry');
            } else if (fetched_details?.goal?.goalTooltipList?.result === "error") {
                setGoalTooltipList((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                    }
                })
                apiCatchError(0, 1);

                }
            } catch (error) {
                showBoundary(error)
            }

        }, [fetched_details?.goal?.goalTooltipList])

    useEffect(() => {
        try {
            if (goalPayload.goalTitle === '' ||
                goalPayload.count <= 0 ||
                goalPayload.count > playCount.maxLimit ||
                (goalPayload.selectTooltip ? (goalPayload.goalTooltipList.length === 0) : goalPayload.goalGuideList.length === 0) ||
                goalPayload.goalPlayTypes.length === 0 ||
                goalDate.endDateInvalid ||
                goalDate.startDateInvalid ||
                goalGuideList.guideListValidationFlag === false ||
                goalPayload.publishForEditFlag) {
                setGoalPayload((prevState: any) => {
                    return {
                        ...prevState,
                        saveBtnDisabled: true,
                    }
                })
            } else {
                setGoalPayload((prevState: any) => {
                    return {
                        ...prevState,
                        saveBtnDisabled: false
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [goalPayload.goalTitle,
    goalPayload.count,
    goalPayload.goalGuideList,
    goalPayload.goalPlayTypes,
    goalDate.endDateInvalid,
    goalDate.startDateInvalid,
    goalPayload.publishForEditFlag,
    goalPayload.goalEndTimestamp,
    goalPayload.goalStartTimestamp,
    goalPayload.goalDescription,
    fetched_details.eventSource.eventSourceGoalsGuides,
    fetched_details.eventSource.eventSourceGoalsTooltips,
    goalPayload.selectTooltip,
    goalPayload.goalTooltipList
    ])

    //UseEffect for Guide/Tooltip switch
    useEffect(() => {


        setEventSourceSwitchFlag(false);

        setTimeout(() => {
            setEventSourceSwitchFlag(true);
        }, 500)

    }, [goalPayload.selectTooltip])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const returnEventSource = (selectTooltip: any) => {
        try {
            switch (selectTooltip) {
                case true:
                    return <EventSource component={"goalsTooltips"} defaultValue={props.formData.length !== 0 ?
                        props.formData.goalPayloadObject['event_sources'].join('&event_source=')
                        : fetched_details.eventSource.eventSourceGoalsTooltips}></EventSource>
                case false:
                    return <EventSource component={"goalsGuides"} defaultValue={props.formData.length !== 0 ?
                        props.formData.goalPayloadObject['event_sources'].join('&event_source=')
                        : fetched_details.eventSource.eventSourceGoalsGuides}></EventSource>
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    const setPublishEditFlag = () => {
        try {
            // saveBtnDisabled: false
            setGoalPayload((prevState: any) => {
                return {
                    ...prevState,
                    publishForEditFlag: false,
                    isPublishedDisabled: true,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    const onDateChange = (date: any, dateType: string) => {
        try {
            setPublishEditFlag();

            if (dateType === 'endDate') {
                const currentDate = moment(goalDate.startDate).add(1470, 'm').toDate();
                const inputDate = new Date(date);

                if (currentDate.getTime() <= inputDate.getTime()) {
                    setGoalDate((prevState: any) => {
                        return {
                            ...prevState,
                            endDate: date,
                            endDateInvalid: false
                        }
                    })

                    setGoalPayload((prevState: any) => {
                        return {
                            ...prevState,
                            goalEndTimestamp: moment(date).format("YYYY-MM-DD") + '_' + moment(date).format("HH:mm:ss")
                        }
                    })
                } else {
                    setGoalDate((prevState: any) => {
                        return {
                            ...prevState,
                            endDateInvalidFlag: true
                        }
                    })
                }
            }
            else {
                const currentDate = startDate;
                const inputDate = new Date(date);

                if (currentDate.getTime() < inputDate.getTime()) {
                    const selectedDate = new Date(date);
                    const add_minutes = function (dt: any, minutes: number) {
                        return new Date(dt.getTime() + minutes * 60000);
                    }
                    const endDate = add_minutes(new Date(selectedDate), 1470);

                    setGoalDate((prevState: any) => {
                        return {
                            ...prevState,
                            startDate: date,
                            endDate: endDate,
                            endDateDisabled: false,
                            goalDateSetFlag: true,
                            startDateInvalid: false
                        }
                    })

                    setGoalPayload((prevState: any) => {
                        return {
                            ...prevState,
                            goalStartTimestamp: moment(date).format("YYYY-MM-DD") + '_' + moment(date).format("HH:mm:ss"),
                            goalEndTimestamp: moment(endDate).format("YYYY-MM-DD") + '_' + moment(endDate).format("HH:mm:ss")

                        }
                    })
                } else {
                    setGoalDate((prevState: any) => {
                        return {
                            ...prevState,
                            startDateInvalid: true
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const filterPassedTime = (time: any) => {
        try {
            const currentDate = startDate;
            const selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        } catch (error) {
            showBoundary(error)
        }

    };

    const filterEndPassedTime = (time: any) => {
        try {
            const add_minutes = function (dt: any, minutes: number) {
                return new Date(dt.getTime() + minutes * 60000);
            }

            const currentDate = add_minutes(new Date(goalDate.startDate), 1470)
            const selectedDate = new Date(time);

            return currentDate.getTime() <= selectedDate.getTime();
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleChange = (selectedOption: any) => {
        try {
            setPublishEditFlag();

            setGoalGuideList((prevState: any) => {
                return {
                    ...prevState,
                    selectedGuides: selectedOption
                }
            })

            let guideIds: any = [];
            let onlyTutorialGuides: boolean = false;

            for (let itr0 = 0; itr0 < selectedOption.length; itr0++) {
                guideIds.push(selectedOption[itr0]['id']);
                if (itr0 === 0) {
                        selectedOption[itr0]['guideType'] === 'tutorial' ? (onlyTutorialGuides = true) : (onlyTutorialGuides = false);
                    } else {
                        if (onlyTutorialGuides === true) {
                            selectedOption[itr0]['guideType'] === 'tutorial' ? (onlyTutorialGuides = true) : (onlyTutorialGuides = false);

                        }
                    }
                }

                let dummyPlayModeData: any = [...goalPayload.goalPlayTypes];

                setGoalPayload((prevState: any) => {
                    return {
                        ...prevState,
                        goalPlayTypes: onlyTutorialGuides === true ? ['showme'] : guideIds.length === 0 ? [] : dummyPlayModeData,
                    }
                })

                setGoalPayload((prevState: any) => {
                    return {
                        ...prevState,
                        goalGuideList: guideIds,
                        playbuttonDisabled: onlyTutorialGuides
                    }
                })


                setGoalGuideList((prevState: any) => {
                    return {
                        ...prevState,
                        guideListValidationFlag: guideIds.length <= goalGuideList.maxGuideCount ? true : false
                    }
                })
            } catch (error) {
                showBoundary(error)
            }

        };

        const handleChangeTooltip = (selectedOption: any) => {
            try {
                setPublishEditFlag();

                setGoalTooltipList((prevState: any) => {
                    return {
                        ...prevState,
                        selectedtips: selectedOption
                    }
                })

                let tooltipIds: any = [];
                let tooltipGuideIds: any = [];
                for (let item of selectedOption) {
                    tooltipIds.push(item['value']);
                    tooltipGuideIds.push(item['guideId'])
                }


            setGoalPayload((prevState: any) => {
                return {
                    ...prevState,
                    goalTooltipList: tooltipIds,
                    guideListForTooltip: tooltipGuideIds,
                }
            })


            setGoalTooltipList((prevState: any) => {
                return {
                    ...prevState,
                    tooltipListValidationFlag: tooltipIds.length <= goalTooltipList.maxTooltipCount ? true : false
                }
            })
        } catch (error) {
            showBoundary(error)
                }

            };

            const checkNumber = (ev: ChangeEvent<HTMLInputElement> | any) => {
                try {
                    const count: number = +ev.target.value;
                    setPlayCount((prevState: any) => {
                        return {
                            ...prevState,
                            playCountError: count > playCount.maxLimit ? true : count < 1 ? true : false,
                            errorMsg: count > playCount.maxLimit ? `Play count should not be greater than ${playCount.maxLimit}.` : (count < 1 ? `Play count should not be less than 1.` : '')
                        }
                    })
                } catch (error) {
                    showBoundary(error)
                }

            }


            const checkValidNumber = (ev: any) => {
                try {
                    return ev.charCode >= 48 && ev.charCode <= 57;
                } catch (error) {
                    showBoundary(error)
                }

            }

    const getInputData = (inputVal, inputType: string) => {
        try {
            setPublishEditFlag();

                switch (inputType) {
                    case 'goalTitle':
                        setGoalPayload((prevState: any) => {
                            return {
                                ...prevState,
                                goalTitle: inputVal.target.value
                            }
                        })

                        setGoalData((prevState: any) => {
                            return {
                                ...prevState,
                                goalTitleError: false
                            }
                        })
                        break;

                    case 'goalDesc':
                        setGoalPayload((prevState: any) => {
                            return {
                                ...prevState,
                                goalDescription: inputVal.target.value
                            }
                        })
                        break;

                    case 'playCount':
                        setGoalPayload((prevState: any) => {
                            return {
                                ...prevState,
                                count: inputVal.target.value === '' ? 0 : +inputVal.target.value
                            }
                        })
                        break;
                    default:
                        break;
                }
            } catch (error) {
                showBoundary(error)
            }

        }



        const colourStyles = (type: string) => {
        try {
            return {
                placeholder: (styles: any) => {
                    return {
                        ...styles,
                        paddlingLeft: "10px",
                        fontSize: '14px'
                    };
                },
                menu: (styles: any) => {
                    return {
                        ...styles,
                        zIndex: 2,
                        display: type === 'guidelist' ? (goalGuideList.selectedGuides.length >= goalGuideList.maxGuideCount ? 'none' : 'block') : (goalTooltipList.selectedtips.length >= goalTooltipList.maxTooltipCount ? 'none' : 'block')
                    };
                },
                input: (styles: any) => {
                    return {
                        ...styles,
                        visibility: type === 'guidelist' ? (goalGuideList.selectedGuides.length >= goalGuideList.maxGuideCount ? 'hidden' : 'visible') : (goalTooltipList.selectedtips.length >= goalTooltipList.maxTooltipCount ? 'hidden' : 'visible')
                    }
                    }
                }
            } catch (error) {
                showBoundary(error)
            }
        }


        const selectPlayMode = (ev: any, key: string) => {
            try {
                setPublishEditFlag();

            let dummyPlayModeData: any = [...goalPayload.goalPlayTypes];
            if (ev.target.checked) {
                dummyPlayModeData.push(key)
            } else {
                const index = dummyPlayModeData.indexOf(key);
                if (index > -1) {
                    dummyPlayModeData.splice(index, 1);
                }
                    }

                        setGoalPayload((prevState: any) => {
                            return {
                                ...prevState,
                                goalPlayTypes: dummyPlayModeData,
                                selectTooltip: key === 'tooltip' ? !goalPayload.selectTooltip : goalPayload.selectTooltip,
                            }
                        })
                    } catch (error) {
                        showBoundary(error)
                    }

                }

                const saveAsDraft = (apiErrorFlag?: string) => {
                    try {
                        setFormLoader(true);
                        if (!goalPayload.editFlag) {
                            setGoalPayload((prevState: any) => {
                                return {
                                    ...prevState,
                                    saveBtnDisabled: true,
                                }
                            })
            } else {
                if (goalPayload.isPublished === 0) {
                    setGoalPayload((prevState: any) => {
                                    return {
                                        ...prevState,
                                        saveBtnDisabled: true,
                                    }
                                })
                            }
                        }

                        let params = {
                            "app_code": fetched_details?.apps.appCode,
                            "goal_title": encodeURIComponent(goalPayload.goalTitle).trim(),
                            "goal_code": goalPayload.editFlag ? goalPayload.goalCode : ''
                        };

                        getCall(params, "CHECK_GOAL_TITLE", apiErrorFlag ?? '').then((data: any) => {
                            if (data.result === 'success') {
                                if (goalPayload.editFlag) {
                                    const eventSource: any = goalPayload.selectTooltip ? fetched_details.eventSource.eventSourceGoalsTooltips.split('&event_source=') : fetched_details.eventSource.eventSourceGoalsGuides.split('&event_source=');
                                    let edit_goal = {
                                        "goal_code": goalPayload.goalCode,
                                        "goal_title": goalPayload.goalTitle.trim(),
                                        "goal_description": goalPayload.goalDescription,
                                        "goal_type": goalPayload.goalType,
                                        "goal_start_timestamp": goalPayload.goalStartTimestamp, /* should be future date */
                                        "goal_end_timestamp": goalPayload.goalEndTimestamp, /* should be future date and greater than goalStartTimestamp */
                                        "is_published": goalPayload.isPublished,
                                        "app_code": fetched_details.apps.appCode,
                                        "goal_payload_object":
                                        {
                                            "guide_ids": goalPayload.selectTooltip ? goalPayload.guideListForTooltip : goalPayload.goalGuideList, /* atleast 1 and max 10 guides*/
                                            "step_ids": goalPayload.selectTooltip ? goalPayload.goalTooltipList : [], /* atleast 1 and max 10 guides*/
                                            // "guide_ids": goalPayload.selectTooltip ? goalPayload.goalTooltipList : goalPayload.goalGuideList, /* atleast 1 and max 10 guides*/
                                            "play_types": goalPayload.goalPlayTypes,
                                            "event_sources": eventSource, //if blank, then all will be considered
                                            "count": goalPayload.count, // cannot be zero
                                            "time_zone": timeZone,
                                        }

                                    }


                        setPublishValidationModal((prevState: any) => {
                            return {
                                ...prevState,
                                open: false,
                                errorMsg: ''
                                            }
                                        })

                                        let errorMsg: string = '';
                                        const startDateValid: boolean = filterPassedTime(goalDate.startDate);
                                        const endDateValid: boolean = filterEndPassedTime(goalDate.endDate);

                                        if (startDateValid === false && endDateValid === false) {
                                            errorMsg = 'Invalid Start Date and End Date';
                                            setPublishValidationModal((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    open: true,
                                                    errorMsg: errorMsg,
                                    errorSubMsg: [`(${insightsLbls.startTimeMsg})`, `(${insightsLbls.endTimeMsg})`]
                                }
                            })
                        } else {
                            if (startDateValid === false) {
                                errorMsg = insightsLbls.startTimeMsg;
                                setPublishValidationModal((prevState: any) => {
                                    return {
                                        ...prevState,
                                        open: true,
                                        errorMsg: errorMsg,
                                        errorSubMsg: []
                                                    }
                                                })
                                        }

                            if (endDateValid === false) {
                                errorMsg = insightsLbls.endTimeMsg;
                                setPublishValidationModal((prevState: any) => {
                                    return {
                                        ...prevState,
                                        open: true,
                                        errorMsg: errorMsg,
                                        errorSubMsg: []
                                    }
                                })
                            }


                            if (startDateValid && endDateValid) {
                                putCall(edit_goal, 'CREATE_GOAL', '').then((data: any) => {
                                    if (data.result === 'success') {
                                        toast.success(insightsLbls.successfullyUpdated, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        setTimeout(() => {
                                            toast.dismiss();
                                        }, 5000);
                                        // props.dispatchBack('click')

                                        if (data.data.data['isPublished'] === 1) {
                                            let notificationParams = {}
                                            getCall(notificationParams, 'PREFERENCE_DATA', '').then((data: any) => {
                                                if (data.result === 'success') {
                                                    if (data.data.goal.receive_goal_email_notification === true) {
                                                        props.dispatchBack('click')

                                                    } else {
                                                        setNotificationModal((prevState: any) => {
                                                            return {
                                                                ...prevState,
                                                                open: true,
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    props.dispatchBack('click')
                                                }
                                            })
                                        }


                                        // if (data.data.data['isPublished'] === 1) {
                                        //     props.dispatchBack('click')
                                        // }
                                        else {
                                            setGoalPayload((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    saveBtnDisabled: true,
                                                    isPublishedDisabled: false,
                                                    editFlag: true,
                                                    goalCode: data.data.data['goalCode']
                                                }
                                            })

                                            setPublishModal((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    goalCode: data.data.data['goalCode']
                                                }
                                            })
                                        }

                                        setFormLoader(false);

                                    } else if (data.result === 'error') {
                                        if (data.error) {
                                            toast.error(data.error, {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                            setTimeout(() => {
                                                toast.dismiss();
                                            }, 5000);
                                        }

                                        setFormLoader(false);

                                    }
                                })
                            }
                        }
                    } else {
                        const eventSource: any = goalPayload.selectTooltip ? fetched_details.eventSource.eventSourceGoalsTooltips.split('&event_source=') : fetched_details.eventSource.eventSourceGoalsGuides.split('&event_source=');

                        let create_goal = {
                            "goal_title": goalPayload.goalTitle.trim(),
                            "goal_description": goalPayload.goalDescription,
                            "goal_type": goalPayload.goalType,
                            "goal_start_timestamp": goalPayload.goalStartTimestamp,
                            "goal_end_timestamp": goalPayload.goalEndTimestamp,
                            "app_code": fetched_details.apps.appCode,
                            "goal_payload_object": {
                                "guide_ids": goalPayload.selectTooltip ? goalPayload.guideListForTooltip : goalPayload.goalGuideList, /* atleast 1 and max 10 guides*/
                                "step_ids": goalPayload.selectTooltip ? goalPayload.goalTooltipList : [], /* atleast 1 and max 10 guides*/
                                "play_types": goalPayload.goalPlayTypes,
                                "event_sources": eventSource,
                                "count": goalPayload.count, /* cannot be zero*/
                                "time_zone": timeZone,
                                "user_email": email_id
                            }
                        }


                        let errorMsg: string = '';
                        const startDateValid: boolean = filterPassedTime(goalDate.startDate);
                        const endDateValid: boolean = filterEndPassedTime(goalDate.endDate);

                        if (startDateValid === false && endDateValid === false) {
                            errorMsg = insightsLbls.invalidStartAndEndDate;
                            setPublishValidationModal((prevState: any) => {
                                return {
                                    ...prevState,
                                    open: true,
                                    errorMsg: errorMsg,
                                    errorSubMsg: [`(${insightsLbls.startTimeMsg})`, `(${insightsLbls.endTimeMsg})`]
                                }
                            })
                        } else {
                            if (startDateValid === false) {
                                errorMsg = insightsLbls.startTimeMsg;
                                setPublishValidationModal((prevState: any) => {
                                    return {
                                        ...prevState,
                                        open: true,
                                        errorMsg: errorMsg,
                                        errorSubMsg: []
                                    }
                                })
                            }

                            if (endDateValid === false) {
                                errorMsg = insightsLbls.endTimeMsg;
                                setPublishValidationModal((prevState: any) => {
                                    return {
                                        ...prevState,
                                        open: true,
                                        errorMsg: errorMsg,
                                        errorSubMsg: []
                                    }
                                })
                            }


                            if (startDateValid && endDateValid) {
                                postCall(create_goal, 'CREATE_GOAL').then((data: any) => {
                                    if (data.result === 'success') {
                                        toast.success(insightsLbls.successfullyCreated, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        setTimeout(() => {
                                            toast.dismiss();
                                        }, 5000);

                                        // props.dispatchBack('click')
                                            setGoalPayload((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    saveBtnDisabled: true,
                                                    isPublishedDisabled: false,
                                                    editFlag: true,
                                                    goalCode: data.data['goalCode']
                                                }
                                            })

                                            setPublishModal((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    goalCode: data.data['goalCode']
                                                }
                                            })

                                            setFormLoader(false);

                                        } else if (data.result === 'error') {
                                            if (data.error) {
                                                toast.error(data.error, {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                setTimeout(() => {
                                                    toast.dismiss();
                                                }, 5000);

                                                setGoalPayload((prevState: any) => {
                                                    return {
                                                        ...prevState,
                                                        saveBtnDisabled: true,
                                                        isPublishedDisabled: true
                                                    }
                                                })
                                            }

                                            setFormLoader(false);
                                        }
                                })

                            }
                    }



                }
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        setFormLoader(true);
                        saveAsDraft('retry')
                    }, RetryApi.TIMEOUT)
                } else {
                    setFormLoader(false);

                    toast.error(insightsLbls.goalAlreadyExists, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);

                    setGoalData((prevState: any) => {
                        return {
                            ...prevState,
                            goalTitleError: true,
                            saveBtnClicked: false,
                        }
                    })
            }
        })
    } catch (error) {
        showBoundary(error)
    }

    }


const closePublishModal = () => {

    setPublishModal((prevState: any) => {
        return {
            ...prevState,
            open: false
        }
    })

}

const closePublishValidationModal = () => {

    setPublishValidationModal((prevState: any) => {
        return {
            ...prevState,
            open: false
        }
    })

    setFormLoader(false);
}

const closeNotificationModal = () => {
    setNotificationModal((prevState: any) => {
        return {
            ...prevState,
            open: false
        }
    })
    props.dispatchBack('click');
}

const savePublishGoal = () => {
    setPublishModal((prevState: any) => {
        return {
            ...prevState,
            open: true
        }
    })
}

const navigateControlCenter = () => {
    let queryParams = {
        app_id: fetched_details?.apps.appId,
        app_code: fetched_details?.apps.appCode,
    }
    navigate({
        pathname: `/control-center/`,
        search: qs.stringify(queryParams)
    })

}

const publishGoal = () => {
        try {
            setPublishValidationModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false,
                    errorMsg: ''
            }
        })

        const startDateValid: boolean = filterPassedTime(goalDate.startDate);
        const endDateValid: boolean = filterEndPassedTime(goalDate.endDate);
        let errorMsg: string = '';

        if (startDateValid === false && endDateValid === false) {
            errorMsg = 'Invalid Start Date and End Date';
            setPublishValidationModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    errorMsg: errorMsg,
                        errorSubMsg: [`(${insightsLbls.startTimeMsg})`, `(${insightsLbls.endTimeMsg})`]
                    }
                })
            } else {
                if (startDateValid === false) {
                    errorMsg = insightsLbls.startTimeMsg;
                    setPublishValidationModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: true,
                            errorMsg: errorMsg,
                            errorSubMsg: []
                        }
                    })
                }

                if (endDateValid === false) {
                    errorMsg = insightsLbls.endTimeMsg;
                    setPublishValidationModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: true,
                            errorMsg: errorMsg,
                            errorSubMsg: []
                        }
                    })
                }


                if (startDateValid && endDateValid) {
                    setGoalPayload((prevState: any) => {
                        return {
                            ...prevState,
                            isPublished: 1
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const selectGuideListType = (ev: any, selectedType: string) => {
        try {
            setPublishEditFlag();
            if (selectedType === 'guidelist') {
            setGoalPayload((prevState: any) => {
                return {
                    ...prevState,
                    goalPlayTypes: [],
                    selectTooltip: false,
                }
            })
        } else {
            setGoalPayload((prevState: any) => {
                return {
                    ...prevState,
                    goalPlayTypes: ['tooltip'],
                    selectTooltip: true,
                }
            })
        }
    } catch (error) {
        showBoundary(error)
    }

    }

    useEffect(() => {
        if (goalPayload.isPublished === 1) {
            saveAsDraft();
        }

    }, [goalPayload.isPublished])


    // To disable publish button in edit mode if event source is changed.
    useEffect(() => {
        try {
            if (goalPayload.editFlag && goalPayload.eventSourceGoals.length) {
                if (goalPayload.selectTooltip) {
                    if (fetched_details.eventSource.eventSourceGoalsTooltips !== goalPayload.eventSourceGoals) {
                        setPublishEditFlag();
                    }
                }
                else {
                    if (fetched_details.eventSource.eventSourceGoalsGuides !== goalPayload.eventSourceGoals) {
                        setPublishEditFlag();
                    }
                }

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.eventSource.eventSourceGoalsGuides, fetched_details.eventSource.eventSourceGoalsTooltips])


    return (
        <section className="demo width100 goalFormSection">
            <div className="backList-div backToList">
                <span className="backToCatList" onClick={() => props.dispatchBack('click')}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>{insightsLbls.showList}
                </span>
            </div>

            <>
                {props.firstTimeLoad || goalPayload.formDataLoad ?
                    <Form
                        className="font14 positionRelative createEdit-form"
                        style={{ display: 'inline-table' }}>

                        {formLoader && (
                            <div className="formLoader-div">
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className="outerBox bkg-1">
                            <div className="col-md-12 col-12 col-lg-12 row marginLR-0 inputFlexDiv">
                                <FormGroup controlId="goalTitle" className="col-md-7 col-7 col-lg-7 paddingLeft-2">
                                    <div className="font12 marginBottom5">{insightsLbls.goalTitle}<span className="requiredStar">*</span></div>
                                    <FormControl
                                        name="goalTitle"
                                        type="text"
                                        placeholder={insightsLbls.goalTitle}
                                        autoComplete="off"
                                        required={true}
                                        onChange={(ev) => getInputData(ev, 'goalTitle')}
                                        defaultValue={goalPayload.goalTitle}
                                    />

                                    {goalData.goalTitleError && (<p className="errorMsg displayBlock height20">{insightsLbls.goalExistsMsg}</p>)}
                                </FormGroup>
                            </div>

                            <div className="col-md-12 col-12 col-lg-12 row marginLR-0 inputFlexDiv">
                                <FormGroup controlId="formEmail" className="col-md-7 col-7 col-lg-7 marginTop-30 paddingLeft-2">
                                    <div className="font12 marginBottom5">{insightsLbls.goalDescription}</div>
                                    <FormControl
                                        name="goalDesc"
                                        type="text"
                                        placeholder={insightsLbls.goalDescription}
                                        autoComplete="off"
                                        required={false}
                                        as="textarea"
                                        rows={5}
                                        className="minHeight70 goalDesc width100"
                                        onChange={(ev) => getInputData(ev, 'goalDesc')}
                                        defaultValue={goalPayload.goalDescription}
                                    />
                                </FormGroup>
                            </div>
                        </div>


                        <div className="outerBox bkg-2 marginTop-30">
                            <div className="col-md-12 col-12 col-lg-12 row marginLR-0 inputFlexDiv">
                                {goalDate.startDateInvalid && (<p className="errorMsg displayBlock height20">{insightsLbls.startTimeMsg}</p>)}
                                {goalDate.endDateInvalid && (<p className="errorMsg displayBlock height20">{insightsLbls.endTimeMsg}</p>)}
                                <div className="displayInlineFlex paddingLeft-2">
                                    <div className="startDate ">
                                        <p className="font12 marginBottom5">{insightsLbls.goalStartDate}<span className="requiredStar">*</span></p>
                                        <div className="displayFlex positionRelative">
                                            <DatePicker
                                                selected={goalDate.startDate}
                                                onChange={(dateVal) => onDateChange(dateVal, 'startDate')}
                                                showTimeSelect
                                                filterTime={filterPassedTime}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                minDate={new Date()}
                                                timeIntervals={30}
                                                dropdownMode="select"
                                            />
                                            <span className="timeZoneSpan">{timeZone}</span>
                                        </div>

                                    </div>

                                    <div className="startDate  marginLeft-40">
                                        <p className="font12 marginBottom5">{insightsLbls.goalEndDate}<span className="requiredStar">*</span></p>
                                        <div className="displayFlex positionRelative">
                                            <DatePicker
                                                selected={goalDate.endDate}
                                                onChange={(dateVal) => onDateChange(dateVal, 'endDate')}
                                                showTimeSelect
                                                filterTime={filterEndPassedTime}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                minDate={new Date().setDate(new Date(goalDate.startDate).getDate() + 1)}
                                                timeIntervals={30}
                                                dropdownMode="select"
                                                disabled={goalDate.endDateDisabled}
                                            />
                                            <span className="timeZoneSpan">{timeZone}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="outerBox bkg-3 marginTop-30">
                            <div className="col-md-12 col-12 col-lg-12 row marginLR-0 inputFlexDiv-end">
                                {!goalPayload.selectTooltip ?
                                    (goalPayload.goalGuideList.length === goalGuideList.maxGuideCount - 1) && (<p className="errorMsg displayBlock height20 marginBottom-5">{insightsLbls.canAddupto} {goalGuideList.maxGuideCount} guides</p>)
                                    :
                                    (goalPayload.goalTooltipList.length === goalTooltipList.maxTooltipCount - 1) && (<p className="errorMsg displayBlock height20 marginBottom-5">{insightsLbls.canAddupto} {goalTooltipList.maxTooltipCount} tooltips</p>)
                                }
                                <div className="col-md-7 col-7 col-lg-7 padding0">
                                    <div className='displayFlex'>
                                        <div className='radiobutton col-lg-7 paddingLeft-2'>
                                            <ToggleButtonGroup
                                                className="gaol-radio-container"
                                                name="guidelist"
                                                type="radio"
                                            // value={dateOptions.dateSelection}
                                            // onClick={() => changeCalendarSelection(item.dateSelection)}
                                            >
                                                <ToggleButton
                                                    id="toggleButton_1"
                                                    style={{ cursor: 'pointer' }}
                                                    // disabled={item.optn_disabled}
                                                    className={"goalRadioButton " + (!goalPayload.selectTooltip ? "active" : '')}
                                                    value='guidelist'
                                                    checked={!goalPayload.selectTooltip}
                                                    onClick={(ev) => selectGuideListType(ev, 'guidelist')}
                                                >
                                                    {!goalPayload.selectTooltip ? (<span className="dot">&nbsp;</span>) : (null)}
                                                </ToggleButton>
                                            </ToggleButtonGroup>

                                            <span className={"guidetooltiplist " + (!goalPayload.selectTooltip ? "font600" : '')}>{insightsLbls.guideList}<span className="requiredStar">*</span></span>
                                        </div>

                                        <div className='radiobutton'>
                                            <ToggleButtonGroup
                                                className="gaol-radio-container"
                                                name="tooltiplist"
                                                type="radio"
                                            // value={dateOptions.dateSelection}
                                            // onClick={() => changeCalendarSelection(item.dateSelection)}
                                            >
                                                <ToggleButton
                                                    id="toggleButton_2"
                                                    style={{ cursor: 'pointer' }}
                                                    // disabled={item.optn_disabled}
                                                    className={"goalRadioButton " + (goalPayload.selectTooltip ? "active" : '')}
                                                    value='tooltiplist'
                                                    checked={goalPayload.selectTooltip}
                                                    onClick={(ev) => selectGuideListType(ev, 'tooltiplist')}
                                                >
                                                    {goalPayload.selectTooltip ? (<span className="dot">&nbsp;</span>) : (null)}
                                                </ToggleButton>
                                            </ToggleButtonGroup>

                                            <span className={"guidetooltiplist " + (goalPayload.selectTooltip ? "font600" : '')}>{insightsLbls.tooltipList}<span className="requiredStar">*</span></span>
                                        </div>
                                    </div>

                                    {!goalPayload.selectTooltip ?
                                        goalGuideList.dataFetched ? (
                                            <Select
                                                id='guidelist'
                                                key='guidelist'
                                                options={goalGuideList.data}
                                                isMulti
                                                onChange={handleChange}
                                                className=""
                                                styles={colourStyles('guidelist')}
                                                defaultValue={goalGuideList.selectedGuides}
                                                placeholder={insightsLbls.select}
                                            // isDisabled= {goalPayload.selectTooltip}
                                            />
                                        ) : (
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader width={50} height={'auto'} margin={'none'}></Loader>
                                            </div>
                                        )
                                        : goalTooltipList.dataFetched ? (
                                            <Select
                                                id='tooltiplist'
                                                key='tooltiplist'
                                                options={goalTooltipList.data}
                                                isMulti
                                                onChange={handleChangeTooltip}
                                                className=""
                                                styles={colourStyles('tooltiplist')}
                                                defaultValue={goalTooltipList.selectedtips}
                                                placeholder={insightsLbls.select}
                                            // isDisabled= {!goalPayload.selectTooltip}
                                            />
                                        ) : (
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader width={50} height={'auto'} margin={'none'}></Loader>
                                            </div>
                                        )}


                                </div>
                            </div>
                            {/* {(goalPayload.goalTooltipList.length === goalTooltipList.maxTooltipCount - 1) && (<p className="errorMsg displayBlock height20 marginBottom-5">Can add upto {goalTooltipList.maxTooltipCount} tooltips</p>)} */}

                        </div>

                        <div className="outerBox bkg-4 marginTop-30 ">
                            <div className="col-md-12 col-12 col-lg-12 row marginLR-0 inputFlexDiv-center">
                                <FormGroup controlId="formEmail" className="col-md-3 col-3 col-lg-3 paddingLeft-2">
                                    <div className="font12 marginBottom5">{insightsLbls.playCount}<span className="requiredStar">*</span></div>
                                    <FormControl
                                        name="playCount"
                                        type="number"
                                        min={1}
                                        max={playCount.maxLimit}
                                        placeholder={insightsLbls.playCount}
                                        autoComplete="off"
                                        required={true}
                                        className="width250"
                                        onKeyUp={(ev) => checkNumber(ev)}
                                        pattern="[0-9]+"
                                        onKeyPress={(ev) => checkValidNumber(ev)}
                                        onChange={(ev) => getInputData(ev, 'playCount')}
                                        defaultValue={goalPayload.count}
                                    />

                                    <p className="errorMsg displayBlock height20">
                                        {playCount.playCountError ? playCount.errorMsg : ''}
                                    </p>
                                </FormGroup>

                                <div className="startDate col-md-3 col-3 col-lg-3 row">
                                    <div className="font12 marginBottom5">{insightsLbls.eventSource}<span className="requiredStar">*</span></div>

                                    {
                                        eventSourceSwitchFlag ?
                                            (returnEventSource(goalPayload.selectTooltip))
                                            :
                                            (<Loader width={50}></Loader>)
                                    }

                                </div>

                                <div className="startDate col-md-12 col-12 col-lg-12 playMode row paddingLeft-2">
                                    <div className="font12 marginBottom5">
                                        {insightsLbls.playMode}
                                        <span className="requiredStar">*</span>
                                    </div>

                                    <div className="playModeContainer row">
                                        {playMode.map((option: any, index: number) => {
                                            return (
                                                !goalPayload.selectTooltip ?

                                                    (option.key !== 'tooltip' &&
                                                        (<div className="marginTop25 custom-control custom-checkbox mr-sm-2 marginBottom-10 check-container col-md-3 col-3 col-lg-3" key={option.key}>
                                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--25 marginTop-0">
                                                                <span className="marginLeft-30">{option.value}</span>
                                                                <input type="checkbox"
                                                                    className="custom-control-input chk checkbox"
                                                                    id="guideMe"
                                                                    name="chk-box"
                                                                    value="delete"
                                                                    onClick={(event) => selectPlayMode(event, option.key)}
                                                                    checked={option.key === 'tooltip' ? goalPayload.selectTooltip : goalPayload.goalPlayTypes.includes(option.key)}
                                                                    disabled={!(option.key === 'showme') ? goalPayload.playbuttonDisabled : false}
                                                                />
                                                                <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                            </label>
                                                        </div>)
                                                    )
                                                    :
                                                    (option.key === 'tooltip' &&
                                                        <div className="marginTop25 custom-control custom-checkbox mr-sm-2 marginBottom-10 check-container col-md-3 col-3 col-lg-3" key={option.key}>
                                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--25 marginTop-0">
                                                                <span className="marginLeft-30">{option.value}</span>
                                                                <input type="checkbox"
                                                                    className="custom-control-input chk checkbox"
                                                                    id="guideMe"
                                                                    name="chk-box"
                                                                    value="delete"
                                                                    // onClick={(event) => selectPlayMode(event, option.key)}
                                                                    checked={option.key === 'tooltip' ? goalPayload.selectTooltip : goalPayload.goalPlayTypes.includes(option.key)}
                                                                    disabled={option.key === 'tooltip' ? !goalPayload.selectTooltip : goalPayload.selectTooltip}
                                                                />
                                                                <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                            </label>
                                                        </div>
                                                    )
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="marginTop-30 textAlignRight col-lg-12 ">
                            <button type="button"
                                onClick={() => saveAsDraft()}
                                disabled={goalPayload.saveBtnDisabled}
                                className="btn modalSaveBtn confirmButton marginRight-15 modal-save-btn floatRight marginTop-30 marginLeft-20">
                                {insightsLbls.save}
                            </button>
                            <button type="button"
                                onClick={() => savePublishGoal()}
                                disabled={goalPayload.isPublishedDisabled}
                                className="btn modalSaveBtn confirmButton marginRight-15 modal-save-btn floatRight marginTop-30 marginLeft-20">
                                {insightsLbls.publish}
                            </button>
                            <button type="button"
                                onClick={() => props.dispatchBack('click')}
                                className="btn btn-secondary clearBtn modal-cancel-btn floatRight marginTop-30">
                                {insightsLbls.cancel}
                            </button>
                        </div>
                    </Form>
                    :
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>}
            </>

            <CommonModal
                modalState={publishModal.open}
                dispatchModalState={closePublishModal}
                modalTitle={insightsLbls.publishGoal}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalOk={publishGoal}
                dispatchModalCancel={closePublishModal}
                okBtn={insightsLbls.confirm}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="modal-body padding30">
                    <p>{insightsLbls.msgCannotEditGoal}</p>
                </div>
            </CommonModal>

            <CommonModal
                modalState={publishValidationModal.open}
                dispatchModalState={closePublishValidationModal}
                modalTitle={insightsLbls.invalidDate}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalOk={closePublishValidationModal}
                okBtn={insightsLbls.ok}
            >
                <div className="modal-body padding30">
                    <p>{publishValidationModal.errorMsg}</p>
                    {publishValidationModal.errorSubMsg.map((option, index) => {
                        return <p className="font13" key={index}>{option}</p>;
                    })}
                </div>
            </CommonModal>

            <CommonModal
                modalState={notificationModal.open}
                dispatchModalState={closeNotificationModal}
                modalTitle={insightsLbls.goalNotificationEmail}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalCancel={closeNotificationModal}
                cancelBtn={'OK'}
            >
                <div className="modal-body padding30">
                    <p>{insightsLbls.goalEmailNotification}</p>
                    <p>{insightsLbls.updatePreference} <Link to='/control-center/'>{insightsLbls.controlCenter} &gt; {insightsLbls.goalNotificationPreference}</Link></p>
                </div>
            </CommonModal>


            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    )
};

export default withRouter(withReducer("goal", manageGoalsReducer)(GoalForm));
