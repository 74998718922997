import React, { useEffect, useState } from "react";
import withRouter from "hoc/withRouter";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppsState, DomainState, LanguageState } from "store/Reducers";
import $ from 'jquery';
import { AppDomains } from '../../utils/GlobalConstants';
import { useErrorBoundary } from 'react-error-boundary';

import "./Rules.scss";





import { InsightLabels } from "labels";
import { Placement } from "react-bootstrap/esm/types";

interface CurrentState {
    domain: DomainState,
    apps: AppsState,
    languageData: LanguageState
}

const Rules = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);

    let domains: any = [];
    domains = AppDomains.domains;

    const [domain, setDomain] = useState({
        data: []
    })



    let urlType: any = [];
    urlType = [{ key: '0', value: 'Select' },
    { key: 'URL', value: 'URL' },
    { key: 'URL Path', value: 'URL Path' },
    { key: 'URL Parameters', value: 'URL Parameters' },
    { key: 'URL Hash', value: 'URL Hash' }];

    let urlOption: any = [];
    urlOption = [{ key: 'Equals', value: 'Equals', rule_show: 1 },
    { key: 'Not Equals', value: 'Not Equals', rule_show: 1 },
    { key: 'Contains', value: 'Contains', rule_show: 1 },
    { key: 'Not Contains', value: 'Not Contains', rule_show: 1 },
    { key: 'Starts With', value: 'Starts With', rule_show: 1 },
    { key: 'Similar to', value: 'Similar to', rule_show: 1 },
    { key: 'Ends With', value: 'Ends With', rule_show: 1 }];


    let rulesErrorMsgs: any = {
        commonMsg: insightsLbls.commonMsg,
        slashMsg: insightsLbls.slashMsg,
        angularBracketMsg: insightsLbls.angularBracketMsg,
        tilEqAngularMsg: insightsLbls.tilEqAngularMsg,
        tilEqTogetMsg: insightsLbls.tilEqTogetMsg,
        twoStarMsg: insightsLbls.twoStarMsg,
        noDoubleSlashMsg: insightsLbls.noDoubleSlashMsg,
        onlyHyphenUnderMsg: insightsLbls.onlyHyphenUnderMsg,
        noHyphenUnderMsg: insightsLbls.noHyphenUnderMsg,
        filedRequired: insightsLbls.filedRequired,
        hashMsg: insightsLbls.hashMsg,
        paramaterAlphAndSpecialChar: insightsLbls.paramaterAlphAndSpecialChar,
        parameterStartMsg: insightsLbls.parameterStartMsg,
        parameterAllowedMsg: insightsLbls.parameterAllowedMsg,
        urlTypeSelected: insightsLbls.urlTypeSelected

    }


    let outerRules: any = [];
    if (outerRules[0] === undefined) {
        outerRules[0] = [];
    }
    if (outerRules[0].innerRules === undefined) {
        outerRules[0].innerRules = [];
    }

    const [rules, setRules] = useState({
        outer_rules: outerRules,
        url_options: urlOption,
        selectedRuleType: "",
        selectedOptnType: "",
        rulesFlag: false,
        setDomain: ['select'],
        noDomainWarning: false
    });

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            urlType['0'] = insightsLbls.select;
            urlType['URL'] = insightsLbls.url;
            urlType['URL Path'] = insightsLbls.URLPath;
            urlType['URL Parameters'] = insightsLbls.URLParameters;
            urlType['URL Hash'] = insightsLbls.URLHash;

            urlOption['Equals'] = insightsLbls.equals;
            urlOption['Not Equals'] = insightsLbls.notEquals;
            urlOption['Contains'] = insightsLbls.contains;
            urlOption['Not Contains'] = insightsLbls.notContains;
            urlOption['Starts With'] = insightsLbls.startsWith;
            urlOption['Similar to'] = insightsLbls.similarTo;
            urlOption['Ends With'] = insightsLbls.endsWith;

            rulesErrorMsgs['commonMsg'] = insightsLbls.commonMsg;
            rulesErrorMsgs['slashMsg'] = insightsLbls.slashMsg;
            rulesErrorMsgs['angularBracketMsg'] = insightsLbls.angularBracketMsg;
            rulesErrorMsgs['tilEqAngularMsg'] = insightsLbls.tilEqAngularMsg;
            rulesErrorMsgs['tilEqTogetMsg'] = insightsLbls.tilEqTogetMsg;
            rulesErrorMsgs['starAloneMsg'] = insightsLbls.starAloneMsg;
            rulesErrorMsgs['twoStarMsg'] = insightsLbls.twoStarMsg;
            rulesErrorMsgs['noDoubleSlashMsg'] = insightsLbls.noDoubleSlashMsg;
            rulesErrorMsgs['onlyHyphenUnderMsg'] = insightsLbls.onlyHyphenUnderMsg;
            rulesErrorMsgs['noHyphenUnderMsg'] = insightsLbls.noHyphenUnderMsg;
            rulesErrorMsgs['filedRequired'] = insightsLbls.filedRequired;
            rulesErrorMsgs['hashMsg'] = insightsLbls.hashMsg;
            rulesErrorMsgs['paramaterAlphAndSpecialChar'] = insightsLbls.paramaterAlphAndSpecialChar;
            rulesErrorMsgs['parameterStartMsg'] = insightsLbls.parameterStartMsg;
            rulesErrorMsgs['parameterAllowedMsg'] = insightsLbls.parameterAllowedMsg;
            rulesErrorMsgs['urlTypeSelected'] = insightsLbls.urlTypeSelected;
        } catch (error) {
            showBoundary(error)
        }


    }, [])

    useEffect(() => {
        try {
            const index = fetched_details.domain.allAppsData.findIndex((data: any) => data.app_id === fetched_details.apps.appId);

            setDomain((prevState: any) => {
                return {
                    ...prevState,
                    data: fetched_details.domain.allAppsData[index].domains
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.domain])



    useEffect(() => {
        try {
            let caloptn = {
                ...rules
            }
            if (insightsLbls) {
                if (domain.data !== undefined) {
                    if (domain.data.length > 0) {
                        // this.noDomainWarning = false;
                        for (let i = 0; i < domain.data.length; i++) {
                            if (domain?.data[i]['is_default'] === true) {
                                // caloptn.setDomain[0] = domain.data[i]['domain'];
                                caloptn.setDomain[0] = domain.data[i]?.domain;
                                break;
                            } else {
                                // caloptn.setDomain[0] = domain.data[0]['domain'];
                                caloptn.setDomain[0] = domain.data[0]?.domain;
                            }
                        }
                    }
                } else {
                    setRules((prevState: any) => {
                        return {
                            ...prevState,
                            noDomainWarning: true
                        }
                    })
                }

                if (props.setRulesData !== undefined && props.setRulesData.length !== 0) {
                    caloptn.outer_rules = props.setRulesData;
                    caloptn.setDomain[0] = caloptn.outer_rules[0]?.innerRules[0]?.value;
                    for (let i = 0; i < caloptn.outer_rules.length; i++) {
                        caloptn.setDomain[i] = caloptn.outer_rules[i]?.innerRules[0]?.value;
                    }


                    caloptn.rulesFlag = true;
                    setRules(caloptn);
                    props.ruleData(rules.outer_rules);
                    for (let outerKey = 0; outerKey < caloptn.outer_rules.length; outerKey++) {
                        for (let innerKey = 0; innerKey < caloptn.outer_rules[outerKey].innerRules.length; innerKey++) {
                            setTimeout(() => {
                                const elem1 = document.getElementById('urlOptn' + outerKey + "_" + innerKey);
                                if (elem1) {
                                    elem1.removeAttribute('disabled');
                                }

                                const elem2 = document.getElementById('url' + outerKey + "_" + innerKey);
                                if (elem2) {
                                    elem2.removeAttribute('disabled');
                                }
                            }, 1000);
                        }
                    }
                } else {

                    if (rules.outer_rules[0] === undefined) {
                        caloptn.outer_rules[0] = [];
                    }
                    if (rules.outer_rules[0].innerRules === undefined) {
                        caloptn.outer_rules[0].innerRules = [];
                    }

                    if (rules.outer_rules[0].innerRules[0] === undefined) {
                        caloptn.outer_rules[0].innerRules[0] = {};
                        if (domain.data.length) {
                            caloptn.outer_rules[0].innerRules[0] = { type: insightsLbls.URLHostname, value: domain !== undefined ? domain?.data[0]?.domain : null, condition: insightsLbls.equals, logical_condition: "", name: "", order: 1, showValueField: true };
                        } else {
                            caloptn.outer_rules[0].innerRules[0] = { type: insightsLbls.URLHostname, value: null, condition: insightsLbls.equals, logical_condition: "", name: "", order: 1, showValueField: true };
                        }
                    } else {
                        caloptn.outer_rules[0].innerRules[0] = {};
                        caloptn.outer_rules[0].innerRules[0] = { type: insightsLbls.URLHostname, value: domain !== undefined ? domain?.data[0]?.domain : null, condition: insightsLbls.equals, logical_condition: "", name: "", order: 1, showValueField: true };
                    }

                    caloptn.rulesFlag = true;
                    setRules(caloptn);

                    props.ruleData(rules.outer_rules);
                }


                const btnElem = document.getElementById('addDisabled_' + 0);
                if (btnElem) {
                    $('#addDisabled_0 span').css("pointer-events", "auto");
                    $('#addDisabled_0 span i').css("pointer-events", "auto");
                    btnElem.removeAttribute('disabled');
                    props.ruleEvent("openCheck", false);
                    // props.ruleEvent("validation", false);
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [domain, insightsLbls]);



    const addRules = (outerIndex: any) => {
        try {
            let caloptn = {
                ...rules
            }
            if (caloptn.outer_rules[outerIndex].innerRules === undefined) {
                caloptn.outer_rules[outerIndex].innerRules = [];
            }


            if (caloptn.outer_rules[outerIndex].innerRules.length === 0) {
                caloptn.outer_rules[outerIndex].innerRules = rules.outer_rules[outerIndex].innerRules;
                caloptn.outer_rules[outerIndex].innerRules[0] = {};
                caloptn.outer_rules[outerIndex].innerRules[0].type = insightsLbls.URLHostname;
                caloptn.outer_rules[outerIndex].innerRules[0].value = rules.setDomain[0];
                caloptn.outer_rules[outerIndex].innerRules[0].condition = insightsLbls.equals;
                caloptn.outer_rules[outerIndex].innerRules[0].logical_condition = "";
                caloptn.outer_rules[outerIndex].innerRules[0].name = "";
                caloptn.outer_rules[outerIndex].innerRules[0].order = 0;
                caloptn.outer_rules[outerIndex].innerRules[0].showValueField = true;
            }

            caloptn.outer_rules[outerIndex].innerRules[caloptn.outer_rules[outerIndex].innerRules.length] = { "condition": insightsLbls.equals }

            const btnElem = document.getElementById('addDisabled_' + outerIndex);
            if (btnElem) {
                btnElem.setAttribute('disabled', 'true');
                $('#addDisabled_' + outerIndex + ' span').css("pointer-events", "none");
                $('#addDisabled_' + outerIndex + ' span i').css("pointer-events", "none");
                props.ruleEvent("validation", true);
            }


            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });
        } catch (error) {
            showBoundary(error)
        }



    }


    const addOuterRules = () => {

        try {
            let caloptn = {
                ...rules
            }

            caloptn.outer_rules[caloptn.outer_rules.length] = [];
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules = [];
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules = rules.outer_rules[caloptn.outer_rules.length - 1].innerRules;
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0] = {};
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].type = insightsLbls.URLHostname;
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].value = domains[0]?.domain ?? '';
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].condition = insightsLbls.equals;
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].logical_condition = "";
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].name = "";
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].order = 0;
            caloptn.outer_rules[caloptn.outer_rules.length - 1].innerRules[0].showValueField = true;

            const index = caloptn.outer_rules.length - 1
            let cnt = 0;
            for (let i = 0; i < caloptn.outer_rules.length; i++) {
                for (let j = 0; j < caloptn.outer_rules[i].innerRules.length; j++) {
                    if (caloptn.outer_rules[i].innerRules[0].value === domains[0].domain && caloptn.outer_rules[i].innerRules[0].type === insightsLbls.URLHostname) {
                        cnt++;
                    }
                }
            }


            setTimeout(() => {
                const elem = document.getElementById('errorDiv_host_' + index) as HTMLDivElement;
                if (elem) {
                    if (cnt > 1) {
                        elem.style.display = 'block';
                        elem.innerHTML = insightsLbls.errorHostnameAlreadyExists;
                        props.ruleEvent("validation", true);
                    } else {
                        elem.style.display = 'none';
                        elem.innerHTML = '';
                        props.ruleEvent("validation", false);
                    }
                }

                const inputValue = $('#createPageTitle').val();
                if (inputValue === "") {
                    props.ruleEvent("validation", true);
                }

            }, 100);

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });
        } catch (error) {
            showBoundary(error)
        }


    }

    const selectOptn = (event: any, outerKey: number, innerKey: number) => {
        try {
            let caloptn = {
                ...rules
            }
            if (event.target.options.selectedIndex === 1) {
                caloptn.selectedOptnType = 'equal';
            } else if (event.target.options.selectedIndex === 2) {
                caloptn.selectedOptnType = 'not_equals';
            } else if (event.target.options.selectedIndex === 3) {
                caloptn.selectedOptnType = 'contains';
            } else if (event.target.options.selectedIndex === 4) {
                caloptn.selectedOptnType = 'not_contains';
            } else if (event.target.options.selectedIndex === 5) {
                caloptn.selectedOptnType = 'starts_with';
            } else if (event.target.options.selectedIndex === 6) {
                caloptn.selectedOptnType = 'ends_with';
            }

            if (caloptn.outer_rules[outerKey] === undefined) {
                caloptn.outer_rules[outerKey] = [];
            }

            if (caloptn.outer_rules[outerKey].innerRules === undefined) {
                caloptn.outer_rules[outerKey].innerRules = [];
            }
            if (caloptn.outer_rules[outerKey].innerRules[innerKey] === undefined) {
                caloptn.outer_rules[outerKey].innerRules[innerKey] = {};
            }
            caloptn.outer_rules[outerKey].innerRules[innerKey].condition = event.target.options[event.target.options.selectedIndex].text;

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const selectUrl = (event: any, outerKey: number, innerKey: number) => {
        try {
            const elem1 = document.getElementById('urlOptn' + outerKey + "_" + innerKey) as HTMLSelectElement;
            if (elem1) {
                elem1.removeAttribute('disabled');
            }

            const elem2 = document.getElementById('url' + outerKey + "_" + innerKey) as HTMLInputElement;
            if (elem2) {
                elem2.removeAttribute('disabled');
                // $('#url' + outerKey + "_" + innerKey).val("");
            }


            const elem3 = document.getElementById('errorDiv' + outerKey + "_" + innerKey);

        let validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
        let invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);

            if (elem3) {
                if ($('#url' + outerKey + "_" + innerKey).val() === '') {
                    elem3.innerHTML = rulesErrorMsgs.filedRequired;

                    if (validIcon && invalidIcon) {
                        validIcon.style.display = 'none';
                        invalidIcon.style.display = 'block';
                    }
                }
                else {
                    checkValidation(null, $('#url' + outerKey + "_" + innerKey).val(), outerKey, innerKey)
                }
            }

            let caloptn = {
                ...rules
            }
            // URL type is parameter
            if (event.target.options.selectedIndex === 3) {
                caloptn.url_options[4].rule_show = 0;
                caloptn.url_options[5].rule_show = 0;
                caloptn.url_options[6].rule_show = 0;
            } else {
                caloptn.url_options[4].rule_show = 1;
                caloptn.url_options[5].rule_show = 1;
                caloptn.url_options[6].rule_show = 1;
            }

            if (event.target.options.selectedIndex === 1) {
                caloptn.selectedRuleType = 'url';
            } else if (event.target.options.selectedIndex === 2) {
                caloptn.selectedRuleType = 'path';
            } else if (event.target.options.selectedIndex === 3) {
                caloptn.selectedRuleType = 'parameter';
            } else if (event.target.options.selectedIndex === 4) {
                caloptn.selectedRuleType = 'hash';
            }

            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);
            for (let ind = innerKey - 1; ind > 0; ind--) {
                if ($('#urlType' + outerKey + "_" + ind).val() === $('#urlType' + outerKey + "_" + innerKey).val()) {
                    if (elem) {
                        elem.innerHTML = rulesErrorMsgs.urlTypeSelected;
                    }

                    $('#urlOptn' + outerKey + "_" + (ind + 1)).css("pointer-events", "none");
                    $('#url' + outerKey + "_" + (ind + 1)).css("pointer-events", "none");

                    if (validIcon && invalidIcon) {
                        validIcon.style.display = 'none';
                        invalidIcon.style.display = 'block';
                    }

                    break;
                } else {
                    if (elem) {
                        elem.innerHTML = "";
                    }
                    $('#urlOptn' + outerKey + "_" + (ind + 1)).css("pointer-events", "auto");
                    $('#url' + outerKey + "_" + (ind + 1)).css("pointer-events", "auto");

                    if (validIcon && invalidIcon) {
                        validIcon.style.display = 'block';
                        invalidIcon.style.display = 'none';
                    }
                }
            }

            if (rules.outer_rules[outerKey] === undefined) {
                caloptn.outer_rules[outerKey] = [];
            }

            if (rules.outer_rules[outerKey] === undefined) {
                caloptn.outer_rules[outerKey] = [];
            }
            if (rules.outer_rules[outerKey].innerRules[innerKey] === undefined) {
                caloptn.outer_rules[outerKey].innerRules[innerKey] = {};
            }
            caloptn.outer_rules[outerKey].innerRules[innerKey].type = event.target.options[event.target.options.selectedIndex].text;

            // set rules 
            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });

            if (event.target.options.selectedIndex === 0) {
                const btnElem = document.getElementById('addDisabled_' + outerKey);
                if (btnElem) {
                    btnElem.setAttribute('disabled', 'true');
                    $('#addDisabled_' + outerKey + ' span').css("pointer-events", "none");
                    $('#addDisabled_' + outerKey + ' span i').css("pointer-events", "none");
                    props.ruleEvent("validation", true);

                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }



    const checkValidation = (event: any, value: any, outerKey: number, innerKey: number) => {
        try {
            const validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
            const invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);

            getValue(event?.target?.value ?? value, outerKey, innerKey);

            const urlType = $('#urlType' + outerKey + "_" + innerKey).val();
            let url_val = '';
            if (urlType === 'URL') {
                url_val = 'url';
            } else if (urlType === 'URL Path') {
                url_val = 'path';
            } else if (urlType === 'URL Parameters') {
                url_val = 'parameter';
            } else if (urlType === 'URL Hash') {
                url_val = 'hash';
            }

            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);
            if (elem) {
                elem.innerHTML = "";
            }
            let inputVal = event?.target?.value ?? value;

            if (url_val === 'url') {
                let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
                if (regex.test(inputVal)) {
                    if (elem) {
                        elem.innerHTML = "";

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'block';
                            invalidIcon.style.display = 'none';
                        }

                        const existedFlag = checkExistedValue('URL', inputVal);
                        if (existedFlag) {
                            elem.innerHTML = insightsLbls.errorValueAlreadyExsts;
                        }
                    }
                } else {
                    if (elem) {
                        if (inputVal.length === 0) {
                            if (elem) {
                                elem.innerHTML = rulesErrorMsgs.filedRequired;
                            }
                        } else {
                            if (elem) {
                                elem.innerHTML = insightsLbls.errorInvalidURL;
                            }
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                }

            } else if (url_val === 'hash') {
                let regex = /^\#.*(.*)+/;
                if (regex.test(inputVal)) {
                    if (elem) {
                        elem.innerHTML = "";

                        const existedFlag = checkExistedValue('URL Hash', inputVal);
                        if (existedFlag) {
                            elem.innerHTML = insightsLbls.errorValueAlreadyExsts;
                        }


                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'block';
                            invalidIcon.style.display = 'none';
                        }
                    }

                } else {
                    if (elem) {
                        if (inputVal.length === 0) {
                            elem.innerHTML = rulesErrorMsgs.filedRequired;
                        } else {
                            elem.innerHTML = rulesErrorMsgs.hashMsg;
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                }
            } else if (url_val === 'parameter') {
                const regex = /^\?([\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/;
                const regex_extraChars = /^[A-Za-z0-9/*?&=_-]*$/;

                if (regex.test(inputVal)) {
                    if (elem) {
                        elem.innerHTML = "";

                        const existedFlag = checkExistedValue('URL Parameters', inputVal);
                        if (existedFlag) {
                            elem.innerHTML = insightsLbls.errorValueAlreadyExsts;
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'block';
                            invalidIcon.style.display = 'none';
                        }
                    }


                } else {
                    if (elem) {
                        if (inputVal.length === 0) {
                            elem.innerHTML = rulesErrorMsgs.filedRequired;
                        } else {
                            if (regex_extraChars.test(inputVal)) {
                                if (inputVal.includes('&')) {
                                    elem.innerHTML = rulesErrorMsgs.paramaterAlphAndSpecialChar;
                                } else {
                                    elem.innerHTML = rulesErrorMsgs.parameterStartMsg;
                                }
                            } else {
                                elem.innerHTML = rulesErrorMsgs.parameterAllowedMsg;
                            }
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                }
            } else if (url_val === 'path') {
                const regex = /^[A-Za-z0-9/*<>~=_-]*$/;
                const double_slash = '//';
                const starcheck = '***';
                if (regex.test(inputVal)) {
                    if (elem) {
                        elem.innerHTML = "";

                        const existedFlag = checkExistedValue('URL Path', inputVal);
                        if (existedFlag) {
                            elem.innerHTML = insightsLbls.errorValueAlreadyExsts;
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'block';
                            invalidIcon.style.display = 'none';
                        }
                    }
                } else {
                    if (elem) {
                        if (inputVal.length === 0) {
                            elem.innerHTML = rulesErrorMsgs.filedRequired;
                        } else {
                            elem.innerHTML = rulesErrorMsgs.commonMsg;
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                }

                // double slash


                // start with slash
                if (inputVal.charAt(0) !== '/' && inputVal.charAt(0) !== '') {
                    if (elem) {
                        elem.innerHTML = rulesErrorMsgs.slashMsg;

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                } else {
                    if (inputVal.indexOf(double_slash) > -1) {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.noDoubleSlashMsg;
                        }

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    } else {
                        // if (elem) {
                        //     elem.innerHTML = "";
                        // }
                    }
                }

                if (inputVal.indexOf(starcheck) > -1) {
                    if (elem) {
                        elem.innerHTML = rulesErrorMsgs.twoStarMsg;

                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                }
                // else {
                //     if (elem) {
                //         elem.innerHTML = "";
                //     }
                // }


                let splitArray = inputVal.split('/');
                if (splitArray.length) {
                    for (let index = 0; index < splitArray.length; index++) {
                        if (splitArray[index].length >= 1 && splitArray[index].indexOf('~=') > -1 && (splitArray[index].indexOf('<') > -1 || splitArray[index].indexOf('>') > -1)) {
                            if (elem) {
                                if (validIcon && invalidIcon) {
                                    validIcon.style.display = 'none';
                                    invalidIcon.style.display = 'block';
                                }
                                elem.innerHTML = rulesErrorMsgs.tilEqAngularMsgVar;
                                break;

                            }
                        } else {
                            // if (elem) {
                            //     elem.innerHTML = "";
                            // }
                        }
                    }
                }

                validateSingleStar(inputVal, outerKey, innerKey);

                if (inputVal.length === 0) {
                    if (elem) {
                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                        elem.innerHTML = rulesErrorMsgs.filedRequired;
                    }
                }
            }
            setAddDisabled(outerKey);
            // to set url type (Should be at bottom)
            setRules({
                ...rules,
                selectedRuleType: url_val,
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const checkExistedValue = (type: string, inpVal: string) => {
        try {
            let cnt = 0;
            for (let i = 0; i < rules.outer_rules.length; i++) {
                for (let j = 1; j < rules.outer_rules[i].innerRules.length; j++) {
                    if (rules.outer_rules[i].innerRules[j].type === type) {
                        if (inpVal === rules.outer_rules[i].innerRules[j].value) {
                            cnt++;
                        }
                    }
                }
            }

            return cnt > 1 ? true : false
        } catch (error) {
            showBoundary(error)
        }

    }

    const setAddDisabled = (outerKey: number) => {
        try {
            let disabledFlag: boolean = false;
            for (let i = 0; i < document.querySelectorAll('.valErrors').length; i++) {
                const el1 = document.getElementsByClassName('valErrors')[i];
                if (!$(el1).is(':empty')) {
                    disabledFlag = true;
                    break;
                } else {
                    disabledFlag = false;
                }
            }

            const btnElem = document.getElementById('addDisabled_' + outerKey);
            if (disabledFlag) {
                if (btnElem) {
                    btnElem.setAttribute('disabled', 'true');
                    $('#addDisabled_' + outerKey + ' span').css("pointer-events", "none");
                    $('#addDisabled_' + outerKey + ' span i').css("pointer-events", "none");
                    props.ruleEvent("validation", true);

                }
            } else {
                if (btnElem) {
                    btnElem.removeAttribute('disabled');
                    $('#addDisabled_' + outerKey + ' span').css("pointer-events", "auto");
                    $('#addDisabled_' + outerKey + ' span i').css("pointer-events", "auto");
                    props.ruleEvent("validation", false);
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    const validateSingleStar = (inputVal: any, outerKey: number, innerKey: number) => {
        try {
            const validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
            const invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);
            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);
            // if (elem) {
            //     elem.innerHTML = "";
            // }

            let starIndices = [];

            for (let i = 0; i < inputVal.length; i++) {
                if (inputVal[i] === '*' && (inputVal[i + 1] !== '*' && inputVal[i - 1] !== '*')) {
                    starIndices.push(i);
                }
            }

            if (starIndices.length) {
                for (let j = 0; j < starIndices.length; j++) {
                    if (inputVal[starIndices[j] + 1] === '*') {
                        if (elem) {
                            elem.innerHTML = "";
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'block';
                                invalidIcon.style.display = 'none';
                            }
                        }
                    } else {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.starAloneMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                    }


                    if (inputVal[starIndices[j] - 1] === '/' && inputVal[starIndices[j] + 1] === '/') {
                        if (elem) {
                            elem.innerHTML = "";
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'block';
                                invalidIcon.style.display = 'none';
                            }
                        }
                    } else {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.starAloneMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                    }



                    if ((inputVal[starIndices[j] - 1] !== '/' && inputVal[starIndices[j] + 1] === '/') || (inputVal[starIndices[j] - 1] === '/' && inputVal[starIndices[j] + 1] !== '/') || (inputVal[starIndices[j] - 1] !== '/' && inputVal[starIndices[j] + 1] !== '/')) {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.starAloneMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                        break;
                    } else {
                        if (elem) {
                            elem.innerHTML = "";
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'block';
                                invalidIcon.style.display = 'none';
                            }
                        }
                    }

                    if (inputVal[starIndices[j] - 2] === '/' || inputVal[starIndices[j] + 2] === '/') {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.starAloneMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                        break;
                    } else {
                        if (elem) {
                            elem.innerHTML = "";
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'block';
                                invalidIcon.style.display = 'none';
                            }
                        }
                    }


                }
            } else {
                if (elem) {
                    // elem.innerHTML = "";
                }
            }

            validateDoubleStar(inputVal, outerKey, innerKey);
        } catch (error) {
            showBoundary(error)
        }

    }


    const validateDoubleStar = (inputVal: any, outerKey: number, innerKey: number) => {
        try {
            const validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
            const invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);


            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);

            let doubleStar = '**';

            if (inputVal.indexOf(doubleStar) > -1 && inputVal.indexOf(doubleStar) <= inputVal.length - 3) {
                if ((inputVal.indexOf(doubleStar) > -1 && (inputVal.indexOf(doubleStar) !== inputVal.length - 2)) || ((inputVal.indexOf(doubleStar) > -1 && inputVal.length > inputVal.indexOf(doubleStar) + 2))) {
                    if (elem) {
                        elem.innerHTML = rulesErrorMsgs.twoStarMsg;
                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                } else {
                    // if (elem) {
                    //     elem.innerHTML = "";
                    // }
                }
            }
            validateTildEqual(inputVal, outerKey, innerKey);
        } catch (error) {
            showBoundary(error)
        }

    }

    const validateTildEqual = (inputVal: any, outerKey: number, innerKey: number) => {
        try {
            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);
            const validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
            const invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);
            let tilEqIndex = [];
            for (let i = 0; i < inputVal.length; i++) {
                if (inputVal[i] === '~' || inputVal[i] === '=' || (inputVal[i] === '~' && (inputVal[i + 1] === '=')) || (inputVal[i] === '=' && (inputVal[i + 1] === '~'))) {
                    tilEqIndex.push(i);
                }
            }

            if (tilEqIndex.length) {
                for (let j = 0; j < tilEqIndex.length; j++) {
                    if ((inputVal[tilEqIndex[j]] === '~' && inputVal[tilEqIndex[j] + 1] === '=') || inputVal[tilEqIndex[j]] === '=' && inputVal[tilEqIndex[j] - 1] === '~') {
                        // if (elem) {
                        //     elem.innerHTML = "";
                        // }
                    } else {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.tilEqTogetMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                        break;
                    }
                }
            }

            validateAngularBrackets(inputVal, outerKey, innerKey);
        } catch (error) {
            showBoundary(error)
        }

    }

    const validateAngularBrackets = (inputVal: any, outerKey: number, innerKey: number) => {
        try {
            const elem = document.getElementById('errorDiv' + outerKey + "_" + innerKey);
            const validIcon = document.getElementById('validIcon' + outerKey + "_" + innerKey);
            const invalidIcon = document.getElementById('invalidIcon' + outerKey + "_" + innerKey);
            if ((inputVal.indexOf('<') > -1 && inputVal.indexOf('>') > -1)) {
                let splitData = inputVal.split('<');
                let regex = /^[A-Za-z0-9]*$/;

                for (let i = 0; i < splitData.length; i++) {
                    if (splitData[i].length > 1 && splitData[i].indexOf('>') > -1) {
                        let newSplitPrev = splitData[i].split('>')[0];
                        let newSplitNext = splitData[i].split('>')[1];

                        if (newSplitPrev.indexOf('/') > -1) {
                            if (elem) {
                                elem.innerHTML = rulesErrorMsgs.onlyHyphenUnderMsgVar;
                                if (validIcon && invalidIcon) {
                                    validIcon.style.display = 'none';
                                    invalidIcon.style.display = 'block';
                                }
                            }
                            break;
                        } else if ((newSplitNext.indexOf('-') > -1 || newSplitNext.indexOf('_') > -1)) {
                            if (elem) {
                                elem.innerHTML = "";
                                if (validIcon && invalidIcon) {
                                    validIcon.style.display = 'block';
                                    invalidIcon.style.display = 'none';
                                }
                            }
                            break;
                        }

                        if ((newSplitPrev && newSplitPrev.length) && (newSplitPrev.indexOf('-') > -1 || newSplitPrev.indexOf('_') > -1 || regex.test(newSplitPrev))) {
                            if (elem) {
                                elem.innerHTML = "";
                                if (validIcon && invalidIcon) {
                                    validIcon.style.display = 'block';
                                    invalidIcon.style.display = 'none';
                                }
                            }
                        } else {
                            if (elem) {
                                elem.innerHTML = rulesErrorMsgs.onlyHyphenUnderMsgVar;
                                if (validIcon && invalidIcon) {
                                    validIcon.style.display = 'none';
                                    invalidIcon.style.display = 'block';
                                }
                            }
                            break;
                        }
                    }
                }
            } else {
                if ((inputVal.indexOf('<') === -1 && inputVal.indexOf('>') === -1) && (inputVal.indexOf('-') > -1 || inputVal.indexOf('_') > -1)) {
                    if (elem) {
                        elem.innerHTML = rulesErrorMsgs.noHyphenUnderMsg;
                        if (validIcon && invalidIcon) {
                            validIcon.style.display = 'none';
                            invalidIcon.style.display = 'block';
                        }
                    }
                } else {
                    // if (elem) {
                    //     elem.innerHTML = "";
                    // }
                }
            }

            let angularIndex = [];
            for (let i = 0; i < inputVal.length; i++) {
                if (inputVal[i] === '<' || inputVal[i] === '>') {
                    angularIndex.push(i);
                }
            }

            if (angularIndex.length) {
                for (let i = 0; i < angularIndex.length; i++) {
                    if ((inputVal[angularIndex[i]] === '<' && inputVal[angularIndex[i] + 1] === '>') || (inputVal[angularIndex[i]] === '>' && inputVal[angularIndex[i] - 1] === '<') || (inputVal[angularIndex[i]] === '<' && inputVal[angularIndex[i + 1]] !== '>') || (inputVal[angularIndex[i]] === '>' && inputVal[angularIndex[i - 1]] !== '<')) {
                        if (elem) {
                            elem.innerHTML = rulesErrorMsgs.angularBracketMsg;
                            if (validIcon && invalidIcon) {
                                validIcon.style.display = 'none';
                                invalidIcon.style.display = 'block';
                            }
                        }
                    } else {
                        // if (elem) {
                        //     elem.innerHTML = "";
                        // }
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    const addDomain = (selectValue: string, outerKey: number) => {
        try {
            let caloptn = {
                ...rules
            }
            caloptn.setDomain[outerKey] = selectValue;
            caloptn.outer_rules[outerKey].innerRules = rules.outer_rules[outerKey].innerRules;
            caloptn.outer_rules[outerKey].innerRules[0] = {};
            caloptn.outer_rules[outerKey].innerRules[0].type = insightsLbls.URLHostname;
            caloptn.outer_rules[outerKey].innerRules[0].value = selectValue;
            caloptn.outer_rules[outerKey].innerRules[0].condition = insightsLbls.equals;
            caloptn.outer_rules[outerKey].innerRules[0].logical_condition = "";
            caloptn.outer_rules[outerKey].innerRules[0].name = "";
            caloptn.outer_rules[outerKey].innerRules[0].order = 0;
            caloptn.outer_rules[outerKey].innerRules[0].showValueField = true;

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });

            let cnt = 0;
            setTimeout(() => {
                for (let i = 0; i < rules.outer_rules.length; i++) {
                    if (outerKey !== i) {
                        const elem = document.getElementById('domain_' + i) as HTMLSelectElement;
                        if (elem) {
                            if (selectValue === elem.value) {
                                cnt++;
                            }
                        }
                    }
                    // if (outerKey !== i){
                    //     for (let j = 0; j < rules.outer_rules[i].innerRules.length; j++) {
                    //         if (rules.outer_rules[i].innerRules[j].value === selectValue) {
                    //             cnt++;
                    //         }
                    //     }
                    // }
                }

                const selectElement = document.querySelectorAll('.errorDomain');
                for (let i = 0; i < selectElement.length; i++) {
                    const id = selectElement[i].id;
                    const elemVal = document.getElementById(id);
                    if (elemVal) {
                        elemVal.style.display = 'none';
                    }
                }

                const elem = document.getElementById('errorDiv_host_' + outerKey) as HTMLDivElement;
                if (elem) {
                    if (cnt > 0) {
                        elem.style.display = 'block';
                        elem.innerHTML = insightsLbls.errorHostnameAlreadyExists;
                        props.ruleEvent("validation", true);
                    } else {
                        elem.style.display = 'none';
                        elem.innerHTML = '';
                        props.ruleEvent("validation", false);
                    }
                }


            }, 100);
        } catch (error) {
            showBoundary(error)
        }

    }

    const getValue = (inputValue: any, outerKey: number, innerKey: number) => {

        try {
            let caloptn = {
                ...rules
            }
            if (caloptn.outer_rules[outerKey] === undefined) {
                caloptn.outer_rules[outerKey] = [];
            }

            if (caloptn.outer_rules[outerKey].innerRules === undefined) {
                caloptn.outer_rules[outerKey].innerRules = [];
            }
            if (caloptn.outer_rules[outerKey].innerRules[innerKey] === undefined) {
                caloptn.outer_rules[outerKey].innerRules[innerKey] = {};
            }
            caloptn.outer_rules[outerKey].innerRules[innerKey].value = inputValue;

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });
            props.ruleData(rules.outer_rules);
        } catch (error) {
            showBoundary(error)
        }

    }

    const deleteRule = (outerKey: number, innerKey: number) => {
        try {
            let caloptn = {
                ...rules
            }

            if (caloptn.outer_rules.length > 1) {
                if (caloptn.outer_rules[outerKey].innerRules.length > 1) {
                    caloptn.outer_rules[outerKey].innerRules.splice(innerKey, 1);
                } else {
                    caloptn.outer_rules.splice(outerKey, 1);
                }
            }
            else {
                caloptn.outer_rules[outerKey].innerRules.splice(innerKey, 1);
            }
            setAddDisabled(outerKey);


            let disabledFlag: boolean = false;

            for (let outerKey = 0; outerKey < caloptn.outer_rules.length; outerKey++) {
                for (let innerKey = 0; innerKey < caloptn.outer_rules[outerKey].innerRules.length; innerKey++) {
                    if (caloptn.outer_rules[outerKey].innerRules[innerKey].value === undefined) {
                        disabledFlag = true;
                        break;
                    } else {
                        disabledFlag = false;
                    }
                }
            }

            const btnElem = document.getElementById('addDisabled_' + outerKey);
            if (disabledFlag) {
                if (btnElem) {
                    $('#addDisabled_' + outerKey + ' span').css("pointer-events", "none");
                    $('#addDisabled_' + outerKey + ' span i').css("pointer-events", "none");

                    btnElem.setAttribute('disabled', 'true');

                    props.ruleEvent("validation", true);

                }
            } else {
                if (btnElem) {
                    $('#addDisabled_' + outerKey + ' span').css("pointer-events", "auto");
                    $('#addDisabled_' + outerKey + ' span i').css("pointer-events", "auto");
                    btnElem.removeAttribute('disabled');
                    props.ruleEvent("validation", false);
                }
            }

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    caloptn,
                }
            });
            props.ruleData(rules.outer_rules);
        } catch (error) {
            showBoundary(error)
        }


    }

    const deleteOuterRule = (outerKey: number) => {
        try {
            let dummyData = [...rules.outer_rules];
            let domain_dummyData = [...rules.setDomain];

            dummyData.splice(outerKey, 1);
            domain_dummyData.splice(outerKey, 1);

            setRules((prevState: any) => {
                return {
                    ...prevState,
                    outer_rules: dummyData,
                    setDomain: domain_dummyData
                }
            });

            props.ruleEvent("validation", false);

            props.ruleData(dummyData);
        } catch (error) {
            showBoundary(error)
        }

    }

    let tooltipText = insightsLbls.URLHostnameMsg;
    let placement: Placement = "bottom";
    const tooltip = (
        <Tooltip id="tooltip">
            <br></br>
            <div><span className="">{tooltipText}</span></div>
            <br></br>
        </Tooltip>
    );



    const getFilterValue = (value: string) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        switch (value) {

            case 'Select':
                return insightsLbls.select;

            case 'URL Path':
                return insightsLbls.URLPath;

            case 'URL Parameters':
                return insightsLbls.URLParameters;

            case 'URL Hash':
                return insightsLbls.URLHash;

            case 'URL Hostname':
                return insightsLbls.URLHostname;

            case 'Equals':
                return insightsLbls.equals;

            case 'Not Equals':
                return insightsLbls.notEquals;

            case 'Contains':
                return insightsLbls.contains;

            case 'Not Contains':
                return insightsLbls.notContains;

            case 'Starts With':
                return insightsLbls.startsWith;

            case 'Similar to':
                return insightsLbls.similarTo;

            case 'Ends With':
                return insightsLbls.endsWith;


            default:
                return value;
        }


    }
    return (
        <div>

            {!rules.noDomainWarning

                ? (<>
                    <div className="main-rules-div">

                        {rules.outer_rules.map((outerRules: any, outer_key: number) => {

                            if (rules.rulesFlag) {
                                return (
                                    <div key={outer_key}>
                                        {outer_key > 0 ?
                                            (<div className="tags-and-text">{insightsLbls.or}</div>) : null
                                        }

                                        <div className="tags-outer-div" id="tag_outer">
                                            <div className="tags-inner-div">
                                                {rules.outer_rules.length > 1 && (
                                                    <div className="deleteOuterRule-div">
                                                        <span className="deleteOuterRule-span cursorPointer" onClick={() => deleteOuterRule(outer_key)}>
                                                            <i className="fa fa-trash fa-lg deleteIcon cursorPointer deleteOuterRule-icon" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                )}

                                                <div>
                                                    <div className="alignCenter">

                                                        <div className="ruleInputs-div">
                                                            <div className="width30Perct tag-inputBox">{insightsLbls.URLHostname}<i aria-hidden="true"
                                                                className="fa fa-caret-down collapseIcon floatRight"></i></div>
                                                            <div className="width30Perct tag-inputBox">{insightsLbls.equals}<i className="fa fa-caret-down floatRight" aria-hidden="true"></i>
                                                            </div>

                                                            <select className='width30Perct tag-inputBox domainDropdown' id={"domain_" + outer_key}
                                                                onChange={(event) => addDomain(event.target.value, outer_key)}
                                                                value={rules.setDomain[outer_key]}>
                                                                {domain.data.map((domains: any, key: number) => {
                                                                    return (
                                                                        <option key={key}> {domains.domain} </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div className="ruleActions-div">
                                                            <div className="tag-inputBox infoIcon">
                                                                <OverlayTrigger placement={placement} overlay={tooltip}>
                                                                    <i className="fa fa-info-circle" aria-hidden="true"> </i>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div id={"errorDiv_host_" + outer_key} className="errorMsg valErrors errorDomain"></div>



                                                </div>
                                                {outerRules.innerRules.map((innerRule: any, inner_key: number) => {
                                                    if (inner_key > 0) {
                                                        return (
                                                            <div className="tag-rule-div" key={inner_key}>
                                                                {inner_key > -1 ?
                                                                    (<div className="tags-and-text"> {insightsLbls.and}</div>) : null
                                                                }

                                                                <div className="alignCenter">
                                                                    <div className="ruleUserInput-div">
                                                                        <select className='selectUrl form-control  width31Perct selectType-dropdown'
                                                                            onChange={(event) => selectUrl(event, outer_key, inner_key)}
                                                                            id={"urlType" + outer_key + "_" + inner_key}
                                                                            value={innerRule.type !== undefined ? innerRule.type : 0}>
                                                                            {urlType.map((data: any, key: number) => {
                                                                                return (
                                                                                    <option key={key}> {getFilterValue(data.value)} </option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <select className='selectUrl form-control width31Perct'
                                                                            id={"urlOptn" + outer_key + "_" + inner_key} disabled
                                                                            onChange={(event) => selectOptn(event, outer_key, inner_key)}
                                                                            value={innerRule.condition !== undefined ? innerRule.condition : 0}>
                                                                            {rules.url_options.map((data: any, key: number) => {
                                                                                if (data.rule_show === 1) {
                                                                                    return (<option key={key} > {getFilterValue(data.value)} </option>)
                                                                                }
                                                                            })}
                                                                        </select>
                                                                        <input type="text" autoComplete="off" className="urlInput form-control font14 width31Perct"
                                                                            placeholder=" " disabled onInput={(event) => checkValidation(event, null, outer_key, inner_key)}
                                                                            id={"url" + outer_key + "_" + inner_key}
                                                                            value={innerRule.value !== undefined ? innerRule.value : ''} />
                                                                    </div>
                                                                    <div className="ruleUserAction-div">

                                                                        <i className="fa fa-check iconDisplay validIcon" aria-hidden="true" id={"validIcon" + outer_key + "_" + inner_key}></i>
                                                                        <i className="fa fa-times iconDisplay invalidIcon" aria-hidden="true" id={"invalidIcon" + outer_key + "_" + inner_key}></i>

                                                                        {/* <div className="tag-inputBox infoIcon ">
                                                                            <OverlayTrigger placement={"" + `${placement}`} overlay={(
                                                                                <Tooltip>
                                                                                   <div id={"errorDiv" + outer_key + "_" + inner_key} className="errorMsg valErrors"></div>
                                                                                </Tooltip>
                                                                            )}>
                                                                                <i className="fa fa-times" aria-hidden="true"> </i>
                                                                            </OverlayTrigger>
                                                                        </div> */}

                                                                        <i className="fa fa-trash fa-lg deleteIcon cursorPointer" aria-hidden="true"
                                                                            onClick={() => deleteRule(outer_key, inner_key)}></i>
                                                                    </div>


                                                                </div>
                                                                <div id={"errorDiv" + outer_key + "_" + inner_key} className="errorMsg valErrors"></div>
                                                            </div>
                                                        )
                                                    }

                                                })}
                                                <button className="create-rules-container modal-addMore-btn"
                                                    onClick={() => addRules(outer_key)} id={"addDisabled_" + outer_key}>
                                                    <span className="displayFlex margin-0-auto alignCenter">
                                                        <i className="fa fa-plus floatLeft  margin4" aria-hidden="true"></i>
                                                        {insightsLbls.add}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                        })}
                    </div>
                    <div className="create-rules-container modal-addMoreGroup-btn">

                        <button className="create-rules-container modal-addMore-btn"
                            onClick={() => addOuterRules()}>
                            <span className="displayFlex margin-0-auto alignCenter">
                                <i className="fa fa-plus floatLeft  margin4" aria-hidden="true"></i>
                                {insightsLbls.addGroup}
                            </span>
                        </button>

                    </div>
                </>
                )
                : null}


        </div>
    );
}

export default React.memo(withRouter(Rules));