import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from "react";


import { useDispatch, useSelector } from "react-redux";
import { SegmentsFilterState, LanguageState } from "../../store/Reducers";
import { setSegmentsFilter } from "../../store/Actions";
import { getCall } from "utils/ApiCallActions";
import { useLocation } from "react-router-dom";

import $ from 'jquery';


import CommonModal from 'components/CommonModal/CommonModal';
import Loader from 'components/Loader/Loader';



import "./Segmentation.scss";
import { RetryApi } from "utils/GlobalConstants";
import { useErrorBoundary } from 'react-error-boundary';



import { InsightLabels } from "labels";


interface CurrentState {
    segmentsFilter: SegmentsFilterState;
    languageData: LanguageState

}
const Segments = (props: any) => {
    const location = useLocation();
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);



    const dispatch = useDispatch();
    const segmentsMount = useRef(true);
    let fetched_details = useSelector((state: CurrentState) => state);

    const [segmentsModal, setSegmentsModal] = useState({
        isOpen: false,
        okDisabled: false,
        selectedTitle: '',
        selectedId: 0
    });

    // let segmentsFilterLocalStorageObject: any = localStorage.getItem('segmentsFilterState');
    // segmentsFilterLocalStorageObject = JSON.parse(segmentsFilterLocalStorageObject);

    const [segmentsList, setSegmentsList] = useState({
        segmentsListFetched: false,
        apiError: false,
        segmentsList: [],
        selectedSegment: (props.component !== 'customDashboard' ? "Segment Filter" : (props.defaultValue.segmentTitle !== '' ? props.defaultValue.segmentTitle : 'Segment Filter')),
        searchQuery: "",
        finalId: 0,
        firstLoad: true,
        clearFlag: false,

    });

    const openSegmentsModal = () => {
        try {
            segmentsMount.current = true;
            setSegmentsModal((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: true,
                    segmentsListFetched: true,
                }
            })

            setSegmentsList((prevState: any) => {
                return {
                    ...prevState,
                    searchQuery: ''
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    const closeSegmentsModal = (val: any) => {
        try {
            setSegmentsModal((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: val,
                    segmentsListFetched: false,

                }
            })
            segmentsMount.current = false;
        } catch (error) {
            showBoundary(error)
        }

    }

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            setSegmentsList((prevState: any) => {
                return {
                    ...prevState,
                    segmentsListFetched: false,
                    firstLoad: false,
                    searchQuery: e.target.value
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };


    const getSegmentsList = (searchText: string, apiErrorFlag?: string) => {
        try {
            const paramsData = {
                search_text: searchText
            };
            let segmentGroupsList: any[] = [];

            let urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });
            getCall(paramsData, "SEGMENTS_FILTER", apiErrorFlag ?? '').then((res: any) => {
                if (res.result === 'success') {


                    for (let i = 0; i < res.data.totalCount; i++) {
                        if (res.data.segments[i].type === "segment_groups") {
                            segmentGroupsList.push(res.data.segments[i]);
                        }
                    }

                    setSegmentsList((prevState: any) => {
                        return {
                            ...prevState,
                            segmentsList: segmentGroupsList,
                            segmentsListFetched: true,
                            apiError: false,
                        }
                    })


                    let flag = 0;

                    if (urlData['segment']) {
                        for (let i = 0; i < res.data.totalCount; i++) {
                            if (res.data.segments[i].type === "segment_groups") {
                                if (urlData['segment'] == res.data.segments[i].id) {
                                    flag = 1;
                                    setSegmentsList((prevState: any) => {
                                        return {
                                            ...prevState,
                                            selectedSegment: res.data.segments[i].key_name,
                                            finalId: res.data.segments[i].id,
                                        }
                                    })

                                    setSegmentsModal((prevState: any) => {
                                        return {
                                            ...prevState,
                                            selectedTitle: res.data.segments[i].key_name,
                                            selectedId: res.data.segments[i].id
                                        }
                                    })
                                    if (searchText == '' || searchText == null) {
                                        dispatch(setSegmentsFilter({
                                            segmentsFilter: res.data.segments[i].id,
                                            segmentTitle: res.data.segments[i].key_name,
                                            listFetched: true

                                        }))
                                    }


                                    break;
                                }
                            }
                        }
                    } else {
                        for (let i = 0; i < res.data.totalCount; i++) {
                            if (res.data.segments[i].type === "segment_groups") {
                                if (fetched_details.segmentsFilter.segmentsFilter == res.data.segments[i].id) {
                                    flag = 1;
                                    setSegmentsList((prevState: any) => {
                                        return {
                                            ...prevState,
                                            selectedSegment: res.data.segments[i].key_name,
                                            finalId: res.data.segments[i].id,
                                        }
                                    })

                                    setSegmentsModal((prevState: any) => {
                                        return {
                                            ...prevState,
                                            selectedTitle: res.data.segments[i].key_name,
                                            selectedId: res.data.segments[i].id
                                        }
                                    })
                                    dispatch(setSegmentsFilter({
                                        segmentsFilter: res.data.segments[i].id,
                                        segmentTitle: res.data.segments[i].key_name,
                                        listFetched: true

                                    }))
                                    break;
                                }
                            }
                        }
                    }


                    if (flag === 0) {
                        if (searchText == '' || searchText == null) {
                            setSegmentsList((prevState: any) => {
                                return {
                                    ...prevState,
                                    selectedSegment: 'Segment Filter',
                                    finalId: 0
                                }
                            })

                            setSegmentsModal((prevState: any) => {
                                return {
                                    ...prevState,
                                    selectedTitle: 'Segment Filter',
                                    selectedId: 0
                                }
                            })
                            dispatch(setSegmentsFilter({
                                segmentsFilter: 0,
                                segmentTitle: 'Segment Filter',
                                listFetched: true
                            }))
                        }

                    }
                } else if (res.result === 'retry') {
                    setTimeout(() => {
                        getSegmentsList(segmentsList.searchQuery, 'retry')
                    }, RetryApi.TIMEOUT)
                } else if (res.result === "error") {
                    setSegmentsList((prevState: any) => {
                        return {
                            ...prevState,
                            segmentsListFetched: true,
                            apiError: true,
                        }
                    })

                    dispatch(setSegmentsFilter({
                        segmentsFilter: segmentsModal.selectedId,
                        segmentTitle: segmentsModal.selectedTitle,
                        listFetched: true

                    }))
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        let urlData: any = {};
        const query = new URLSearchParams(location.search);
        query.forEach((v, k) => {
            urlData[k] = v;
        });
    }, [location.pathname])

    useEffect(() => {

        if (insightsLbls['segementFilter']) {
            setSegmentsModal((prevState: any) => {
                return {
                    ...prevState,
                    selectedTitle: insightsLbls['segementFilter']
                }
            })
            if (props.component !== 'customDashboard') {
                setSegmentsList((prevState: any) => {
                    return {
                        ...prevState,
                        selectedSegment: insightsLbls['segementFilter'],
                    }
                })
            } else {
                if (props.defaultValue.segmentTitle === "") {
                    setSegmentsList((prevState: any) => {
                        return {
                            ...prevState,
                            selectedSegment: insightsLbls['segementFilter'],
                        }
                    })
                }
            }
        }
    }, [insightsLbls])


    useEffect(() => {
        getSegmentsList(segmentsList.searchQuery)
    }, [segmentsList.searchQuery])


    useEffect(() => {
        try {
            if (segmentsModal.isOpen === true && segmentsMount.current === true) {
                if (props.component !== 'customDashboard') {
                    $("#segmentListDiv" + fetched_details.segmentsFilter.segmentsFilter).addClass("segmentsListDivActive")
                    if (fetched_details.segmentsFilter.segmentsFilter !== 0) {
                        setSegmentsList((prevState: any) => {
                            return {
                                ...prevState,
                                finalId: fetched_details.segmentsFilter.segmentsFilter,
                            }
                        })
                    }
                    else {
                        setSegmentsList((prevState: any) => {
                            return {
                                ...prevState,
                                finalId: 0,
                                selectedSegment: insightsLbls['segmentFilter'],
                            }
                        })
                    }
                } else {

                    if (props.defaultValue.segmentsFilter.segmentsFilter !== 0) {
                        setSegmentsList((prevState: any) => {
                            return {
                                ...prevState,
                                finalId: props.defaultValue.segmentsFilter.segmentsFilter,
                                selectedSegment: props.defaultValue.segmentsFilter.segmentTitle
                            }
                        })
                    }
                    else {
                        setSegmentsList((prevState: any) => {
                            return {
                                ...prevState,
                                finalId: 0,
                                selectedSegment: insightsLbls['segmentFilter'],
                            }
                        })

                        // setTimeout(() => {
                        //     $("#segmentListDiv" + 0).addClass("segmentsListDivActive");
                        // }, 100);
                    }
                }

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [segmentsModal.isOpen, segmentsList.segmentsListFetched])



    useEffect(() => {
        try {
            let segmentTitle_value: string = '';
            let segmentId_value: number = 0;
            if (props.component !== 'customDashboard') {
                if (fetched_details?.segmentsFilter?.segmentTitle === '') {
                    segmentTitle_value = insightsLbls && insightsLbls['segmentFilter'];
                    segmentId_value = 0;
                } else {
                    segmentTitle_value = fetched_details?.segmentsFilter?.segmentTitle;
                    segmentId_value = fetched_details?.segmentsFilter?.segmentsFilter;

                }
            } else if (props?.defaultValue?.segmentTitle !== '') {
                segmentTitle_value = props?.defaultValue?.segmentTitle;
                segmentId_value = props?.defaultValue?.segmentsFilter;
            } else {
                segmentTitle_value = insightsLbls && insightsLbls['segmentFilter'];
                segmentId_value = 0;
            }

            setSegmentsModal((prevState: any) => {
                return {
                    ...prevState,
                    selectedTitle: segmentTitle_value,
                    selectedId: segmentId_value
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.segmentsFilter?.segmentTitle, props?.defaultValue?.segmentTitle])


    const handleSelect = (dataId: number, segmentName: string) => {
        try {
            $(".segmentsModalDiv .segmentsListDiv").removeClass("segmentsListDivActive")
            $("#segmentListDiv" + segmentsList.finalId).removeClass("segmentsListDivActive")
            $("#segmentListDiv" + dataId).addClass("segmentsListDivActive")
            setSegmentsList((prevState: any) => {
                return {
                    ...prevState,
                    finalId: dataId,
                    selectedSegment: segmentName.toUpperCase(),
                    clearFlag: false
                }
            })
            setSegmentsModal((prevState: any) => {
                return {
                    ...prevState,
                    okDisabled: false,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const clearSegments = () => {
        setSegmentsList((prevState: any) => {
            return {
                ...prevState,
                clearFlag: true
            }
        })

        // setSegmentsModal((prevState: any) => {
        //     return {
        //         ...prevState,
        //         selectedId: 0,
        //         selectedTitle: insightsLbls['segmentFilter']
        //     }
        // })
        $(".segmentsModalDiv .segmentsListDiv").removeClass("segmentsListDivActive")
    }

    const applySegments = () => {
        try {
            segmentsMount.current = false;
            if (props.component !== 'customDashboard') {
                setSegmentsModal((prevState: any) => {
                    return {
                        ...prevState,
                        isOpen: false,
                        selectedTitle: !segmentsList.clearFlag ? segmentsList.selectedSegment : insightsLbls && insightsLbls['segmentFilter']
                    }
                })
                if (!segmentsList.clearFlag) {
                    localStorage.setItem("segmentsFilterState", JSON.stringify({ segmentsFilter: segmentsList.finalId, segmentTitle: segmentsList.selectedSegment }));
                }
                else {
                    localStorage.setItem("segmentsFilterState", JSON.stringify({ segmentsFilter: 0, segmentTitle: insightsLbls && insightsLbls['segmentFilter'] }));
                }
                dispatch(setSegmentsFilter({
                    segmentsFilter: !segmentsList.clearFlag ? segmentsList.finalId : 0,
                    segmentTitle: !segmentsList.clearFlag ? segmentsList.selectedSegment : insightsLbls && insightsLbls['segmentFilter'],
                    listFetched: true
                }))
            } else {
                setSegmentsModal((prevState: any) => {
                    return {
                        ...prevState,
                        isOpen: false,
                        selectedTitle: !segmentsList.clearFlag ? segmentsList.selectedSegment : insightsLbls && insightsLbls['segmentFilter'],
                        selectedId: !segmentsList.clearFlag ? segmentsList.finalId : 0,
                    }
                })

                props.segmentsFilterValue("applySegmentFilter", {
                    segmentsFilter: !segmentsList.clearFlag ? segmentsList.finalId : 0,
                    segmentTitle: !segmentsList.clearFlag ? segmentsList.selectedSegment : insightsLbls && insightsLbls['segmentFilter'],
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getSegmentFilterTitle = (value: string) => {
        try {
            if (value) {
                if (value === 'Segment Filter') {
                    return insightsLbls.none;
                } else if (value.length) {
                    return value;
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <React.Fragment>

            {props.component === ''}
            <p className="segmentFilterTitle">{insightsLbls.segmentFilterTitle}</p>
            <div className={"segmentsFilterDiv" + (props.component === "tooltips" ? (" segmentsFilterDivTooltips") : (""))} onClick={openSegmentsModal}>
                {segmentsList.segmentsListFetched
                    ? <span className="segmentsTitle">{getSegmentFilterTitle(segmentsModal.selectedTitle)}</span>
                    : <span>
                        <img src={require(`${process.env.REACT_APP_LOADER}`)} className="width50px" />
                    </span>}
                <i aria-hidden="true" className={"dropdownIcon " + (segmentsModal.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
            </div>

            <CommonModal
                modalState={segmentsModal.isOpen}
                dispatchModalState={closeSegmentsModal}
                dispatchModalOk={applySegments}
                dispatchModalCancel={clearSegments}
                modalTitle={insightsLbls && insightsLbls['selectSegments']}
                okBtn={insightsLbls && insightsLbls['apply']}
                cancelBtn={insightsLbls && insightsLbls['clearAll']}
                size={"md"}
                okBtnState={segmentsModal.okDisabled}>
                <>
                    <div className="segmentsSearchDiv">
                        <p className="pull-right search-field-wrapper">
                            <input
                                type="text"
                                autoComplete="off"
                                className="searchIcon form-control round-input"
                                onChange={(event) => onSearchText(event)}
                                placeholder={insightsLbls && insightsLbls['searchSegment']}
                                disabled={segmentsList.segmentsList.length === 0 && segmentsList.firstLoad === true}
                            />
                        </p>
                    </div>

                    <hr className="margin0"></hr>

                    <div className="segmentsModalDiv">
                        {
                            segmentsList.segmentsListFetched === true ?
                                (
                                    segmentsList.apiError !== true ?
                                        (
                                            segmentsList.segmentsList.length !== 0 ?
                                                (
                                                    segmentsList.segmentsList.map((data: any, index: any) => {
                                                        return (

                                                            <div key={index} id={"segmentListDiv" + data.id}
                                                                className={(segmentsModal?.selectedTitle?.toUpperCase() === data?.key_name?.toUpperCase())
                                                                    ? ("segmentsListDiv segmentsListDivActive col-md-6 col-lg-6 col-6 col-sm-6")
                                                                    : ("segmentsListDiv col-md-6 col-lg-6 col-6 col-sm-6")}
                                                                onClick={() => handleSelect(data.id, data.key_name)}>
                                                                <div className="segmentsListTitle">
                                                                    <div className="segTitle-overflow">{data?.key_name?.toUpperCase()}</div>
                                                                </div>
                                                            </div>

                                                        );
                                                    })
                                                )
                                                :
                                                (
                                                    <div className="messageDiv">
                                                        <div className="noDataText-font">
                                                            {insightsLbls.noRecordFound}.
                                                        </div>
                                                    </div>
                                                )
                                        )
                                        :
                                        (
                                            <div className="messageDiv">
                                                <div className="noDataText-font">
                                                    {insightsLbls.serviceNotAvailable}
                                                </div>
                                            </div>
                                        )
                                )
                                :
                                (
                                    <div className="messageDiv">
                                        <Loader height={110} width={110}></Loader>
                                    </div>
                                )
                        }
                    </div>
                </>
            </CommonModal>
        </React.Fragment>

    )
}
export default React.memo(Segments);