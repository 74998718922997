import React, { useEffect } from "react";


import * as d3 from "d3";
import $ from "jquery";
import { WorldMapServiceData } from './WorldMapServiceData';
import * as topojson from 'topojson';

import { useErrorBoundary } from 'react-error-boundary';

import "./WorldMap.scss";




const WorldMap = (props) => {
    const { showBoundary } = useErrorBoundary();
    let bucketData = props.WorldMapData;
    setTimeout(() => {

        try {
            $('#worldmap').empty();

            let countryTag = document.getElementById('country');
            if (countryTag) {
                countryTag.innerHTML = '';
            }

            const colorScale = d3.scaleQuantile()
                .domain(props.boudaryValues)
                .range(props.colors);

            // globals used in graph
            let mapdata = {};
            // let palette = ['#7cbcd0', '#fde08f', '#fb8c59', '#ee6262', '#aa89c0', '#a7d595', '#f57b07', '#249de4', '#538427', '#ff5050'];
            let width = 700, height = 700;
            let minDocCount = 0, quantiles = {};
            // projection definitions
            let projection = d3.geoMercator()
                .scale((width + 1) / 2 / Math.PI)
                .translate([width / 2, height / 2])
                .precision(.1);
            let path = d3.geoPath().projection(projection);
            d3.geoGraticule();



            // SVG related definitions
            let svg = d3.select('#worldmap').append('svg')
                .attr('width', width).attr('height', '500px')
                .style("background-color", "white")
                // .style("position", "relative")
                // .style("left", "50%")
                // .style("transform", "translateX(-50%)")

               

                .call(d3.zoom().scaleExtent([0, 2.5]).on("zoom", function (event) {
                    // console.log(event)
                    // let translate = event.transform.x;
                    // if (event.scale === 1) {
                    //     translate = [0, 0]
                    // }
                    svg.attr("transform", "translate(" + event.transform.x +"," + event.transform.y +")" + " scale(" +  event.transform.k + ")")
          
                }))
                .append('g');

      

            let mockdata = {
                "took": 492,
                "timed_out": false,
                "_shards": {
                    "total": 5,
                    "successful": 3,
                    "failed": 0
                },
                "hits": {
                    "total": 30111166,
                    "max_score": 0,
                    "hits": []
                },
                "aggregations": {
                    "world_map": {
                        "doc_count_error_upper_bound": 0,
                        "sum_other_doc_count": 0,
                        "buckets": bucketData
                    }
                }
            }

            mapdata = mockdata;
            draw(mockdata)

            function draw(data) {
                for (let i = 0; i < WorldMapServiceData.GEOMETRIES.length; i++) {
                    WorldMapServiceData.GEOMETRIES[i].properties.count = 0;
                }
                let world = {
                    "type": "Topology",
                    "objects": {
                        "subunits": {
                            "type": "GeometryCollection",
                            "geometries": {}
                        }
                    },
                    "arcs": WorldMapServiceData.ARCS,
                    "transform": {
                        "scale": [0.036003600360036005, 0.016927109510951093],
                        "translate": [-180, -85.609038]
                    }
                }
                world.objects.subunits.geometries = WorldMapServiceData.GEOMETRIES;
                processWorldD(world, data);


            }
            function processWorldD(world, data) {
                // var self = this;
                for (var idx = 0; idx < data.aggregations.world_map.buckets.length; idx++) {
                    let cCode = data.aggregations.world_map.buckets[idx].countryCode.toUpperCase();
                    let count = 0;
                    count = data.aggregations.world_map.buckets[idx].count;
                    for (let wdx = 0; wdx < world.objects.subunits.geometries.length; wdx++) {
                        let cName = world.objects.subunits.geometries[wdx].id.toUpperCase();
                        if (cCode === cName) {
                            world.objects.subunits.geometries[wdx].properties.count = count;
                        }
                    }
                }
                let subunits = topojson.feature(world, world.objects.subunits);
                subunits.features = subunits.features.filter(function (d) { return d.id !== "ATA"; });
                // if(len == 0) {
                //     for(let i=0; i<subunits.features.length; i++) {
                //         subunits.features[i].properties.count = 0;
                //     }
                // }
                minDocCount = d3.min(subunits.features, function (d) {
                    return d.properties.count;
                });
                let count = subunits.features.map(function (d) { return d.properties.count; });
                count = count.filter(function (d) { return d; }).sort(d3.ascending);
                quantiles['0.95'] = d3.quantile(count, '0.95');
                let countries = svg.selectAll('path.subunit')
                    .data(subunits.features).enter();
                countries.insert('path', '.graticule')
                    .attr('class', function (d) { return 'subunit ca' + d.id; })
                    .style('fill', function (d, i) {
                        if (d.properties.count == 0 || d.properties.count == undefined) {
                            return '#EEEEEE';
                        } else {
                            return colorScale(d.properties.count);
                        }
                    })
                    .attr('d', path)
                    .on('mouseover', mouseoverLegend).on('mouseout', mouseoutLegend)
                    // .on('mouseover', function (d) {
                    //     tooltip.html("hjgj");

                    //     tooltip.style('display', 'block');
                    // })
                    .on('mousemove', function (event) {
                        const pageX = event['pageX'];
                        const pageY = event['pageY'];
                        return tooltip.style('top', (pageY + 30) + 'px').style('left', (pageX - 50) + 'px');
                    })

                    // .on('mouseout', function () { return tooltip.style('display', 'none'); })
                    .on('click', coutryclicked);

                countries.append('svg:text')
                    .attr('class', function (d) { return 'subunit-label la' + d.id + d.properties.name.replace(/[ \.#']+/g, ''); })
                    // .attr('transform', function(d) { return 'translate('+ path.centroid(d) +')'; })
                    .attr('transform', function (d) { return 'translate(' + (width - (5 * d.properties.name.length)) + ',' + (15) + ')'; })
                    .attr('dy', '.35em')
                    .attr('filter', 'url(#gray-background)')
                    .append('svg:tspan')
                    .attr('x', 0)
                    .attr('dy', 5)
                    .text(function (d) { return d.properties.name; })
                    .append('svg:tspan')
                    .attr('x', 0)
                    .attr('dy', 20)
                    .text(function (d) { return d.properties.count ? d.properties.count : ''; });
            }

            const tooltip = d3.select('body')
                .append('div')
                .style('position', 'absolute')
                .style('z-index', '10')
                .style('display', 'none')
                .style('color', '#222')
                .style('padding', '8px')
                .style('background-color', 'rgba(255, 255, 255, 0.75)')
                .style('border-radius', '4px')
                .style('border', '1px solid #666')
                .style('font', '14px sans-serif')
                .style('z-index', '9991')

                .attr('class', 'tooltipVisibility')
                .text('tooltip');


            function mouseoverLegend(datum) {
                let country_value = 0;
                // d3.selectAll('.subunit-label.la' + datum.id + datum.properties.name.replace(/[ \.#']+/g, ''))
                //     .style('display', 'inline-block');
                d3.selectAll('.subunit.ca' + datum.id)
                    .style('opacity', '0.5');
                if (datum.srcElement.__data__.properties.count == undefined) {
                    country_value = 0;
                } else {
                    country_value = datum.srcElement.__data__.properties.count;
                }
                tooltip.html(datum.srcElement.__data__.properties.name + ': ' + `<b>${country_value}</b> `);

                tooltip.style('display', 'block');
            }

            function mouseoutLegend(datum) {
                // d3.selectAll('.subunit-label.la' + datum.id + datum.properties.name.replace(/[ \.#']+/g, ''))
                //     .style('display', 'none');
                d3.selectAll('.subunit.ca' + datum.id)
                    .style('fill', function () {
                        if (datum.properties.count == 0 || datum.properties.count == undefined) {
                            return '#EEEEEE';
                        } else {
                            return colorScale(datum.properties.count);
                        }
                    })
                    .style('opacity', '1');
                tooltip.style('display', 'none');
            }

            function coutryclicked() {
                // filter event for this country should be applied here
            }
        } catch (error) {
            showBoundary(error)
        }


        
    }, 100);

  

    return (
        <>
            <div className="paddingTop45 minHeight450">
                <div id="worldmap" className="margin-0-auto" style={{ width: 700 }}></div>

            </div>
        </>

    );
}
export default React.memo(WorldMap);
