import axiosInstance from '../network/apis/interceptor';
import {  ApiRelativePaths, RetryApi } from './GlobalConstants';
import { CommonUtils } from './CommonUtils';
import fileDownload from 'js-file-download';
import axios from 'axios';



let ApiPaths: any = ApiRelativePaths;

export const getCall = async (params: any, url_path: string, apiErrorFlag: string, optionalData?: any) => {

    let data:any = {}
    let path = ApiPaths[url_path];
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);

    let urlData;

    if (optionalData !== '' && optionalData !== undefined) {
        urlData = generatedURL + optionalData;
    } else {
        urlData = generatedURL;
    }

    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // await axiosInstance.get(urlData, { cancelToken: source.token })
    
    await axiosInstance.get(urlData)
    .then(res => {
        data = {
            "result": "success",
            "data": res.data.data,
        };
    })
    .catch(err => {
        if (apiErrorFlag === '') {
            data = {
                "result": "retry",
                "data": err,
            };
        } else {
            data = {
                "result": "error",
                "data": err,
            };
        }
    });

    return data;
}

export const getCallExportExcel = async (params: any, url_path: string, type: boolean) => {


    // let data = {}
    let path = ApiPaths[url_path];
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    const filename = `${new Date()}_file.xlsx`;

    if (type) {
        generatedURL += '&export_to=excel&export_viewer=ms_excel_online';
    } else {
        generatedURL += '&export_to=excel';
    }
    if (type) {
        await axiosInstance.get(generatedURL)
            .then(res => {
                window.open(res.data.viewerUrl, "_blank", 'noopener');
            })
            .catch(err => {
            });
    } else {
        await axiosInstance.get(generatedURL, {
            responseType: 'blob',
          })
            .then(res => {
                fileDownload(res.data, filename);
            })
            .catch(err => {
            });
    }
}

export const getCallExportExcelModal = async (params: any, url_path: string, type: boolean) => {
    // let data = {}
    let path = ApiPaths[url_path];
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    const filename = `${new Date()}_file.xlsx`;

    if (type) {
        generatedURL += '&export_to=excel&export_viewer=ms_excel_online';
    } else {
        generatedURL += '&export_to=excel';
    }
    if (type) {
        await axiosInstance.get(generatedURL)
            .then(res => {
                window.open(res.data.viewerUrl, "_blank", 'noopener');
            })
            .catch(err => {
            });
    } else {
        await axiosInstance.get(generatedURL, {
            responseType: 'blob',
          })
            .then(res => {
                fileDownload(res.data, filename);
            })
            .catch(err => {
            });
    }
}

export const getCallExportExcelEmail = async (params: any, url_path: string) => {
    let data = {}
    let path = ApiPaths[url_path];
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);

    await axiosInstance.get(generatedURL)
    .then(res => {
        data = {
            "result": "success",
            "data": res.data.data,
        };
    })
    .catch(err => {
        data = {
            "result": "error",
            "data": err,
        };
    });
    return data;
}

export const postCall = async (data: any, url_path: string, param? :any, optionalData?: any) => {
    let params = param?? {};
    let path = ApiPaths[url_path];
    let host = process.env.REACT_APP_ANALYTICS_HOST;
    let paramObj = {};
    let generatedURL: string;
    let result = {}

    if (url_path === 'LOGOUT'){
        generatedURL = host + '/' + path;
    }
    else {
        generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    }

    if (optionalData !== '' && optionalData !== undefined) {
        generatedURL = generatedURL + optionalData;
    } 

    await axiosInstance.post(generatedURL, data)
        .then(res => {
            result = {
                "result": "success",
                "data": res.data.data,
            };
        }, error => { 
            result = {
                "result": "error",
                "error": error.response.data.error,
            };
        })
        .catch(err => { 
            result = {
                "result": "catch_error",
                "error": err?.response?.data?.error,
            };
        })

    return result;
}

export const putCall = async (data: any, url_path: string, optionalData?: any) => {
    let params = {};
    let path = ApiPaths[url_path];
    // let host = process.env.REACT_APP_ANALYTICS_HOST;
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    let result = {}

    let urlData;
    if (optionalData !== '') {
        urlData = generatedURL + optionalData;
    } else {
        urlData = generatedURL;
    }

    await axiosInstance.put(urlData, data)
        .then(res => {
            result = {
                "result": "success",
                "data": res.data,
            };
        }, error => {
            result = {
                "result": "error",
                "error": error.response.data.error,
            };
        })
        .catch(err => {
            result = {
                "result": "catch_error",
                "error": err.response.data.error,
            };
        })

        return result;
}

export const patchCall = async (params: any, data: any, url_path: string, optionalData?: any) => {
    // let params = {};
    let path = ApiPaths[url_path];
    // let host = process.env.REACT_APP_ANALYTICS_HOST;
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    let result = {}

    let urlData;
    if (optionalData !== '') {
        urlData = generatedURL + optionalData;
    } else {
        urlData = generatedURL;
    }
    await axiosInstance.patch(urlData, data)
        .then(res => {
            result = {
                "result": "success",
                "data": res.data,
            };
        }, error => {
            result = {
                "result": "error",
                "error": error.response.data.error,
            };
        })
        .catch(err => {
            result = {
                "result": "cacth_error",
                "error": err.response.data.error,
            };
        })

        return result;
}

export const deleteCall = async (params : any, url_path: string, optionalData?: any) => {


    let path = ApiPaths[url_path];
    // let host = process.env.REACT_APP_ANALYTICS_HOST;
    let paramObj = {};
    let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
    let result = {}



    let urlData;
    if (optionalData !== '') {
        urlData = generatedURL + optionalData;
    } else {
        urlData = generatedURL;
    }



    await axiosInstance.delete(urlData)
        .then(res => {
            result = {
                "result": "success",
                "data": res.data,
            };
        }, error => {
            result = {
                "result": "error",
                "error": error.response.data.error,
            };
        })
        .catch(err => {
            result = {
                "result": "cacth_error",
                "error": err.response.data.error,
            };
        })

    return result;
}

export const getAssimaURLCall = async ( url_path: string, type: string) => {

    if (url_path){


        let data = {};

            let path = ApiRelativePaths.UPDATED_IMAGE_URL;
            let host = process.env.REACT_APP_SERVER_API_ENDPOINT;
            let generatedURL = host + path + "?organization_id=" + localStorage.getItem("ORG_ID") + "&url=" + url_path;

            await axios.get(generatedURL)
                .then(res => {
                    data = {
                        "result": "success",
                        "data": res.data.data,
                        "type": type
                    };
                })
                .catch(err => {
                    data = {
                        "result": "error",
                        "data": err,
                    };
                });
        
        

        return data;
    }
    
}


