import React, { useRef, useState, useEffect } from "react";


import $ from "jquery";
import { ChartColors } from '../../utils/GlobalConstants';
import * as d3 from "d3";
import BubbleChart from 'assets/js/react-bubble-chart-d3';
import './Bubblechart.scss';
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';

const Bubblechart = (props) => {
	const { showBoundary } = useErrorBoundary();
	let fetched_details = useSelector((state) => state);


	const [insightsLbls, setInsightsLbls] = useState({})
	const componentRef = useRef();

	const [chart, setChart] = useState({
		data: [],
		dataFetched: false,
		totalCount: 0
	})

	useEffect(() => {
		if (Object.keys(fetched_details.languageData.languageData).length) {
			setInsightsLbls(fetched_details.languageData.languageData);
		}
		
	}, [fetched_details, props])

	useEffect(() => {
		try {
			if (props.data.length) {
				let chartOptions = [];
				let cnt = 0;

				for (let itr0 = 0; itr0 < props.data.length; itr0++) {
					if (props.data[itr0]['count'] > 0) {
						let dummy_chartOptions = {};
						dummy_chartOptions["label"] = props.data[itr0]['legendTitle'];
						dummy_chartOptions["value"] = props.data[itr0]['count'];
						dummy_chartOptions["color"] = props.data[itr0]['color'];
						chartOptions.push(dummy_chartOptions);
					}

					cnt = cnt + props.data[itr0]['count'];
				}
				setChart((prevState) => {
					return {
						...prevState,
						data: chartOptions,
						dataFetched: true,
						totalCount: cnt
					}
				})
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [props])


	useEffect(() => {
		try {
			setTimeout(() => {
				d3.selectAll("title").remove();
				d3.selectAll(".tooltipVisibility").remove();
				const tooltip = d3.select('body')
					.append('div')
					.style('position', 'absolute')
					.style('z-index', '10')
					.style('display', 'none')
					.style('color', '#222')
					.style('padding', '8px')
					.style('background-color', 'rgba(255, 255, 255, 0.75)')
					.style('border-radius', '4px')
					.style('border', '1px solid #666')
					.style('font', '14px sans-serif')

					.attr('class', 'tooltipVisibility')
					.text('tooltip');



				let bubbleChart = d3.selectAll(".label-text");
				bubbleChart.selectAll('tspan')
					.attr('dy', 20)


				let bubbleChartNode = d3.selectAll(".bubble-chart");
				bubbleChartNode.selectAll('.node')
					.on('mouseover', function (d) {
						tooltip.html(`<center>${d.target.__data__.label}<br><br><b><span style='font-size:14px'>Count: ${d.target.__data__.value}</span></b></center>`);
						tooltip.style('display', 'block');
					})
					.on('mousemove', function (event) {
						const pageX = event['pageX'];
						const pageY = event['pageY'];
						return tooltip.style('top', (pageY + 5) + 'px').style('left', (pageX) + 'px');
					})
					.on('mouseout', function () { return tooltip.style('display', 'none'); });
			}, 2000)
		} catch (error) {
			showBoundary(error)
		}
	}, [props.data])


	return (
		<>
			{(chart.dataFetched) && (
				<>
					{(chart.totalCount == 0) ? (
						<div className="width300 positionRelative height350">
							<div className="noDataText-font textCenter-absolute">
								{insightsLbls.noRecordFound}
							</div>
						</div>

					) : (
						<div className="bubbleChart" id="bubbleChart">
							<div className="bubbleInfo-div">
								<p className="font18 marginBottom-0 textCenter">{props.component === 'dashboard' ? insightsLbls.totalUserCount : insightsLbls.totalCount}: <b>{chart.totalCount}</b> </p>
								<p className="font14 marginBottom-0 textCenter">{insightsLbls.selectedAppsTitle}: <b>{props.selectedAppCount}</b></p>
							</div>
							<BubbleChart
								ref={componentRef}
								graph={{
									zoom: 1,
									offsetX: 0.05,
									offsetY: 0.01,
								}}
								width={300}
								height={400}
								padding={0} // optional value, number that set the padding between bubbles
								showLegend={false} // optional value, pass false to disable the legend.
								legendPercentage={20}
								showValue={true}
								charsBeforeSplit={50}

								// number that represent the % of with that legend going to use.

								valueFont={{
									family: 'Arial',
									size: 14,
									color: '#fff',
									weight: 'bold',
									lineHeight: 2
								}}
								labelFont={{
									family: 'Arial',
									size: 13,
									color: '#fff',
									weight: 'bold',
									display: 'none'
								}}
								//Custom bubble/legend click functions such as searching using the label, redirecting to other page

								data={chart.data}

							/>
						</div>
					)}

				</>
			)}
		</>

	);
}
export default React.memo(Bubblechart);
