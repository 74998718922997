// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useCallback, useRef } from "react";


import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CommonComponentState, LanguageState } from "../../store/Reducers";
import { UserEngagementState } from "../../store/Reducers/GuideInsights/userEngagement";
import * as userEngagementActions from "../../store/Actions/index";
import * as ActionTypes from "../../store/Actions/ActionTypes";


import moment from "moment";
import { ChartColors } from '../../utils/GlobalConstants';
import { CommonUtils } from "utils/CommonUtils";


import Linechart from "components/Chart/Linechart";
import Loader from "components/Loader/Loader";


import './UserEngagementLazyLoad.scss';
import { useErrorBoundary } from 'react-error-boundary';


import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";



type PathParamsType = {
    // param1: string,
}

interface CurrentState {
    // apps: AppsState,
    // domain: DomainState,
    // dates: DatesState,
    userEngagements: UserEngagementState,
    // eventSource: EventSourceState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}


const UserEngagementLazyLoad = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const dispatch = useDispatch();


    let fetched_details = useSelector((state: CurrentState) => state);
    let fetched_user_details = useSelector((state: CurrentState) => state.userEngagements.userEngagementUserChartData);


    const previousDate_flag = 0;

    let dummyData: any = [];
    let datesNew: any = [];


    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    };

    let linechart_height = 300;

    const [userEngagmentUserChart, setUserEngagmentUserChart] = useState({
        dateDiff: 0,
        dateFlag: false,
        dates: [{ fdate: [], tdate: [] }],
        _showLoader: false,
        refreshbtn: false,
        refreshFlag: false,
        errorcode: 0,
    })

    const [chartStats, setChartStats] = useState({
        chartData: [],
        chartFlag: false,
        userChart: [],
        userChartOptions: [],
        firstLoad: true,
        initial_data: [{
            startTimestamp: '',
            counts: {
                engagedUsers: 0,
                newUsers: 0,
                returningUsers: 0,
                totalVisitors: 0
            }
        }],
        datesNew: [],
        chartLazyLoadFlag: false,
        overlayFlag: false,
    });


    const [tileData, setTileData] = useState({
        tile1: [],
        tile2: [],
        tile3: [],
        tile4: []
    });

    const [dateState, setDateState] = useState(false);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if(isInitialMount.current) {
        setDateState(true);
        setUserEngagmentUserChart((prevState: any) => {
            return {
                ...prevState,
                dateDiff: 0,
                dateFlag: false,
                dates: [{ fdate: [], tdate: [] }],
                _showLoader: false,
                refreshbtn: false,
                refreshFlag: false,
                errorcode: 0,
            }
        })

        // setTimeout(() => {
        setChartStats((prevState: any) => {
            return {
                ...prevState,
                chartData: [],
                chartFlag: false,
                userChart: [],
                userChartOptions: [],
                firstLoad: true,
                initial_data: [{
                    startTimestamp: '',
                    counts: {
                        engagedUsers: 0,
                        newUsers: 0,
                        returningUsers: 0,
                        totalVisitors: 0
                    }
                }],
                datesNew: [],
                chartLazyLoadFlag: false,
                overlayFlag: false,
            };
        });

        setTileData((prevState: any) => {
            return {
                ...prevState,
                tile1: [],
                tile2: [],
                tile3: [],
                tile4: []
            }
        })
        //to set refresh icon
        props.dispatchOverlay(false);

        if (fetched_details?.commonComponentData?.datesData.dateSelection === 8
            || fetched_details?.commonComponentData?.datesData.dateSelection === 9
            || fetched_details?.commonComponentData?.datesData.dateSelection === 12
            || fetched_details?.commonComponentData?.datesData.dateSelection === 11
            || fetched_details?.commonComponentData?.datesData.dateSelection === 10
            || fetched_details?.commonComponentData?.datesData.dateSelection === 16
            || fetched_details?.commonComponentData?.datesData.dateSelection === 13
            || fetched_details?.commonComponentData?.datesData.dateSelection === 14
            || fetched_details?.commonComponentData?.datesData.dateSelection === 15) {

            if (fetched_details?.commonComponentData?.datesData.dateSelection === 15) {
                const a = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate);
                const b = moment(fetched_details?.commonComponentData?.datesData.fdate);

                const daysLen = Math.ceil(a.diff(b, 'days', true)) + 1;
                if (daysLen < 7) {
                    getUserEngagementUserData(moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).subtract(6, 'd').format('YYYY-MM-D'), fetched_details?.commonComponentData?.datesData.realTime_tdate, true,'',2);
                } else {
                    getDates_array();
                }
            } else {
                getDates_array();
            }
            props.dispatchOverlay(true);

        }
        else {
           
                getUserEngagementUserData(fetched_details?.commonComponentData?.datesData.fdate, fetched_details?.commonComponentData?.datesData.realTime_tdate, true,'',3);
                setUserEngagmentUserChart((prevState: any) => {
                    return {
                        ...prevState,
                        dateDiff: 0
                    }
                })
    
                setChartStats((prevState: any) => {
                    return {
                        ...prevState,
                        firstLoad: true,
                    }
                })
                props.dispatchOverlay(true);
                
           
            
        }
        isInitialMount.current = false;
        setTimeout(() => {
            isInitialMount.current = true;
        },1000)
    }
    }, [fetched_details?.commonComponentData?.datesData.dateSelection,
    props.refreshFlag,
    fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
    fetched_details?.commonComponentData?.datesData.excludeFlag,
    fetched_details?.commonComponentData?.datesData.fdate,
    fetched_details?.commonComponentData?.appsData,
    fetched_details?.commonComponentData?.datesData.realTime_tdate,
    fetched_details?.commonComponentData?.envFilter])

    const getDates_array = () => {
        try {
            //to set refresh icon
            props.dispatchOverlay(false);
            const a = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate);
            const b = moment(fetched_details?.commonComponentData?.datesData.fdate);

            const daysLen = Math.ceil(a.diff(b, 'days', true)) + 1;
            let dates_new: any = [];


            if (daysLen < 7) {
                dates_new[0] = [];
                dates_new[0].fdate = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).subtract(6, 'd').format('YYYY-MM-D');
                dates_new[0].tdate = fetched_details?.commonComponentData?.datesData.realTime_tdate;

            } else {

                let cnt = 0;
                for (let i = 0; i < Math.floor((daysLen) / 7); i++) {
                    dates_new[i] = [];
                    const fdate = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).subtract(cnt + 6, 'd').format('YYYY-MM-D');
                    dates_new[i].fdate = fdate;
                    dates_new[i].tdate = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).subtract(cnt, 'd').format('YYYY-MM-D');
                    cnt = cnt + 7;
                }

                if ((daysLen % 7) >= 3) {
                    // if extra days are greater than or equal to 3 then add new array

                    let lastFdate = dates_new[dates_new.length - 1].fdate;

                    let extraDays: any = [];
                    extraDays[0] = []

                    extraDays[0].fdate = fetched_details?.commonComponentData?.datesData.fdate;
                    extraDays[0].tdate = moment(lastFdate).subtract(1, 'd').format('YYYY-MM-D');
                    dates_new = dates_new.concat(extraDays);
                } else {
                    // if extra days are less than 3 then add it to last value of fdate
                    dates_new[dates_new.length - 1].fdate = fetched_details?.commonComponentData?.datesData.fdate
                }
            }

            setUserEngagmentUserChart((prevState: any) => {
                return {
                    ...prevState,
                    dates: dates_new
                }
            })

            setChartStats((prevState: any) => {
                return {
                    ...prevState,
                    firstLoad: true
                }
            })
            getUserEngagementUserData(dates_new[0].fdate, dates_new[0].tdate, true,'',4);
        } catch (error) {
            showBoundary(error)
        }

    };


    const getUserEngagementUserData = (fDate: any, tDate: any, firstLoad: boolean, apiErrorFlag?: string, optn?: number) => {
        try {
            if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
                setChartStats((prevState: any) => {
                    return {
                        ...prevState,
                        chartLazyLoadFlag: false,
                        overlayFlag: true

                    }
                })

                if (firstLoad) {
                    setUserEngagmentUserChart((prevState: any) => {
                        return {
                            ...prevState,
                            refreshFlag: true
                        }
                    })
                }

                if (fDate !== 'null' && tDate !== 'null') {
                    const params = {
                        'end_date': tDate + '_23:59:59',
                        'start_date': fDate + '_00:00:00',
                        'app_id': fetched_details?.commonComponentData.appsData.appId,
                        'time_zone': timeZone,
                        "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
                        'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
                        'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
                        "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
                    };

                    userEngagementActions.getUserEngagementData(
                        params,
                        "GUIDEME_USER_ENGAGEMENT",
                        ActionTypes.SET_USER_ENGAGEMENT_USER_CHART_DATA,
                        apiErrorFlag ?? '',
                        firstLoad,
                        fetched_details?.commonComponentData?.datesData.dateSelection
                    ).then((res: any) => dispatch(res));
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    useEffect(() => {
        try {
            if (fetched_user_details.data !== undefined && fetched_user_details.result === 'success') {
                if (fetched_user_details.firstTimeload) {
                    setTileData((prevState: any) => {
                        return {
                            ...prevState,
                            tile1: [],
                            tile2: [],
                            tile3: [],
                            tile4: [],
                        }
                    })
                }
    
                setUserEngagmentUserChart((prevState: any) => {
                    return {
                        ...prevState,
                        errorcode: 0,
                    }
                })
    
                if (userEngagmentUserChart.dates.length > 1) {
                    const datesArray = userEngagmentUserChart.dates;
                    if (userEngagmentUserChart.dates) {
    
                        // first shift array and then call api
                        datesArray.shift();
    
                        if (datesArray.length === 1) {
                            setTimeout(() => {
                                setChartStats((prevState: any) => {
                                    return {
                                        ...prevState,
                                        overlayFlag: false
                                    }
                                })
                            }, 1000);
    
                            //to set refresh icon
                            props.dispatchOverlay(true);
                        }
    
                        setChartStats((prevState: any) => {
                            return {
                                ...prevState,
                                firstLoad: false
                            }
                        })
    
                        getUserEngagementUserData(datesArray[0].fdate, datesArray[0].tdate, false,'',0);
    
                        setUserEngagmentUserChart((prevState: any) => {
                            return {
                                ...prevState,
                                dates: datesArray
                            }
                        })
                    }
                }
    
                if (chartStats.firstLoad) {
                    setTileData((prevState: any) => {
                        return {
                            ...prevState,
                            tile1: [],
                            tile2: [],
                            tile3: [],
                            tile4: [],
                        }
                    })
    
                    let lenCount = 0;
                    var len = moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).diff(moment(fetched_details?.commonComponentData?.datesData.fdate), 'days', true);
    
    
                    if (len < 7) {
                        lenCount = 7;
                    } else {
                        lenCount = len + 1;
                    }
    
                    var cnt = 1;
                    for (let i = 0; i < lenCount; i++) {
                        if (i === 0) {
                            datesNew.push(moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).format("YYYY-MM-D"));
                        }
                        else {
                            datesNew.push(moment(fetched_details?.commonComponentData?.datesData.realTime_tdate).subtract(cnt, 'days').format("YYYY-MM-D"));
                            cnt++;
                        }
                    }
    
                    datesNew.reverse();
    
                    // If exclude weekend is true
                    if (fetched_details?.commonComponentData?.datesData.excludeFlag) {
                        for (let i = 0; i < datesNew.length; i++) {
                            // 1 is Monday, 7 is Sunday
                            if (moment(datesNew[i]).isoWeekday() === 6) {
                                datesNew.splice(i, 1);
                            }
    
                            if (moment(datesNew[i]).isoWeekday() === 7) {
                                datesNew.splice(i, 1);
                            }
                        }
                    }
    
                    let chart_data: any = [];
                    for (let i = 0; i < datesNew.length; i++) {
                        chart_data[i] = [];
                        let datesSplit = datesNew[i].split('-')
                        chart_data[i].startTimestamp = datesSplit[2] + ' ' + (moment.monthsShort(datesSplit[1] - 1)) + ' ' + datesSplit[0];
                        chart_data[i].counts = {};
                        chart_data[i].counts.engagedUsers = 0;
                        chart_data[i].counts.newUsers = 0;
                        chart_data[i].counts.returningUsers = 0;
                        chart_data[i].counts.totalVisitors = 0;
                    }
    
    
                    const graphData = fetched_user_details.data.details;
                    for (let i = 0; i < chart_data.length; i++) {
                        for (let j = 0; j < graphData.length; j++) {
                            if (chart_data && graphData) {
                                if (chart_data[i].startTimestamp === graphData[j].startTimestamp) {
                                    chart_data[i].counts.engagedUsers = graphData[j].counts['engagedUsers'];
                                    chart_data[i].counts.newUsers = graphData[j].counts['newUsers'];
                                    chart_data[i].counts.returningUsers = graphData[j].counts['returningUsers'];
                                    chart_data[i].counts.totalVisitors = graphData[j].counts['totalVisitors']
                                }
                            }
                        }
                    }
    
                    setChartStats((prevState: any) => {
                        return {
                            ...prevState,
                            initial_data: chart_data,
                            datesNew: datesNew,
                            overlayFlag: false
                        }
                    })
    
                    renderChart(chart_data, fetched_user_details.data.totalCounts);
                } else {
                    setChartStats((prevState: any) => {
                        return {
                            ...prevState,
                            initial_data: compareData(),
                        }
                    })
    
                    renderChart(compareData(), fetched_user_details.data.totalCounts);
                }
    
                if (fetched_user_details.data) {
                    const totalCountKeys = Object.keys(fetched_user_details.data.totalCounts);
    
                    if (fetched_user_details.optionSelection === +(localStorage.getItem('SELECTED_OPTN')!)) {
                        if (fetched_user_details.data.totalCounts) {
                            for (const tkey of totalCountKeys) {
                                if (tkey === 'totalVisitorsID') {
                                    for (let data of fetched_user_details.data.totalCounts[tkey]) {
                                        let dummyTile1Data: any = tileData.tile1;
                                        if (data && (!dummyTile1Data.includes(data))) {
                                            dummyTile1Data.push(data)
                                        }
                                        setTileData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                tile1: dummyTile1Data,
                                            }
                                        })
                                    }
                                } else if (tkey === 'engagedUsersID') {
                                    for (let data of fetched_user_details.data.totalCounts[tkey]) {
                                        let dummyTile2Data: any = tileData.tile2;
                                        if (data && (!dummyTile2Data.includes(data))) {
                                            dummyTile2Data.push(data)
                                        }
                                        setTileData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                tile2: dummyTile2Data,
                                            }
                                        })
                                    }
                                } else if (tkey === 'newUsersID') {
                                    for (let data of fetched_user_details.data.totalCounts[tkey]) {
                                        let dummyTile3Data: any = tileData.tile3;
                                        if (data && (!dummyTile3Data.includes(data))) {
                                            dummyTile3Data.push(data)
                                        }
                                        setTileData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                tile3: dummyTile3Data,
                                            }
                                        })
                                    }
                                } else if (tkey === 'returningUsersID') {
                                    for (let data of fetched_user_details.data.totalCounts[tkey]) {
                                        let dummyTile4Data: any = tileData.tile4;
                                        if (data && (!dummyTile4Data.includes(data))) {
                                            dummyTile4Data.push(data)
                                        }
                                        setTileData((prevState: any) => {
                                            return {
                                                ...prevState,
                                                tile4: dummyTile4Data,
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    }
    
                } else if (fetched_user_details.result === 'retry') {
                    getUserEngagementUserData(fetched_details?.commonComponentData?.datesData.fdate, fetched_details?.commonComponentData?.datesData.realTime_tdate, true, 'retry',1);
                } else if (fetched_user_details.result === 'error') {
                    setUserEngagmentUserChart((prevState: any) => {
                        return {
                            ...prevState,
                            errorcode: 1,
                        }
                    })
    
                }
            }
        } catch (error) {
            showBoundary(error)
        }
      

    }, [fetched_user_details.data]);


    useEffect(() => {
        try {
            dummyData = [];
            // dummyData[1] = { color: ChartColors.brightred, count: tileData.tile3.length, key: insightsLbls && insightsLbls['newUser'] };
            dummyData[0] = { color: ChartColors.lightseagreen, count: tileData.tile1.length, key: insightsLbls && insightsLbls['totalUsers'] };
            dummyData[1] = { color: ChartColors.slateblue, count: tileData.tile2.length, key: insightsLbls && insightsLbls['engagedUsers'] };

            setChartStats((prevState: any) => {
                return {
                    ...prevState,
                    chartData: dummyData,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [tileData])

    const compareData = () => {
        try {
            const graphData = fetched_user_details?.data?.details;
            for (let i = 0; i < chartStats.datesNew.length; i++) {
                for (let j = 0; j < graphData.length; j++) {
                    if (chartStats.initial_data && graphData) {
                        if (chartStats.initial_data[i].startTimestamp === graphData[j].startTimestamp) {
                            chartStats.initial_data[i].counts.engagedUsers = graphData[j].counts['engagedUsers'];
                            chartStats.initial_data[i].counts.newUsers = graphData[j].counts['newUsers'];
                            chartStats.initial_data[i].counts.returningUsers = graphData[j].counts['returningUsers'];
                            chartStats.initial_data[i].counts.totalVisitors = graphData[j].counts['totalVisitors'];
                        }
                    }
                }
            }
            return chartStats.initial_data;
        } catch (error) {
            showBoundary(error)
        }

    };



    const renderChart = useCallback((data: any, totalCounts) => {
        try {
            let userChartData: any = [];

            userChartData = CommonUtils.dateSeparator(data);

            let userChartOptions: any = [];

            let engagedUsers: any = [];
            let newUsers: any = [];
            let totalVisitors: any = [];

            for (let i = 0; i < userChartData.length; i++) {
                engagedUsers[i] = {};
                engagedUsers[i].y = userChartData[i].counts.engagedUsers;
                engagedUsers[i].x = userChartData[i].startTimestamp;
                engagedUsers[i].startTimestamp = userChartData[i].startTimestamp;
                engagedUsers[i].date = userChartData[i].date;

                // newUsers[i] = {};
                // newUsers[i].y = userChartData[i].counts.newUsers;
                // newUsers[i].x = userChartData[i].startTimestamp;
                // newUsers[i].startTimestamp = userChartData[i].startTimestamp;
                // newUsers[i].date = userChartData[i].date;

                totalVisitors[i] = {};
                totalVisitors[i].y = userChartData[i].counts.totalVisitors;
                totalVisitors[i].x = userChartData[i].startTimestamp;
                totalVisitors[i].startTimestamp = userChartData[i].startTimestamp;
                totalVisitors[i].date = userChartData[i].date;
            }


            userChartOptions = [
                // {
                //     key: insightsLbls && insightsLbls['newUser'],
                //     values: newUsers,
                //     color: ChartColors.brightred
                // },
                // {
                //     key: 'Returning Users',
                //     values: returningUsers,
                //     color: ChartColors.brightred
                // }, 
                {
                    key: insightsLbls && insightsLbls['totalUsers'],
                    values: totalVisitors,
                    color: ChartColors.lightseagreen
                }, {
                    key: insightsLbls && insightsLbls['engagedUsers'],
                    values: engagedUsers,
                    color: ChartColors.slateblue
                }
            ];


            setChartStats((prevState: any) => {
                return {
                    ...prevState,
                    userChart: userChartData,
                    userChartOptions: userChartOptions,
                    chartFlag: true,
                    chartLazyLoadFlag: true,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [chartStats.userChart, insightsLbls])




    return (
        <section className="mainSection">
            <div className="col-sm-12 paddingBottom30 displayFlex" id="tilesData">
                {(chartStats.chartData.length !== 0 && chartStats.chartFlag && insightsLbls['newUser']) ? (
                    userEngagmentUserChart.errorcode === 0 ? (
                        userEngagmentUserChart.refreshFlag ? (
                            <div className="width100 displayBlock">
                                <div className="width100 row marginLR-0">
                                    {chartStats.chartData.map((data: any, index: number) => {
                                        return (
                                            <div className="col-md-6 col-sm-6 col-lg-6 userStats-card marginBottom-50" key={index}>
                                                <div className="heigth105 tiles-border" style={{ borderBottomColor: data.color }}>
                                                    <div style={{ fontSize: '28px', color: data.color }}>{data.count}</div>
                                                    <div style={{ fontSize: '16px' }} >{data.key}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="width100 displayBlock">
                                    <Linechart
                                        chartData={chartStats.userChart}
                                        chartOptions={chartStats.userChartOptions}
                                        value_type={"count"}
                                        height={linechart_height}
                                        margin={lineChartMargin}
                                        refId={'user_lazyload'}


                                    ></Linechart>
                                    {chartStats.overlayFlag
                                        ? (<div className="overlay-div"></div>)
                                        : null}


                                    {chartStats.overlayFlag
                                        ? (
                                            <div className="loadingDiv">
                                                <Loader></Loader>
                                            </div>
                                        )
                                        : null
                                    }
                                </div>
                            </div>
                        ) : (<div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>)
                    ) : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>
                )}

            </div>
        </section>
    )
}

export default React.memo(UserEngagementLazyLoad);