import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";



// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { useNavigate, useLocation } from 'react-router-dom';
import { CommonComponentState, EventSourceState, VisibilityState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { GuideAutomationState } from "store/Reducers/AutomationInsights/guideAutomation";
import guideAutomationReducer from "store/Reducers/AutomationInsights/guideAutomation";
import * as guideAutomationActions from "store/Actions/index";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


import "./GuideAutomation.scss";

import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';
import Linechart from "components/Chart/nvd3Charts/LineChart";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import { InsightLabels } from "labels";
import SectionFilters from "components/Layouts/SectionFilters";
import { useErrorBoundary } from 'react-error-boundary';


interface CurrentState {
    commonComponentData: CommonComponentState,
    guideAutomation: GuideAutomationState,
    eventSource: EventSourceState,
    sectionVisibility: VisibilityState,
    languageData: LanguageState
}

const GuideAutomation: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();



    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    let linechart_height = 400;
    let guide_linechart_height = 320;

    let westpacFlag = 'false';

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;


    const GuideAutomationLineChartMount = useRef(false);
    const GuideAutomationSummaryMount = useRef(false);

    const [parameter, setParameter] = useState({
        parameter: "",
        title: ""
    });
    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [guideAutomationLineChart, setGuideAutomationLineChart] = useState({
        loadLineChartData: [],
        loadLineChartOptions: [],
        guideAutomationLineChartDataFetched: false,
        lineChart: {},
        lineChartFetched: false,
    });

    const [guideAutomationSummaryTable, setGuideAutomationSummaryTable] = useState({
        guideAutomationSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'play_time',
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        runType: 'All',
        status: 'All',
        resultStatus: 'All'
    });

    let dynamicRunTypeSortValue = [
        { key: 'All', value: 'All' },
        { key: 'Scheduled', value: 'Scheduled' },
        { key: 'Manual', value: 'Manual' }
    ];

    let dynamicStatusSortValue = [
        { key: 'All', value: 'All' },
        { key: 'Pass', value: 'Pass' },
        { key: 'Fail', value: 'Fail' }
    ];

    let dynamicResultStatusSortValue = [
        { key: 'All', value: 'All' },
        { key: 'Complete', value: 'Complete' },
        { key: 'Interrupted', value: 'Interrupted' }
    ];

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])


    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['docTitleGuideAutomation'];
        }
    }, [insightsLbls])

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(5, 0, 0, true, false);

        // getGuideAutomationLineChartData();
        // getGuideAutomationSummaryTableData();
    }, []);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideAutomationLineChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuideAutomation,
            'exclude_weekends': false,
        };

        guideAutomationActions.getGuideAutomationData(
            params,
            "GUIDE_AUTOMATION_LINE_CHART",
            ActionTypes.SET_GUIDE_AUTOMATION_LINE_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getGuideAutomationSummaryTableData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "page_size": guideAutomationSummaryTable.limit,
            "sort_by": guideAutomationSummaryTable.sortKey,
            "order": guideAutomationSummaryTable.sortValue,
            "page_index": guideAutomationSummaryTable.page,
            "search_text": guideAutomationSummaryTable.searchQuery,
            "result": guideAutomationSummaryTable.resultStatus.toLowerCase(),
            "result_status": guideAutomationSummaryTable.status.toLowerCase(),
            "run_type": guideAutomationSummaryTable.runType.toLowerCase(),
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuideAutomation,
            'exclude_weekends': false,
        };

        guideAutomationActions.getGuideAutomationData(
            params,
            "GUIDE_AUTOMATION_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            guideAutomationSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideAutomationLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAutomationLineChartDataFetched: false,
                        lineChart: {},
                        lineChartFetched: false,
                    };
                });
                getGuideAutomationLineChartData();

            } else if (optn === 1) {
                setGuideAutomationSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAutomationSummaryTableDataFetched: false,
                        searchQuery: '',
                        sortKey: 'play_time',
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        runType: 'All',
                        status: 'All',
                        resultStatus: 'All'
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            parameter: data.playCode,
                            title: data.title,
                        };
                    });
                    break;

                case "sortColumn":

                    setGuideAutomationSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":

                    setGuideAutomationSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideAutomationSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":
                    setGuideAutomationSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changeDynamicEntries":
                    switch (data.title) {
                        case 'Run Type':
                            setGuideAutomationSummaryTable((prevState: any) => {
                                return {
                                    ...prevState,
                                    runType: data.data
                                }
                            })
                            break;

                        case 'Status':
                            setGuideAutomationSummaryTable((prevState: any) => {
                                return {
                                    ...prevState,
                                    status: data.data
                                }
                            })
                            break;

                        case 'Result':
                            setGuideAutomationSummaryTable((prevState: any) => {
                                return {
                                    ...prevState,
                                    resultStatus: data.data
                                }
                            })
                            break;

                        default:
                            break;
                    }

                    setGuideAutomationSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            guideAutomationSummaryTableDataFetched: false,
                            sortKey: 'play_time',
                            sortValue: "desc",
                            page: 1,
                            searchQuery: "",
                            filterLabels: [],
                            firstLoad: false,
                        };
                    });
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAutomationSummaryTable]);

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    //LineChart 
    useEffect(() => {
        try {
            if (
                fetched_details.guideAutomation.guideAutomationLineChartData.data !== undefined &&
                fetched_details.guideAutomation.guideAutomationLineChartData.result === "success"
            ) {
                GuideAutomationLineChartMount.current = true;
                let guideAutomationLineChartData: any = [];

                guideAutomationLineChartData = fetched_details.guideAutomation.guideAutomationLineChartData.data.guideTestDetails;

                for (let i = 0; i < guideAutomationLineChartData.length; i++) {
                    guideAutomationLineChartData[i].counts.scheduledPlay = parseInt(guideAutomationLineChartData[i].counts.scheduledPlay, 10);
                    guideAutomationLineChartData[i].counts.manualPlay = parseInt(guideAutomationLineChartData[i].counts.manualPlay, 10);

                }

                setGuideAutomationLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadLineChartData: [...guideAutomationLineChartData],
                        guideAutomationLineChartDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.guideAutomation.guideAutomationLineChartData.result === 'retry') {
                getGuideAutomationLineChartData('retry');
            } else if (
                fetched_details.guideAutomation.guideAutomationLineChartData.result === "error"
            ) {
                setGuideAutomationLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAutomationLineChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAutomation.guideAutomationLineChartData]);

    //Summary
    useEffect(() => {
        try {
            if (
                fetched_details.guideAutomation.guideAutomationSummaryTableData.data !== undefined &&
                fetched_details.guideAutomation.guideAutomationSummaryTableData.result === "success"
            ) {
                GuideAutomationSummaryMount.current = true;
                setGuideAutomationSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAutomationSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.guideAutomation.guideAutomationSummaryTableData.result === 'retry') {
                getGuideAutomationSummaryTableData('retry');
            } else if (
                fetched_details.guideAutomation.guideAutomationSummaryTableData.result === "error"
            ) {
                setGuideAutomationSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAutomationSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAutomation.guideAutomationSummaryTableData]);

    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            if (Object.keys(fetched_details.commonComponentData.datesData).length !== 0) {
                if (fetched_details.sectionVisibility.myBot_flag === true && fetched_details?.commonComponentData.appsData.appGuideAutomationEnabled === 'false') {
                    if (location.pathname.includes('automation-insights')) {
                        let queryParams = {
                            // app_code: "",
                        };
                        navigate({
                            pathname: `/dashboard/`,
                            search: qs.stringify(queryParams),
                        });
                    }
                } else {
                    let queryParams = {
                        app_id: fetched_details?.commonComponentData.appsData.appId,
                        app_code: fetched_details?.commonComponentData.appsData.appCode,
                        fdate: fetched_details?.commonComponentData.datesData.fdate,
                        tdate: fetched_details?.commonComponentData.datesData.tdate,
                        event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuideAutomation
                    }

                    navigate({
                        pathname: `/automation-insights/guide-automation/`,
                        search: qs.stringify(queryParams)
                    })
                }

                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                if (fetched_details?.commonComponentData.datesData?.url?.includes('automation/') && fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code')) {
                    enableExportPdf(0, false);
                    setGuideAutomationLineChart((prevState: any) => {
                        return {
                            ...prevState,
                            guideAutomationLineChartDataFetched: false,
                            lineChartFetched: false,
                            lineChart: {},
                        };
                    });

                    getGuideAutomationLineChartData();

                    enableExportPdf(1, false);
                    setGuideAutomationSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            guideAutomationSummaryTableDataFetched: false,
                            searchQuery: '',
                            sortKey: 'play_time',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 2,
                                'sortValue': 'desc'
                            },
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                            runType: 'All',
                            status: 'All',
                            resultStatus: 'All'
                        };
                    });
                }

            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.commonComponentData.datesData,
    fetched_details?.commonComponentData?.eventSource?.eventSourceGuideAutomation,
    fetched_details.languageData.languageData])

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('automation-insights/')) {
                enableExportPdf(1, false);
                getGuideAutomationSummaryTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAutomationSummaryTable.sortKey,
        guideAutomationSummaryTable.sortValue,
        guideAutomationSummaryTable.defaultSort,
        guideAutomationSummaryTable.page,
        guideAutomationSummaryTable.searchQuery,
        guideAutomationSummaryTable.limit,
        guideAutomationSummaryTable.runType,
        guideAutomationSummaryTable.status,
        guideAutomationSummaryTable.resultStatus
    ]);

    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Detail Navigation
    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: "/automation-insights/automation-detail/" + parameter.parameter + "/",
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [parameter])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getLineChartData = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return guideAutomationLineChart.loadLineChartData;
                } else {
                    return guideAutomationLineChart.loadLineChartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [guideAutomationLineChart.loadLineChartData, guideAutomationLineChart.loadLineChartOptions]
    );

    const LineChartGuideAutomation = useCallback(() => {
        try {
            setGuideAutomationLineChart((prevState: any) => {
                return {
                    ...prevState,
                    lineChart: <Linechart
                        chartData={getLineChartData("data")}
                        chartEntities={['scheduledPlay', 'manualPlay']}
                        optionEntities={[
                            { key: insightsLbls !== undefined ? insightsLbls['scheduledPlay'] : '', color: ChartColors.darkturquoise, area: true },
                            { key: insightsLbls !== undefined ? insightsLbls['manualPlay'] : '', color: ChartColors.lightseagreen, area: true },
                        ]}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}>
                    </Linechart>,
                    lineChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [getLineChartData, lineChartMargin, linechart_height]);

    return (
        <section className="demo guideAutomation width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls['guideAutomation']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EventSource component={"guideAutomation"}></EventSource>
                    <Datepicker source="component"></Datepicker>
                </SectionFilters>
            </div>

            <SectionButtons sectionTitle={insightsLbls['guideAutomation']} svgImage={"guideAutomation"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"guideAutomation"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons>
            <div id="guideAutomationSection">


                {
                    <SectionRefresh sectionTitle={insightsLbls.guideAutomationPlay} refreshClass={""}>
                        <i
                            className="fa fa-refresh pull-right pointer row displayFlex"
                            aria-hidden="true" title={insightsLbls['refresh']}
                            onClick={() => refreshSections(0)}>
                        </i>
                    </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.testCasesExecutedGraph,
                                    "childern": [
                                        {
                                            "key": insightsLbls.scheduledPlay + ":",
                                            "value": insightsLbls.numberOfTimesTestCasesAutoExecuted
                                        },
                                        {
                                            "key": insightsLbls.manualPlay + ":",
                                            "value": insightsLbls.numberOfTimesTestCasesManuallyExecuted
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                            {guideAutomationLineChart.guideAutomationLineChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAutomationLineChart.loadLineChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        guideAutomationLineChart.lineChartFetched ? <>{guideAutomationLineChart.lineChart}</> :
                                            (<LineChartGuideAutomation></LineChartGuideAutomation>)
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>
                {
                    <SectionRefresh sectionTitle={insightsLbls.guideAutomationSummary} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                    </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.summary,
                                    "tooltipText": insightsLbls.testCasesTable,
                                    "childern": [
                                        {
                                            "key": insightsLbls.title + ":",
                                            "value": insightsLbls.nameOfTestCase
                                        },
                                        {
                                            "key": insightsLbls.playTime + ":",
                                            "value": insightsLbls.testCaseTriggeredTime
                                        },
                                        {
                                            "key": insightsLbls.runType + ":",
                                            "value": insightsLbls.automaticallTriggered
                                        },
                                        {
                                            "key": insightsLbls.status + ":",
                                            "value": insightsLbls.testCaseModes
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                        </ContainerTooltip>
                        {
                            guideAutomationSummaryTable.guideAutomationSummaryTableDataFetched && insightsLbls ? (
                                errorCount.errorCount[1] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable">
                                        <CommonTable tableId="1"
                                            data={fetched_details.guideAutomation.guideAutomationSummaryTableData.data}
                                            currentPage={guideAutomationSummaryTable.page}
                                            barColors="positiveColors"
                                            linkPresent={true}
                                            showExport={false}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={guideAutomationSummaryTable.defaultSort}
                                            tablePagination={guideAutomationSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details.guideAutomation.guideAutomationSummaryTableData.firstTimeload}
                                            dynamicSort={[{
                                                title: insightsLbls['runType'],
                                                value: dynamicRunTypeSortValue,
                                                defaultValue: guideAutomationSummaryTable.runType
                                            }, {
                                                title: insightsLbls['status'],
                                                value: dynamicStatusSortValue,
                                                defaultValue: guideAutomationSummaryTable.status
                                            }, {
                                                title: insightsLbls['result'],
                                                value: dynamicResultStatusSortValue,
                                                defaultValue: guideAutomationSummaryTable.resultStatus
                                            }]}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[1] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}

        </section>
    )
}

export default withRouter(withReducer("guideAutomation", guideAutomationReducer)(GuideAutomation))

