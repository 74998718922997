import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, FormGroup, FormControl, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from 'moment';

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

import './ManageSurvey.scss';
import { useErrorBoundary } from 'react-error-boundary';



import { InsightLabels } from 'labels';

interface CurrentState {
    languageData: LanguageState
}

const SurveyShareModal = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);



    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate(), +moment(today).format("HH"), +moment(today).format("mm"), +moment(today).format("ss")));


    const ShareModalMount = useRef(false);

    const [shareModal, setShareModal] = useState({
        open: false,
        sentimentTitle: "",
        lengthError: null,
        publicUrlString: "Generating...",
        publicUrlFlag: false,
        copiedText: "Copied!",
        urlCopyFlag: false,
        anonymousViewFlag: true,
        linkExpiry: "",
        linkExpiryFlag: false,
        linkExpiryDropdownOpen: false,
        dropdownTitle: "Never",
        linkExpiryCustomFlag: false,
        validDate: false
    })
    let linkExpiresMenu = ["Never", "Custom"];




    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])



    let days = [];

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            days = [insightsLbls['su'],
            insightsLbls['mo'],
            insightsLbls['tu'],
            insightsLbls['we'],
            insightsLbls['th'],
            insightsLbls['fr'],
            insightsLbls['sa']]
            const months = [insightsLbls['january'],
            insightsLbls['february'],
            insightsLbls['march'],
            insightsLbls['april'],
            insightsLbls['may'],
            insightsLbls['june'],
            insightsLbls['july'],
            insightsLbls['august'],
            insightsLbls['september'],
            insightsLbls['october'],
            insightsLbls['november'],
            insightsLbls['december']]
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls) {
            linkExpiresMenu[0] = insightsLbls['never'];
            linkExpiresMenu[1] = insightsLbls['Custom'];

            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    publicUrlString: insightsLbls['generating'],
                    copiedText: insightsLbls['copied'],
                    dropdownTitle: insightsLbls['never']
                }
            })

        }
    }, [insightsLbls])

    const filterPassedTime = (time: any) => {
        try {
            const currentDate = new Date();
            const selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleChange = (e: any) => {
        try {
            const { name, value } = e.target;
            switch (name) {
                case "surveyName":
                    setShareModal((prevState: any) => {
                        return {
                            ...prevState,
                            sentimentTitle: value,
                        };
                    });
                    let lengthError = value.length > 250 ? (insightsLbls['surveyModalErrorMsg']) : (null)
                    setShareModal((prevState: any) => {
                        return {
                            ...prevState,
                            lengthError: lengthError,
                        };
                    });
                    break;
                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    };
    const handleCopy = useCallback(() => {
        try {
            const elem = document.getElementById("url") as HTMLInputElement;
            let urlToCopy;
            if (elem) {
                urlToCopy = elem.innerHTML;
                navigator.clipboard.writeText(urlToCopy).then(
                    () => {
                        setShareModal((prevState) => {
                            return {
                                ...prevState,
                                urlCopyFlag: true,
                            };
                        })

                        setTimeout(() => {
                            setShareModal((prevState) => {
                                return {
                                    ...prevState,
                                    urlCopyFlag: false,
                                };
                            })
                        }, 3000);
                    }
                )
                    .catch(
                        () => {
                            setShareModal((prevState) => {
                                return {
                                    ...prevState,
                                    urlCopyFlag: false,
                                };
                            })
                        });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, []);


    const handleAnonymousView = (e: any) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    anonymousViewFlag: e.target.checked,
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };

    const handleLinkExpiry = (e: any) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    linkExpiryFlag: e.target.checked,
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };


    const toggleDropDown = (event: boolean) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    linkExpiryDropdownOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };


    const selectLinkExpiry = (selectedName: string) => {
        try {
            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    linkExpiryCustomFlag: (selectedName === "Custom"),
                    linkExpiry: selectedName === "Custom" ? Date.now() : ''
                };
            });

            let labelTitle;
            if (selectedName === 'Never') {
                labelTitle = insightsLbls['never']
            } else {
                labelTitle = insightsLbls['custom']
            }

            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    linkExpiryDropdownOpen: false,
                    dropdownTitle: labelTitle,
                };
            });
        } catch (error) {
            showBoundary(error)
        }


    };

    const getOptionValue = (selectedName: string) => {
        if (selectedName === 'Never') {
            return insightsLbls['never']
        } else {
            return insightsLbls['custom']
        }
    }

    useEffect(() => {
        ShareModalMount.current = true;
        setShareModal((prevData: any) => {
            return {
                ...prevData,
                publicUrlString: props.surveyURL,
            }
        });
    }, [props])

    useEffect(() => {
        try {
            ShareModalMount.current = true;

            setShareModal((prevData: any) => {
                return {
                    ...prevData,
                    publicUrlString: insightsLbls['generating'],
                }
            })


            if (props.surveyCode) {
                const data = {
                    viewFlag: shareModal.anonymousViewFlag,
                    surveyCode: props.surveyCode,
                    linkExpiry: (shareModal.linkExpiryCustomFlag && shareModal.linkExpiryFlag) ? moment(shareModal.linkExpiry).format("YYYY-MM-DD_HH:mm:ss") : ''
                }
                props.getEvent(data);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [shareModal.anonymousViewFlag, props.surveyCode, shareModal.linkExpiryFlag, shareModal.linkExpiry])


    // const yesterday = moment().subtract(1, 'day');
    // const getValidDate = (current: any) => {
    //     return current.isAfter(yesterday);
    // }

    const onDateChange = (date: any) => {
        try {
            const currentDate = new Date();
            const selectedDate = new Date(date);

            setShareModal((prevState: any) => {
                return {
                    ...prevState,
                    validDate: currentDate.getTime() < selectedDate.getTime()
                }
            })
            setStartDate(date)

            if (currentDate.getTime() < selectedDate.getTime()) {
                setShareModal((prevState: any) => {
                    return {
                        ...prevState,
                        linkExpiry: date
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <>

            <div className="shareModalBody">
                <Form
                    className="font14"
                    noValidate
                >
                    <FormGroup controlId="formShareSurvey">
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            <div className="marginBottom5">
                                {insightsLbls['surveyName']}
                            </div>
                        }


                        <FormControl
                            name="surveyName"
                            type="text"
                            autoComplete="off"
                            // value= {props.surveyTitle}
                            value={props.surveyTitle}
                            onInput={handleChange}
                            // onFocus={handleTouch}
                            disabled={true}
                        />
                        <div className="errorContainer">
                            <div className="requiredErrorMsg">
                                {shareModal.lengthError !== null && (
                                    <span className="error">{shareModal.lengthError}</span>
                                )}
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup controlId="formShareLink">
                        <div className="width100 height35 displayFlex padding5 urlDiv" >
                            <span className="urlString width100" id="url">{shareModal.publicUrlString}</span>
                            {props.publicURLFlag === true &&
                                (
                                    <span className="copyUrl focusBg padding5 pointer">
                                        <i className="fa fa-clipboard positionRelative top-2" onClick={handleCopy}></i>
                                    </span>
                                )
                            }
                        </div>
                        <div className="copiedContainer">
                            <div className="copiedMsg">
                                {shareModal.urlCopyFlag === true && (
                                    <span className="error">"{(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                        insightsLbls['copied']}"</span>
                                )}
                            </div>
                        </div>
                    </FormGroup>

                    <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter  marginLeft-20 marginTop-10 check-container padding-chk-0 width100">
                        <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer width100">
                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5 width100">

                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAnonymousView"
                                    onChange={(e) => handleAnonymousView(e)} checked={shareModal.anonymousViewFlag}></input>
                                <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                <span className="marginLeft-10">{(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                    insightsLbls['allowView']}</span>
                                {/* (Option available only if User Tracking is enabled) */}
                            </label>
                        </div>
                    </div>
                    <div className="marginLeft-20 marginTop-20 width100">
                        <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer LinkExpiryDiv width100">
                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5 width100">

                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAnonymousView"
                                    onChange={(e) => handleLinkExpiry(e)} checked={shareModal.linkExpiryFlag}></input>
                                <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                <span className="marginLeft-10 width150 ">{(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                    insightsLbls['linkExpiry']}</span>
                            </label>

                            <div className="col-sm-12 col-md-12 col-lg-12 padding0 marginTop--20">

                                {shareModal.linkExpiryFlag === true &&
                                    (
                                        <>

                                            <div className="height15 marginTop-10">
                                                {(!shareModal.validDate && shareModal.linkExpiryCustomFlag === true) &&
                                                    (<p className="errorMsg font12 marginBottom-0 marginLeft-10">{insightsLbls['invalidDate']}. {insightsLbls['select']} {moment(new Date()).add(30, 'm').format("LLL")} {insightsLbls['orLater']}.</p>)
                                                }
                                            </div>


                                            <div className="displayFlex">
                                                <span className="LinkExpiryDropdownSpan">
                                                    <div className="LinkExpiryDropdownDiv">
                                                        <Dropdown id="linkExpiryMenuButton" onToggle={(event) => toggleDropDown(event)}>
                                                            <Dropdown.Toggle className={"btn btn-lg btn-outline-primar LinkExpiryDropdownToggleDiv"} variant={'customBorder'}>
                                                                <span className="selectedLinkExpiry">{shareModal.dropdownTitle}</span>
                                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (shareModal.linkExpiryDropdownOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                                            </Dropdown.Toggle>

                                                            {
                                                                (shareModal.linkExpiryDropdownOpen === true && insightsLbls) ?
                                                                    (
                                                                        <Dropdown.Menu className={"dropdown-menu LinkExpiryDropdownToggleMenuDiv"} id="survey_filter_dropdown_menu">
                                                                            {
                                                                                linkExpiresMenu.map((source: any, index: number) => {
                                                                                    return (
                                                                                        <div className="DropdownToggleMenuItemDiv" onClick={() => selectLinkExpiry(source)}>
                                                                                            {getOptionValue(source)}
                                                                                        </div>
                                                                                    );

                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    )
                                                                    :
                                                                    (null)
                                                            }
                                                        </Dropdown>

                                                    </div>

                                                </span>
                                                <span className="LinkExpiryCustomCalenderSpan">
                                                    <div className="LinkExpiryCustomCalenderDiv">
                                                        {
                                                            shareModal.linkExpiryCustomFlag === true ?
                                                                (
                                                                    <>
                                                                        <div className="selectDate-div">
                                                                            <DatePicker
                                                                                selected={startDate}
                                                                                onChange={(dateVal) => onDateChange(dateVal)}
                                                                                showTimeSelect
                                                                                filterTime={filterPassedTime}
                                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                                minDate={new Date()}
                                                                                timeIntervals={30}
                                                                                dropdownMode="select"
                                                                            />
                                                                        </div>

                                                                    </>
                                                                    // <Datetime 
                                                                    //     timeFormat={true}
                                                                    //     initialValue={new Date()} 
                                                                    //     onClose={(e) => handleDateTime(e)}
                                                                    //     isValidDate={getValidDate}>
                                                                    // </Datetime>
                                                                )
                                                                :
                                                                (null)
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                        </>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </Form>

            </div>
        </>
    )
};

export default SurveyShareModal;
