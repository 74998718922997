
// Modified by: Mrunal Mane
// On: 2024-05-13
// Reason: Removed language dependancy from useEffect (props.answeredYes, props.answeredNo)


import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { ChartColors } from 'utils/GlobalConstants';
import NVD3Chart from "react-nvd3";
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from 'labels';
interface CurrentState {
    languageData: LanguageState
}
const YesNo = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const [tableData, setTableData] = useState({
        showData: false,
        total: 0,
        qResponses: [],
        percentageSort: false,
        valueSort: false,
    })

    const [chart, setChart] = useState({
        data: [],
        options: [],
        flag: false
    })



    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            setTableData((prevState) => {
                return {
                    ...prevState,
                    showData: true
                }
            })
            setTimeout(() => {
                initChartData();

            }, 100);
            initResponseTable();
        } catch (error) {
            showBoundary(error)
        }

    }, [props.answeredYes, props.answeredNo])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const initChartData = () => {
        try {
            const datum = [{
                key: "Yes-No",
                values: [
                    {
                        "label": "Yes",
                        "value": props.answeredYes,
                        'color': ChartColors.lightseagreen
                    },
                    {
                        "label": "No",
                        "value": props.answeredNo,
                        'color': ChartColors.orangesandybrown

                    },
                ]
            }
            ];
            setChart((prevState: any) => {
                return {
                    ...prevState,
                    data: datum,
                    flag: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const initResponseTable = () => {
        try {
            let qResponses_data: any = [];
            let totalCount = ((+props.answeredNo) + (+props.answeredYes));

            let percentage = (parseFloat(props.answeredNo) / totalCount) * 100;
            qResponses_data.push({ key: 'No', value: props.answeredNo, percentage: (totalCount === 0 && props.answeredNo ? 0.0 : percentage) });
            percentage = (parseFloat(props.answeredYes) / totalCount) * 100;
            qResponses_data.push({ key: 'Yes', value: props.answeredYes, percentage: (totalCount === 0 && props.answeredYes ? 0.0 : percentage) });


            setTableData((prevState) => {
                return {
                    ...prevState,
                    showTable: true,
                    total: totalCount,
                    qResponses: qResponses_data
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    const sortData = (key: string, flag: boolean, field: any) => {
        try {
            const table_data: any = tableData.qResponses;

            if (tableData[field] === true) {
                table_data.sort((a: any, b: any) => (a[key] < b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            } else if (tableData[field] === false) {
                table_data.sort((a: any, b: any) => (a[key] > b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getKeyValue = (value: string) => {
        try {
            switch (value) {
                case "Yes":
                    return insightsLbls.yes;

                case "No":
                    return insightsLbls.no;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <>
            <div className="marginTop-60 minWidth800">

                {tableData.showData
                    ? (<>
                        <div className="col-md-12 col-sm-12 col-xs-12 row">
                            <div style={{ float: 'left' }} className="col-md-5 col-sm-5 col-xs-5">
                                {chart.flag &&
                                    (
                                        <NVD3Chart id="barChart"
                                            type="discreteBarChart"
                                            datum={chart.data}
                                            x="label"
                                            y="value"
                                            width='250'
                                            height='400'

                                            margin={{
                                                top: "10",
                                                right: "00",
                                                bottom: "20",
                                                left: "75",
                                            }}

                                            xAxis={{
                                                tickFormat: function (d: any) {
                                                    switch (d) {
                                                        case "Yes":
                                                            return insightsLbls.yes;

                                                        case "No":
                                                            return insightsLbls.no;

                                                        default:
                                                            break;
                                                    }
                                                }
                                            }}

                                            yAxis={{
                                                axisLabel: insightsLbls.playCount,
                                                axisLabelDistance: 5,
                                                tickFormat: function (d: any) {
                                                    return d;
                                                    // return d3.format("d")(d)
                                                },
                                            }}
                                            tooltip={{
                                                contentGenerator: function (key: any) {
                                                    return `<div><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.data.color}'></div><b>${getKeyValue(key.data.label)} <span style="margin-left:"10px">${key.data.value}<span></b></div>`
                                                    // return key
                                                }
                                            }} />
                                    )
                                }

                            </div>

                            <div className="col-md-7 col-sm-7 col-xs-7 block-grph-table paddingRight-20">
                                <p></p>
                                <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table displayInlineBlock chartTables">
                                    <h5>{insightsLbls.responseStats}</h5>
                                    <table className=" color_table table table-bordered" >
                                        <thead className="" >
                                            <tr>
                                                <th>{insightsLbls.answerChoices}</th>
                                                <th>
                                                    {insightsLbls.responses}
                                                    <i className={"marginLeft-5 fa pointer " + `${tableData.percentageSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                        onClick={() => sortData('percentage', tableData.percentageSort, 'percentageSort')}></i>
                                                </th>
                                                <th>
                                                    {insightsLbls.responseCount}
                                                    <i className={"marginLeft-5 fa pointer " + `${tableData.valueSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                        onClick={() => sortData('value', tableData.valueSort, 'valueSort')}></i>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tfoot className="">
                                            <tr>
                                                <td><strong>{insightsLbls.total}</strong></td>
                                                <td>&nbsp;</td>
                                                <td><strong>{tableData.total ? tableData.total : 0}</strong></td>
                                            </tr>
                                        </tfoot>
                                        <tbody>

                                            {tableData.qResponses.map((data: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{getKeyValue(data.key)}</td>
                                                        <td>{data.percentage ? Math.round(data.percentage) : 0}%</td>
                                                        <td>{data.value ? data.value : 0}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>)
                    : (<div style={{ textAlign: 'center', paddingBottom: '50px', fontSize: '13px', color: 'black', fontWeight: 600 }}>
                        {insightsLbls.noResponseYet}
                    </div >)
                }

            </div>
        </>
    )
};

export default React.memo(YesNo);
