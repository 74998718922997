import React, { useEffect, useState, useReducer, useCallback, useRef } from "react";
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BlockPicker } from 'react-color';
import withRouter from "hoc/withRouter";


import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";


import $ from 'jquery';
import { useErrorBoundary } from 'react-error-boundary';

import "./Label.scss";
import { InsightLabels } from "labels";

interface CurrentState {
    languageData: LanguageState
}



interface Option {
    readonly label: string;
    readonly value: string;
}

const OutsideClickListener = ({ children, onClickOutside }) => {
    const wrapperRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          onClickOutside();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClickOutside]);
  
    return <div ref={wrapperRef}>{children}</div>;
};


const InputLabel = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_details = useSelector((state: CurrentState) => state);

    const createOption = (label: string) => ({
        label,
        value: label,
    });


    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = useState([]);
    const [emailState, setEmailState] = useState({
        limit: 10,
        validFlag: true,
        errorMsg: '',
        warningFlag: false
    })

    let cnt = 10;

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (props.email_cc.length) {
            let data = [];
            for (let itr0 = 0; itr0 < props.email_cc.length; itr0++) {
                data.push({ label: props.email_cc[itr0], value: props.email_cc[itr0] })
            }
            setValue(data)
        }
    }, [props.email_cc])

    const handleKeyDown = (event) => {
        try {
            setEmailState((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: '',
                }
            })

            if (!inputValue) return;

            if (value.length < cnt) {
              
                const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                switch (event.key) {
                    case 'Enter':
                    case 'Tab':
                        if (regexEmail.test(inputValue)) {
                            if (!value.find(item => item.label === inputValue)) {
                                setValue((prev) => [...prev, createOption(inputValue)]);
                                setInputValue('');
                                event.preventDefault();
                                setEmailState((prevState: any) => {
                                    return {
                                        ...prevState,
                                        validFlag: true,
                                        errorMsg: ''
                                    }
                                })

                                props.dispatchValidity(true);

                            } else {
                                setEmailState((prevState: any) => {
                                    return {
                                        ...prevState,
                                        validFlag: false,
                                        errorMsg: insightsLbls.duplicateEmailId
                                    }
                                })

                                props.dispatchValidity(false);

                            }
                        } else {
                            setEmailState((prevState: any) => {
                                return {
                                    ...prevState,
                                    validFlag: false,
                                    errorMsg: insightsLbls.invalidEmailId
                                }
                            })

                            props.dispatchValidity(false);

                        }
                }

            }

        } catch (error) {
            showBoundary(error)
        }
    };

    useEffect(() => {
        props.dispatchEmailIds(value)
        props.dispatchValidity(true);
    }, [value])

    const colourStyles = {
        multiValueLabel: (styles: any, data: any) => {
            return {
                ...styles,
                backgroundColor: '#c1f7ed',
            };
        },
        multiValue: (styles: any, data: any) => {
            return {
                ...styles,
                backgroundColor: '#c1f7ed'
            };
        },
        placeholder: (styles: any) => {
            return {
                ...styles,
                fontSize: '14px'
            };
        },
    }

    const inputChange = (newValue) => {
        if(value.length === cnt && newValue.length === 0){
            setEmailState((prevState: any) => {
                return {
                    ...prevState,
                    warningFlag: false
                }
            })
        }  

        if(value.length === cnt && newValue.length > 0) {
            setEmailState((prevState: any) => {
                return {
                    ...prevState,
                    warningFlag: true
                }
            })
        }
        setInputValue(newValue)
    }

    const setNewValue = (value) => {
        setEmailState((prevState: any) => {
            return {
                ...prevState,
                warningFlag: false
            }
        })
        
        setValue(value)
    }

    const handleClickOutside = () => {
        if(!emailState.validFlag) {
            setEmailState((prevState:any) => {
                return {
                    ...prevState,
                    validFlag: true,
                    errorMsg: ''
                }
            })
        }
    };

    return (

        <>
            <p className="font14 marginBottom-0">{insightsLbls.emailCC}</p>
            <OutsideClickListener onClickOutside={handleClickOutside}>
                <div className="inputCC-div">
                    <CreatableSelect
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(value) => setNewValue(value)}
                        onInputChange={(newValue) => inputChange(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder={insightsLbls.emailCCPlaceholder}
                        value={value}
                        styles={colourStyles}
                    />
                </div>
            </OutsideClickListener>
           
            <p className="height20 errorMsg marginBottom-0">
                {!emailState.validFlag && (
                    <span>{emailState.errorMsg}</span>
                )}

            {emailState.warningFlag && (<span>{insightsLbls.emailCCWarningMessage}</span>)}
            </p>
        </>

    );
}
export default React.memo(withRouter(InputLabel));