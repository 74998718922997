export const menuListData =  [
        {
            "title": 'cioDashboardTitle',
            "svg": "dashboard_ic.svg",
            "routerLink": "/dashboard/",
            "index": "0",
            "isParent": true,
            "children": [
                {
                    "title": 'Dashboard' + " 1",
                    "svg": "custom_dashboard.svg",
                    "routerLink": "/custom-dashboard/1/",
                    "index": "0",
                    "isParent": false
                },
                {
                    "title": 'Dashboard' + " 2",
                    "svg": "custom_dashboard.svg",
                    "routerLink": "/custom-dashboard/2/",
                    "index": "1",
                    "isParent": false

                },
                {
                    "title": 'Dashboard' + " 3",
                    "svg": "custom_dashboard.svg",
                    "routerLink": "/custom-dashboard/3/",
                    "index": "2",
                    "isParent": false

                },
                {
                    "title": 'myGuideDashboard',
                    "svg": "dashboard_ic.svg",
                    "routerLink": "/dashboard/",
                    "index": "3",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'guideInsights',
            "svg": "guideAnalytics.svg",
            "routerLink": "/guide-insights/guide-analytics/",
            "index": "1",
            "option": "GuideMe",
            "isParent": true,
            "children": [
                {
                    "title": 'guidesTab',
                    "svg": "GuideMe.svg",
                    "option": "GuideMe",
                    "index": "0",
                    "routerLink": "/guide-insights/guide-analytics/",
                    "isParent": true,
                    "children": [
                        {
                            "title": 'guideMe',
                            "svg": "GuideMe.svg",
                            "option": "GuideMe",
                            "index": "0",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false

                        },
                        {
                            "title": 'showMe',
                            "svg": "ShowMe.svg",
                            "option": "ShowMe",
                            "index": "1",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        },
                        {
                            "title": 'testMe',
                            "svg": "TestMe.svg",
                            "option": "TestMe",
                            "index": "2",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        },
                        {
                            "title": 'doItForMe',
                            "svg": "DoItForMe.svg",
                            "option": "DoItForMe",
                            "index": "3",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        },
                        {
                            "title": 'survey',
                            "svg": "Sentiment.svg",
                            "option": "Survey",
                            "index": "4",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        },
                        {
                            "title": 'guidesPlayedByPushAndBeacons',
                            "svg": "push-notification.svg",
                            "option": "Push-Beacon",
                            "index": "5",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        },
                        {
                            "title": 'summary',
                            "svg": "Summary.svg",
                            "option": "Summary",
                            "index": "6",
                            "routerLink": "/guide-insights/guide-analytics/",
                            "isParent": false
                        }
                    ]
                },
                {
                    "title": 'tutorialGuides',
                    "svg": "searchActivity.svg",
                    "index": "1",
                    "routerLink": "/guide-insights/tutorial-guides/",
                    "isParent": true,
                    "children": [
                        {
                            "title": 'guideMe',
                            "svg": "GuideMe.svg",
                            "option": "TutorialGuideGraph",
                            "index": "0",
                            "routerLink": "/guide-insights/tutorial-guides/",
                            "isParent": false

                        },
                        {
                            "title": 'summary',
                            "svg": "Summary.svg",
                            "option": "TutorialGuideSummary",
                            "index": "1",
                            "routerLink": "/guide-insights/tutorial-guides/",
                            "isParent": false
                        }
                    ]
                },
                {
                    "title": 'pushNotificationsAndBeacons',
                    "svg": "searchActivity.svg",
                    "index": "2",
                    "routerLink": "/guide-insights/push-notifications-beacons/",
                    "isParent": true,
                    "children": [
                        {
                            "title": 'pushNotifications',
                            "svg": "GuideMe.svg",
                            "option": "TutorialGuideGraph",
                            "index": "0",
                            "routerLink": "/guide-insights/push-notifications-beacons/",
                            "isParent": false

                        },
                        {
                            "title": 'beacons',
                            "svg": "Summary.svg",
                            "option": "TutorialGuideSummary",
                            "index": "1",
                            "routerLink": "/guide-insights/push-notifications-beacons/",
                            "isParent": false
                        }
                    ]
                },
                {
                    "title": 'tooltips',
                    "svg": "tooltip.svg",
                    "routerLink": "/guide-insights/tooltips/",
                    "index": "3",
                    "isParent": true,
                    "children": [
                        {
                            "title": 'tooltipAndValidation',
                            "svg": "tooltip-validation.svg",
                            "option": "Tooltips_Validation",
                            "index": "0",
                            "routerLink": "/guide-insights/tooltips/",
                            "isParent": false

                        },
                        {
                            "title": 'powerForm',
                            "svg": "tooltip-power-form.svg",
                            "option": "PowerForm",
                            "index": "1",
                            "routerLink": "/guide-insights/tooltips/",
                            "isParent": false
                        },
                        {
                            "title": 'ductTape',
                            "svg": "tooltip-html.svg",
                            "option": "DuctTape",
                            "index": "2",
                            "routerLink": "/guide-insights/tooltips/",
                            "isParent": false
                        },
                        {
                            "title": 'summary',
                            "svg": "Summary.svg",
                            "option": "Summary",
                            "index": "3",
                            "routerLink": "/guide-insights/tooltips/",
                            "isParent": false
                        }
                    ]
                },
                {
                    "title": 'taskList',
                    "svg": "searchActivity.svg",
                    "option": "TaskList",
                    "index": "4",
                    "routerLink": "/guide-insights/task-list/",
                    "isParent": false
                },
                {
                    "title": 'userEngagement',
                    "svg": "userEngagement.svg",
                    "index": "5",
                    "routerLink": "/guide-insights/user-engagement/",
                    "option": "userEngagement",
                    "isParent": true,
                    "children": [
                        {
                            "title": 'userActivity',
                            "svg": "userEngagement.svg",
                            "option": "UserActivity",
                            "index": "0",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false

                        },
                        {
                            "title": 'guideMe',
                            "svg": "GuideMe.svg",
                            "option": "GuideMe",
                            "index": "1",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false

                        },
                        {
                            "title": 'showMe',
                            "svg": "ShowMe.svg",
                            "option": "ShowMe",
                            "index": "2",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false
                        },
                        {
                            "title": 'testMe',
                            "svg": "TestMe.svg",
                            "option": "TestMe",
                            "index": "3",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false
                        },
                        {
                            "title": 'doItForMe',
                            "svg": "DoItForMe.svg",
                            "option": "DoItForMe",
                            "index": "4",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false
                        },
                        {
                            "title": 'tutorialGuides',
                            "svg": "searchActivity.svg",
                            "option": "Tutorial Guides",
                            "index": "5",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false
                        },
                        {
                            "title": 'summary',
                            "svg": "Summary.svg",
                            "option": "Summary",
                            "index": "6",
                            "routerLink": "/guide-insights/user-engagement/",
                            "isParent": false
                        }
                    ]
                },
                {
                    "title": 'searches',
                    "svg": "searchActivity.svg",
                    "index": "6",
                    "routerLink": "/guide-insights/search-activities/",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'appInsights',
            "svg": "intelligence.svg",
            "routerLink": "/app-insights/pages/",
            "index": "2",
            "option": "page",
            "isParent": true,
            "children": [
                {
                    "title": 'pages',
                    "svg": "page.svg",
                    "option": "page",
                    "index": "0",
                    "routerLink": "/app-insights/pages/",
                    "isParent": false
                },
                {
                    "title": 'feature',
                    "svg": "page.svg",
                    "option": "feature",
                    "index": "0",
                    "routerLink": "/app-insights/feature/",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'survey',
            "svg": "Sentiment.svg",
            "routerLink": "/survey/survey/",
            "index": "3",
            "option": "survey",
            "isParent": true,
            "children": [
                {
                    "title": 'stats',
                    "svg": "stats.svg",
                    "option": "survey-stats",
                    "index": "0",
                    "routerLink": "/survey/survey/",
                    "isParent": false
                },
                {
                    "title": 'manage',
                    "svg": "manage.svg",
                    "option": "survey",
                    "index": "1",
                    "routerLink": "/survey/manage-survey/",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'chatbotConversation',
            "svg": "comment-dots-regular.svg",
            "routerLink": "/chatbot/chatbot/",
            "index": "4",
            "option": "chatbot",
            "isParent": true,
            "children": [
                {
                    "title": 'stats',
                    "svg": "stats.svg",
                    "option": "chatbot-stats",
                    "index": "0",
                    "routerLink": "/chatbot/chatbot/",
                    "isParent": false
                },
                {
                    "title": 'manage',
                    "svg": "manage.svg",
                    "option": "chatbot",
                    "index": "1",
                    "routerLink": "/chatbot/manage/",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'automationInsights',
            "svg": "qa_automation.svg",
            "routerLink": "/automation-insights/guide-automation/",
            "index": "5",
            "option": "automation",
            "isParent": true,
            "children": [
                {
                    "title": 'guideAutomation',
                    "svg": "myBot.svg",
                    "index": "0",
                    "routerLink": "/automation-insights/guide-automation/",
                    "option": "automation",
                    "isParent": false
                }
            ]
        },
        {
            "title": 'goal',
            "svg": "goal.svg",
            "routerLink": "/goal/manage-goal/",
            "index": "6",
            "option": "goals",
            "isParent": false

        },
        {
            "title": 'controlCenter',
            "svg": "controlCenter.svg",
            "routerLink": "/control-center/",
            "index": "7",
            "option": "control-center",
            "isParent": false

        },
        {
            "title": 'export',
            "svg": "export.svg",
            "routerLink": "/export/export-list/",
            "index": "8",
            "option": "export",
            "isParent": false,
        }
    ]




// export const menuList = [
//     {
//         "title": insightsLbls.cioDashboardTitle,
//         "svg": "dashboard_ic.svg",
//         "routerLink": "/dashboard/",
//         "index": "0",
//         "isParent": true,
//         "children": [
//             {
//                 "title": insightsLbls.dashboard + " 1",
//                 "svg": "custom_dashboard.svg",
//                 "routerLink": "/custom-dashboard/1/",
//                 "index": "0",
//                 "isParent": false
//             },
//             {
//                 "title": insightsLbls.dashboard + " 2",
//                 "svg": "custom_dashboard.svg",
//                 "routerLink": "/custom-dashboard/2/",
//                 "index": "1",
//                 "isParent": false

//             },
//             {
//                 "title": insightsLbls.dashboard + " 3",
//                 "svg": "custom_dashboard.svg",
//                 "routerLink": "/custom-dashboard/3/",
//                 "index": "2",
//                 "isParent": false

//             },
//             {
//                 "title": insightsLbls.myGuideDashboard,
//                 "svg": "dashboard_ic.svg",
//                 "routerLink": "/dashboard/",
//                 "index": "3",
//                 "isParent": false
//             }
//         ]
//     },
//     {
//         "title": insightsLbls.guideInsights,
//         "svg": "guideAnalytics.svg",
//         "routerLink": "/guide-insights/guide-analytics/",
//         "index": "1",
//         "option": "GuideMe",
//         "isParent": true,
//         "children": [
//             {
//                 "title": insightsLbls.guidesTab,
//                 "svg": "GuideMe.svg",
//                 "option": "GuideMe",
//                 "index": "0",
//                 "routerLink": "/guide-insights/guide-analytics/",
//                 "isParent": true,
//                 "children": [
//                     {
//                         "title": insightsLbls.guideMe,
//                         "svg": "GuideMe.svg",
//                         "option": "GuideMe",
//                         "index": "0",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false

//                     },
//                     {
//                         "title": insightsLbls.showMe,
//                         "svg": "ShowMe.svg",
//                         "option": "ShowMe",
//                         "index": "1",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.testMe,
//                         "svg": "TestMe.svg",
//                         "option": "TestMe",
//                         "index": "2",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.doItForMe,
//                         "svg": "DoItForMe.svg",
//                         "option": "DoItForMe",
//                         "index": "3",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.survey,
//                         "svg": "Sentiment.svg",
//                         "option": "Survey",
//                         "index": "4",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.pusNotificationAndBeacons,
//                         "svg": "push-notification.svg",
//                         "option": "Push-Beacon",
//                         "index": "5",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.summary,
//                         "svg": "Summary.svg",
//                         "option": "Summary",
//                         "index": "6",
//                         "routerLink": "/guide-insights/guide-analytics/",
//                         "isParent": false
//                     }
//                 ]
//             },
//             {
//                 "title": insightsLbls.tutorialGuides,
//                 "svg": "searchActivity.svg",
//                 "index": "1",
//                 "routerLink": "/guide-insights/tutorial-guides/",
//                 "isParent": true,
//                 "children": [
//                     {
//                         "title": insightsLbls.guideMe,
//                         "svg": "GuideMe.svg",
//                         "option": "TutorialGuideGraph",
//                         "index": "0",
//                         "routerLink": "/guide-insights/tutorial-guides/",
//                         "isParent": false

//                     },
//                     {
//                         "title": insightsLbls.summary,
//                         "svg": "Summary.svg",
//                         "option": "TutorialGuideSummary",
//                         "index": "1",
//                         "routerLink": "/guide-insights/tutorial-guides/",
//                         "isParent": false
//                     }
//                 ]
//             },
//             {
//                 "title": insightsLbls.tooltips,
//                 "svg": "tooltip.svg",
//                 "routerLink": "/guide-insights/tooltips/",
//                 "index": "2",
//                 "isParent": true,
//                 "children": [
//                     {
//                         "title": insightsLbls.tooltipAndValidation,
//                         "svg": "tooltip-validation.svg",
//                         "option": "Tooltips_Validation",
//                         "index": "0",
//                         "routerLink": "/guide-insights/tooltips/",
//                         "isParent": false

//                     },
//                     {
//                         "title": insightsLbls.powerForm,
//                         "svg": "tooltip-power-form.svg",
//                         "option": "PowerForm",
//                         "index": "1",
//                         "routerLink": "/guide-insights/tooltips/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.ductTape,
//                         "svg": "tooltip-html.svg",
//                         "option": "DuctTape",
//                         "index": "2",
//                         "routerLink": "/guide-insights/tooltips/",
//                         "isParent": false
//                     },
//                     {
//                         "title": insightsLbls.summary,
//                         "svg": "Summary.svg",
//                         "option": "Summary",
//                         "index": "3",
//                         "routerLink": "/guide-insights/tooltips/",
//                         "isParent": false
//                     }
//                 ]
//             },
//             {
//                 "title": insightsLbls.userEngagement,
//                 "svg": "userEngagement.svg",
//                 "index": "3",
//                 "routerLink": "/guide-insights/user-engagement/",
//                 "option": "userEngagement",
//                 "isParent": true,
//                 "children": [
//                     {
//                         "title": insightsLbls.userActivity,
//                         "svg": "userEngagement.svg",
//                         "option": "UserActivity",
//                         "index": "0",
//                         "routerLink": "/guide-insights/user-engagement/",
//                         "isParent": false

//                     },
//                     {
//                         "title": insightsLbls.guideMe,
//                         "svg": "GuideMe.svg",
//                         "option": "GuideMe",
//                         "index": "1",
//                         "routerLink": "/guide-insights/user-engagement/",
//                         "isParent": false


                    // },
                    // {
                    //     "title": insightsLbls.showMe,
                    //     "svg": "ShowMe.svg",
                    //     "option": "ShowMe",
                    //     "index": "2",
                    //     "routerLink": "/guide-insights/user-engagement/",
    //                     "isParent": false
    //                 },
    //                 {
    //                     "title": insightsLbls.testMe,
    //                     "svg": "TestMe.svg",
    //                     "option": "TestMe",
    //                     "index": "3",
    //                     "routerLink": "/guide-insights/user-engagement/",
    //                     "isParent": false
    //                 },
    //                 {
    //                     "title": insightsLbls.doItForMe,
    //                     "svg": "DoItForMe.svg",
    //                     "option": "DoItForMe",
    //                     "index": "4",
    //                     "routerLink": "/guide-insights/user-engagement/",
    //                     "isParent": false
    //                 },
    //                 {
    //                     "title": insightsLbls.tutorialGuides,
    //                     "svg": "searchActivity.svg",
    //                     "option": "Tutorial Guides",
    //                     "index": "5",
    //                     "routerLink": "/guide-insights/user-engagement/",
    //                     "isParent": false
    //                 },
    //                 {
    //                     "title": insightsLbls.summary,
    //                     "svg": "Summary.svg",
    //                     "option": "Summary",
    //                     "index": "6",
    //                     "routerLink": "/guide-insights/user-engagement/",
    //                     "isParent": false
    //                 }
    //             ]
    //         },
    //         {
    //             "title": insightsLbls.searches,
    //             "svg": "searchActivity.svg",
    //             "index": "4",
    //             "routerLink": "/guide-insights/search-activities/",
    //             "isParent": false
    //         }
    //     ]
    // },
    // {
    //     "title": insightsLbls.appInsights,
    //     "svg": "intelligence.svg",
    //     "routerLink": "/app-insights/pages/",
    //     "index": "2",
    //     "option": "page",
    //     "isParent": true,
    //     "children": [
    //         {
    //             "title": insightsLbls.pages,
    //             "svg": "page.svg",
    //             "option": "page",
    //             "index": "0",
    //             "routerLink": "/app-insights/pages/",
    //             "isParent": false
    //         },
    //         // {
    //         //     "title": insightsLbls.workflows,
    //         //     "svg": "WorkFlow_panel.svg",
    //         //     "option": "combined_workflow",
    //         //     "index": "0",
    //         //     "routerLink": "/app-insights/workflows/",
    //         //     "isParent": false
    //         // }
    //     ]
    // },
    // {
    //     "title": insightsLbls.survey,
    //     "svg": "Sentiment.svg",
    //     "routerLink": "/survey/survey/",
    //     "index": "3",
    //     "option": "survey",
    //     "isParent": true,
    //     "children": [
    //         {
    //             "title": insightsLbls.stats,
    //             "svg": "stats.svg",
    //             "option": "survey-stats",
    //             "index": "0",
    //             "routerLink": "/survey/survey/",
    //             "isParent": false
    //         },
    //         {
    //             "title": insightsLbls.manage,
    //             "svg": "manage.svg",
    //             "option": "survey",
    //             "index": "1",
    //             "routerLink": "/survey/manage-survey/",
    //             "isParent": false
    //         }
    //     ]
    // },
    // {
    //     "title": insightsLbls.chatbotConversation,
    //     "svg": "Sentiment.svg",
    //     "routerLink": "/chatbot/manage/",
    //     "index": "4",
    //     "option": "chatbot",
    //     "isParent": true,
    //     "children": [
    //         {
    //             "title": insightsLbls.manage,
    //             "svg": "manage.svg",
    //             "option": "chatbot",
    //             "index": "1",
    //             "routerLink": "/chatbot/manage/",
    //             "isParent": false
    //         }
    //     ]
    // },
    // {
    //     "title": insightsLbls.automationInsights,
    //     "svg": "qa_automation.svg",
    //     "routerLink": "/automation-insights/guide-automation/",
    //     "index": "5",
    //     "option": "automation",
    //     "isParent": true,
    //     "children": [
    //         {
    //             "title": insightsLbls.guideAutomation,
    //             "svg": "myBot.svg",
    //             "index": "0",
    //             "routerLink": "/automation-insights/guide-automation/",
    //             "option": "automation",
    //             "isParent": false
    //         }
    //     ]
    // },
    // {
    //     "title": insightsLbls.organizationView,
    //     "svg": "organization.svg",
    //     "routerLink": "/organization/org-view/",
    //     "index": "6",
    //     "option": "org-view",
    //     "isParent": false
    // },
    // {
    //     "title": insightsLbls.goal,
    //     "svg": "goal.svg",
    //     "routerLink": "/goal/manage-goal/",
    //     "index": "7",
    //     "option": "goals",
    //     "isParent": false

//     },
//     {
//         "title": insightsLbls.controlCenter,
//         "svg": "controlCenter.svg",
//         "routerLink": "/control-center/",
//         "index": "8",
//         "option": "control-center",
//         "isParent": false

//     },
//     {
//         "title": insightsLbls.export,
//         "svg": "export.svg",
//         "routerLink": "/export/export-list/",
//         "index": "9",
//         "option": "export",
//         "isParent": false
//     }
// ]
