import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { ChatBotStatsState } from "store/Reducers/ChatBot/chatBotStats";
import chatBotStatsReducer from "store/Reducers/ChatBot/chatBotStats";
import * as chatBotStatsActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";
import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import "./Stats.scss";
import Datepicker from "components/Calender/Datepicker";
import SectionFilters from "components/Layouts/SectionFilters";
import SectionHeader from "components/Layouts/SectionHeader";
import SectionButtons from "components/Layouts/SectionButtons";
import Loader from "components/Loader/Loader";
import CommonTable from "components/Table/CommonTable";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

interface CurrentState {
    commonComponentData: CommonComponentState,
    chatBotStats: ChatBotStatsState,
    languageData: LanguageState
}

const Stats: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const { showBoundary } = useErrorBoundary();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const dispatch = useDispatch();

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0,],
        showMaintenance_flag: false
    });

    const [parameter, setParameter] = useState({
        parameter: "",
        isDeleted: "",
        conversationTitle: ""
    });

    const [chatBotStatsChart, setChatBotStatsChart] = useState({
        loadChatBotStatsChartData: [],
        loadChatBotStatsChartOptions: [],
        chatBotStatsChartDataFetched: false,
        chatBotStatsChart: {},
        chatBotStatsChartFetched: false,
    });

    const [chatBotStatsSummaryTable, setChatBotStatsSummaryTable] = useState({
        chatBotStatsSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'conversation_responses',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    const [request_uuid, setUuid] = useState({
        value: ''
    })

    const linechart_height = 400;

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        if (insightsLbls) {
            document.title = insightsLbls.conversationStats;
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(4, 0, 0, true, false);
    }, [insightsLbls])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            if (isInitialMount.current) {
                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: `/chatbot/chatbot/`,
                    search: qs.stringify(queryParams)
                })

                if (fetched_details?.commonComponentData.datesData.source === 'component') {
                    if (fetched_details?.commonComponentData.datesData?.url?.includes('chatbot/chatbot/')) {
                        isInitialMount.current = false;
                        const reqUUID = uuidv4();
                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })

                        setChatBotStatsChart((prevState: any) => {
                            return {
                                ...prevState,
                                chatBotStatsChartDataFetched: false,
                                chatBotStatsChart: {},
                                chatBotStatsChartFetched: false
                            };
                        });

                        enableExportPdf(0, false);
                        getChatBotStatsChartData(reqUUID);

                        enableExportPdf(1, false);
                        setChatBotStatsSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                chatBotStatsSummaryTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'conversation_responses',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 2,
                                    'sortValue': 'desc'
                                },
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true,
                            };
                        });
                    }
                }
            }

            setTimeout(() => {
                isInitialMount.current = true;
            },100)

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.commonComponentData.datesData])

    useEffect(() => {
        try {
            if (fetched_details?.commonComponentData.datesData?.url?.includes('chatbot/chatbot/') && request_uuid.value) {
                enableExportPdf(1, false);
                getChatBotStatsSummaryTableData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        chatBotStatsSummaryTable.sortKey,
        chatBotStatsSummaryTable.sortValue,
        chatBotStatsSummaryTable.defaultSort,
        chatBotStatsSummaryTable.page,
        chatBotStatsSummaryTable.searchQuery,
        chatBotStatsSummaryTable.limit,
        chatBotStatsSummaryTable.filterLabels,
    ]);


    //Chart Data
    useEffect(() => {
        try {
            if (
                fetched_details.chatBotStats.chatBotStatsChartData.data !== undefined &&
                fetched_details.chatBotStats.chatBotStatsChartData.result === "success" &&
                fetched_details?.chatBotStats.chatBotStatsChartData?.requestUUID === request_uuid.value
            ) {

                // let surveyBarChartData: any = [];
                // surveyBarChartData = fetched_details.chatBotStats.chatBotStatsChartData.data;

                setChatBotStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsChartDataFetched: true,
                        loadChatBotStatsChartData: fetched_details.chatBotStats.chatBotStatsChartData.data
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.chatBotStats.chatBotStatsChartData.result === 'retry') {
                getChatBotStatsChartData('retry');
            } else if (
                fetched_details.chatBotStats.chatBotStatsChartData.result === "error"
            ) {
                setChatBotStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsBarChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.chatBotStats.chatBotStatsChartData]);

    //Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details.chatBotStats.chatBotStatsSummaryTableData.data !== undefined &&
                fetched_details.chatBotStats.chatBotStatsSummaryTableData.result === "success" &&
                fetched_details?.chatBotStats.chatBotStatsSummaryTableData?.requestUUID === request_uuid.value
            ) {

                setChatBotStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.chatBotStats.chatBotStatsSummaryTableData.result === 'retry') {
                getChatBotStatsSummaryTableData('retry');
            } else if (
                fetched_details.chatBotStats.chatBotStatsSummaryTableData.result === "error"
            ) {
                setChatBotStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.chatBotStats.chatBotStatsSummaryTableData]);

    //Detail Navigation
    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }

                navigate({
                    pathname: "/chatbot/chatbot-details/" + parameter.parameter + "/",
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setChatBotStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsChartDataFetched: false,
                        chatBotStatsChart: {},
                        chatBotStatsChartFetched: false
                    };
                });
                getChatBotStatsChartData(request_uuid.value);

            } else if (optn === 1) {
                setChatBotStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsSummaryTableDataFetched: false,
                        searchQuery: '',
                        sortKey: 'conversation_responses',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        hideDeleted: 0
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const getChatBotStatsChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            'request_uuid': reqUUID
        };

        chatBotStatsActions.getChatBotStatsData(
            params,
            "CHATBOT_STATS_CHART",
            ActionTypes.SET_CHATBOT_STATS_CHART,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }

    const getChatBotStatsSummaryTableData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "page_size": chatBotStatsSummaryTable.limit,
            "sort_by": chatBotStatsSummaryTable.sortKey,
            "order": chatBotStatsSummaryTable.sortValue,
            "page_index": chatBotStatsSummaryTable.page,
            "search_text": chatBotStatsSummaryTable.searchQuery,
            "filter_label": chatBotStatsSummaryTable.filterLabels,
            'request_uuid': reqUUID
        };

        chatBotStatsActions.getChatBotStatsData(
            params,
            "CHATBOT_STATS_SUMMARY",
            ActionTypes.SET_CHATBOT_STATS_SUMMARY,
            apiErrorFlag ?? '',
            chatBotStatsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    if (data.conversationResponse > 0) {
                        setParameter((prevState: any) => {
                            return {
                                ...prevState,
                                parameter: data.conversationCode,
                                isDeleted: data.isDeleted,
                                conversationTitle: data.conversationTitle
                            };
                        });
                    }
                    break;
                case "sortColumn":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "applyLabels":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            filterLabels: data,
                            firstLoad: false
                        };
                    });
                    break;

                case "changeEntries":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "hideDeleted":
                    setChatBotStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [chatBotStatsSummaryTable]);

    const exportExcel = (data: any) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "page_size": chatBotStatsSummaryTable.limit,
            "sort_by": chatBotStatsSummaryTable.sortKey,
            "order": chatBotStatsSummaryTable.sortValue,
            "page_index": chatBotStatsSummaryTable.page,
            "search_text": chatBotStatsSummaryTable.searchQuery,
            "filter_label": chatBotStatsSummaryTable.filterLabels,
        };
        getCallExportExcel(params, 'CHATBOT_STATS_SUMMARY', data);
    }

    return (
        <section className="demo surveyStats width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.chatBot}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            <SectionButtons sectionTitle={insightsLbls.stats} svgImage={"surveys"} className={'marginTop-50'}>
                {/* <ExportToPdf
                    componentRef={componentRef}
                    source={"ChatBotStats"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf> */}
            </SectionButtons>

            <div id="chatBotStatsSection">
                {
                    <SectionRefresh sectionTitle={insightsLbls.conversation} refreshClass={""}>
                        <i
                            className="fa fa-refresh pull-right pointer row displayFlex"
                            aria-hidden="true" title={insightsLbls.refresh}
                            onClick={() => refreshSections(0)}>
                        </i>
                    </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {<SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.lineChartConversationStatTooltip,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            }
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {chatBotStatsChart.chatBotStatsChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    chatBotStatsChart.loadChatBotStatsChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (

                                        <LineChart
                                            chartEntities={['conversationsResponse']}
                                            optionEntities={
                                                [
                                                    {
                                                        key: insightsLbls.conversationsResponse,
                                                        color: ChartColors.lightseagreen, area: true
                                                    }
                                                ]}
                                            chartData={fetched_details?.chatBotStats?.chatBotStatsChartData?.data.graphData}
                                            value_type={"count"}
                                            height={linechart_height}
                                            margin={lineChartMargin}
                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                            refId="chatbot_conversationResponse">
                                        </LineChart>

                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}

                        </div>
                    </CardContainer>
                </div>


                {
                    <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                    </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.conversationSummary,
                                        "tooltipText": insightsLbls.tableActitiesofConversation,
                                        "childern": [
                                            {
                                                "key": insightsLbls.conversationName,
                                                "value": insightsLbls.nameConversationCreated
                                            },
                                            {
                                                "key": insightsLbls.label + ":",
                                                "value": insightsLbls.labelsofConversation
                                            },
                                            {
                                                "key": insightsLbls.responses + ":",
                                                "value": insightsLbls.numberResponsesEachConversation
                                            },
                                            {
                                                "key": insightsLbls.lastUpdatedOn + ":",
                                                "value": insightsLbls.dateTimeofConversationModification
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                        </ContainerTooltip>
                        {
                            chatBotStatsSummaryTable.chatBotStatsSummaryTableDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable chatBotStatsTable">
                                        <CommonTable tableId="1"
                                            data={fetched_details.chatBotStats.chatBotStatsSummaryTableData.data}
                                            currentPage={chatBotStatsSummaryTable.page}
                                            barColors="positiveColors"
                                            linkPresent={true}
                                            showExport={false}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={chatBotStatsSummaryTable.defaultSort}
                                            tablePagination={chatBotStatsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details.chatBotStats.chatBotStatsSummaryTableData.firstTimeload}
                                            modalTable={false}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[1] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>
            </div>
        </section>

    )
}

export default withRouter(withReducer("chatBotStats", chatBotStatsReducer)(Stats));
