import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';



// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";

import { FeatureStatsState } from "store/Reducers/AppInsights/feature";
import featureStatsReducer from "store/Reducers/AppInsights/feature";

import * as featureStatsActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


// JSON Imports
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import LineChart from "components/Chart/nvd3Charts/LineChart";


// SCSS Imports
import "./Feature.scss";



import Datepicker from "components/Calender/Datepicker";
import SectionFilters from "components/Layouts/SectionFilters";
import SectionHeader from "components/Layouts/SectionHeader";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import SectionButtons from "components/Layouts/SectionButtons";
import Loader from "components/Loader/Loader";
import CommonTable from "components/Table/CommonTable";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

interface CurrentState {
    commonComponentData: CommonComponentState,
    featureStats: FeatureStatsState,
    languageData: LanguageState
}

const Stats: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    },[fetched_details.languageData.languageData])

    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const dispatch = useDispatch();


    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0,],
        showMaintenance_flag: false
    });

    const [parameter, setParameter] = useState({
        parameter: "",
        isDeleted: "",
        surveyName: ""
    });

    const [featureStatsChart, setFeatureStatsChart] = useState({
        loadFeatureStatsChartData: [],
        loadFeatureStatsChartOptions: [],
        featureStatsChartDataFetched: false,
        featureStatsChart: {},
        featureStatsChartFetched: false,
    });

    const [featureStatsSummaryTable, setFeatureStatsSummaryTable] = useState({
        featureStatsSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'feature_interactions',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    const linechart_height = 400;


    const [request_uuid, setUuid] = useState({
        value: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        document.title = insightsLbls.feature;
    }, [insightsLbls])

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(2, 1, 0, false, false);
    }, [insightsLbls]);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
            }
            navigate({
                pathname: `/app-insights/feature/`,
                search: qs.stringify(queryParams)
            })

            if (fetched_details?.commonComponentData.datesData.source === 'component') {
                if (fetched_details?.commonComponentData.datesData?.url?.includes('feature/')) {

                    const reqUUID = uuidv4();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })

                    setFeatureStatsChart((prevState: any) => {
                        return {
                            ...prevState,
                            featureStatsChartDataFetched: false,
                            featureStatsChart: {},
                            featureStatsChartFetched: false
                        };
                    });

                    enableExportPdf(0, false);
                    getFeatureStatsChartData(reqUUID);

                    enableExportPdf(1, false);
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            featureStatsSummaryTableDataFetched: false,
                            searchQuery: '',
                            sortKey: 'feature_interactions',
                            filterLabels: [],
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 2,
                                'sortValue': 'desc'
                            },
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                        };
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.commonComponentData.datesData])

    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('feature/') && request_uuid.value) {
                enableExportPdf(1, false);
                getFeatureStatsSummaryTableData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        featureStatsSummaryTable.sortKey,
        featureStatsSummaryTable.sortValue,
        featureStatsSummaryTable.defaultSort,
        featureStatsSummaryTable.page,
        featureStatsSummaryTable.searchQuery,
        featureStatsSummaryTable.limit,
        featureStatsSummaryTable.filterLabels,
    ]);


    //Chart Data
    useEffect(() => {

        try {
            if (
                fetched_details.featureStats.featureStatsChartData.data !== undefined &&
                fetched_details.featureStats.featureStatsChartData.result === "success" &&
                fetched_details.featureStats.featureStatsChartData?.requestUUID === request_uuid.value
            ) {

                // let surveyBarChartData: any = [];
                // surveyBarChartData = fetched_details.featureStats.featureStatsChartData.data;

                setFeatureStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        featureStatsChartDataFetched: true,
                        loadFeatureStatsChartData: fetched_details.featureStats.featureStatsChartData.data
                    };
                });

                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.featureStats.featureStatsChartData.result === 'retry') {
                getFeatureStatsChartData('retry');
            } else if (
                fetched_details.featureStats.featureStatsChartData.result === "error"
            ) {
                setFeatureStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        surveyStatsBarChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.featureStats.featureStatsChartData]);

    //Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details.featureStats.featureStatsSummaryTableData.data !== undefined &&
                fetched_details.featureStats.featureStatsSummaryTableData.result === "success" &&
                fetched_details.featureStats.featureStatsSummaryTableData?.requestUUID === request_uuid.value
            ) {

                setFeatureStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        featureStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.featureStats.featureStatsSummaryTableData.result === 'retry') {
                getFeatureStatsSummaryTableData(request_uuid.value, 'retry');
            } else if (
                fetched_details.featureStats.featureStatsSummaryTableData.result === "error"
            ) {
                setFeatureStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        featureStatsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.featureStats.featureStatsSummaryTableData]);

    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setFeatureStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        featureStatsChartDataFetched: false,
                        featureStatsChart: {},
                        featureStatsChartFetched: false
                    };
                });
                getFeatureStatsChartData(request_uuid.value);

            }
            else if (optn === 1) {
                setFeatureStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        featureStatsSummaryTableDataFetched: false,
                    };
                });
                getFeatureStatsSummaryTableData(request_uuid.value);

            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const getFeatureStatsChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            'request_uuid': reqUUID
        };

        featureStatsActions.getFeatureStatsData(
            params,
            "FEATURE_STATS_CHART",
            ActionTypes.SET_FEATURE_STATS_CHART,
            apiErrorFlag ?? '',
        ).then((res : any) => dispatch(res));
    }

    const getFeatureStatsSummaryTableData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "page_size": featureStatsSummaryTable.limit,
            "sort_by": featureStatsSummaryTable.sortKey,
            "order": featureStatsSummaryTable.sortValue,
            "page_index": featureStatsSummaryTable.page,
            "search_text": featureStatsSummaryTable.searchQuery,
            "filter_label": featureStatsSummaryTable.filterLabels,
            'request_uuid': reqUUID
        };

        featureStatsActions.getFeatureStatsData(
            params,
            "FEATURE_STATS_SUMMARY",
            ActionTypes.SET_FEATURE_STATS_SUMMARY,
            apiErrorFlag ?? '',
            featureStatsSummaryTable.firstLoad
        ).then((res : any) => dispatch(res));
    };


    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            parameter: data.featureCode,
                            isDeleted: data.isDeleted,
                            featureGuideTitle: data.featureGuideTitle
                        };
                    });
                    break;

                case "sortColumn":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "applyLabels":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            filterLabels: data,
                            firstLoad: false
                        };
                    });
                    break;

                case "changeEntries":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "hideDeleted":
                    setFeatureStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [featureStatsSummaryTable]);

    const exportExcel = (data: any) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "page_size": featureStatsSummaryTable.limit,
            "sort_by": featureStatsSummaryTable.sortKey,
            "order": featureStatsSummaryTable.sortValue,
            "page_index": featureStatsSummaryTable.page,
            "search_text": featureStatsSummaryTable.searchQuery,
            "filter_label": featureStatsSummaryTable.filterLabels,
        };
        getCallExportExcel(params, 'CHATBOT_STATS_SUMMARY', data);
    }


    // navigation to feature detail @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@fa-border

    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }

                navigate({
                    pathname: "/app-insights/feature/feature-detail/" + parameter.parameter + "/",
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    return (
        <section className="demo surveyStats width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.feature}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            <SectionButtons sectionTitle={insightsLbls.feature} svgImage={"surveys"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"FeatureStats"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons>

            <div id="featureStatsSection">
                {
                    <SectionRefresh sectionTitle={insightsLbls.feature} refreshClass={""}>
                        <i
                            className="fa fa-refresh pull-right pointer row displayFlex"
                            aria-hidden="true" title={insightsLbls.refresh}
                            onClick={() => refreshSections(0)}>
                        </i>
                    </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {<SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.lineChartTooltipFeatureDetail,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            }
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {featureStatsChart.featureStatsChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    featureStatsChart.loadFeatureStatsChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (

                                        <LineChart
                                            chartEntities={['featureInteractions']}
                                            optionEntities={
                                                [
                                                    {
                                                        key: insightsLbls.featureInteractions,
                                                        color: ChartColors.lightseagreen, area: true
                                                    }
                                                ]}
                                            chartData={fetched_details?.featureStats?.featureStatsChartData?.data.graphData}
                                            value_type={"count"}
                                            height={linechart_height}
                                            margin={lineChartMargin}
                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                            refId="chatbot_conversationResponse">
                                        </LineChart>

                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}

                        </div>
                    </CardContainer>
                </div>


                {
                    <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                    </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.featureSummary,
                                        "tooltipText": insightsLbls.tableActivitiesOfFeature,
                                        "childern": [
                                            {
                                                "key": insightsLbls.featureSetName + ":",
                                                "value": insightsLbls.nameOfFeatureSet
                                            },
                                            {
                                                "key": insightsLbls.interactions + ":",
                                                "value": insightsLbls.numOfInteractions
                                            },
                                            {
                                                "key": insightsLbls.lastUpdatedOn + ":",
                                                "value": insightsLbls.dateTimeOfFeatureModification
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                        </ContainerTooltip>
                        {
                            featureStatsSummaryTable.featureStatsSummaryTableDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable featureStatsSummaryTable">
                                        <CommonTable tableId="1"
                                            data={fetched_details.featureStats.featureStatsSummaryTableData.data}
                                            currentPage={featureStatsSummaryTable.page}
                                            barColors="positiveColors"
                                            linkPresent={true}
                                            showExport={false}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={featureStatsSummaryTable.defaultSort}
                                            tablePagination={featureStatsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details.featureStats.featureStatsSummaryTableData.firstTimeload}
                                            modalTable={false}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[1] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>
            </div>
        </section>

    )
}

export default withRouter(withReducer("featureStats", featureStatsReducer)(Stats));
