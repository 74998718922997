import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CommonComponentState, LanguageState } from "../../store/Reducers";
import { DashboardState } from "store/Reducers/Dashboard/dashboard";

import * as userEngagementActions from "../../store/Actions/index";
import * as ActionTypes from "../../store/Actions/ActionTypes";
import moment from "moment";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import Loader from "components/Loader/Loader";
import { useErrorBoundary } from 'react-error-boundary';

import './UserEngagementLazyLoad.scss';
import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import Bubblechart from "components/Chart/Bubblechart";
import { InsightLabels } from "labels";

interface CurrentState {
    commonComponentData: CommonComponentState,
    dashboard: DashboardState,
    languageData: LanguageState
}


const DashboardUserEngagementLazyLoad = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

    useEffect(() => {
        if (insightsLbls) {
            getSelectedLabels.then((data) => {
                setInsightsLbls(data);
            });
        }
    }, [insightsLbls])

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const dispatch = useDispatch();
    let fetched_details = useSelector((state: CurrentState) => state);
    let datesNew: any = [];
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    };

    let linechart_height = 450;

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const [userEngagmentUserChart, setUserEngagmentUserChart] = useState({
        dateDiff: 0,
        dateFlag: false,
        dates: [{ fdate: [], tdate: [] }],
        _showLoader: false,
        refreshbtn: false,
        refreshFlag: false,
        errorcode: 0,
    })

    const [chartStats, setChartStats] = useState({
        chartData: [],
        chartFlag: false,
        userChart: [],
        userChartOptions: [],
        firstLoad: true,
        initial_data: [{
            startTimestamp: '',
            counts: {}
        }],
        datesNew: [],
        chartLazyLoadFlag: false,
        overlayFlag: false,
        bubbleData: [],
        totalAppsCount: 0,
        optionEntities: []
    });


    const [appsList, setAppsList] = useState({
        appsFetched: false,
        appList: [],
        appNames: [],
        appListString: [],
        lineChartEntities: ["app1", "app2", "app3", "app4", "app5", "app6", "app7", "app8", "app9", "app10","appx"]
    });


    const [tileData, setTileData] = useState({
        app1: [],
        app2: [],
        app3: [],
        app4: [],
        app5: [],
        app6: [],
        app7: [],
        app8: [],
        app9: [],
        app10: [],
        appx: [],
    });

    const [dateState, setDateState] = useState(false);

    useEffect(() => {
     
        try {
            setDateState(true);
            setUserEngagmentUserChart((prevState: any) => {
                return {
                    ...prevState,
                    dateDiff: 0,
                    dateFlag: false,
                    dates: [{ fdate: [], tdate: [] }],
                    _showLoader: false,
                    refreshbtn: false,
                    refreshFlag: false,
                    errorcode: 0,
                }
            })

            // setTimeout(() => {
            setChartStats((prevState: any) => {
                return {
                    ...prevState,
                    chartData: [],
                    chartFlag: false,
                    userChart: [],
                    userChartOptions: [],
                    firstLoad: true,
                    initial_data: [{
                        startTimestamp: '',
                        counts: {}
                    }],
                    datesNew: [],
                    chartLazyLoadFlag: false,
                    overlayFlag: false,
                    bubbleData: [],
                    totalAppsCount: 0,
                    optionEntities: []
                };
            });

            setTileData((prevState: any) => {
                return {
                    ...prevState,
                    app1: [],
                    app2: [],
                    app3: [],
                    app4: [],
                    app5: [],
                    app6: [],
                    app7: [],
                    app8: [],
                    app9: [],
                    app10: [],
                }
            })

            props.dispatchOverlay(false);

            if (fetched_details?.commonComponentData?.datesData.dateSelection === 8
                || fetched_details?.commonComponentData?.datesData.dateSelection === 9
                || fetched_details?.commonComponentData?.datesData.dateSelection === 10
                || fetched_details?.commonComponentData?.datesData.dateSelection === 12
                || fetched_details?.commonComponentData?.datesData.dateSelection === 11
                || fetched_details?.commonComponentData?.datesData.dateSelection === 16
                || fetched_details?.commonComponentData?.datesData.dateSelection === 13
                || fetched_details?.commonComponentData?.datesData.dateSelection === 14
                || fetched_details?.commonComponentData?.datesData.dateSelection === 15) {

                if (fetched_details?.commonComponentData?.datesData.dateSelection === 15) {
                    const a = moment(fetched_details?.commonComponentData?.datesData.tdate);
                    const b = moment(fetched_details?.commonComponentData?.datesData.fdate);

                    const daysLen = Math.ceil(a.diff(b, 'days', true)) + 1;
                    if (daysLen < 7) {
                        getUserEngagementUserData(moment(fetched_details?.commonComponentData?.datesData.tdate).subtract(6, 'd').format('YYYY-MM-DD'), fetched_details?.commonComponentData?.datesData.tdate, true);
                    } else {
                        getDates_array();
                    }
                } else {
                    getDates_array();
                }

                props.dispatchOverlay(true);

            }
            else {
                getUserEngagementUserData(fetched_details?.commonComponentData?.datesData.fdate, fetched_details?.commonComponentData?.datesData.tdate, true);
                setUserEngagmentUserChart((prevState: any) => {
                    return {
                        ...prevState,
                        dateDiff: 0
                    }
                })

                setChartStats((prevState: any) => {
                    return {
                        ...prevState,
                        firstLoad: true,
                    }
                })
                props.dispatchOverlay(true);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.commonComponentData?.datesData.dateSelection,
    props.refreshFlag,
    fetched_details?.commonComponentData?.eventSource.eventSourceDashboard,
    fetched_details?.commonComponentData?.datesData.fdate,
    props.appList])

    const getDates_array = () => {
        try {
            //to set refresh icon
            props.dispatchOverlay(false);
            const a = moment(fetched_details?.commonComponentData?.datesData.tdate);
            const b = moment(fetched_details?.commonComponentData?.datesData.fdate);

            const daysLen = Math.ceil(a.diff(b, 'days', true)) + 1;
            let dates_new: any = [];

            if (daysLen < 7) {
                dates_new[0] = [];
                dates_new[0].fdate = moment(fetched_details?.commonComponentData?.datesData.tdate).subtract(6, 'd').format('YYYY-MM-DD');
                dates_new[0].tdate = fetched_details?.commonComponentData?.datesData.tdate;

            } else {

                let cnt = 0;
                for (let i = 0; i < Math.floor((daysLen) / 7); i++) {
                    dates_new[i] = [];
                    const fdate = moment(fetched_details?.commonComponentData?.datesData.tdate).subtract(cnt + 6, 'd').format('YYYY-MM-DD');
                    dates_new[i].fdate = fdate;
                    dates_new[i].tdate = moment(fetched_details?.commonComponentData?.datesData.tdate).subtract(cnt, 'd').format('YYYY-MM-DD');
                    cnt = cnt + 7;
                }

                if ((daysLen % 7) >= 3) {
                    // if extra days are greater than or equal to 3 then add new array

                    let lastFdate = dates_new[dates_new.length - 1].fdate;

                    let extraDays: any = [];
                    extraDays[0] = []

                    extraDays[0].fdate = fetched_details?.commonComponentData?.datesData.fdate;
                    extraDays[0].tdate = moment(lastFdate).subtract(1, 'd').format('YYYY-MM-DD');
                    dates_new = dates_new.concat(extraDays);
                } else {
                    // if extra days are less than 3 then add it to last value of fdate
                    dates_new[dates_new.length - 1].fdate = fetched_details?.commonComponentData?.datesData.fdate
                }
            }

            setUserEngagmentUserChart((prevState: any) => {
                return {
                    ...prevState,
                    dates: dates_new
                }
            })

            setChartStats((prevState: any) => {
                return {
                    ...prevState,
                    firstLoad: true
                }
            })
            getUserEngagementUserData(dates_new[0].fdate, dates_new[0].tdate, true);
        } catch (error) {
            showBoundary(error)
        }

    };


    const getUserEngagementUserData = (fDate: any, tDate: any, firstLoad: boolean, apiErrorFlag?: string) => {
        try {
            if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
                setChartStats((prevState: any) => {
                    return {
                        ...prevState,
                        chartLazyLoadFlag: false,
                        overlayFlag: fetched_details?.commonComponentData?.datesData.dateSelection === 3 ? false : true

                    }
                })
                if (firstLoad) {
                    setUserEngagmentUserChart((prevState: any) => {
                        return {
                            ...prevState,
                            refreshFlag: true
                        }
                    })
                }

                if (fDate !== 'null' && tDate !== 'null') {
                    const params = {
                        'end_date': tDate + '_23:59:59',
                        'start_date': fDate + '_00:00:00',
                        'app_id': props.appList,
                        'time_zone': timeZone,
                        "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceDashboard
                    };

                    userEngagementActions.getDashboardData(
                        params,
                        "DASHBOARD_USER_ENGAGEMENT",
                        ActionTypes.SET_DASHBOARD_USER_ENGAGEMENT,
                        apiErrorFlag ?? '',
                        firstLoad,
                        fetched_details?.commonComponentData?.datesData.dateSelection
                    ).then((res: any) => dispatch(res))
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    useEffect(() => {
        try {
            if (fetched_details.dashboard.dashboardUserEngagementData.data !== undefined && fetched_details.dashboard.dashboardUserEngagementData.result === 'success') {

                if (fetched_details.dashboard.dashboardUserEngagementData.firstTimeload) {
                    setTileData((prevState: any) => {
                        return {
                            ...prevState,
                            app1: [],
                            app2: [],
                            app3: [],
                            app4: [],
                            app5: [],
                            app6: [],
                            app7: [],
                            app8: [],
                            app9: [],
                            app10: [],
                            appx: [],
                        }
                    })
                }

                setUserEngagmentUserChart((prevState: any) => {
                    return {
                        ...prevState,
                        errorcode: 0,
                    }
                })



                if (userEngagmentUserChart.dates.length > 1) {
                    const datesArray = userEngagmentUserChart.dates;
                    if (userEngagmentUserChart.dates) {

                        // first shift array and then call api
                        datesArray.shift();
                        if (datesArray.length === 1) {
                            setTimeout(() => {
                                setChartStats((prevState: any) => {
                                    return {
                                        ...prevState,
                                        overlayFlag: false
                                    }
                                })
                            }, 1000);

                            //to set refresh icon
                            props.dispatchOverlay(true);
                        }

                        setChartStats((prevState: any) => {
                            return {
                                ...prevState,
                                firstLoad: false
                            }
                        })

                        getUserEngagementUserData(datesArray[0].fdate, datesArray[0].tdate, false);

                        setUserEngagmentUserChart((prevState: any) => {
                            return {
                                ...prevState,
                                dates: datesArray
                            }
                        })
                    }
                } else {
                    setTimeout(() => {
                        setChartStats((prevState: any) => {
                            return {
                                ...prevState,
                                overlayFlag: false
                            }
                        })
                    }, 1000);
                }

                if (chartStats.firstLoad) {
                    setTileData((prevState: any) => {
                        return {
                            ...prevState,
                            app1: [],
                            app2: [],
                            app3: [],
                            app4: [],
                            app5: [],
                            app6: [],
                            app7: [],
                            app8: [],
                            app9: [],
                            app10: [],
                        }
                    })


                    let lenCount = 0;
                    var len = moment(fetched_details?.commonComponentData?.datesData.tdate).diff(moment(fetched_details?.commonComponentData?.datesData.fdate), 'days', true);

                    if (len < 7) {
                        lenCount = 7;
                    } else {
                        lenCount = len + 1;
                    }

                    var cnt = 1;
                    for (let i = 0; i < lenCount; i++) {
                        if (i === 0) {
                            datesNew.push(moment(fetched_details?.commonComponentData?.datesData.tdate).format("YYYY-MM-DD"));
                        }
                        else {
                            datesNew.push(moment(fetched_details?.commonComponentData?.datesData.tdate).subtract(cnt, 'days').format("YYYY-MM-DD"));
                            cnt++;
                        }
                    }

                    datesNew.reverse();

                    let chart_data: any = [];
                    for (let i = 0; i < datesNew.length; i++) {
                        chart_data[i] = [];
                        let datesSplit = datesNew[i].split('-')
                        chart_data[i].startTimestamp = datesSplit[2] + ' ' + (moment.monthsShort(datesSplit[1] - 1)) + ' ' + datesSplit[0];
                        chart_data[i].counts = {};
                        chart_data[i].counts.app1 = 0;
                        chart_data[i].counts.app2 = 0;
                        chart_data[i].counts.app3 = 0;
                        chart_data[i].counts.app4 = 0;
                        chart_data[i].counts.app5 = 0;
                        chart_data[i].counts.app6 = 0;
                        chart_data[i].counts.app7 = 0;
                        chart_data[i].counts.app8 = 0;
                        chart_data[i].counts.app9 = 0;
                        chart_data[i].counts.app10 = 0;
                        chart_data[i].counts.appx = 0;
                    }

                    const graphData = fetched_details?.dashboard?.dashboardUserEngagementData?.data.graphData;

                    let optionEntities: any = [];
                    optionEntities = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.metadata;

                    for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                        let cnt = 0;
                        for (let itx2 = 0; itx2 < graphData.length; itx2++) {
                            cnt = cnt + graphData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                        }
                        optionEntities[itx1].count = cnt
                    }


                    const dummyChartData = [...optionEntities];
                    dummyChartData.sort((a, b) => {
                        return b.count - a.count;
                    });


                    let userChartOptions: any = [];
                    let maxCountApps:any = []
                    if(dummyChartData.length > 5){
                      
                        for (let itr0 = 0; itr0 < 5; itr0++) {
                            userChartOptions[itr0] = {
                                key: dummyChartData[itr0].legendTitle?.trim(),
                                color: dummyChartData[itr0].color,
                                area: dummyChartData[itr0].area,
                                associatedDataPoint: dummyChartData[itr0].associatedDataPoint
                            }

                            // to save all the maxCounted apps
                            maxCountApps.push(dummyChartData[itr0].associatedDataPoint)
                           
                        }

                        userChartOptions.push({
                            key: 'Others',
                            color: '#808080',
                            area: false,
                            associatedDataPoint: 'appx'
                        })
                    } else {
                        for (let itr0 = 0; itr0 < dummyChartData.length; itr0++) {
                                 userChartOptions[itr0] = {
                                key: dummyChartData[itr0].legendTitle?.trim(),
                                color: dummyChartData[itr0]?.color,
                                area: dummyChartData[itr0]?.area,
                                associatedDataPoint: dummyChartData[itr0]?.associatedDataPoint
                            }
                        }
                    }


                    for (let i = 0; i < chart_data.length; i++) {
                        for (let j = 0; j < graphData.length; j++) {
                            let cnt = 0;
                            if (chart_data && graphData) {
                                if (chart_data[i].startTimestamp === graphData[j].startTimestamp) {
                                    chart_data[i].counts.app1 = graphData[j].counts['app1'];
                                    chart_data[i].counts.app2 = graphData[j].counts['app2'];
                                    chart_data[i].counts.app3 = graphData[j].counts['app3'];
                                    chart_data[i].counts.app4 = graphData[j].counts['app4'];
                                    chart_data[i].counts.app5 = graphData[j].counts['app5'];
                                    chart_data[i].counts.app6 = graphData[j].counts['app6'];
                                    chart_data[i].counts.app7 = graphData[j].counts['app7'];
                                    chart_data[i].counts.app8 = graphData[j].counts['app8'];
                                    chart_data[i].counts.app9 = graphData[j].counts['app9'];
                                    chart_data[i].counts.app10 = graphData[j].counts['app10'];

                                    // appx is count of all the apps which are not inclued in maxCount
                                    if(dummyChartData.length > 5){
                                        for (const data in graphData[j].counts) {
                                            if(!maxCountApps.includes(data)){
                                                cnt = cnt + graphData[j].counts[data]

                                            }
                                        }
                                        chart_data[i].counts.appx = cnt;
                                    }
                                }
                            }
                        }
                    }

                    setChartStats((prevState: any) => {
                        return {
                            ...prevState,
                            chartData: [...chart_data],
                            initial_data: [...chart_data],
                            datesNew: datesNew,
                            userChart: [...chart_data],
                            userChartOptions: userChartOptions,
                            totalAppsCount: fetched_details?.dashboard?.dashboardUserEngagementData?.data?.metadata?.length,
                            optionEntities: optionEntities,
                        };
                    });

                } else {
                    const graphData = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.graphData;

                    let optionEntities: any = [];
                    optionEntities = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.metadata;

                    for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                        let cnt = 0;
                        for (let itx2 = 0; itx2 < graphData.length; itx2++) {
                            cnt = cnt + graphData[itx2]['counts'][optionEntities[itx1]?.associatedDataPoint]
                        }
                        // to add previous count
                        optionEntities[itx1].count = cnt + chartStats.optionEntities[itx1]?.count
                    }


                    const dummyChartData = [...optionEntities];
                    dummyChartData.sort((a, b) => {
                        return b.count - a.count;
                    });

               
                    let userChartOptions: any = [];

                    // to calculate to 5 apps and others on appx
                    let maxCountApps:any = []
                    if(dummyChartData.length > 5){
                      
                        for (let itr0 = 0; itr0 < 5; itr0++) {
                            userChartOptions[itr0] = {
                                key: dummyChartData[itr0].legendTitle?.trim(),
                                color: dummyChartData[itr0].color,
                                area: dummyChartData[itr0].area,
                                associatedDataPoint: dummyChartData[itr0].associatedDataPoint
                            }
                            maxCountApps.push(dummyChartData[itr0].associatedDataPoint)
                        }

                        userChartOptions.push({
                            key: 'Others',
                            color: '#808080',
                            area: false,
                            associatedDataPoint: 'appx'
                        })
                          
                    } else {
                        for (let itr0 = 0; itr0 < dummyChartData.length; itr0++) {
                            userChartOptions[itr0] = {
                                key: dummyChartData[itr0]?.legendTitle?.trim(),
                                color: dummyChartData[itr0]?.color,
                                area: dummyChartData[itr0]?.area,
                                associatedDataPoint: dummyChartData[itr0]?.associatedDataPoint
                            }
                        }
                    }

                    for (let i = 0; i < chartStats.datesNew.length; i++) {
                        for (let j = 0; j < graphData.length; j++) {
                            let cnt = 0;
                            if (chartStats.initial_data && graphData) {
                                if (chartStats.initial_data[i].startTimestamp === graphData[j].startTimestamp) {
                                    chartStats.initial_data[i].counts = {};
                                    chartStats.initial_data[i].counts["app1"] = graphData[j].counts['app1'];
                                    chartStats.initial_data[i].counts["app2"] = graphData[j].counts['app2'];
                                    chartStats.initial_data[i].counts["app3"] = graphData[j].counts['app3'];
                                    chartStats.initial_data[i].counts["app4"] = graphData[j].counts['app4'];
                                    chartStats.initial_data[i].counts["app5"] = graphData[j].counts['app5'];
                                    chartStats.initial_data[i].counts["app6"] = graphData[j].counts['app6'];
                                    chartStats.initial_data[i].counts["app7"] = graphData[j].counts['app7'];
                                    chartStats.initial_data[i].counts["app8"] = graphData[j].counts['app8'];
                                    chartStats.initial_data[i].counts["app9"] = graphData[j].counts['app9'];

                                    chartStats.initial_data[i].counts["app10"] = graphData[j].counts['app10'];

                                    // appx is count of all the apps which are not inclued in maxCount
                                    if(dummyChartData.length > 5){
                                        for (const data in graphData[j].counts) {
                                            if(!maxCountApps.includes(data)){
                                                cnt = cnt + graphData[j].counts[data]

                                            }
                                        }
                                        chartStats.initial_data[i].counts["appx"] = cnt;
                                    }

                                }
                            }
                        }
                    }

                    setChartStats((prevState: any) => {
                        return {
                            ...prevState,
                            userChart: chartStats.initial_data,
                            userChartOptions: userChartOptions,
                        };
                    });
                }


                if (fetched_details?.dashboard?.dashboardUserEngagementData?.data) {
                    let optionEntities: any = [];
                    optionEntities = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.metadata;
                    
                    let dummyData = [];
                    dummyData = optionEntities;

                   

                    for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                        dummyData[itx1].count = 0;
                        if (optionEntities[itx1].associatedDataPoint === 'app1') {
                            let cnt = 0;
                            cnt = tileData.app1.length;                            
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app1;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }

                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app1: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                        } else if (optionEntities[itx1].associatedDataPoint === 'app2') {
                            let cnt = 0;
                            cnt = tileData.app2.length;    
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app2;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app2: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                        } else if (optionEntities[itx1].associatedDataPoint === 'app3') {
                            let cnt = 0;
                            cnt = tileData.app3.length;   
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app3;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app3: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                        } else if (optionEntities[itx1].associatedDataPoint === 'app4') {
                            let cnt = 0;
                            cnt = tileData.app4.length;   
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app4;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app4: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                        } else if (optionEntities[itx1].associatedDataPoint === 'app5') {
                            let cnt = 0;
                            cnt = tileData.app5.length;   
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app5;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app5: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                            
                        } else if (optionEntities[itx1].associatedDataPoint === 'app6') {
                            let cnt = 0;
                            cnt = tileData.app6.length;   

                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app6;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app6: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                            
                        } else if (optionEntities[itx1].associatedDataPoint === 'app7') {
                            let cnt = 0;
                            cnt = tileData.app7.length;   
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app7;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app7: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                            
                        } else if (optionEntities[itx1].associatedDataPoint === 'app8') {
                            let cnt = 0;
                            cnt = tileData.app8.length; 
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app8;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app8: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                            
                        } else if (optionEntities[itx1].associatedDataPoint === 'app9') {
                            let cnt = 0;
                            cnt = tileData.app9.length; 
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app9;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        app9: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                            
                        } else if (optionEntities[itx1].associatedDataPoint === 'app10') {
                            let cnt = 0;
                            cnt = tileData.app10.length; 
                            for (let data of optionEntities[itx1].uniqueEntities) {
                                let dummyTile1Data: any = tileData.app10;
                                if (data && (!dummyTile1Data.includes(data))) {
                                    dummyTile1Data.push(data)
                                }
                                cnt = dummyTile1Data.length
                                setTileData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        appx: dummyTile1Data,
                                    }
                                })
                            }
                            dummyData[itx1].count = cnt;
                        }
                    }                   

                    setChartStats((prevState: any) => {
                        return {
                            ...prevState,
                            chartFlag: true,
                            chartLazyLoadFlag: true,
                            bubbleData: [...dummyData]
                        };
                    });

                } else if (fetched_details.dashboard.dashboardUserEngagementData.result === 'retry') {
                    getUserEngagementUserData(fetched_details?.commonComponentData?.datesData.fdate, fetched_details?.commonComponentData?.datesData.realTime_tdate, true, 'retry');
                } else if (fetched_details.dashboard.dashboardUserEngagementData.result === 'error') {
                    setUserEngagmentUserChart((prevState: any) => {
                        return {
                            ...prevState,
                            errorcode: 1,
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.dashboard.dashboardUserEngagementData]);

    return (
        <section className="mainSection">
            <div className="col-sm-12 paddingBottom30 displayFlex" id="tilesData">
                {(chartStats.chartFlag) ? (
                    userEngagmentUserChart.errorcode === 0 ? (
                        userEngagmentUserChart.refreshFlag ? (
                            <div className="width100 displayBlock">
                                <div className="width100 displayBlock">
                                    <div className="displayFlex">
                                        <div className="col-8">
                                            <LineChart
                                                chartEntities={appsList.lineChartEntities}
                                                chartData={chartStats.userChart}
                                                optionEntities={chartStats.userChartOptions}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                refId="dashboard_showme"
                                                component='dashboard'>
                                            </LineChart>
                                        </div>
                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                            <Bubblechart data={chartStats.bubbleData} selectedAppCount={chartStats.totalAppsCount} component="dashboard"></Bubblechart>
                                        </div>
                                    </div>

                                    {chartStats.overlayFlag
                                        ? (<div className="overlay-div"></div>)
                                        : null}

                                    {chartStats.overlayFlag
                                        ? (
                                            <div className="dashboardloadingDiv">
                                                <Loader></Loader>
                                            </div>
                                        )
                                        : null
                                    }
                                </div>
                            </div>
                        ) : (<div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>)
                    ) : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>
                )}

            </div>
        </section>
    )
}

export default React.memo(DashboardUserEngagementLazyLoad);


