// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";


// Redux
import { useDispatch, useSelector } from 'react-redux';
import { PageDetailState } from '../../../store/Reducers/AppInsights/pageDetail';
import * as ActionTypes from '../../../store/Actions/ActionTypes';
import * as pageDetailActions from '../../../store/Actions/index';
import { getCall } from '../../../utils/ApiCallActions';
import { withReducer } from "../../../store/withReducer";
import pageDetailReducer from "../../../store/Reducers/AppInsights/pageDetail";
import { CommonComponentState, LanguageState } from "store/Reducers"


// Common files
import qs from 'querystring';
import $ from 'jquery';
import { ChartColors, RetryApi } from '../../../utils/GlobalConstants';
import { CommonUtils } from '../../../utils/CommonUtils';
import { putCall, deleteCall } from '../../../utils/ApiCallActions';

// SCSS
import "./PageDetail.scss";

// Componenets
import Datepicker from '../../../components/Calender/Datepicker';
import LinechartPageDetail from "../../../components/Chart/LinechartPageDetail";
import Linechart from "../../../components/Chart/Linechart";
import Label from "../../../components/Label/Label";
import Rules from '../../../components/Rules/Rules';
import Loader from '../../../components/Loader/Loader';
import Heatmap from "../../../components/Chart/Heatmap";
import SectionHeader from "../../../components/Layouts/SectionHeader";
import SectionTooltip from '../../../components/SectionTooltip/SectionTooltip';
import SectionButtons from '../../../components/Layouts/SectionButtons';
import SectionRefresh from '../../../components/Layouts/SectionRefresh';
import ContainerTooltip from '../../../components/Layouts/ContainerTooltip';
import CardContainer from '../../../components/Layouts/CardContainer';
import BrowserStats from '../../../components/BrowserStats/BrowserStats';
import ExportToPdf from '../../../components/ExportToPdf/ExportToPdf';
import Maintenance from 'components/Maintenance/Maintenance';
import LineChart from 'components/Chart/nvd3Charts/LineChart';
import SectionFilters from "components/Layouts/SectionFilters";
import CommonTable from 'components/Table/CommonTable';
import CommonModal from 'components/CommonModal/CommonModal';
import { useErrorBoundary } from 'react-error-boundary';

import { InsightLabels } from 'labels';


let urlData: any = {};

interface CurrentState {
    pageDetail: PageDetailState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const PageDetails: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const navigate = useNavigate();

    const { tag_code } = useParams();
    const tagCode = tag_code;
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const dispatch = useDispatch();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    const linechart_height = 400;

    const PITracking = localStorage.getItem('PI-TRACKING');

    let fetched_details = useSelector((state: CurrentState) => state);

    const [pageData, setPageData] = useState({
        pageTitle: 'Page Title'
    });

    const [domainCount, setDomainCount] = useState(0);

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        disabled: false
    });

    const [rulesModal, setRulesModal] = useState({
        show_rules_modal: false,
        rulesSave: true,
        labelData: [],
        labelFlag: false,
        labelListLoad: false,
        selectedLabels: [],
        rulesData: [{
            innerRules: [{
                type: '',
                value: '',
                condition: '',
                showValueField: ''
            }]
        }],
        dataFetched: false
    });

    const [tagsModal, setTagsModal] = useState({
        show_tagDelete_modal: false
    })

    const [userActivityChart, setUserActivityChart] = useState({
        loadState: [{
            chartValues: [],
            count: null,
            data: [],
            id: 0,
            sessionType: ""
        }],
        mainLineChart: [{}],
        allChartsData: [{
            chartValues: [],
            count: null,
            data: [],
            id: 0,
            sessionType: ""
        }],
        userDataFetched: false,
        errorMsg: ''
    });

    const [heatmapChart, setHeatmapChart] = useState({
        heatmapFetched: false,
        heatMapData: [],
        days: [],
        errorMsg: ''
    });

    const [loadTimeChart, setLoadTimeChart] = useState({
        loadChartOptions: [],
        loadChartData: [],
        bestTime: 0,
        avgTime: 0,
        worstTime: 0,
        loadtimeChartDataFetched: false,
        errorMsg: ''
    });

    const [usageData, setUsageData] = useState({
        pieChartDataFetched: false,
        usageTableDataFetched: false,
        errorMsg: ''
    })

    const [userEngagementModalDetails, setUserEngagementModalDetails] = useState({
        isModalOpen: false,
        userFullName: '',
        userID: ''
    });

    const [userEngagementStatsChart, setUserEngagementStatsChart] = useState({
        loadUserEngagementStatsChart: [],
        loadUserEngagementStatsChartOptions: [],
        userEngagementStatsChartDataFetched: false,
        userEngagementStatsChart: {},
        userEngagementStatsChartFetched: false,
    })


    const [userEngagementStatsSummaryTable, setUserEngagementStatsSummaryTable] = useState({
        userEngagementStatsSummaryDataFetched: false,
        searchQuery: '',
        sortKey: 'page_visits',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [userEngagementModalChart, setUserEngagementModalChart] = useState({
        loadUserEngagementModalChart: [],
        loadUserEngagementModalChartOptions: [],
        userEngagementModalChartFetched: false,
        userEngagementModalChart: {},
        userID: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['pageDetails'];
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(2, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        try {
            let component_path: any[] = location.pathname.split('/');
            component_path = component_path.filter(function (el) {
                return el != '';
            });

            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
            }

            navigate({
                pathname: "/app-insights/page-detail/" + component_path[component_path.length - 1] + '/',
                search: qs.stringify(queryParams),
            });


            if (fetched_details?.commonComponentData.datesData.source === 'component') {
                if (fetched_details?.commonComponentData.datesData.url?.includes('page-detail')) {
                    userActivityCharts();
                    getUserTimeChartData();
                    getLoadTimeChartData();
                    getUsageStats('count', 'asc', 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
                    getLabels();
                    getUserEngagementStatsChartData();
                    getUserEngagementSummaryTableData();
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData.datesData])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const userActivityCharts = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            // "app_code": urlData['app_code'],
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "tag_code": tagCode,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };
        pageDetailActions.getPageDetailData(params, 'USER_STATS', ActionTypes.USER_STATS, apiErrorFlag ?? '').then((res: any) => dispatch(res));;
    }

    const getUserTimeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            // "app_code": urlData['app_code'],
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "tag_code": tagCode,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };
        pageDetailActions.getPageDetailData(params, 'PAGE_BLOCK_CHART', ActionTypes.PAGE_BLOCK_CHART, apiErrorFlag ?? '').then((res: any) => dispatch(res));
    }

    const getLoadTimeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            // "app_code": urlData['app_code'],
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "tag_code": tagCode,
            "app_id": fetched_details?.commonComponentData.appsData.appId
        };
        pageDetailActions.getPageDetailData(params, 'INSIGHTS_LOADTIME', ActionTypes.INSIGHTS_LOADTIME, apiErrorFlag ?? '').then((res: any) => dispatch(res));
    }

    const getUsageStats = (sortBy: string, order: string, optn: number, fdate: any, tdate: any, apiErrorFlag?: string) => {
        let params;
        if (optn === 2) {
            params = {
                "start_date": fdate + "_00:00:00",
                "end_date": tdate + "_23:59:59",
                "time_zone": timeZone,
                // "app_code": urlData['app_code'],
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                "tag_code": tagCode,
                "app_id": fetched_details?.commonComponentData.appsData.appId
            }
        }
        else {
            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                // "app_code": urlData['app_code'],
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                "tag_code": tagCode,
                "app_id": fetched_details?.commonComponentData.appsData.appId
            };
        }
        pageDetailActions.getPageDetailData(params, 'USAGE_STATS', ActionTypes.USAGE_STATS, apiErrorFlag ?? '').then((res: any) => dispatch(res));

    }

    const getPageTagDetail = (apiErrorFlag?: string) => {

        try {
            pageDetailActions.getPageDetailData('', 'TAG_PAGE', ActionTypes.TAG_PAGE, apiErrorFlag ?? '', tagCode).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }
    }

    const getLabels = async (apiErrorFlag?: string) => {
        getCall('', 'GET_LABEL_LIST', apiErrorFlag ?? '').then((data: any) => {
            if (data.result === 'success') {
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        labelData: data.data,
                        labelListLoad: true
                    }
                })
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    getLabels('retry')
                }, RetryApi.TIMEOUT)
            }
        })
    }

    const getSortEvent = useCallback((renderVal: boolean, optn: string, sortBy: string, order: string) => {
        try {
            getUsageStats(sortBy, order, 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
        } catch (error) {
            showBoundary(error)
        }
    }, []);

    const submitTag = () => {
        try {
            let asset_rulesD: any[] = [];

            for (var i = 0; i < rulesModal.rulesData.length; i++) {
                for (var j = 0; j < rulesModal.rulesData[i].innerRules.length; j++) {
                    if (asset_rulesD[i] === undefined) {
                        asset_rulesD[i] = [];
                    }
                    if (asset_rulesD[i][j] === undefined) {
                        asset_rulesD[i][j] = {};
                    }
                    asset_rulesD[i][j].type = rulesModal.rulesData[i].innerRules[j].type;
                    asset_rulesD[i][j].value = rulesModal.rulesData[i].innerRules[j].value;
                    asset_rulesD[i][j].condition = rulesModal.rulesData[i].innerRules[j].condition;
                    asset_rulesD[i][j].logical_condition = "";
                    asset_rulesD[i][j].name = "";
                    asset_rulesD[i][j].order = j;
                    asset_rulesD[i][j].showValueField = true;
                }
            }

            let modifiedLabels: any = [];
            if (rulesModal.selectedLabels) {
                for (let i = 0; i < rulesModal.selectedLabels.length; i++) {
                    modifiedLabels[i] = {};
                    modifiedLabels[i].chipName = rulesModal.selectedLabels[i]['chipName'];
                    modifiedLabels[i].chipColorCode = rulesModal.selectedLabels[i]['chipColorCode'];
                    modifiedLabels[i].chipFontColorCode = rulesModal.selectedLabels[i]['chipFontColorCode'];
                    modifiedLabels[i].set_color = rulesModal.selectedLabels[i]['set_color'];
                }
            }


            let data = {
                "tagTitle": $("#updatedPageTitle").val(),
                "tagDescription": $("#updatedPageDescription").val(),
                "tagType": "page",
                "tagLabels": modifiedLabels,
                "rules": asset_rulesD,
                "elementDetails": {},
                "parentTagCode": "",
                // "app_code": urlData['app_code'],
                "appCode": fetched_details?.commonComponentData.appsData.appCode,
                "appId": fetched_details?.commonComponentData.appsData.appId
            };

            putCall(data, 'TAG_PAGE', tagCode).then((data: any) => {
                if (data.result === 'success') {
                    handleClose();

                    setPageData((prevState: any) => {
                        return {
                            ...prevState,
                            pageTitle: data.data.data.tagTitle
                        }
                    })

                    toast.success(insightsLbls['successfullyAdded'], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);

                } else if (data.result === 'error') {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            toast.dismiss();
                        }, 5000);
                    }
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const deletePageTag = () => {

        deleteCall("", 'TAG_PAGE', tagCode).then((data: any) => {
            if (data.result === 'success') {
                handleClose_delete();
                navigateToPage();
                toast.success(insightsLbls['successfullyDeleted'], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (data.result === 'error') {
                if (data.error) {
                    toast.error(data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }


    const getUserEngagementStatsChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "tag_code": tagCode,
        };

        pageDetailActions.getPageDetailData(
            params,
            "PAGE_USER_ENGAGEMENT_STATS",
            ActionTypes.SET_PAGE_USER_ENGAGEMENT_STATS_CHART,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }

    const getUserEngagementSummaryTableData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "page_size": userEngagementStatsSummaryTable.limit,
            "sort_by": userEngagementStatsSummaryTable.sortKey,
            "order": userEngagementStatsSummaryTable.sortValue,
            "page_index": userEngagementStatsSummaryTable.page,
            "filter_label": userEngagementStatsSummaryTable.filterLabels,
            "tag_code": tagCode,
            "search_text": userEngagementStatsSummaryTable.searchQuery,
        };

        PITracking === "1" && pageDetailActions.getPageDetailData(
            params,
            "PAGE_USER_ENGAGEMENT_SUMMARY",
            ActionTypes.SET_PAGE_USER_ENGAGEMENT_SUMMARY,
            apiErrorFlag ?? '', undefined,
            userEngagementStatsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementModalChart = (userId: string, apiErrorFlag?: string) => {

        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "tag_code": tagCode,
            "user_id": userId,
        };

        pageDetailActions.getPageDetailData(
            params,
            "PAGE_USER_ENGAGEMENT_MODAL_CHART",
            ActionTypes.SET_PAGE_USER_ENGAGEMENT_MODAL_CHART,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) => (
                        i === index ? option : item
                    ))
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const enableExportbtn = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleClose = () => {
        try {
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    show_rules_modal: false,
                    rulesSave: false,
                    labelData: [],
                    labelFlag: false,
                    labelListLoad: false,
                    selectedLabels: [],
                    rulesData: [{
                        innerRules: [{
                            type: '',
                            value: '',
                            condition: '',
                            showValueField: ''
                        }]
                    }],
                    dataFetched: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleShow = () => {
        try {
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    show_rules_modal: true,
                }
            })
            getLabels();
            getPageTagDetail();
        } catch (error) {
            showBoundary(error)
        }
    }

    const handleClose_delete = () => {
        try {
            setTagsModal((prevState: any) => {
                return {
                    ...prevState,
                    show_tagDelete_modal: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleShow_delete = () => {
        try {
            setTagsModal((prevState: any) => {
                return {
                    ...prevState,
                    show_tagDelete_modal: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getCountInTime = (count: number, inMilliSeconds = false) => {
        try {
            count = Number(count / (inMilliSeconds ? 1000 : 1));
            var hr = Math.floor(count / 3600);
            var min = Math.floor(count % 3600 / 60);
            var sec = Math.floor(count % 3600 % 60);
            var hDisplay = hr > 0 ? hr + (hr === 1 ? "h " : "h ") : "";
            var mDisplay = min > 0 ? min + (min === 1 ? "m " : "m ") : "";
            var sDisplay = sec > 0 ? sec + (sec === 1 ? "s " : "s ") : "";

            if (count === 0) {
                return '0s';

            } else {
                return hDisplay + mDisplay + sDisplay;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const switchMap = (optn: number) => {
        try {
            let data: any[] = [];
            data[0] = userActivityChart.allChartsData[optn];

            setUserActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    loadState: [...data],
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            if (optn === 0) {
                setUserActivityChart((prevState: any) => {
                    return {
                        ...prevState,
                        userDataFetched: false,
                    }
                });

                setHeatmapChart((prevState: any) => {
                    return {
                        ...prevState,
                        heatmapFetched: false,
                    }
                });

                userActivityCharts();
                getUserTimeChartData();
                enableExportbtn(0, false);
                enableExportbtn(1, false);

            } else if (optn === 1) {
                setLoadTimeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadtimeChartDataFetched: false,
                    }
                })
                getLoadTimeChartData();
                enableExportbtn(2, false);

            } else if (optn === 2) {
                enableExportbtn(3, false);
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                    }
                })

                getUsageStats('count', 'asc', optn, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
            }
            else if (optn === 4) {

                enableExportbtn(4, false);
                setUserEngagementStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsChartDataFetched: false,
                    }
                })
                userEngagementStatsSummaryTable.searchQuery = '';
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsSummaryDataFetched: false,
                    }
                })

                getUserEngagementStatsChartData();
                getUserEngagementSummaryTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getRuleData = useCallback((data: any) => {
        try {
            if (data[0].innerRules.length !== 0) {
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        rulesData: data
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [rulesModal.rulesData])

    const getLabelData = useCallback((data: any) => {
        try {
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    selectedLabels: data,
                    labelFlag: true
                }
            })

            if (data.length) {
                const inputTitleVal = $("#createPageTitle").val();
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        rulesSave: inputTitleVal === '' ? true : false
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [rulesModal.selectedLabels])

    const onTagTitle = (event: any) => {
        try {
            let inputValue = event.target.value;
            let rulesSave: boolean = false;

            if (!inputValue.replace(/\s/g, '').length) {
                rulesSave = true;
            } else {
                rulesSave = false;
            }

            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    rulesSave: rulesSave
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const onTagDesc = (event: any) => {
        try {
            const inputTitleVal = $("#createPageTitle").val();
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    rulesSave: inputTitleVal === '' ? true : false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleRuleEvents = useCallback((optn: any, data: boolean) => {
        try {
            const inputValue = $('#createPageTitle').val();
            if (inputValue === "") {
                data = true;
            }
            if (optn === 'validation') {
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        rulesSave: data
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [rulesModal])

    const navigateToPage = () => {
        try {
            let queryParams = {
                // app_id: urlData['app_id'],
                app_id: fetched_details?.commonComponentData.appsData.appId,
                // app_code: urlData['app_code'],
                app_code: fetched_details?.commonComponentData.appsData.appCode,
            }
            navigate({
                pathname: '/app-insights/pages/',
                search: qs.stringify(queryParams),
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const setRulesData = useCallback(() => {
        return rulesModal?.rulesData;
    }, [rulesModal]);

    const refreshBrowser = useCallback((optn: number) => {
        enableExportbtn(3, false);
        refreshSections(2)
    }, []);


    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setUserEngagementModalChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementModalChartFetched: false,
                        }
                    })

                    

                    setUserEngagementModalDetails((prevState: any) => {
                        return {
                            ...prevState,
                            userID: data.userID
                        }
                    })
                    getUserEngagementModalChart(data.userID);
                    openUserEngagementModal(data.userFullName);

                    break;

            case "sortColumn":
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        sortKey: data.sortKey,
                        sortValue: data.sortValue,
                        page: 1,
                        defaultSort: {
                            sortColumn_index: data.index,
                            sortValue: data.sortValue,
                        },
                        firstLoad: false
                    }
                });
                break;

            case "changePage":
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        page: data + 1,
                        firstLoad: false
                    }
                });
                break;

            case "searchTxt":
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        page: 1,
                        searchQuery: data,
                        firstLoad: false
                    }
                });
                break;

            case "applyLabels":
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        page: 1,
                        filterLabels: data,
                        firstLoad: false
                    };
                });
                break;

            case "changeEntries":
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        page: 1,
                        limit: data,
                        tablePagination: {                   // object that we want to update
                            ...prevState.tablePagination,    // keep all other key-value pairs
                            perPage: data       // update the value of specific key
                        },
                        firstLoad: false
                    }
                });
                break;
        }
    } catch (error) {
        showBoundary(error)
    }

}, [userEngagementStatsSummaryTable]);



const openUserEngagementModal = (userFullName: string) => {
    try {
        setUserEngagementModalDetails((prevState) => {
            return {
                ...prevState,
                isModalOpen: true,
                userFullName: userFullName,
            }
        });
    } catch (error) {
        showBoundary(error)
    }

}

const closeUserEngagementModal = (userFullName: string) => {
    try {
        setUserEngagementModalDetails((prevState) => {
            return {
                ...prevState,
                isModalOpen: false,
                userFullName: '',
            }
        });
    } catch (error) {
        showBoundary(error)
    }

}
// UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// User Activity
useEffect(() => {
    try {
        if (fetched_details.pageDetail.userActivity.data !== undefined && fetched_details.pageDetail.userActivity.result === 'success') {
            let chartData_dummy = fetched_details.pageDetail.userActivity.data.insightData;

            for (let i = 0; i < chartData_dummy.length; i++) {
                for (let j = 0; j < chartData_dummy[i].data.length; j++) {
                    chartData_dummy[i].data[j].xValue = {};
                    chartData_dummy[i].data[j].xValue = chartData_dummy[i].data[j].x;
                }
            }

            let loadChartsData: any[] = [];
            loadChartsData[0] = fetched_details.pageDetail.userActivity.data.insightData[0];
            for (let j = 0; j < loadChartsData[0].data.length; j++) {
                loadChartsData[0].data[j].xValue = {};
                loadChartsData[0].data[j].xValue = loadChartsData[0].data[j].x;
            }

            setUserActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    mainLineChart: [{
                        key: insightsLbls['userActivity'],
                        color: ChartColors['salmon'],
                        area: true
                    }],
                    allChartsData: [...chartData_dummy],
                    loadState: [...loadChartsData],
                    userDataFetched: true
                }
            });

            setPageData((prevState: any) => {
                return {
                    ...prevState,
                    pageTitle: fetched_details.pageDetail.userActivity.data.pageTitle
                }
            })

            apiCatchError(0, 0);
            enableExportbtn(0, true);
        } else if (fetched_details.pageDetail.userActivity.result === 'retry') {
            userActivityCharts('retry');
        } else if (fetched_details.pageDetail.userActivity.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.userActivity.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setUserActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    userDataFetched: true,
                    errorMsg: errorMsg
                }
            });
            setPageData({
                ...pageData,
                pageTitle: '',
            });

            apiCatchError(0, errorFlag);
            enableExportbtn(0, false);

        }
    } catch (error) {
        showBoundary(error)
    }
}, [fetched_details.pageDetail.userActivity])

// Heatmap
useEffect(() => {
    try {
        if (fetched_details.pageDetail.userVisit.data !== undefined && fetched_details.pageDetail.userVisit.result === 'success') {
            let days: any = [];
            let daysArr: any = [];
            let chartData: any = [];

            chartData[0] = fetched_details.pageDetail.userVisit.data;
            if (chartData[0]['chartData'].length > 0 && chartData[0].chartTimes.length > 0) {
                for (let i = 0; i < chartData[0]['chartData'].length; i++) {
                    daysArr.push(chartData[0]['chartData'][i]['day']);
                }
                days[0] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

                setHeatmapChart((prevState: any) => {
                    return {
                        ...prevState,
                        heatmapFetched: true,
                        heatMapData: [...chartData],
                        days: [...days]
                    }
                })

                apiCatchError(1, 0);
                enableExportbtn(1, true);

            }
        } else if (fetched_details.pageDetail.userVisit.result === 'retry') {
            getUserTimeChartData('retry');
        } else if (fetched_details.pageDetail.userVisit.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.userVisit.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setHeatmapChart((prevState: any) => {
                return {
                    ...prevState,
                    heatmapFetched: true,
                    errorMsg: errorMsg
                }
            });
            apiCatchError(1, errorFlag);
            enableExportbtn(1, false);
        }
    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.userVisit])

// PageLoad Time
useEffect(() => {
    try {
        if (fetched_details.pageDetail.pageLoadTime.data !== undefined && fetched_details.pageDetail.pageLoadTime.result === 'success') {
            let chartData: any = [];
            let chartTileData: any = [];
            let best: any = [];
            let average: any = [];
            let worst: any = [];

            chartData = fetched_details.pageDetail.pageLoadTime.data.details;
            chartTileData = fetched_details.pageDetail.pageLoadTime.data.data;

            const bestTime = getCountInTime(chartTileData.bestTime);
            const avgTime = getCountInTime(chartTileData.avgTime);
            const worstTime = getCountInTime(chartTileData.worstTime);

            setLoadTimeChart((prevState: any) => {
                return {
                    ...prevState,
                    loadChartData: [...chartData],
                    bestTime: bestTime,
                    avgTime: avgTime,
                    worstTime: worstTime,
                    loadtimeChartDataFetched: true,
                }
            });

            apiCatchError(2, 0);
            enableExportbtn(2, true);
        } else if (fetched_details.pageDetail.pageLoadTime.result === 'retry') {
            getLoadTimeChartData('retry');
        } else if (fetched_details.pageDetail.pageLoadTime.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.pageLoadTime.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setLoadTimeChart((prevState: any) => {
                return {
                    ...prevState,
                    loadtimeChartDataFetched: true,
                    errorMsg: errorMsg
                }
            });
            apiCatchError(2, errorFlag);
            enableExportbtn(2, false);
        }

    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.pageLoadTime]);

// User System data
useEffect(() => {
    try {
        if (fetched_details.pageDetail.userSystemData.data !== undefined && fetched_details.pageDetail.userSystemData.result === 'success') {
            setUsageData((prevState: any) => {
                return {
                    ...prevState,
                    pieChartDataFetched: true,
                    usageTableDataFetched: true,
                }
            })
            apiCatchError(3, 0);
            enableExportbtn(3, true);
        } else if (fetched_details.pageDetail.userSystemData.result === 'retry') {
            getUsageStats('count', 'asc', 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate, 'retry');
        } else if (fetched_details.pageDetail.userSystemData.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.userSystemData.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setUsageData((prevState: any) => {
                return {
                    ...prevState,
                    pieChartDataFetched: true,
                    usageTableDataFetched: true,
                    errorMsg: errorMsg
                }
            })
            apiCatchError(3, errorFlag);
            enableExportbtn(3, false);
        }
    } catch (error) {
        showBoundary(error)
    }


}, [fetched_details.pageDetail.userSystemData]);

// PageTag Rules
useEffect(() => {
    try {
        if (fetched_details.pageDetail.pageTag.data !== undefined && fetched_details.pageDetail.pageTag.result === 'success') {
            $("#updatedPageTitle").val(fetched_details.pageDetail.pageTag.data.tagTitle);
            $("#updatedPageDescription").val(fetched_details.pageDetail.pageTag.data.tagDescription);
            let outerRules: any = [];

            for (let i = 0; i < fetched_details.pageDetail.pageTag.data.rules.length; i++) {
                for (let j = 0; j < fetched_details.pageDetail.pageTag.data.rules[i].length; j++) {

                    if (outerRules[i] === undefined) {
                        outerRules[i] = [];
                    }
                    if (outerRules[i].innerRules === undefined) {
                        outerRules[i].innerRules = [];
                    }
                    if (outerRules[i].innerRules[j] === undefined) {
                        outerRules[i].innerRules[j] = {};
                    }
                    outerRules[i].innerRules[j].type = fetched_details.pageDetail.pageTag.data.rules[i][j].type;
                    outerRules[i].innerRules[j].value = fetched_details.pageDetail.pageTag.data.rules[i][j].value;
                    outerRules[i].innerRules[j].condition = fetched_details.pageDetail.pageTag.data.rules[i][j].condition;
                    outerRules[i].innerRules[j].showValueField = true;

                }
            }

            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    selectedLabels: fetched_details.pageDetail.pageTag.data.tagLabels,
                    labelFlag: true,
                    rulesData: [...outerRules],
                    dataFetched: true
                }
            })
        } else if (fetched_details.pageDetail.pageTag.result === 'retry') {
            getPageTagDetail('retry')
        }
    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.pageTag])

// Export pdf btn
useEffect(() => {
    try {
        if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 && loaderBtn.loader4 && loaderBtn.loader5) {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: false,
                };
            });
        } else {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: true,
                };
            });
        }
    } catch (error) {
        showBoundary(error)
    }

}, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4, loaderBtn.loader5])


//user engagement chart data
useEffect(() => {
    try {
        if (
            fetched_details.pageDetail.userEngagementStatsChartData.data !== undefined &&
            fetched_details.pageDetail.userEngagementStatsChartData.result === "success"
        ) {
            setUserEngagementStatsChart((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementStatsChartDataFetched: true,
                    loadUserEngagementStatsChart: fetched_details.pageDetail.userEngagementStatsChartData.data
                }
            })
            apiCatchError(4, 0);
            enableExportbtn(4, true);
        } else if (fetched_details.pageDetail.userEngagementStatsChartData.result === 'retry') {
            getUserEngagementStatsChartData('retry');
        } else if (fetched_details.pageDetail.userEngagementStatsChartData.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.userEngagementStatsChartData.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setUserEngagementStatsChart((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementStatsChartDataFetched: true,
                    errorMsg: errorMsg
                }
            });

            apiCatchError(4, errorFlag);
            enableExportbtn(4, false);
        }
    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.userEngagementStatsChartData])


//user engagement summary data
useEffect(() => {
    try {
        if (fetched_details?.commonComponentData.datesData?.url?.includes('page-detail') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
            enableExportbtn(5, false);
            getUserEngagementSummaryTableData();
        }
    } catch (error) {
        showBoundary(error)
    }

}, [
    userEngagementStatsSummaryTable.sortKey,
    userEngagementStatsSummaryTable.sortValue,
    userEngagementStatsSummaryTable.defaultSort,
    userEngagementStatsSummaryTable.page,
    userEngagementStatsSummaryTable.searchQuery,
    userEngagementStatsSummaryTable.limit,
    userEngagementStatsSummaryTable.filterLabels,
]);

//user engagement summary data
useEffect(() => {
    try {
        if (
            fetched_details.pageDetail.userEngagementStatsSummaryData.data !== undefined &&
            fetched_details.pageDetail.userEngagementStatsSummaryData.result === "success"
        ) {

            setUserEngagementStatsSummaryTable((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementStatsSummaryDataFetched: true,
                };
            });
            apiCatchError(5, 0);
            enableExportbtn(5, true);
        } else if (fetched_details.pageDetail.userEngagementStatsSummaryData.result === 'retry') {
            getUserEngagementSummaryTableData('retry');
        } else if (
            fetched_details.pageDetail.userEngagementStatsSummaryData.result === "error"
        ) {
            setUserEngagementStatsSummaryTable((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementStatsSummaryDataFetched: true,
                };
            });
            apiCatchError(5, 1);
            enableExportbtn(5, false);
        }
    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.userEngagementStatsSummaryData]);

//Modal Chart
useEffect(() => {
    try {
        if (
            fetched_details.pageDetail.userEngagementModalChartData.data !== undefined &&
            fetched_details.pageDetail.userEngagementModalChartData.result === "success"
        ) {
            setUserEngagementModalChart((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementModalChartFetched: true,
                    loadUserEngagementModalChart: fetched_details.pageDetail.userEngagementModalChartData.data
                }
            })
            apiCatchError(6, 0);
        } else if (fetched_details.pageDetail.userEngagementModalChartData.result === 'retry') {
            getUserEngagementModalChart(userEngagementModalDetails.userID, 'retry'); 
        } else if (fetched_details.pageDetail.userEngagementModalChartData.result === 'error') {
            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.pageDetail.userEngagementModalChartData.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 2;
            }
            setUserEngagementModalChart((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementModalChartFetched: true,
                    errorMsg: errorMsg
                }
            });

            apiCatchError(6, errorFlag);
            // enableExportbtn(0, false);
        }
    } catch (error) {
        showBoundary(error)
    }

}, [fetched_details.pageDetail.userEngagementModalChartData])

useEffect(() => {
    try {
        const result = errorCount.errorCount.every((val) => val === 1);

        setErrorCount((prevState: any) => {
            return {
                ...prevState,
                showMaintenance_flag: result
            }
        })
    } catch (error) {
        showBoundary(error)
    }


}, [errorCount.errorCount])


// Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


const getUserActivityMainChart = useCallback((optn: string) => {
    try {
        if (optn === 'data') {
            return userActivityChart.loadState[0].data;
        } else if (optn === 'option') {
            return userActivityChart.mainLineChart;
        } else if (optn === 'margin') {
            return {
                top: 50,
                right: 50,
                bottom: 100,
                left: 100,
            }
        } else {
            return {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }
        }
    } catch (error) {
        showBoundary(error)
    }


}, [userActivityChart.loadState, userActivityChart.mainLineChart]);


const getTitle = (value: string) => {
    try {
        switch (value) {
            case 'Total Unique Users':
                return insightsLbls['totalUniqueUsers'];
            case 'New Users':
                return insightsLbls['newUser'];
            case 'No. of Sessions':
                return insightsLbls['numberOfSessions'];
            case 'Avg. Session Duration':
                return insightsLbls['avgSessionDuration'];
            case 'Bounced Users':
                return insightsLbls['bouncedUsers'];
            case 'Page Views':
                return insightsLbls['pageView'];
        }
    } catch (error) {
        showBoundary(error)
    }

}

const navigateToMainPage = () => {
    let queryParams = {
        app_id: fetched_details?.commonComponentData.datesData?.appId,
        app_code: fetched_details?.commonComponentData.appsData?.appCode,
        fdate: fetched_details?.commonComponentData.datesData.fdate,
        tdate: fetched_details?.commonComponentData.datesData.realTime_tdate,
    }
    navigate({
        pathname: `/app-insights/pages/`,
        search: qs.stringify(queryParams)
    })
}

return (
    <section className="demo pageDetailSection width100" ref={componentRef}>


        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <SectionHeader>
                    <li>
                        <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['page']}</span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </li>
                    <li className="active">{insightsLbls['pageDetails']}</li>
                </SectionHeader>
            </div>

            <SectionFilters>
                <Datepicker source={'component'} />
            </SectionFilters>
        </div>

        <SectionButtons sectionTitle={pageData.pageTitle} svgImage={"pages"} className={"marginTop-50"}>
            <button
                className="floatRight createPage-btn-square top10"
                onClick={handleShow}>
                <span>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                        insightsLbls['edit']
                    }
                </span>
            </button>
            <button
                className="floatRight deletePage-btn-square top10 paddingRight-1 margin"
                onClick={handleShow_delete}>
                <span>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                        insightsLbls['delete']
                    }
                </span>
            </button>

            <ExportToPdf
                componentRef={componentRef}
                source={"PageDetails"}
                widthDividedBy={10}
                disabled={loaderBtn.disabled}
            ></ExportToPdf>
        </SectionButtons>

        <div>
            {
                <SectionRefresh sectionTitle={insightsLbls.userActivity} refreshClass={""}>
                    <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(0)}></i>
                </SectionRefresh>
            }


            <CardContainer
                div1Classes={
                    "card col-md-12 col-xs-12 marginTop-0 page_section borderBottom borderTop section-wrapper"
                }
                div2Classes={
                    "icon-text chart-wrapper1 borderBottom marginBottom-20 paddingLeft-15 paddingRight-15"
                }
                div3Classes={"userActivity-div-header"}>
                <ContainerTooltip
                    div1Classes={"graph-title displayFlex marginBottom-20"}
                    div2Classes={"col-lg-8 col-md-8 col-sm-8 col-8"}
                    hTagClasses={"section-heading drop-off-funnel headingText"}>
                    <SectionTooltip tooltipText={{
                        "subTitle": insightsLbls.pageDetail,
                        "tooltipText": insightsLbls.collectionChartsTaggedPages,
                        "childern": [

                            {
                                "key": insightsLbls.totalUniqueUsers + ":",
                                "value": insightsLbls.totalUniqueUsersGraph
                            },
                            {
                                "key": insightsLbls.totalUniqueUsers + ":",
                                "value": insightsLbls.noOfSessionsGraph
                            },
                            {
                                "key": insightsLbls.avgSessionDuration + ":",
                                "value": insightsLbls.avgSessionDurationGraph
                            },
                            {
                                "key": insightsLbls.totalUniqueUsers + ":",
                                "value": insightsLbls.pageViewsGraph
                            }
                        ]
                    }} placement="right"></SectionTooltip>

                </ContainerTooltip>
                <div
                    className="card-body marginTop-30 overflowY-hidden"
                    id="user_activity_chart">
                    <div>
                        <div className="col-sm-12 userStats-lineChart marginTop-40">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="stat-digit">
                                    {userActivityChart.loadState[0] !== undefined && userActivityChart.userDataFetched
                                        ? getTitle(userActivityChart.loadState[0].sessionType)
                                        : null}
                                </div>
                                <div className="stat-text">
                                    {userActivityChart.loadState[0] !== undefined && userActivityChart.userDataFetched
                                        ? userActivityChart.loadState[0].id === 3 ? (getCountInTime(userActivityChart.loadState[0].count ?? 0, true)) : (userActivityChart.loadState[0].count)
                                        : null}
                                </div>
                            </div>
                            {userActivityChart.userDataFetched &&
                                userActivityChart.loadState[0] !== undefined ? (
                                errorCount.errorCount[0] === 0 ? (
                                    userActivityChart.loadState[0].data.length === 0 ? (
                                        <div
                                            id="noBubbleData"
                                            className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <LinechartPageDetail
                                            chartData={getUserActivityMainChart("data")}
                                            options={getUserActivityMainChart("option")}
                                            xAxis_vals={true}
                                            chartId={"main"}
                                            yAxis_vals={true}
                                            width={1000}
                                            height={350}
                                            margin={getUserActivityMainChart("margin")}
                                            chartName={userActivityChart.loadState[0].sessionType}
                                            userType={userActivityChart.loadState[0].id}
                                        ></LinechartPageDetail>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {userActivityChart.errorMsg}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight550">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                        {(userActivityChart.userDataFetched) && (
                            <div className="col-lg-12 padding0">
                                <div className="col-lg-12 row margin0">
                                    {userActivityChart.allChartsData.map((data, key) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => switchMap(key)}
                                                className={
                                                    key + 1 === userActivityChart.loadState[0].id
                                                        ? "col-lg-4 col-md-6 col-sm-12 border-style statBg-color "
                                                        : "col-lg-4 col-md-6 col-sm-12 border-style"
                                                }>
                                                <CardContainer
                                                    div1Classes={"marginTB-19"}
                                                    div2Classes={"card-body userStats-tile nopadding"}
                                                    div3Classes={"stat-icon"}>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="stat-digit">
                                                            {getTitle(data.sessionType)}
                                                        </div>
                                                        <div className="stat-text">
                                                            {data.id === 3
                                                                ? getCountInTime(data.count ?? 0, true)
                                                                : data.count}
                                                        </div>
                                                    </div>
                                                    <div className="removeGrid col-12 col-sm-12 col-md-12 col-lg-12">
                                                        {
                                                            errorCount.errorCount[0] === 0 ? (
                                                                data.data.length === 0 ? (
                                                                    <div
                                                                        id="noBubbleData"
                                                                        className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <LinechartPageDetail
                                                                        chartId={key}
                                                                        chartData={data.data}
                                                                        options={userActivityChart.mainLineChart}
                                                                        xAxis_vals={false}
                                                                        yAxis_vals={false}
                                                                        width={300}
                                                                        height={100}
                                                                        chartName={data.sessionType}
                                                                        margin={getUserActivityMainChart(
                                                                            "margin-options"
                                                                        )}
                                                                    ></LinechartPageDetail>
                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {userActivityChart.errorMsg}
                                                                </h5>
                                                            )}
                                                    </div>
                                                </CardContainer>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </CardContainer>

            <div className="col-md-12 col-sm-12 padding0 page_section">
                <div className="heatCharts-container displayFLex">
                    <CardContainer
                        div1Classes={"col-sm-12 col-xs-12 col-md-12 col-lg-12 padding0"}
                        div2Classes={
                            "card icon-text section-wrapper chart-wrapper3 borderTop marginTop-0 width100"
                        }
                        div3Classes={"card-body page-view-card"}>
                        <ContainerTooltip
                            div1Classes={"graph-title displayFlex"}
                            div2Classes={"col-lg-8 col-md-8 col-sm-8 col-8"}
                            hTagClasses={
                                "section-heading drop-off-funnel headingText floatLeft"
                            }>
                            <SectionTooltip tooltipText={{
                                "subTitle": insightsLbls.whenUsersVisit,
                                "tooltipText": insightsLbls.heatMapforMaxTraffic,
                                "childern": []
                            }} placement="right"></SectionTooltip>

                        </ContainerTooltip>
                        <div className="user-timer chart-wrapper4">
                            <div
                                id="userTimeChart"
                                className="timesChart width100 margin-0-auto height450 marginTop-30">
                                {heatmapChart.heatmapFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        heatmapChart.heatMapData.length === 0 ? (
                                            <div
                                                id="noBubbleData"
                                                className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (
                                            <Heatmap
                                                chartData={heatmapChart.heatMapData[0]["chartData"]}
                                                chartTimes={
                                                    heatmapChart.heatMapData[0]["chartTimes"]
                                                }
                                                days={heatmapChart.days[0]}
                                            ></Heatmap>
                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {heatmapChart.errorMsg}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardContainer>
                </div>
            </div>
            {
                <SectionRefresh sectionTitle={insightsLbls.page} refreshClass={"marginTop-30"}>
                    <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                </SectionRefresh>
            }

            <div className="col-sm-12 padding0 page_section">
                <CardContainer
                    div1Classes={
                        "card icon-text section-wrapper chart-wrapper borderTop marginTop-0"
                    }
                    div2Classes={"card-body height450 overflowY-hidden"}
                    div3Classes={"graph-title"}>
                    <ContainerTooltip
                        div1Classes={"col-sm-12 width100"}
                        div2Classes={""}
                        hTagClasses={"section-heading headingText width100"}>
                        <SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.pageLoadTime,
                                "tooltipText": insightsLbls.timeOfPageLoading1,
                                "childern": [
                                    {
                                        "key": insightsLbls.bestTime + ":",
                                        "value": insightsLbls.bestTimePageLoad1
                                    },
                                    {
                                        "key": insightsLbls.averageTime + ":",
                                        "value": insightsLbls.averageTimePageLoad1
                                    },
                                    {
                                        "key": insightsLbls.worstTime + ":",
                                        "value": insightsLbls.worstTimePageLoad1
                                    }
                                ]
                            }
                        } placement="right"></SectionTooltip>
                    </ContainerTooltip>
                    <div className="col-sm-12 marginTop-20 overflowX chartdiv displayFlex width100">
                        <div className="col-sm-9 col-lg-9 col-md-9">
                            {loadTimeChart.loadtimeChartDataFetched ? (
                                errorCount.errorCount[2] === 0 ? (
                                    loadTimeChart.loadChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <LineChart
                                            chartEntities={['bestTime', 'avgTime', 'worstTime']}
                                            optionEntities={[
                                                { key: insightsLbls && insightsLbls['best'], color: ChartColors.mediumorchid, area: true },
                                                { key: insightsLbls && insightsLbls['average'], color: ChartColors.slateblue, area: true },
                                                { key: insightsLbls && insightsLbls['worst'], color: ChartColors.darkturquoise, area: true }
                                            ]}
                                            chartData={fetched_details?.pageDetail?.pageLoadTime?.data?.details}
                                            value_type={"count"}
                                            height={380}
                                            margin={{ top: '50', right: '50', bottom: '50', left: '65' }}
                                            yAxisLabel={insightsLbls['yAxisLabelCount']}
                                            refId="pageDetail_usage">
                                        </LineChart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {loadTimeChart.errorMsg}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-3 col-lg-3 col-md-3 chartDiv">
                            <div className="margin-0-auto width200 workflow-countBox bestBox">
                                <div className="textCenter bestTime-count">
                                    {loadTimeChart.bestTime}
                                </div>
                                <div className="textCenter font14">
                                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                        insightsLbls['bestTime']
                                    }
                                </div>
                            </div>

                            <div className="margin-0-auto width200 workflow-countBox avgBox">
                                <div className="textCenter avgTime-count">
                                    {loadTimeChart.avgTime}
                                </div>
                                <div className="textCenter font14">
                                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                        insightsLbls['avgTime']
                                    }
                                </div>
                            </div>

                            <div className="margin-0-auto width200 workflow-countBox worstBox">
                                <div className="textCenter worstTime-count">
                                    {loadTimeChart.worstTime}
                                </div>
                                <div className="textCenter font14">
                                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                        insightsLbls['worstTime']
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContainer>
            </div>

            <div className=" margin-0-Auto statCards" id="user_stats">
                <React.Fragment>
                    <SectionRefresh sectionTitle={insightsLbls.pageAcivity} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(2)}></i>
                    </SectionRefresh>
                    {usageData.pieChartDataFetched &&
                        usageData.usageTableDataFetched ? (
                        errorCount.errorCount[3] === 0 ? (

                            <>
                                <ContainerTooltip
                                    div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                    div2Classes={""}
                                    hTagClasses={"section-heading drop-off-funnel headingText row"}
                                >
                                    {
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.webBrowser,
                                                "tooltipText": insightsLbls.webBrowserTooltip,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>
                                    }
                                </ContainerTooltip>
                                <BrowserStats
                                    statsData={fetched_details.pageDetail.userSystemData.data}
                                    calUsageData={getSortEvent}
                                    refreshSection={refreshBrowser}
                                    refreshSectionOption={2}
                                    fdate={fetched_details?.commonComponentData.datesData.fdate}
                                    tdate={fetched_details?.commonComponentData.datesData.tdate}
                                    serviceAvailable={errorCount.errorCount[1]}
                                    type="browser"
                                ></BrowserStats>


                                <ContainerTooltip
                                    div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                    div2Classes={""}
                                    hTagClasses={"section-heading drop-off-funnel headingText row"}
                                >
                                    {
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.operatingSystem,
                                                "tooltipText": insightsLbls.osTooltip,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>
                                    }
                                </ContainerTooltip>

                                <BrowserStats
                                    statsData={fetched_details.pageDetail.userSystemData.data}
                                    calUsageData={getSortEvent}
                                    refreshSection={refreshBrowser}
                                    refreshSectionOption={2}
                                    fdate={fetched_details?.commonComponentData.datesData.fdate}
                                    tdate={fetched_details?.commonComponentData.datesData.tdate}
                                    serviceAvailable={errorCount.errorCount[1]}
                                    type="os"
                                ></BrowserStats>

                                <ContainerTooltip
                                    div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                    div2Classes={""}
                                    hTagClasses={"section-heading drop-off-funnel headingText row"}
                                >
                                    {
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.screenResolution,
                                                "tooltipText": insightsLbls.screenResolutionTooltip,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>
                                    }
                                </ContainerTooltip>

                                <BrowserStats
                                    statsData={fetched_details.pageDetail.userSystemData.data}
                                    calUsageData={getSortEvent}
                                    refreshSection={refreshBrowser}
                                    refreshSectionOption={2}
                                    fdate={fetched_details?.commonComponentData.datesData.fdate}
                                    tdate={fetched_details?.commonComponentData.datesData.tdate}
                                    serviceAvailable={errorCount.errorCount[1]}
                                    type="screen_resolution"
                                ></BrowserStats>

                            </>



                        ) : (
                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                {usageData.errorMsg}
                            </h5>
                        )
                    ) : <BrowserStats
                        loading
                    ></BrowserStats>}
                </React.Fragment>
            </div>




        </div>


        <Modal
            show={rulesModal.show_rules_modal}
            onHide={handleClose}
            size={"lg"}
            backdrop={false}>
            <Modal.Header>
                <Modal.Title>{(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                    insightsLbls['tagPage']}</Modal.Title>
                <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            insightsLbls['close']}
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {rulesModal.dataFetched
                    ? (
                        <div>
                            <div className="col-lg-12 pageTitle-div allowEdit">
                                {/* <div className="col-lg-12 noDomainWarning">
                                App currently doesnt have a domain added. Please add a domain
                                to Create or Tag Page.
                            </div> */}
                                <span className="tagModalText">
                                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                        insightsLbls['name']}<span className="requiredStar">*</span>
                                </span>
                                <div className="width100 displayFlex">
                                    <input
                                        className="width100 padding5 modalTitleEdit"
                                        id="updatedPageTitle"
                                        placeholder={insightsLbls['nameTagOnPage']}
                                        autoComplete="off"
                                        onInput={(event) => onTagTitle(event)}
                                        defaultValue={fetched_details?.pageDetail?.pageTag?.data?.tagTitle}
                                    />
                                </div>
                                <div className="width100 marginTop-15">
                                    <span className="tagModalText">
                                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                            insightsLbls['description']}
                                    </span>
                                    <textarea
                                        name="description"
                                        placeholder={insightsLbls['describeTagOnPage']}
                                        id="updatedPageDescription"
                                        className="modalTitleEdit width100"
                                        rows={2}
                                        cols={5}
                                        maxLength={500}
                                        onInput={(event) => onTagDesc(event)}
                                        defaultValue={fetched_details?.pageDetail?.pageTag?.data?.tagDescription}
                                    ></textarea>
                                </div>
                                <div className="width100 marginTop-15">
                                    <span className="tagModalText">
                                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                            insightsLbls['label']}
                                    </span>
                                    {(rulesModal.labelFlag && rulesModal.labelListLoad) ? (
                                        <Label
                                            labeldata={rulesModal.labelData}
                                            selectedLabels={rulesModal.selectedLabels}
                                            getLabelData={getLabelData}
                                        ></Label>
                                    ) : <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader width={60} height={60}></Loader>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                            <CardContainer
                                div1Classes={"col-lg-12 overflowAuto height300 paddingTB-20"}
                                div2Classes={"font14 paddingLR-35 createEdit-form"}
                                div3Classes={"catMargin toast-left-50-perct"}>
                                {rulesModal.labelFlag ? (
                                    <Rules
                                        ruleEvent={handleRuleEvents}
                                        ruleData={getRuleData}
                                        setRulesData={setRulesData()}
                                    ></Rules>
                                ) : null}
                            </CardContainer>
                        </div>
                    )
                    : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}

            </Modal.Body>
            <Modal.Footer>
                {rulesModal.dataFetched && (
                    <div className="formButtons paddingLR-25 width100">
                        <button
                            className="btn-secondary btn  modal-cancel-btn floatRight marginLeft-10"
                            onClick={() => handleClose()}>
                            {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                insightsLbls['cancel']}
                        </button>
                        <button
                            className="btn modalSaveBtn validateBtn modal-save-btn floatRight"
                            disabled={rulesModal.rulesSave}
                            onClick={() => submitTag()}>
                            {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                                insightsLbls['save']}
                        </button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>

        <Modal
            show={tagsModal.show_tagDelete_modal}
            onHide={handleClose_delete}
            size={'lg'}
            backdrop={false}>
            <Modal.Header>
                <Modal.Title className="text-alignLeft">
                    {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                        insightsLbls['deletePageTag']}
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    onClick={handleClose_delete}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            insightsLbls['close']}
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="padding35 text-alignLeft">
                    <p className="text-alignLeft">
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            insightsLbls['deleteConfirmation']}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="formButtons paddingLR-25 width100">
                    <button
                        className="btn-secondary btn  modal-cancel-btn floatRight marginLeft-10"
                        onClick={handleClose_delete}>
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            insightsLbls['cancel']}
                    </button>
                    <button
                        className="btn modalSaveBtn validateBtn modal-save-btn floatRight"
                        onClick={() => deletePageTag()}>
                        {(JSON.stringify(insightsLbls) !== '{}' && insightsLbls !== undefined) &&
                            insightsLbls['confirm']}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
        <div className=" marginTop-30 margin-0-Auto statCards" id="userEngagementSection">
            {
                <SectionRefresh sectionTitle={insightsLbls.userEngagement} refreshClass={""}>
                    <i
                        className="fa fa-refresh pull-right pointer row displayFlex"
                        aria-hidden="true" title={insightsLbls.refresh}
                        onClick={() => refreshSections(4)}>
                    </i>
                </SectionRefresh>
            }

            <div className="dashboardTiles  borderTop col-lg-12 page_section padding0 height535 blockImportant">
                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop borderBottom height100-percent"}
                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                        {<SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.activity,
                                "tooltipText": insightsLbls.lineChartTooltipUserEngagementPage,
                                "childern": []
                            }
                        } placement="right"></SectionTooltip>
                        }
                    </ContainerTooltip>
                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                        {userEngagementStatsChart.userEngagementStatsChartDataFetched ? (
                            errorCount.errorCount[4] === 0 ? (
                                userEngagementStatsChart.loadUserEngagementStatsChart.length === 0 ? (
                                    <div className="noDataText-font textCenter-absolute">
                                        {insightsLbls.noRecordFound}
                                    </div>
                                ) : (

                                    <LineChart
                                        chartEntities={['pageVisits', 'pageUsers']}
                                        optionEntities={
                                            [
                                                {
                                                    key: insightsLbls.pageVisits,
                                                    color: ChartColors.lightseagreen, area: true
                                                },
                                                {
                                                    key: insightsLbls.pageUsers,
                                                    color: ChartColors.slateblue, area: true
                                                }
                                            ]}
                                        chartData={fetched_details?.pageDetail?.userEngagementStatsChartData?.data.graphData}
                                        value_type={"count"}
                                        height={linechart_height}
                                        margin={lineChartMargin}
                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                        refId="chatbot_conversationResponse">
                                    </LineChart>

                                )
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}

                    </div>
                </CardContainer>
            </div>
            {PITracking === "1" && <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                        {
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.userEngagementSummary,
                                    "tooltipText": insightsLbls.tableActivitiesOfFeature,
                                    "childern": [
                                        {
                                            "key": insightsLbls.userName + ":",
                                            "value": insightsLbls.userNameTooltip
                                        },
                                        {
                                            "key": insightsLbls.userEmail + ":",
                                            "value": insightsLbls.userEmailTooltip
                                        },
                                        {
                                            "key": insightsLbls.pageVisits + ":",
                                            "value": insightsLbls.numOfPageVisitsforUser
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                        }
                    </ContainerTooltip>
                    {
                        userEngagementStatsSummaryTable.userEngagementStatsSummaryDataFetched ? (
                            errorCount.errorCount[5] === 0 ? (
                                    <div className="tabelEditing commonSummaryTable userEngagementStatsSummaryTable">
                                        <CommonTable tableId="1"
                                            data={fetched_details.pageDetail.userEngagementStatsSummaryData.data}
                                            currentPage={userEngagementStatsSummaryTable.page}
                                            barColors="positiveColors"
                                            linkPresent={true}
                                            showExport={false}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={userEngagementStatsSummaryTable.defaultSort}
                                            tablePagination={userEngagementStatsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            modalTable={false}
                                        />
                                    </div>
                                    )
                                    : errorCount.errorCount[5] === 1 ? (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                    )
                                    : null)
                                    : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                    )

                        }
                                    {userEngagementModalDetails.isModalOpen &&
                                        <CommonModal modalState={userEngagementModalDetails.isModalOpen} modalTitle="User Engagement" size={'lg'} footerDisabled={true} exportModal={false}
                                            padding={true} dispatchModalState={closeUserEngagementModal} modalDialogClass={"userEngagement-modal"} key="">
                                            <div className='pb-2'>
                                                <span className='font13 font600'>{userEngagementModalDetails.userFullName + ' :'}</span>
                                            </div>
                                            <div className="dashboardTiles borderBottom borderTop col-lg-12 page_section padding0 height535 blockImportant">
                                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginBottom-20"}
                                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                                    <ContainerTooltip div1Classes={"padding10"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                        {<SectionTooltip tooltipText={
                                                            {
                                                                "subTitle": insightsLbls.activity,
                                                                "tooltipText": insightsLbls.lineChartTooltipFeatureDetail,
                                                                "childern": []
                                                            }
                                                        } placement="right"></SectionTooltip>
                                                        }
                                                    </ContainerTooltip>
                                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                                        {userEngagementModalChart.userEngagementModalChartFetched ? (
                                                            errorCount.errorCount[6] === 0 ? (
                                                                userEngagementModalChart.loadUserEngagementModalChart.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (

                                                                    <LineChart
                                                                        chartEntities={['pageVisits']}
                                                                        optionEntities={
                                                                            [
                                                                                {
                                                                                    key: insightsLbls.pageVisits,
                                                                                    color: ChartColors.lightseagreen, area: true
                                                                                }
                                                                            ]}
                                                                        chartData={fetched_details?.pageDetail?.userEngagementModalChartData?.data.graphData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="chatbot_conversationResponse">
                                                                    </LineChart>

                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </CardContainer>
                                            </div>
                                        </CommonModal>}
                                </CardContainer>
                </div>
            }
        </div>
        <div className="overlayCursor" id="overlayCursor"></div>
        {errorCount.showMaintenance_flag && (
            <Maintenance></Maintenance>
        )}
    </section>
);
}


export default withRouter(withReducer("pageDetail", pageDetailReducer)(PageDetails));
