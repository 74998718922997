import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import goalDetailsReducer from "store/Reducers/Goal/goalDetails";
import { withReducer } from 'store/withReducer';

import { AppsState, DatesState, EventSourceState, CommonComponentState, LanguageState } from 'store/Reducers';

import { GoalDetailsState } from 'store/Reducers/Goal/goalDetails';
import { useSelector, useDispatch } from 'react-redux';
import * as goalDetailsActions from "store/Actions/index";
import * as ActionTypes from "store/Actions/ActionTypes";
import { patchCall } from 'utils/ApiCallActions'
import qs from 'querystring';
import { CommonUtils } from 'utils/CommonUtils';

import Linechart from "components/Chart/nvd3Charts/LineChart";
import HorizontalStackedBarChart from "components/Chart/apexCharts/ApexHorizontalStackedBarChart";
import './GoalDetails.scss'
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Maintenance from "components/Maintenance/Maintenance";
import { useErrorBoundary } from 'react-error-boundary';
// type PathParamsType = {
//     // param1: string,
// }
// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }



import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from 'labels';

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    eventSource: EventSourceState,
    goalDetails: GoalDetailsState,
    languageData: LanguageState,
    commonComponentData: CommonComponentState

}
interface PayloadInterFace {
    payloadFetched: boolean,
    description: string,
    publishDate: string,
    startDate: string,
    endDate: string,
    guides: any[],
    playCount: number,
    eventSource: any[],
    playMode: any[],
    tooltips: any[],
    errorMsg: string

}
const GoalDetails: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const GoalDetailsLineChartMount = useRef(false);
    const GoalDetailsProgressBarMount = useRef(false);
    const WatecherMount = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });
    const dispatch = useDispatch();

    const { goal_code } = useParams();
    const goalCode = goal_code;
    // const goalCode = props.match.params.goal_code;


    const playModesTotal = [
        { key: "guideme", value: "GuideMe" },
        { key: "showme", value: "ShowMe" },
        { key: "doitforme", value: "DoItForMe" },
        { key: "tooltip", value: "ToolTip" }
    ]
    const eventSourcesTotal = [
        { key: 'creator', value: 'Web Creator' },
        { key: 'player', value: 'Web Player' },
        { key: 'desktop_creator', value: 'Desktop Creator' },
        { key: 'mobile_creator', value: 'Mobile Creator' },
        { key: 'mobile_player', value: 'Mobile Player' },
    ];
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }
    let linechart_height = 400;
    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });
    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0],
        showMaintenance_flag: false
    });
    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });
    const [goalDetailsLineChart, setGoalDetailsLineChart] = useState({
        loadLineChartData: [],
        loadLineChartOptions: [],
        goalDetailsLineChartDataFetched: false,
        optionEntities: [],
        chartEntities: [],
        lineChart: {},
        lineChartFetched: false,
        errorMsg: ''
    });
    const [goalDetailsProgressBar, setGoalDetailsProgressBar] = useState({
        loadProgressBarData: [],
        loadProgressBarOptions: [],
        goalDetailsProgressBarDataFetched: false,
        progressBar: {},
        progressBarFetched: false,
        goalStatus: '',
        errorMsg: ''
    });
    const [goalDetailsWatcher, setGoalDetailsWatcher] = useState({
        watcherDataFectched: false,
        watcherText: '',
        watcher_flag: '',
        buttonText: '',
        statusFetched: false,
        goalStatus: '',
        goalTitle: '',
        errorMsg: ''
    });
    const [goalDetailsPayload, setGoalDetailsPayload] = useState<PayloadInterFace>({
        payloadFetched: false,
        description: '',
        publishDate: '',
        startDate: '',
        endDate: '',
        guides: [],
        playCount: 1,
        eventSource: [],
        playMode: [],
        tooltips: [],
        errorMsg: ''
    });
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    },[fetched_details.languageData.languageData])

    useEffect(() => {
        if(insightsLbls !== undefined){

            document.title = insightsLbls['goalDetails'];
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(6, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });

        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            // tdate: fetched_details.dates.tdate,
        }

        navigate({
            pathname: "/goal/goal-details/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });

        if (fetched_details?.commonComponentData?.datesData.source === 'component') {
            if (fetched_details.commonComponentData?.datesData.url?.includes('goal-details')) {
                getGoalDetailsLineChartData();
                getGoalDetailsProgressBarData();
                getGoalWatcherDetails()
            }
        }
    }, [fetched_details.languageData.languageData, fetched_details?.commonComponentData?.datesData])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGoalDetailsLineChartData = (apiErrorFlag?: string) => {
        const params = {
            "app_code": fetched_details?.commonComponentData?.appsData.appCode,
            "time_zone": timeZone,
            'goal_code': goalCode,
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            'app_id': fetched_details?.commonComponentData?.appsData.appId
        };
        goalDetailsActions.getGoalDetailsData(
            params,
            "GOAL_DETAILS_LINE_CHART_DATA",
            ActionTypes.SET_GOAL_DETAILS_LINE_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };
    const getGoalDetailsProgressBarData = (apiErrorFlag?: string) => {
        const params = {
            "app_code": fetched_details?.commonComponentData?.appsData.appCode,
            "time_zone": timeZone,
            'goal_code': goalCode,
            "app_id": fetched_details?.commonComponentData?.appsData.appId
        };
        goalDetailsActions.getGoalDetailsData(
            params,
            "GOAL_DETAILS_PROGRESS_BAR_DATA",
            ActionTypes.SET_GOAL_DETAILS_PROGRESS_BAR_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };
    const getGoalWatcherDetails = (apiErrorFlag?: string) => {
        const params = {
            app_code: fetched_details?.commonComponentData?.appsData.appCode,
            time_zone: timeZone,
            goal_code: goalCode,
            app_id: fetched_details?.commonComponentData?.appsData.appId
        };
        goalDetailsActions.getGoalDetailsData(
            params,
            "GET_GOAL",
            ActionTypes.SET_GOAL_DETAILS_WATCHER_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }
    const toggleWatcher = (apiErrorFlag?: string) => {
        try {
            setGoalDetailsWatcher((prevState: any) => {
                return {
                    ...prevState,
                    watcherDataFectched: false,
                }
            })
            const params = {
                "app_code": fetched_details.apps.appCode,
                'goal_code': goalCode,
            };
            const urlPath = goalDetailsWatcher.watcher_flag ? 'REMOVE_WATCHER' : 'ADD_WATCHER';
            let watcher_id = {
                "user_email": localStorage.getItem("EMAIL_ID")
            }
            patchCall(params, watcher_id, urlPath, '').then((data: any) => {
                if (data.result === 'success') {
                    toast.success(data.data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);
                    getGoalWatcherDetails();
                } else if (data.result === 'error') {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            toast.dismiss();
                        }, 5000);
                    }
                    setGoalDetailsWatcher((prevState: any) => {
                        return {
                            ...prevState,
                            watcherDataFectched: true,
                        }
                    })
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }
    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGoalDetailsLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        goalDetailsLineChartDataFetched: false,
                        lineChart: {},
                        lineChartFetched: false,
                    };
                });
                getGoalDetailsLineChartData();
            }
            else if (optn === 1) {
                setGoalDetailsProgressBar((prevState: any) => {
                    return {
                        ...prevState,
                        goalDetailsProgressBarDataFetched: false,
                        progressBar: {},
                        progressBarFetched: false,
                    };
                });
                getGoalDetailsProgressBarData();
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }
    const buttonText = (is_curr_user_watching: boolean) => {
        try {

            return (is_curr_user_watching ? insightsLbls["stopWatching"] : insightsLbls["startWatching"])
        } catch (error) {
            showBoundary(error)
        }
    }
    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // LineChart
    useEffect(() => {
        try {
            if (
                fetched_details?.goalDetails?.goalDetailsLineChartData?.data !== undefined &&
                fetched_details?.goalDetails?.goalDetailsLineChartData?.result === "success"
            ) {
                GoalDetailsLineChartMount.current = true;
                let lineChartData: any = [];
                let legendData: any = [];
                let chartEntities: any = [];
                let optionEntities: any = [];
                lineChartData = fetched_details?.goalDetails?.goalDetailsLineChartData?.data?.graphData;
                legendData = fetched_details?.goalDetails?.goalDetailsLineChartData?.data?.legends;
                for (const i of legendData) {
                    chartEntities.push(i.associatedDataPoint)
                    optionEntities.push({ key: i.legendTitle, color: i.color, area: i.area })
                }
                setGoalDetailsLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadLineChartData: [...lineChartData],
                        optionEntities: optionEntities,
                        chartEntities: chartEntities,
                        goalDetailsLineChartDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)
            } else if (fetched_details?.goalDetails?.goalDetailsLineChartData?.result === "retry") {
                getGoalDetailsLineChartData('retry')
            } else if (fetched_details?.goalDetails?.goalDetailsLineChartData?.result === "error") {

                let errorFlag = 1;
                let errorMsg = insightsLbls.serviceNotAvailable
                if (fetched_details?.goalDetails?.goalDetailsLineChartData?.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;

                }


                setGoalDetailsLineChart((prevState: any) => {
                    return {
                        ...prevState,
                        goalDetailsLineChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.goalDetails?.goalDetailsLineChartData]);
    // ProgressBar
    useEffect(() => {
        try {
            if (
                fetched_details.goalDetails.goalDetailsProgressBarData.data !== undefined &&
                fetched_details.goalDetails.goalDetailsProgressBarData.result === "success"
            ) {
                GoalDetailsProgressBarMount.current = true;
                let progressBarData: any = [];
                progressBarData = fetched_details.goalDetails.goalDetailsProgressBarData.data;
                let goalTargetPlays = progressBarData.goalTargetPlays;
                let goalActualPlays = progressBarData.goalActualPlays;
                let goalCompletionPercentage = (goalActualPlays > goalTargetPlays ? ("100%") : (((goalActualPlays / goalTargetPlays) * 100).toFixed(2) + "%"));
                let goalCompletionOverheadPercentage = (goalActualPlays > goalTargetPlays ? ((((goalActualPlays - goalTargetPlays) / goalTargetPlays) * 100).toFixed(2) + "%") : (0));
                let progressBarOptions: any;
                if (goalActualPlays > goalTargetPlays) {
                    progressBarOptions = [
                        {
                            name: insightsLbls['goalCompletionPercent'],
                            data: [goalCompletionPercentage],
                        },
                        {
                            name: insightsLbls['goalCompletionOverheadPercent'],
                            data: [goalCompletionOverheadPercentage],
                        },
                    ];
                }
                else {
                    progressBarOptions = [
                        {
                            name: insightsLbls['goalCompletionPercent'],
                            data: [goalCompletionPercentage],
                        },
                    ];
                }
                setGoalDetailsProgressBar((prevState: any) => {
                    return {
                        ...prevState,
                        loadProgressBarData: progressBarData,
                        loadProgressBarOptions: progressBarOptions,
                        goalDetailsProgressBarDataFetched: true,
                        goalStatus: progressBarData.goalStatus
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true)
            } else if (fetched_details.goalDetails.goalDetailsProgressBarData.result === "retry") {
                getGoalDetailsProgressBarData('retry')
            } else if (fetched_details.goalDetails.goalDetailsProgressBarData.result === "error") {
                let errorFlag = 1;
                let errorMsg = insightsLbls.serviceNotAvailable
                if (fetched_details?.goalDetails?.goalDetailsProgressBarData?.errorCode === 409) {
                    errorFlag = 2;
                    errorMsg = insightsLbls.conflictingParameters
                }
                setGoalDetailsProgressBar((prevState: any) => {
                    return {
                        ...prevState,
                        goalDetailsProgressBarDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.goalDetails.goalDetailsProgressBarData]);
    // Watcher Data
    useEffect(() => {
        try {
            if (
                fetched_details?.goalDetails?.goalDetailsWatcherData?.data !== undefined &&
                fetched_details?.goalDetails?.goalDetailsWatcherData?.result === "success"
            ) {
                let watcherData: any;
                watcherData = fetched_details?.goalDetails?.goalDetailsWatcherData?.data;
                setGoalDetailsWatcher((prevState: any) => {
                    return {
                        ...prevState,
                        watcherDataFectched: true,
                        watcherText: watcherData.goalPayloadObject.watchers,
                        watcher_flag: watcherData.goalPayloadObject.is_curr_user_watching,
                        buttonText: buttonText(watcherData.goalPayloadObject.is_curr_user_watching),
                        goalStatus: watcherData.goalStatus,
                        statusFetched: true,
                        goalTitle: watcherData.goalTitle
                    }
                })
                apiCatchError(2, 0);
                if (!WatecherMount.current) {
                    setGoalDetailsPayload((prevState: any) => {
                        return {
                            ...prevState,
                            payloadFetched: true,
                            description: watcherData.goalDescription,
                            publishDate: watcherData.publishTimestamp,
                            endDate: watcherData.goalEndTimestamp,
                            guides: !watcherData.goalPayloadObject.play_types.includes('tooltip') ? watcherData.goalPayloadObject.guides.map((guide: any) => guide.guideTitle) : '',
                            playCount: watcherData.goalPayloadObject.count,
                            eventSource: watcherData.goalPayloadObject.event_sources,
                            playMode: watcherData.goalPayloadObject.play_types,
                            startDate: watcherData.goalStartTimestamp,
                            tooltips: watcherData.goalPayloadObject.play_types.includes('tooltip') ? watcherData.goalPayloadObject.guides.map((guide: any) => guide.guideTitle) : '',
                        }
                    })
                }
                WatecherMount.current = true;
                // enableExportPdf(0, true)
            } else if (fetched_details?.goalDetails?.goalDetailsWatcherData?.result === "retry") {
                getGoalWatcherDetails('retry')
            } else if (fetched_details?.goalDetails?.goalDetailsWatcherData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1
                if (fetched_details?.goalDetails?.goalDetailsWatcherData?.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2
                }
                setGoalDetailsWatcher((prevState: any) => {
                    return {
                        ...prevState,
                        watcherDataFectched: false,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(2, errorFlag);
                // enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.goalDetails?.goalDetailsWatcherData]);
    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])
    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [errorCount.errorCount])
    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // const getLineChartData = useCallback(
    //     (optn: string) => {
    //         if (optn === "data") {
    //             return goalDetailsLineChart.loadLineChartData;
    //         } else {
    //             return goalDetailsLineChart.loadLineChartOptions;
    //         }
    //     },
    //     [goalDetailsLineChart.loadLineChartData, goalDetailsLineChart.loadLineChartOptions]
    // );
    const LineChartGoalDetails = useCallback(() => {
        try {
            setGoalDetailsLineChart((prevState: any) => {
                return {
                    ...prevState,
                    lineChart: <Linechart
                        chartEntities={goalDetailsLineChart.chartEntities}
                        optionEntities={goalDetailsLineChart.optionEntities}
                        chartData={fetched_details?.goalDetails?.goalDetailsLineChartData?.data?.graphData}
                        value_type={"count"}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        height={linechart_height}
                        margin={lineChartMargin}
                        refId="goalDetailsLineChart">
                    </Linechart>,
                    lineChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);
    const ProgressBarGoalDetails = useCallback(() => {
        try {
            setGoalDetailsProgressBar((prevState: any) => {
                return {
                    ...prevState,
                    progressBar: <HorizontalStackedBarChart
                        chartOptions={goalDetailsProgressBar.loadProgressBarOptions}
                        chartData={goalDetailsProgressBar.loadProgressBarData}
                        refId="goalDetailsProgressBar">
                    </HorizontalStackedBarChart>,
                    progressBarFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);
    const tooltipText = (element: string) => {
        try {
            return (<Tooltip id="tooltipText">
                <br></br>
                <div><span className="fontGreyCard">{element}</span></div>
                <br></br>
            </Tooltip>)
        } catch (error) {
            showBoundary(error)
        }

    }

    const getGoalStatusValue = (value: string) => {
        try {
            switch (value) {
                case 'Live':
                    return insightsLbls['live'];

                case 'Achieved':
                    return insightsLbls['achieved'];

                case 'Ended':
                    return insightsLbls['ended'];

                case 'Achieved and Live':
                    return insightsLbls['achievedAndLive'];
                case 'Saved':
                    return insightsLbls['saved'];
                case 'Published':
                    return insightsLbls['published'];

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getEventSourceData = (value: string) => {
        try {
            switch (value) {
                case 'Web Creator':
                    return insightsLbls['webCreator'];
                case 'Web Player':

                    return insightsLbls['webPlayer'];
                case 'Desktop Creator':

                    return insightsLbls['desktopCreator'];
                case 'Mobile Creator':

                    return insightsLbls['mobileCreator'];
                case 'Mobile Player':

                    return insightsLbls['mobilePlayer'];

                default:
                    return value;
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
        }
        navigate({
            pathname: `/goal/manage-goal/`,
            search: qs.stringify(queryParams)
        })
    }

    return (
        <section className="demo width100 goalDetailsSection" ref={componentRef}>
            <div className="row">
                <SectionHeader>
                    <li>
                        <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['goal']}</span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </li>
                    <li className="active">{insightsLbls['goalDetails']}</li>
                </SectionHeader>
                <div className="col-sm-3 col-md-3 col-lg-3">
                </div>
            </div>
            <SectionButtons sectionTitle={goalDetailsWatcher.goalTitle} svgImage={"guides"}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guides"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
                <Datepicker source="component" style={{ visibility: 'hidden!important' }}></Datepicker>
            </SectionButtons>
            {errorCount.errorCount[2] === 0 ?
                <>
                    <div className="page-title-new col-4 col-sm-4 col-4 section-subheading-div">
                        <h5>{goalDetailsPayload.description}</h5>
                    </div>
                    <div className="status_watcher-div">
                        {
                            goalDetailsWatcher.statusFetched
                                ? (<div className="">
                                    <span className={`alignCenter goalDetailStatus ${goalDetailsProgressBar.goalStatus === 'Achieved and Live' ? 'Achieved_and_live' : goalDetailsProgressBar.goalStatus} me-3`}>
                                        {/* {goalDetailsProgressBar.goalStatus} */}
                                        {getGoalStatusValue(goalDetailsWatcher.goalStatus)}
                                    </span>
                                </div>)
                                : (<div className="displayFlex alignCenter margin-0-auto">
                                    <Loader width={39} height={'auto'} margin={'none'}></Loader>
                                </div>)
                        }
                        {
                            goalDetailsWatcher.watcherDataFectched
                                ?
                                (<div className="watcher-div">
                                    <span className="goalDetailStatus watcherText">{goalDetailsWatcher.watcherText}</span>
                                    <div className="watchbtn-div">
                                        <button type="button" className="events-apply-btn modal-save-btn watchbtn" onClick={() => { toggleWatcher() }} >{goalDetailsWatcher.buttonText}</button>
                                    </div>
                                    {/* <span className="marginLeft-10 font12 alignCenter goalDetailStatus Achieved_and_live" onClick={() => {toggleWatcher()}}>{goalDetailsWatcher.buttonText}</span> */}
                                </div>)
                                : (<div className="loader displayFlex alignCenter">
                                    <Loader width={39} height={'auto'} margin={'none'}></Loader>
                                </div>)
                        }
                    </div>
                    <div className="payload-combined-div">
                        {goalDetailsPayload.payloadFetched
                            ?
                            (<div className="payload-div">
                                <div className="detailFlexAlignCenter">
                                    <span className='startText font14 font600'>{insightsLbls['startDate']} :&nbsp;</span>
                                    <span className="payloadDetailDate payloadText">{goalDetailsPayload.startDate}</span>
                                    <span className='endText font14 font600'>{insightsLbls['endDate']} :&nbsp;</span>
                                    <span className="payloadDetailDate payloadText">{goalDetailsPayload.endDate}</span>
                                </div>
                                <div className='guides detailFlexAlignCenter'>
                                    <span className='startText font14 font600'>{goalDetailsPayload.playMode.includes('tooltip') ? 'Tooltips: ' : 'Guides: '}&nbsp;</span>
                                    {(goalDetailsPayload.playMode.includes('tooltip') ? goalDetailsPayload.tooltips : goalDetailsPayload.guides).map((element: any) => {
                                        return (
                                            <OverlayTrigger placement={"bottom"} overlay={tooltipText(element)}>
                                                <span className="payloadDetail payloadText">{element}</span>
                                            </OverlayTrigger>
                                        )
                                    })
                                    }
                                </div>
                                <div className="detailFlexAlignCenter">
                                    <span className='startText font14 font600'>{insightsLbls['eventSources']} :&nbsp;</span>
                                    {
                                        eventSourcesTotal.map((source) => {
                                            if (goalDetailsPayload.eventSource.includes(source.key)) {
                                                return <span className="payloadDetail payloadText">{getEventSourceData(source.value)}</span>
                                            }
                                        })
                                    }
                                    {/* {goalDetailsPayload.eventSource.map((eventSource: any) => {
                                        return <span className="payloadDetail payloadText">{eventSource}</span>
                                    })} */}
                                    <span className='endText font14 font600'>{insightsLbls['playModes']} :&nbsp;</span>
                                    {
                                        playModesTotal.map((mode, index: number) => {
                                            if (goalDetailsPayload.playMode.includes(mode.key)) {
                                                return <span className="payloadDetail payloadText">{mode.value}</span>
                                            }
                                        })
                                    }
                                </div>
                                <div className="detailFlexAlignCenter">
                                    <span className='startText font14 font600'>{insightsLbls['targetPlayCount']} :&nbsp;</span>
                                    <span className="payloadDetail payloadText"> {goalDetailsPayload.playCount}</span>
                                </div>
                            </div>)
                            : (<div className="loader displayFlex alignCenter">
                                <Loader width={39} height={'auto'} margin={'none'}></Loader>
                            </div>)
                        }
                    </div>
                </>
                : (
                    <h5 className="errorCss card-heading font-weight-400">
                        {goalDetailsWatcher.errorMsg}
                    </h5>
                )
            }
            {
                <SectionRefresh sectionTitle={insightsLbls.goalDetailOverview} refreshClass={"marginTop-30"}>
                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(0)}></i>
                </SectionRefresh>
            }
            {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                div3Classes={"col-sm-12 minHeight490 height100-percent padding15"}>
                <ContainerTooltip div1Classes={""} div3Classes={""} hTagClasses={"section-heading headingText"}>
                    {
                        goalDetailsPayload.playMode.includes('tooltip') ?
                            (
                                (<SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.goalPlayed + ":",
                                        "tooltipText": insightsLbls.goalActivitiesChart,
                                        "childern": [
                                            {
                                                "key": insightsLbls.tooltipPlay + ":",
                                                "value": insightsLbls.totalTooltipsRenderCount
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>)
                            )
                            :
                            (
                                (<SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.goalPlayed + ":",
                                        "tooltipText": insightsLbls.goalActivitiesChart,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>)
                            )

                    }
                </ContainerTooltip>
                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                    {goalDetailsLineChart.goalDetailsLineChartDataFetched ? (
                        errorCount.errorCount[0] === 0 ? (
                            goalDetailsLineChart.loadLineChartData.length === 0 ? (
                                <div className="noDataText-font textCenter-absolute">
                                    {insightsLbls.noRecordFound}
                                </div>
                            ) : (
                                goalDetailsLineChart.lineChartFetched ? <>{goalDetailsLineChart.lineChart}</> :
                                    (<LineChartGoalDetails></LineChartGoalDetails>)
                            )
                        ) : (
                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                {goalDetailsLineChart.errorMsg}
                            </h5>
                        )
                    ) : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}
                </div>
            </CardContainer>
            {
                <SectionRefresh sectionTitle={insightsLbls.goalProgress} refreshClass={"marginTop-30"}>
                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                </SectionRefresh>
            }
            {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                div3Classes={"col-sm-12 minHeight350 height100-percent padding15"}>
                <ContainerTooltip div1Classes={""} div3Classes={""} hTagClasses={"section-heading headingText"}>
                    <SectionTooltip tooltipText={
                        {
                            "subTitle": insightsLbls.userGuidesSummary,
                            "tooltipText": insightsLbls.goalProgressBarChart,
                            "childern": [
                                {
                                    "key": insightsLbls.goalCompletion + ":",
                                    "value": insightsLbls.targetGoalPercentage
                                },
                                {
                                    "key": insightsLbls.goalCompletionOverhead + ":",
                                    "value": insightsLbls.goalsExceedingTargets
                                }
                            ]
                        }
                    } placement="right"></SectionTooltip>
                </ContainerTooltip>
                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                    {goalDetailsProgressBar.goalDetailsProgressBarDataFetched ? (
                        errorCount.errorCount[1] === 0 ? (
                            goalDetailsProgressBar.loadProgressBarData.length === 0 ? (
                                <div className="noDataText-font textCenter-absolute">
                                    {insightsLbls.noRecordFound}
                                </div>
                            ) : (
                                goalDetailsProgressBar.progressBarFetched ? <>{goalDetailsProgressBar.progressBar}</> :
                                    (<ProgressBarGoalDetails></ProgressBarGoalDetails>)
                            )
                        ) : (
                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                {goalDetailsProgressBar.errorMsg}
                            </h5>
                        )
                    ) : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}
                </div>
            </CardContainer>
            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    )
};
export default withRouter(withReducer("goalDetails", goalDetailsReducer)(GoalDetails));