import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CommonComponentState, LanguageState } from "store/Reducers";
import { getCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import { useErrorBoundary } from 'react-error-boundary';

import CommonModal from "../CommonModal";

import "./FeatureListModal.scss";


import TreeStructure from "components/TreeStructure/TreeStructure";
import Loader from "components/Loader/Loader";
import { InsightLabels } from "labels";


interface CurrentState {
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const FeatureListModal = (props: any) => {
    const { showBoundary } = useErrorBoundary();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_details = useSelector((state: CurrentState) => state);

    const [featureModal, setFeatureModal] = useState({
        modalOpen: false,
        listData: [],
        listFetched: false,
        okDisabled: false,
    });


    const [errorCount, setErrorCount] = useState({
        errorCount: [0],
    });

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    const openModal = () => {
        try {
            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: true,
                };
            });

            getFeatureList();
        } catch (error) {
            showBoundary(error)
        }

    };

    const onSearchText = (searchInput: any) => {
        try {
            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    listFetched: false,
                    okDisabled: true,
                }
            })
            // getFeatureList(searchInput.target.value);
        } catch (error) {
            showBoundary(error)
        }
    };

    const getFeatureList = (searchInput?: any, apiErrorFlag?: string) => {
        try {
            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    listFetched: false
                };
            });

            const params = {
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "feature_code": props.featureCode,
            };

            getCall(params, "FEATURE_LIST", apiErrorFlag ?? "").then((res: any) => {
                if (res.result === "success") {
                    setFeatureModal((prevState: any) => {
                        return {
                            ...prevState,
                            listFetched: true,
                            listData: res?.data,
                            okDisabled: res?.data?.length === 0 ? true : false,
                        };
                    });

                    apiCatchError(0, 0);
                } else if (res.result === "retry") {
                    setTimeout(() => {
                        getFeatureList('', 'retry')
                    }, RetryApi.TIMEOUT);
                } else if (res.result === "error") {
                    setFeatureModal((prevState: any) => {
                        return {
                            ...prevState,
                            listFetched: true,
                            listData: {},
                        };
                    });
                    apiCatchError(0, 1);
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    };



    const closeModal = () => {
        try {
            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false,

                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };



    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };


    useEffect(() => {
        try {
            if (featureModal.listFetched) {
                const defaultData = props.appliedList();
                if (!defaultData.length) {
                    for (let itr0 = 0; itr0 < featureModal.listData.length; itr0++) {
                        const elem = document.getElementById("guide_" + itr0) as HTMLInputElement;;
                        if (elem) {
                            elem.checked = true;
                        }

                        for (let itr1 = 0; itr1 < featureModal.listData[itr0].stepDetails.length; itr1++) {
                            const elem1 = document.getElementById("guide_" + itr0 + "_" + itr1) as HTMLInputElement;
                            if (elem1) {
                                elem1.checked = true;
                            }
                        }
                    }
                } else {
                    let data = defaultData.split('&step_id=');
                    for (let itr0 = 1; itr0 < data.length; itr0++) {
                        for (let itr1 = 0; itr1 < featureModal.listData.length; itr1++) {
                            let cnt = 0;

                            for (let itr2 = 0; itr2 < featureModal.listData[itr1].stepDetails.length; itr2++) {
                                if (data[itr0] == featureModal.listData[itr1].stepDetails[itr2].stepID) {
                                    const elem1 = document.getElementById("guide_" + itr1 + "_" + itr2) as HTMLInputElement;
                                    if (elem1) {
                                        elem1.checked = true;
                                        cnt = cnt + 1
                                    }
                                }
                            }


                            if (cnt) {
                                const elem = document.getElementById("guide_" + itr1) as HTMLInputElement;;
                                if (elem) {
                                    elem.checked = true;
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [featureModal.listFetched])


    const applyGuideList = () => {
        try {
            let stepData: String = '';
            for (let itr0 = 0; itr0 < featureModal.listData.length; itr0++) {
                const elem = document.getElementById("guide_" + itr0) as HTMLInputElement;;
                if (elem && elem.checked) {
                    for (let itr1 = 0; itr1 < featureModal.listData[itr0].stepDetails.length; itr1++) {
                        const elem1 = document.getElementById("guide_" + itr0 + "_" + itr1) as HTMLInputElement;
                        if (elem1 && elem1.checked) {
                            stepData = stepData + "&step_id=" + featureModal.listData[itr0].stepDetails[itr1].stepID;
                        }
                    }
                }
            }


            const applied_data = {
                stepData: stepData
            }
            props.dispatchFeatureList(applied_data);
        } catch (error) {
            showBoundary(error)
        }

    }

    const selectGuide = (ev: any, guideId: any) => {
        try {
            const elem = document.getElementById("guide_" + guideId) as HTMLInputElement;
            if (elem) {
                elem.checked = ev.target.checked;
            }


            for (let itr1 = 0; itr1 < featureModal.listData[guideId].stepDetails.length; itr1++) {
                const elem1 = document.getElementById("guide_" + guideId + "_" + itr1) as HTMLInputElement;
                if (elem1) {
                    elem1.checked = ev.target.checked;
                }
            }

            let disableCnt = 0
            for (let itr0 = 0; itr0 < featureModal.listData.length; itr0++) {
                const elem = document.getElementById("guide_" + itr0) as HTMLInputElement;
                if (elem && elem.checked) {
                    disableCnt = disableCnt + 1
                }
            }

            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    okDisabled: disableCnt === 0
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }

    const selectSubGuide = (ev: any, guideId: any, subGuideId: any) => {
        try {
            const elem1 = document.getElementById("guide_" + guideId + "_" + subGuideId) as HTMLInputElement;
            if (elem1) {
                elem1.checked = ev.target.checked;
            }

            let cnt_flag = 0;
            for (let itr1 = 0; itr1 < featureModal.listData[guideId].stepDetails.length; itr1++) {
                const elem2 = document.getElementById("guide_" + guideId + "_" + itr1) as HTMLInputElement;
                if (elem2 && elem2.checked) {
                    cnt_flag = cnt_flag + 1;
                }
            }

            const elem = document.getElementById("guide_" + guideId) as HTMLInputElement;
            if (elem) {
                elem.checked = cnt_flag === 0 ? false : true;
            }


            let disableCnt = 0
            for (let itr0 = 0; itr0 < featureModal.listData.length; itr0++) {
                for (let itr1 = 0; itr1 < featureModal.listData[itr0].stepDetails.length; itr1++) {
                    const elem2 = document.getElementById("guide_" + itr0 + "_" + itr1) as HTMLInputElement;
                    if (elem2 && elem2.checked) {
                        disableCnt = disableCnt + 1;
                    }
                }
            }

            setFeatureModal((prevState: any) => {
                return {
                    ...prevState,
                    okDisabled: disableCnt === 0
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <div className="FeatureModal-container">
            <p className="featureFilterTitle">{insightsLbls.featureList}</p>

            <button
                onClick={() => openModal()}
                className="source-but btn displayFlex"
                type="button"
                style={{
                    position: "static",
                }}
            >
                <i className="fa fa-filter marginTop-4 marginRight-10"></i>
                {insightsLbls.featureList}
            </button>

            <CommonModal
                modalState={featureModal.modalOpen}
                dispatchModalState={closeModal}
                modalTitle={insightsLbls.featureList}
                dispatchModalOk={applyGuideList}
                okBtn={insightsLbls.apply}
                okBtnState={featureModal.okDisabled}
            >
                <>
                    <div className="featureList">
                        {featureModal.listFetched ? (
                            errorCount.errorCount[0] === 0 ? (
                                featureModal.listData.length === 0 ? (
                                    <div className="noDataText-font textCenter-absolute">
                                        {insightsLbls.noRecordFound}
                                    </div>
                                ) : (
                                    <>
                                        <div className="treeSection">
                                            {featureModal.listData.map((item: any, index: any) => {
                                                return (
                                                    <div className=" guideDiv-bg">
                                                        <div className="marginLeft-30 marginTop25 custom-control custom-checkbox mr-sm-2 check-container">
                                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5 font600">
                                                                <span className="marginLeft-30">{item.groupTitle}</span>
                                                                <input type="checkbox"
                                                                    className="custom-control-input chk checkbox"
                                                                    id={"guide_" + index}
                                                                    name="chk-box"
                                                                    onClick={(ev) => selectGuide(ev, index)} />
                                                                <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                            </label>
                                                        </div>

                                                        <div>
                                                            {item.stepDetails.map((viewItem: any, index2: any) => {
                                                                return (
                                                                    <p className="level2">
                                                                        <div className="marginLeft-30 marginTop25 custom-control custom-checkbox mr-sm-2 check-container" key={index2}>
                                                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                                                                <span className="marginLeft-30">{viewItem.stepTitle}</span>
                                                                                <input type="checkbox"
                                                                                    className="custom-control-input chk checkbox"
                                                                                    id={"guide_" + index + "_" + index2}
                                                                                    name="chk-box1"
                                                                                    onClick={(ev) => selectSubGuide(ev, index, index2)} />
                                                                                <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                                            </label>
                                                                        </div>
                                                                    </p>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            </CommonModal>
        </div>
    );
};

export default React.memo(FeatureListModal);