// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props.listData)

import React, { useEffect } from 'react';
import { ChangeEvent, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CommonUtils } from "../../utils/CommonUtils"
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";


import Loader from 'components/Loader/Loader';


import './GoalList.scss';
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from 'labels';
interface CurrentState {
    languageData: LanguageState
}

const GoalList = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

    const [list, setList] = useState({
        flag: false
    })
    const [arrowIndex, setArrowIndex] = useState({
        index_up: null
    });

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        setList((prevState: any) => {
            return {
                ...prevState,
                flag: true
            }
        })
    }, [props.listData])

    const toggleArrow = (event: any) => {
        setArrowIndex((prevState: any) => {
            return {
                ...prevState,
                index_up: prevState.index_up === +event.target.id ? null : +event.target.id
            };
        });
    }

    const tooltipText = (selected: boolean) => {

        return (<Tooltip id="tooltipText">
            <br></br>
            <div><span className="fontGreyCard">{!selected ? `${insightsLbls.moreDetails}...` : `${insightsLbls.lessDetails}...`}</span></div>
            <br></br>
        </Tooltip>)
    }

    const tooltipBtnText = (type: string) => {
        return (<Tooltip id="tooltipText">
            <br></br>
            <div><span className="fontGreyCard">{type === 'edit' ? insightsLbls.editGoal : insightsLbls.deleteGoal}</span></div>
            <br></br>
        </Tooltip>)
    }

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setList((prevState: any) => {
            return {
                ...prevState,
                flag: false
            }
        })
        props.searchList(e.target.value)
    }


    const handleDelete = (data: string) => {
        props.deleteGoal(data)
    }

    const getGoalStatusValue = (value: string) => {
        try {
            switch (value) {
                case 'Live':
                    return insightsLbls.live;

                case 'Achieved':
                    return insightsLbls.achieved;

                case 'Ended':
                    return insightsLbls.ended;

                case 'Achieved and Live':
                    return insightsLbls.achievedAndLive;
                case 'Saved':
                    return insightsLbls.saved;
                case 'Published':
                    return insightsLbls.published;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    return (
        <>
            <div className="displayFlex goalListSection">
                <div className="categoryDiv">
                    <div className="categoryList-div" id="tags_main_div">
                        <div className="searchDiv">
                            <p className="displayFlex marginTop-10">
                                <input type="text"
                                    className="searchIcon form-control round-input searchInput-box"
                                    placeholder={insightsLbls.searchGoal}
                                    autoComplete="off"
                                    onChange={(event) => onSearchText(event)}
                                    disabled={(props.apiError === 1 || (props.firstTimeLoad && props.listCount === 0)) ? true : false} />
                                <button className="createCat-button" onClick={() => props.dispatchClick()}>
                                    <i className="fa fa-plus font20" aria-hidden="true"></i>
                                </button>
                            </p>
                        </div>
                        <div className="categoryMain-div">

                            {
                                list.flag
                                    ? (
                                        props.apiError === 0 ?
                                            (
                                                <>
                                                    {
                                                        props.listCount ?
                                                            (
                                                                <>
                                                                    {
                                                                        props.listData.map((data: any, index: number) => {
                                                                            return (
                                                                                <div className="row category-goal-card highlightCard" key={index}>
                                                                                    <div className="flex-100 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                                                                        <div className="cat-content-div col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 positionRelative">
                                                                                            <span className="nomargin fontW600 textOverflow cat-info marginBottom-0">
                                                                                                {data.goalTitle}
                                                                                            </span>
                                                                                            {
                                                                                                data.goalStatus !== 'Saved' && data.goalStatus !== 'Published' &&
                                                                                                (<span className="viewDetails fontW600" onClick={() => props.dipatchDetail(data)}>{insightsLbls.viewDetails}</span>)
                                                                                            }
                                                                                        </div>
                                                                                        <div className="app-actions-div col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 paddingLR-0">
                                                                                            <div className="catEdit-optn">
                                                                                                <div className="optnsContainer displayFlex alignCenter marginTop-10">
                                                                                                    {data.goalStatus !== 'Saved' || props.deleteGoalFlag ?
                                                                                                        <>
                                                                                                            <button className="editDeleteBtn" onClick={() => props.dispatchEditGoal(data)} disabled>
                                                                                                                <i className="fa fa-edit editCard-icon margin8" aria-hidden="true"></i>
                                                                                                            </button>
                                                                                                            <button className="editDeleteBtn" onClick={() => handleDelete(data.goalCode)} disabled>
                                                                                                                <i className="fa fa-trash editCard-icon margin8" aria-hidden="true"></i>
                                                                                                            </button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <OverlayTrigger placement={"bottom"} overlay={tooltipBtnText('edit')} >
                                                                                                                <button className="editDeleteBtn" onClick={() => props.dispatchEditGoal(data)}>
                                                                                                                    <i className="fa fa-edit editCard-icon margin8" aria-hidden="true" ></i>
                                                                                                                </button>
                                                                                                            </OverlayTrigger>
                                                                                                            <OverlayTrigger placement={"bottom"} overlay={tooltipBtnText('delete')} >
                                                                                                                <button className="editDeleteBtn" onClick={() => handleDelete(data.goalCode)}>
                                                                                                                    <i className="fa fa-trash editCard-icon margin8" aria-hidden="true"></i>
                                                                                                                </button>
                                                                                                            </OverlayTrigger>
                                                                                                        </>}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="alignCenter infoDiv font13">
                                                                                        <div className="col-lg-9 textLeft alignCenter paddingRight-5 paddingLeft-0 wrapngap">
                                                                                            <div className="alignCenter">
                                                                                                <span className="lastUpdatedText">{insightsLbls.goalLiveOn}:&nbsp;</span>
                                                                                                <span className="font600 lastUpdated">{CommonUtils.dateTimeFormat(data.goalStartTimestamp, 'date&time')}</span>
                                                                                            </div>

                                                                                            <div className="alignCenter">
                                                                                                <span className="lastUpdatedText">{insightsLbls.targetCompletion}:&nbsp;</span>
                                                                                                <span className="font600 lastUpdated">{CommonUtils.dateTimeFormat(data.goalEndTimestamp, 'date&time')}</span>
                                                                                            </div>

                                                                                            <div className="alignCenter">
                                                                                                <span className="lastUpdatedText">{insightsLbls.currentStatus}:&nbsp;</span>
                                                                                                <span className="font600 lastUpdated">{`${data.goalCompletionPercentage}% ${insightsLbls.completed}`}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3 alignCenter displayFlex paddingLeft-20">

                                                                                            {/* <>
                                                                                        <div className="alignCenter globalChip marginRight-15" >
                                                                                            Global
                                                                                        </div> &nbsp;
                                                                                    </> */}


                                                                                            <div className={`alignCenter goalStatus ${data.goalStatus === 'Achieved and Live' ? 'Achieved_and_live' : data.goalStatus} me-2`}>
                                                                                                {/* <div className={`alignCenter goalStatus`}> */}
                                                                                                {getGoalStatusValue(data.goalStatus)}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    {
                                                                                        arrowIndex.index_up === index && <div className="alignCenter additionalInfo font12 infoDiv pt-1">
                                                                                            <span className="lastUpdatedText">{insightsLbls.description}:&nbsp;</span>
                                                                                            <span className="font600 lastUpdated descriptionText me-4">{data.goalDescription}</span>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        // arrowIndex.index_up===index && <div className="alignCenter additionalInfo font12 infoDiv">
                                                                                        //     <span className = "lastUpdatedText">Additional Remarks:&nbsp;</span>
                                                                                        //     <span className="font600 lastUpdated">{`${arrowIndex.index_up} 2nd time`}</span>
                                                                                        // </div>
                                                                                    }
                                                                                    <div className="downArrow font12">
                                                                                        <OverlayTrigger placement={"bottom"} overlay={tooltipText(arrowIndex.index_up === index)}>
                                                                                            <i id={`${index}`} className={'fa fa-angle' + (arrowIndex.index_up !== index ? '-down ' : '-up ') + 'pointer ' + 'arrowWeight'} aria-hidden="true" onClick={toggleArrow}></i>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            )}
                                                </>
                                            )
                                            :
                                            (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.serviceNotAvailable}.
                                                </div>
                                            )
                                    )
                                    :
                                    (<div className="minHeight400">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>)
                            }
                        </div>
                    </div>
                </div>

                {/* Sentiment Instructions */}
                <div id="sentiment_instructions" className="width100">
                   
                    <div className="sentimentInstruction">
                        <div>{insightsLbls.click} <span className="createCat-button createCat-button-span"><i className="fa fa-plus font20" aria-hidden="true"></i></span>
                            {insightsLbls.iconForNewGoal}</div>
                        <div>{insightsLbls.clickForEditMessage}</div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default React.memo(GoalList);
