import moment from "moment";


import {
    AppAction,
    DateAction,
    ExportDateAction,
    EventSourceAction,
    SegmentsFilterAction,
    DomainAction,
    OrgAction,
    VisibilityAction,
    HandoffAction,
    StateFilterAction,
    ExportRequestSourceFilterAction,
    UrlOptionAction,
    DashboardsAction,
    AuthAction,
    TooltipFilterAction,
    EnvFilterAction,
    CommmonComponentAction,
    LanguageAction
} from "./types";

export interface AppsState {
    appCode: string | null,
    appId: number,
    appStartDate: string | null,
    appEndDate: string | null,
    appName: string | null,
    appImg: string | null,
    dashboardMaxDate: string | null,
    dashboardMinDate: string | null,
    appGuideAutomationEnabled: string | null,
    pageTrackingEnabled: number,
    featureTrackingEnabled: number
}

let appId: any = localStorage.getItem("switch_app_id");

export const initialAppState = {
    appCode: localStorage.getItem("switch_app_code"),
    appId: +appId,
    appStartDate: localStorage.getItem("min_date"),
    appEndDate: localStorage.getItem("max_date"),
    appName: localStorage.getItem("firstApp_name"),
    appImg: localStorage.getItem("app_img"),
    dashboardMaxDate: localStorage.getItem("dashboard_max_date"),
    dashboardMinDate: localStorage.getItem("dashboard_min_date"),
    appGuideAutomationEnabled: localStorage.getItem("app_guide_automation_enabled"),
    pageTrackingEnabled: 0,
    featureTrackingEnabled: 0,
}

export const AppReducer = (state: AppsState = initialAppState, action: AppAction) => {
    switch (action.type) {
        case 'SET_APP_DETAILS': {
            return {
                ...state,
                appCode: action.appPayload.appCode,
                appId: action.appPayload.appId,
                appStartDate: action.appPayload.appStartDate,
                appEndDate: action.appPayload.appEndDate,
                appName: action.appPayload.appName,
                appImg: action.appPayload.appImg,
                dashboardMaxDate: action.appPayload.dashboardMaxDate,
                dashboardMinDate: action.appPayload.dashboardMinDate,
                appGuideAutomationEnabled: action.appPayload.appGuideAutomationEnabled,
                pageTrackingEnabled: action.appPayload.pageTrackingEnabled,
                featureTrackingEnabled: action.appPayload.featureTrackingEnabled
            }
        }

        default:
            return state;
    }
};
export interface DatesState {
    tdate: string | null,
    fdate: string | null,
    dateSelection: number,
    excludeFlag: boolean,
    source: string | null,
    url: string | null,
    realTimeCheck: boolean,
    realTime_tdate: string | null
    appId: string | number | null,
    appCode: string | number | null
}

let dateSelection: any = localStorage.getItem("SELECTED_OPTN");
let tdateVal = localStorage.getItem("TDATE");
let fdateVal = localStorage.getItem("FDATE");
let realTimeCheck = localStorage.getItem("REAL_TIME_CHECK") === 'true' ? true : false;
let appId_value = null


if (localStorage.getItem('ENABLE_REAL_TIME') !== 'true' && moment(new Date()).format("YYYY-MM-DD") === tdateVal) {
    tdateVal = moment(tdateVal).subtract(1, "days").format("YYYY-MM-DD");
    fdateVal = moment(fdateVal).subtract(1, "days").format("YYYY-MM-DD");
}

// based on real time change tdate
const realTime_tdate = realTimeCheck ? moment(tdateVal).add(1, "days").format("YYYY-MM-DD") : tdateVal


const initialDateState = {
    tdate: tdateVal,
    fdate: fdateVal,
    dateSelection: +dateSelection,
    excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
    source: localStorage.getItem("DATEPICKER_SOURCE") ?? 'component',
    url: '',
    realTimeCheck: realTimeCheck,
    realTime_tdate: realTime_tdate,
    appId: localStorage.getItem("switch_app_id"),
    appCode: localStorage.getItem("switch_app_code"),
    export_tdate: tdateVal,
    export_fdate: fdateVal,
}

export const DateReducer = (state: DatesState = initialDateState, action: DateAction) => {
    switch (action.type) {
        case 'SET_DATES': {
            return {
                ...state,
                tdate: action?.datePayload?.tdate,
                fdate: action?.datePayload?.fdate,
                dateSelection: action?.datePayload?.dateSelection,
                excludeFlag: action?.datePayload?.excludeFlag,
                source: action?.datePayload?.source,
                url: action?.datePayload?.url,
                realTimeCheck: action?.datePayload?.realTimeCheck,
                realTime_tdate: action?.datePayload?.realTime_tdate,
                appId: action?.datePayload?.appId,
                appCode: action?.datePayload?.appCode
            }
        }
        default:
            return state;
    }

};


export interface ExportDatesState {
    export_tdate: string | null,
    export_fdate: string | null,
    dateSelection: number,
}

const initialExportDateState = {
    export_tdate: tdateVal,
    export_fdate: fdateVal,
    dateSelection: 3
}

export const ExportDateReducer = (state: ExportDatesState = initialExportDateState, action: ExportDateAction,) => {
    switch (action.type) {
        case 'SET_EXPORT_DATES': {
            return {
                ...state,
                export_tdate: action?.exportDatePayload?.export_tdate,
                export_fdate: action?.exportDatePayload?.export_fdate,
                dateSelection: action?.exportDatePayload?.dateSelection
            }
        }
        default:
            return state;
    }

};
export interface DomainState {
    domainSet: boolean,
    allAppsData: any
}

const initialDomainState = {
    domainSet: false,
    allAppsData: []
}

export const DomainReducer = (state: DomainState = initialDomainState, action: DomainAction) => {

    switch (action.type) {
        case 'SET_DOMAIN': {
            return {
                ...state,
                domainSet: action.domainPayload.domainSet,
                allAppsData: action.domainPayload.allAppsData,
            }
        }
        default:
            return state;
    }
};
export interface OrgState {
    orgSwitch: boolean,
    orgId: any
}

const initialOrgState = {
    orgSwitch: false,
    orgId: ''
}

export const OrgReducer = (state: OrgState = initialOrgState, action: OrgAction) => {

    switch (action.type) {
        case 'SET_ORG': {
            return {
                ...state,
                orgSwitch: action.orgPayload.orgSwitch,
                orgId: action.orgPayload.orgId,
            }
        }
        default:
            return state;
    }
};

export interface VisibilityState {
    superAdmin: boolean | null,
    isPITrackingEnabled: boolean | null,
    myBot_flag: boolean | null,
    guideInsights_flag: boolean | null,
    appInsights_flag: boolean | null,
    qaAutomator_flag: boolean | null
}

const initialVisibilityState = {
    superAdmin: localStorage.getItem('SUPER_ADMIN') === 'true' ? true : false,
    isPITrackingEnabled: localStorage.getItem('USER_ACTIVITY') === 'true' ? true : false,
    myBot_flag: localStorage.getItem('MYBOT_VISIBILITY') === 'true' ? true : false,
    guideInsights_flag: localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'true' ? true : false,
    appInsights_flag: localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'true' ? true : false,
    qaAutomator_flag: localStorage.getItem('QAAUTOMATOR_VISIBILITY') === 'true' ? true : false
}

export const VisibilityReducer = (state: VisibilityState = initialVisibilityState, action: VisibilityAction) => {

    switch (action.type) {
        case 'SET_SECTION_VISIBILITY': {
            return {
                ...state,
                superAdmin: action.visibilityPayload.superAdmin,
                isPITrackingEnabled: action.visibilityPayload.isPITrackingEnabled,
                myBot_flag: action.visibilityPayload.myBot_flag,
                guideInsights_flag: action.visibilityPayload.guideInsights_flag,
                appInsights_flag: action.visibilityPayload.appInsights_flag,
                qaAutomator_flag: action.visibilityPayload.qaAutomator_flag
            }
        }
        default:
            return state;
    }
};
export interface HandoffState {
    setHandoff: boolean
}

const initialHandoffState = {
    setHandoff: false
}

export const HandoffReducer = (state: HandoffState = initialHandoffState, action: HandoffAction) => {

    switch (action.type) {
        case 'SET_HANDOFF': {
            return {
                ...state,
                setHandoff: action.handoffPayload.setHandoff,
            }
        }
        default:
            return state;
    }
};
export interface StatusFilterState {
    stateFilter: any
}

const initialFilterState = {
    stateFilter: localStorage.getItem('guideState') ?? 'All'
}

export const StateFilterReducer = (state: StatusFilterState = initialFilterState, action: StateFilterAction) => {
    switch (action.type) {
        case 'SET_STATE_FILTER': {
            return {
                ...state,
                stateFilter: action.stateFilterPayload,
            }
        }
        default:
            return state;
    }
};
export interface ExportRequestSourceFilterState {
    exportRequestSourceFilter: any
}

const initialExportRequestSourceFilterState = {
    exportRequestSourceFilter: localStorage.getItem('exportRequestSourceState') ?? 'Guides'
}

export const ExportRequestSourceFilterReducer = (state: ExportRequestSourceFilterState = initialExportRequestSourceFilterState, action: ExportRequestSourceFilterAction) => {
    switch (action.type) {
        case 'SET_EXPORT_REQUEST_SOURCE_FILTER': {
            return {
                ...state,
                exportRequestSourceFilter: action.exportRequestSourceFilterPayload,
            }
        }
        default:
            return state;
    }
};

let eventSourceFilterLocalStorageObject: any = localStorage.getItem('eventSourceFilterState');
eventSourceFilterLocalStorageObject = JSON.parse(eventSourceFilterLocalStorageObject);

export interface EventSourceState {
    eventSourceGuides: any,
    eventSourceTutorialGuides: any,
    eventSourceTooltips: any,
    eventSourceSearches: any,
    eventSourceUserEngagement: any,
    eventSourceDashboard: any,
    eventSourceGuideAutomation: any,
    eventSourceGoals: any,
    eventSourceExportEmailGuide: any,
    eventSourceGoalsGuides: any,
    eventSourceGoalsTooltips: any,
    eventSourcePushNotificationsBeacons: any,
    eventSourceTaskList: any,


    // eventSourceExportEmailTooltip: any,
    // eventSourceExportEmailSearches: any,
}
const initialEventSourceState = {
    eventSourceGuides: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceGuides : 'player',
    eventSourceTutorialGuides: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceTutorialGuides : 'player',
    eventSourceTooltips: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceTooltips : 'player',
    eventSourceSearches: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceSearches : 'player',
    eventSourceUserEngagement: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceUserEngagement : 'player',
    eventSourceDashboard: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceDashboard : 'player',
    eventSourceGuideAutomation: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceGuideAutomation : 'player',
    eventSourceGoals: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceGoals : 'player',
    eventSourceExportEmailGuide: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceExportEmailGuide : 'player',
    eventSourceGoalsGuides: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceGoalsGuides : 'player',
    eventSourceGoalsTooltips: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceGoalsTooltips : 'player',
    eventSourcePushNotificationsBeacons: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourcePushNotificationsBeacons : 'player',
    eventSourceTaskList: eventSourceFilterLocalStorageObject ? eventSourceFilterLocalStorageObject.eventSourceTaskList : 'player',


    // eventSourceExportEmailTooltip: eventSourceFilterLocalStorageObject? eventSourceFilterLocalStorageObject.eventSourceExportEmailTooltip: "player",
    // eventSourceExportEmailSearches: eventSourceFilterLocalStorageObject? eventSourceFilterLocalStorageObject.eventSourceExportEmailSearches: "player",
}

export const EventSourceReducer = (state: EventSourceState = initialEventSourceState, action: EventSourceAction) => {
    switch (action.type) {
        case 'SET_EVENT_SOURCE': {
            return {
                ...state,
                eventSourceGuides: action.eventSourcePayload.eventSourceGuides,
                eventSourceTutorialGuides: action.eventSourcePayload.eventSourceTutorialGuides,
                eventSourceTooltips: action.eventSourcePayload.eventSourceTooltips,
                eventSourceSearches: action.eventSourcePayload.eventSourceSearches,
                eventSourceUserEngagement: action.eventSourcePayload.eventSourceUserEngagement,
                eventSourceDashboard: action.eventSourcePayload.eventSourceDashboard,
                eventSourceGuideAutomation: action.eventSourcePayload.eventSourceGuideAutomation,
                eventSourceGoals: action.eventSourcePayload.eventSourceGoals,
                eventSourceExportEmailGuide: action.eventSourcePayload.eventSourceExportEmailGuide,
                eventSourceGoalsGuides: action.eventSourcePayload.eventSourceGoalsGuides,
                eventSourceGoalsTooltips: action.eventSourcePayload.eventSourceGoalsTooltips,
                eventSourcePushNotificationsBeacons: action.eventSourcePayload.eventSourcePushNotificationsBeacons,
                eventSourceTaskList: action.eventSourcePayload.eventSourceTaskList,

                // eventSourceExportEmailTooltip: action.eventSourcePayload.eventSourceExportEmailTooltip,
                // eventSourceExportEmailSearches: action.eventSourcePayload.eventSourceExportEmailSearches,
            }
        }
        default:
            return state;
    }
};

let segmentsFilterLocalStorageObject: any = localStorage.getItem('segmentsFilterState');
segmentsFilterLocalStorageObject = JSON.parse(segmentsFilterLocalStorageObject);

export interface SegmentsFilterState {
    segmentsFilter: number,
    segmentTitle: string,
    listFetched: boolean
}
// segmentsFilterLocalStorageObject.segmentsFilter? segmentsFilterLocalStorageObject.segmentsFilter: 0
// segmentsFilterLocalStorageObject.segmentTitle? segmentsFilterLocalStorageObject.segmentTitle: ""
const initialSegmentsFilterState = {
    segmentsFilter: segmentsFilterLocalStorageObject ? segmentsFilterLocalStorageObject.segmentsFilter : 0,
    segmentTitle: segmentsFilterLocalStorageObject ? segmentsFilterLocalStorageObject.segmentTitle : "",
    listFetched: false
}

export const SegmentsFilterReducer = (state: SegmentsFilterState = initialSegmentsFilterState, action: SegmentsFilterAction) => {
    switch (action.type) {
        case 'SET_SEGMENTS_FILTER': {
            return {
                ...state,
                segmentsFilter: action.segmentsFilterPayload.segmentsFilter,
                segmentTitle: action.segmentsFilterPayload.segmentTitle,
                listFetched: action.segmentsFilterPayload.listFetched
            }
        }
        default:
            return state;
    }
};
export interface UrlOptionState {
    urlOption: string,
}

const initialUrlOptionStateState = {
    urlOption: '',
}

export const UrlOptionReducer = (state: UrlOptionState = initialUrlOptionStateState, action: UrlOptionAction) => {
    switch (action.type) {
        case 'SET_URL_OPTION': {
            return {
                ...state,
                urlOption: action.urlOptionPayload.urlOption,
            }
        }
        default:
            return state;
    }
};
export interface DashboardsState {
    data: any
}

const initialDashboardsState = {
    data: [{ dashboard1: { active: false, name: "", profileID: "", isDefault: false, dashboardIcon: "", profileDetails: [] } },
    { dashboard2: { active: false, name: "", profileID: "", isDefault: false, dashboardIcon: "", profileDetails: [] } },
    { dashboard3: { active: false, name: "", profileID: "", isDefault: false, dashboardIcon: "", profileDetails: [] } }]
}

export const DashboardsReducer = (state: DashboardsState = initialDashboardsState, action: DashboardsAction) => {
    switch (action.type) {
        case 'SET_DASHBOARDS': {
            return {
                ...state,
                data: action.dashboardsPayload,
            }
        }
        default:
            return state;
    }
};
export interface AuthState {
    userName: string,
    password: string,
    keepLogin: number
}

const initialAuthState = {
    userName: '',
    password: '',
    keepLogin: 0
}

export const AuthReducer = (state: AuthState = initialAuthState, action: AuthAction) => {
    switch (action.type) {
        case 'SET_AUTH': {
            return {
                ...state,
                userName: action.authPayload.userName,
                password: action.authPayload.password,
                keepLogin: action.authPayload.keepLogin,
            }
        }
        default:
            return state;
    }
};

// Tooltip filter
export interface TooltipFilterState {
    tooltipList: string
}
const initialTooltipFilterState = {
    tooltipList: ''
}

export const TooltipFilterReducer = (state: TooltipFilterState = initialTooltipFilterState, action: TooltipFilterAction) => {
    switch (action.type) {
        case 'SET_TOOLTIP_FILTER': {
            return {
                ...state,
                tooltipList: action.tooltipFilterPayload,
            }
        }
        default:
            return state;
    }
};


let envFilterLocalStorageObject: any = localStorage.getItem('ENV_FILTER_STATE');
envFilterLocalStorageObject = JSON.parse(envFilterLocalStorageObject);

export interface EnvFilterState {
    envCode: string,
    value: string,
    listFetched: boolean
}

const initialEnvFilterState = {
    envCode: envFilterLocalStorageObject ? envFilterLocalStorageObject.envCode : '',
    value: envFilterLocalStorageObject ? envFilterLocalStorageObject.value : 'all',
    listFetched: false
}

export const EnvFilterReducer = (state: EnvFilterState = initialEnvFilterState, action: EnvFilterAction) => {
    switch (action.type) {
        case 'SET_ENV_FILTER': {
            return {
                ...state,
                envCode: action.envFilterPayload.envCode,
                value: action.envFilterPayload.value,
                listFetched: action.envFilterPayload.listFetched
            }
        }
        default:
            return state;
    }
};



export interface CommonComponentState {
    datesData: any,
    appsData: any,
    stateFilterData: any,
    segmentsFilter: any,
    eventSource: any,
    envFilter: any
}

const initialCommonComponentState = {
    datesData: {},
    appsData: {},
    stateFilterData: {},
    segmentsFilter: {},
    eventSource: {},
    envFilter: {}
}

export const CommonComponentReducer = (state: CommonComponentState = initialCommonComponentState, action: CommmonComponentAction) => {
    switch (action.type) {
        case 'SET_COMMON_COMPONENT': {
            return {
                ...state,
                datesData: action.commonComponentPayload.datesData,
                appsData: action.commonComponentPayload.appsData,
                stateFilterData: action.commonComponentPayload.stateFilterData,
                segmentsFilter: action.commonComponentPayload.segmentsFilter,
                eventSource: action.commonComponentPayload.eventSource,
                envFilter: action.commonComponentPayload.envFilter
            }
        }
        default:
            return state;
    }
};


export interface LanguageState {
    languageData: any,
}

const initialLanguageState = {
    languageData: {},
}

export const LanguageReducer = (state: LanguageState = initialLanguageState, action: LanguageAction) => {
    switch (action.type) {
        case 'SET_LANGUAGE': {
            return {
                ...state,
                languageData: action.languagePayload,
            }
        }
        default:
            return state;
    }
};

export interface NavigationStatusState {
    navigationStatusData: boolean,
}

const initialNavigationStatusState = {
    navigationStatusData: false,
}

export const NavigationStatusReducer = (state: NavigationStatusState = initialNavigationStatusState, action: any) => {
    switch (action.type) {
        case 'SET_NAVIGATION_STATUS': {
            // console.log("COMIONG IN REDUCER?");
            return {
                ...state,
                navigationStatusData: action.navigationStatusData,
            }
        }
        default:
            return state;
    }
};