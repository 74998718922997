// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props)

import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { postCall } from "utils/ApiCallActions";
import { DashboardsState, LanguageState } from "store/Reducers";
import { setDashboards } from "store/Actions";
import { CommonUtils } from "utils/CommonUtils";
import { ApiRelativePaths } from "utils/GlobalConstants";
import "./SectionButtons.scss";
import { InsightLabels } from "labels";
import Icon from "components/Icon/Icon";
interface CurrentState {
    dashboards: DashboardsState,
    languageData: LanguageState
}

const SectionButtons = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    let dashboard_states = useSelector((state: CurrentState) => state.dashboards);
    let fetched_details = useSelector((state: CurrentState) => state);


    const [dashboardIcon, setDashboardIcon] = useState('')


    const dispatch = useDispatch();
    // const [text, setText] = useState(props.sectionTitle);

    const [warningFlag, setWarningFlag] = useState({
        warningFlag: false,
        warningText: "*Dashboard Name cannot be more than 20 characters",
    });

    const [label, setLabel] = useState({
        dashboard1Label: '',
        dashboard1Edit: false,
        dashboard2Label: '',
        dashboard2Edit: false,
        dashboard3Label: '',
        dashboard3Edit: false,
    })

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])

    useEffect(() => {

            setWarningFlag((prevState: any) => {
                return {
                    ...prevState,
                    warningText: insightsLbls.dashboardName20CharsErrorMsg
                }
            })

    },[])

    useEffect(() => {
        setWarningFlag((prevState: any) => {
            return {
                ...prevState,
                warningFlag: false,
                warningText: "",
            }
        })
    }, [props])

    useEffect(()=>{
        if(props?.type?.iconNumber){
            const iconNumber = props?.type?.iconNumber.split('dashboardIcon-');
            if (iconNumber){
                setDashboardIcon(iconNumber[1])
            }
        }
    },[props.type])






    useEffect(() => {
        if (dashboard_states) {
            setLabel((prevState: any) => {
                return {
                    ...prevState,
                    dashboard1Label: dashboard_states.data[0] ? dashboard_states.data[0].dashboard1.name : '',
                    dashboard2Label: dashboard_states.data[1] ? dashboard_states.data[1].dashboard2.name : '',
                    dashboard3Label: dashboard_states.data[2] ? dashboard_states.data[2].dashboard3.name : '',
                }
            })
        }
    }, [dashboard_states])

    const editButton = (optn: number) => {

        setLabel((prevState: any) => {
            return {
                ...prevState,
                ["dashboard" + `${optn}` + "Edit"]: true,
            }
        })

    };

    const editLabel = (e: any, optn: number) => {
        let finalLabel = e.target.value;
        if (finalLabel.length > 20) {
            setWarningFlag((prevState: any) => {
                return {
                    ...prevState,
                    warningFlag: true,
                    warningText: insightsLbls.dashboardName20CharsErrorMsg,
                }
            })
        }
        else if (finalLabel.length === 0) {
            setWarningFlag((prevState: any) => {
                return {
                    ...prevState,
                    warningFlag: true,
                    warningText: insightsLbls.dashboardNameEmptyErrorMsg,
                }
            })
        }
        else {
            setWarningFlag((prevState: any) => {
                return {
                    ...prevState,
                    warningFlag: false
                }
            })
        }


        setLabel((prevState: any) => {
            return {
                ...prevState,
                ["dashboard" + `${optn}` + "Label"]: finalLabel,
            }
        })

    };

    const confirmLabel = (optn: number) => {
        let profileID = "";
        let profileName = "";
        let profileDetails = [];
        let isDefault = false;



        dashboard_states.data[optn - 1]["dashboard" + `${optn}`].name = label["dashboard" + `${optn}` + "Label"];
        profileID = dashboard_states.data[optn - 1]["dashboard" + `${optn}`].profileID;
        profileName = dashboard_states.data[optn - 1]["dashboard" + `${optn}`].name;
        profileDetails = dashboard_states.data[optn - 1]["dashboard" + `${optn}`].profileDetails;
        isDefault = dashboard_states.data[optn - 1]["dashboard" + `${optn}`].isDefault;
        setLabel((prevState: any) => {
            return {
                ...prevState,
                // dashboard1Label : finalLabel,
                ["dashboard" + `${optn}` + "Edit"]: false,
            }
        })



        const data = {
            profileID: profileID,
            profileName: profileName,
            isDefault: isDefault,
            dashboardNumber: props.type?.option,
            dashboardIcon: props.type?.iconNumber,
            isDashboard: true,
            profileDetails: profileDetails,
        };

        let path = ApiRelativePaths.CREATE_DASHBOARD;
        let params = {};
        let paramObj = {};
        let a = CommonUtils.URLGenerator(params, path, paramObj);
        postCall(data, "CREATE_DASHBOARD").then((data: any) => {
            if (data.result === "success") {
                setDashboards(dashboard_states.data);
                dispatch(setDashboards(dashboard_states.data));

                toast.success(insightsLbls.successfullyRenamed, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });

    };

    

    return (
        <div className={
            `${props.className}` +
            " page-title-new col-12 col-sm-12 col-12 section-heading-div displayFlex no-padding-left"}>
            {
                props.type !== undefined && props.type.component === 'customDashboard' ?
                    (
                        <div className="col-lg-8 col-md-8 col-sm-8 paddingLR-0 text-alignLeft displayFlex alignCenter">
                            <div className="dashboardNameDiv">
                                <div className="">
                                    {dashboardIcon && (
                                        <div className={`dashboardIcon-${dashboardIcon} icon-box`}>
                                            <Icon img={"img/custom_dashboard/icon"+dashboardIcon+".svg"} className="title-icon pagesTitle-icon" />
                                        </div>
                                    )}
                                </div>

                                <div className="dashboardTitle-input">
                                    <div className="textDiv">{label["dashboard" + `${props.type.option}` + "Edit"] ? (<input id="inputDashboard" autoComplete = "off" type="text" value={label["dashboard" + `${props.type.option}` + "Label"]} onInput={(e) => editLabel(e, props.type.option)}></input>) : (<span className="spanText font24">{dashboard_states.data[props.type.option - 1]["dashboard" + `${props.type.option}`]['name']}</span>)}</div>
                                    <div className={"editDiv " + (warningFlag.warningFlag ? "editDisabled" : "")}>{label["dashboard" + `${props.type.option}` + "Edit"] ? (<i className="far fa-check-square faCheckSideNav" onClick={() => confirmLabel(props.type.option)}></i>) : (<i className="fa fa-edit faEditSideNav" onClick={() => editButton(props.type.option)}></i>)}</div>
                                </div>
                            </div>
                            {warningFlag.warningFlag ? (<div className="warningTextDiv">{warningFlag.warningText}</div>) : (null)}
                        </div>
                    )
                    :
                    (
                        <h4 className="col-lg-8 col-md-8 col-sm-8 paddingLR-0 text-alignLeft displayFlex alignCenter no-padding-left">
                            <div>
                                {(() => {
                                    switch (props.svgImage) {
                                        case 'pages':
                                            return (<Icon img={'img/page_dark.svg'} className="title-icon pagesTitle-icon" />)

                                        case 'workflows':
                                            return (<Icon img={'img/WorkFlow_panel_dark.svg'} className="title-icon pagesTitle-icon" />)

                                        case 'dashboard':
                                            return (<Icon img={'img/dashboard_ic_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)
                                          
                                        case 'userEngagement':
                                            return (<Icon img={'img/userEngagement_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'userEngagementDetail':
                                            return (<Icon img={'img/userEngagement_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'guides':
                                            return (<Icon img={'img/GuideMe_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'tooltips':
                                            return (<Icon img={'img/tooltip_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'tooltipsDetail':
                                            return (<Icon img={'img/tooltip_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'searches':
                                            return (<Icon img={'img/searchActivity_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'surveys':
                                            return (<Icon img={'img/sentiments_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'guideAutomation':
                                            return (<Icon img={'img/myBot_dark.svg'} className="title-icon pagesTitle-icon guideAutomtionIcon userEngegment-icon" />)

                                        case 'qaautomation':
                                            return (<Icon img={'img/qa_automation_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)
      
                                        case 'organization':
                                            return (<Icon img={'img/organization_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)
                                        
                                        case 'export':
                                            return (<Icon img={'img/export_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                        case 'controlCenter':
                                            return (<Icon img={'img/controlCenter_dark.svg'} className="title-icon pagesTitle-icon userEngegment-icon" />)

                                    }
                                })()}
                            </div>
                  
                            {props.type === undefined
                                && (props.sectionTitle)
                            }
                        </h4>
                    )

            }

            <div className="col-lg-4 col-md-4 col-sm-4 displayFlex paddingLR-0 createBtnDiv exportSpacing" data-html2canvas-ignore="true">
                {props.children}
            </div>
        </div>
    )
}

export default React.memo(SectionButtons);