// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props)

import React, { useRef, useCallback, useEffect, useState } from "react";
import NVD3Chart from 'react-nvd3';

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

import * as d3 from "d3";
import $ from "jquery";
import { CommonUtils } from "utils/CommonUtils";
import { InsightLabels } from "labels";

import { useErrorBoundary } from 'react-error-boundary';

interface CurrentState {
    languageData: LanguageState
}

const LineChart = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    let lineChartRef = [];
    lineChartRef[props.refId] = useRef(null);
    let max = 0;
    let minFlag = 0;

    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        flag: false,
        max: 0
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    // const isValidData = (data) => {
    //     if (data != undefined) {
    //         if (data[0] != undefined) {
    //             if (typeof (data) == "object" && Object.keys(data).length > 0) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }

    useEffect(() => {
        // if (isValidData(props.chartData)) {
        setTimeout(() => {
            try {
                if (props.chartData) {
                    const data = CommonUtils.dateSeparator(props.chartData);
                    let initialArray: any = [];

                    if (props.component !== 'dashboard') {
                        for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                            let temp_arr: any = [];
                            for (let itx2 = 0; itx2 < data.length; itx2++) {
                                let temp_obj: any = {};
                                temp_obj["startTimestamp"] = data[itx2].startTimestamp;
                                temp_obj["x"] = data[itx2].startTimestamp;
                                temp_obj["date"] = data[itx2].date;
                                temp_obj["y"] = data[itx2]['counts'][props.chartEntities[itx1]];

                                if (max < +data[itx2]['counts'][props.chartEntities[itx1]]) {
                                    max = data[itx2]['counts'][props.chartEntities[itx1]]
                                }

                                temp_arr.push(temp_obj);
                            }
                            initialArray.push(temp_arr)
                        }
                    } else {
                        for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                            let temp_arr: any = [];
                            for (let itx2 = 0; itx2 < data.length; itx2++) {
                                for (let itx3 = 0; itx3 < props.optionEntities.length; itx3++) {
                                    if (props.optionEntities[itx3].associatedDataPoint === props.chartEntities[itx1]) {
                                        let temp_obj: any = {};
                                        temp_obj["startTimestamp"] = data[itx2].startTimestamp;
                                        temp_obj["x"] = data[itx2].startTimestamp;
                                        temp_obj["date"] = data[itx2].date;
                                        temp_obj["y"] = data[itx2]['counts'][props.chartEntities[itx1]];

                                        if (max < +data[itx2]['counts'][props.chartEntities[itx1]]) {
                                            max = data[itx2]['counts'][props.chartEntities[itx1]]
                                        }
                                        temp_arr.push(temp_obj);
                                    }
                                }
                            }
                            if (temp_arr.length) {
                                initialArray.push(temp_arr)
                            }
                        }
                    }


                    let item = 0;
                    let chartOptions: any = [];
                    if (props.component !== 'dashboard') {
                        for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                            let dummy_chartOptions: any = {};
                            dummy_chartOptions["key"] = props.optionEntities[itx1]['key'];
                            dummy_chartOptions["values"] = initialArray[itx1];
                            dummy_chartOptions["color"] = props.optionEntities[itx1]['color'];
                            dummy_chartOptions["area"] = props.optionEntities[itx1]['area'];
                            chartOptions.push(dummy_chartOptions);
                        }
                    } else {
                        for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                            for (let itx2 = 0; itx2 < props.optionEntities.length; itx2++) {
                                if (props.optionEntities[itx2].associatedDataPoint === props.chartEntities[itx1]) {
                                    let dummy_chartOptions: any = {};
                                    dummy_chartOptions["key"] = props.optionEntities[itx2]['key'];
                                    dummy_chartOptions["values"] = initialArray[item];
                                    dummy_chartOptions["color"] = props.optionEntities[itx2]['color'];
                                    dummy_chartOptions["area"] = props.optionEntities[itx2]['area'];
                                    chartOptions.push(dummy_chartOptions);
                                    item++;
                                }
                            }
                        }
                    }
                    setRenderData((prevState: any) => {
                        return {
                            ...prevState,
                            chart: [...data],
                            options: [...chartOptions],
                            max: max
                        }
                    })
                }
            } catch (error) {
                showBoundary(error)
            }
        }, 0)
        // }
    }, [props])

    useEffect(() => {
        try {
            if (renderData.options) {
                setRenderData((prevState: any) => {
                    return {
                        ...prevState,
                        flag: true
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [renderData.options])



    return (
        <div>
            <div id={props.refId}>
                {renderData.flag && (
                    <NVD3Chart
                        ref={lineChartRef[props.refId]}
                        type="lineChart"
                        datum={renderData.options}
                        showValues={true}
                        wrapLabels={true}
                        useInteractiveGuideline={false}
                        clipEdge={false}
                        legend={{
                            maxKeyLength: 100,
                            margin: {
                                right: 0,
                                top: 5,
                                left: -50,
                                bottom: 15,
                            },
                        }}
                        legendPosition={'top'}

                        x={function (d: any, i: any) {
                            return i;
                        }}
                        y={function (d: any) {
                            if (d.y !== undefined) {
                                return d.y;
                            }
                        }}
                        margin={{
                            left: props.margin["left"],
                            right: props.margin["right"],
                            top: props.margin["top"],
                            bottom: props.margin["bottom"],
                        }}
                        xAxis={{
                            tickFormat: function (d: any) {


                                if (renderData.chart !== undefined && renderData.chart[d] !== undefined) {
                                    if (props.refId === 'goalDetailsLineChart') {
                                        return renderData.chart[d]['startTimestamp']
                                    } else {
                                        return (renderData.chart[d]['startTimestamp']);

                                    }
                                }
                            },
                        }}
                        yAxis={{
                            tickFormat: function (d: any) {
                                if (props.value_type === "time") {
                                    d = Number(d);
                                    let h = Math.floor(d / 3600);
                                    let m = Math.floor((d % 3600) / 60);
                                    let s;

                                    if (h === 0) {
                                        s = Math.floor((d % 3600) % 60);
                                    }
                                    var hDisplay = h > 0 ? h + (h === 1 ? insightsLbls.hour : insightsLbls.hour) : "";
                                    var mDisplay = m > 0 ? m + (m === 1 ? insightsLbls.minute : insightsLbls.minute) : "";
                                    var sDisplay = s > 0 ? s + (s === 1 ? insightsLbls.second : insightsLbls.second) : "";
                                    return hDisplay + mDisplay + sDisplay;
                                    // return self.timeConverter.transform(d, true);
                                } else {
                                    return Number.isInteger(d) ? d : "";
                                }
                            },
                            axisLabelDistance: 2,
                            axisLabel: props.yAxisLabel,

                        }}
                        yDomain={[0, renderData.max]}
                        width={props.width}
                        height={props.height}
                        tooltip={{
                            contentGenerator: function (key: any, item: any) {
                                // solution for tooltip stuck issue
                                d3.selectAll('.nvtooltip').style('opacity', 0);
                                let time = 0;
                                if (key.point.startTimeStamp) {
                                    time = key.point.startTimeStamp;
                                } else {
                                    time = key.point.startTimestamp;
                                }
                                if (props.chartData[0].startTimestamp.includes("|")) {
                                    $(".nv-point-paths path, .nv-groups rect").css(
                                        "cursor",
                                        "default"
                                    );
                                    if (props.value_type === "time") {
                                        var hr = Math.floor(key.point.y / 3600);
                                        var min = Math.floor((key.point.y % 3600) / 60);
                                        var sec = Math.floor((key.point.y % 3600) % 60);
                                        var hDisplay =
                                            hr > 0 ? hr + (hr === 1 ? insightsLbls.hour : insightsLbls.hours) : "";
                                        var mDisplay =
                                            min > 0 ? min + (min === 1 ? insightsLbls.minute : insightsLbls.minutes) : "";
                                        var sDisplay =
                                            sec > 0 ? sec + (sec === 1 ? insightsLbls.second : insightsLbls.seconds) : "";
                                        var label = hDisplay + mDisplay + sDisplay;
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');
                                        return `<div><b>${key.point.date} ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color}'></div>${key.series[0].key} <b>${label}</b></div>`;
                                    } else {
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');

                                        return `<div><b>${(key.point.date)} ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color
                                            }'></div>${key.series[0].key
                                            } <b>${key.point.y
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></div>`;
                                    }
                                } else {


                                    $(" .nv-point-paths path, .nv-groups rect").css(
                                        "cursor",
                                        "default"
                                    );
                                    if (props.value_type === "time") {
                                        const hr = Math.floor(key.point.y / 3600);
                                        const min = Math.floor((key.point.y % 3600) / 60);
                                        const sec = Math.floor((key.point.y % 3600) % 60);
                                        const hDisplay =
                                            hr > 0 ? hr + (hr === 1 ? insightsLbls.hour : insightsLbls.hours) : "";
                                        const mDisplay =
                                            min > 0 ? min + (min === 1 ? insightsLbls.minute : insightsLbls.minutes) : "";
                                        const sDisplay =
                                            sec > 0 ? sec + (sec === 1 ? insightsLbls.second : insightsLbls.seconds) : "";
                                        const label = hDisplay + mDisplay + sDisplay;
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');

                                        return `<div><b>${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color}'></div>${key.series[0].key} <b>${label}</b></div>`;
                                    } else {
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');

                                        return `<div><b>${(time)}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color
                                            }'></div>${key.series[0].key} <b>${key.point.y}</b></div>`;
                                    }
                                }
                            }
                        }}
                    />
                )}

            </div>
        </div>
    );
};
export default React.memo(LineChart);
