import React from "react";
import "./SectionHeader.scss";


const ContainerTooltip = (props: any) => {

    return (
        <>
            {(props.div1Classes && props.div2Classes) && (
                <div className={props.div1Classes}>
                    <div className={props.div2Classes}>
                        <h5 className={props.hTagClasses}>
                            {props.children}
                        </h5>
                    </div>
                </div>
            )}
        
            {(props.div1Classes || props.div2Classes) && (
                <div className={props.div1Classes + " " + props.div2Classes}>
                    <h5 className={props.hTagClasses}>
                        {props.children}
                    </h5>
                </div>
            )}


            {(!props.div1Classes && !props.div2Classes) && (
                <h5 className={props.hTagClasses}>
                    {props.children}
                </h5>
            )}
            
        </>
    )
}

export default ContainerTooltip;