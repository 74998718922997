import React, { useState, useEffect, useRef } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import { FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";



import { withReducer } from "store/withReducer";
import * as otpActions from "store/Actions/index";
import otpReducer, { OtpState } from "store/Reducers/Authentication/otp";
import { useDispatch, useSelector } from "react-redux";
import { CommonUtils } from "utils/CommonUtils";
import { getCall } from "utils/ApiCallActions";
import { AuthState } from "store/Reducers";
import * as ActionTypes from "store/Actions/ActionTypes";


import qs from "querystring";
import mainLogo_1 from "assets/js/images/mainLogo_1";
import mainLogo_2 from "assets/js/images/mainLogo_2";


import Loader from "components/Loader/Loader";


import './Authentication.scss';
import { RetryApi } from "utils/GlobalConstants";


// import insightsLabels from "utils/stringConstants/trans_utils";
import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
// let insightsLbls = insightsLabels;

interface CurrentState {
    otp: OtpState,
    auth: AuthState
}

const Otp: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    useEffect(() => {
        if (insightsLbls) {
            getSelectedLabels.then((data: InsightLabels) => {
                setInsightsLbls(data);
            });
        }
    }, [insightsLbls])

    const dispatch = useDispatch();
    const currentYear = new Date().getFullYear();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const counter = useRef(60);
    const navigate = useNavigate();


    let fetched_details = useSelector((state: CurrentState) => state.otp);
    let fetched_auth_details = useSelector((state: CurrentState) => state.auth);

    const [otpData, setOtpData] = useState({
        errorMsg: '',
        errorFlag: false,
        loader: false,
        numberFlag: false,
        resendEnable: false,
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "OTP";
        navigate({
            pathname: `/otp/`,
        });

        enableResend();
    }, [])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const verifyOtp = (e: any) => {
        e.preventDefault();
        const elem = document.getElementById('otpInput') as HTMLInputElement;
        if (elem) {
            let data = {
                'otp_code': elem.value,
                'otp_relay': localStorage.getItem('OTP_RELAY')
            };

            otpActions.getOtpData(
                data,
                "OTP_VERIFY",
                ActionTypes.SET_OTP_DATA,
            ).then((res: any) => dispatch(res));
        }
    }

    const resendOtp = () => {
        let data = {
            'email_id': fetched_auth_details.userName,
            'password': fetched_auth_details.password,
            'keep_login': fetched_auth_details.keepLogin,
        };

        otpActions.getOtpData(
            data,
            "LOGIN",
            ActionTypes.SET_RESEND_OTP_DATA,
        ).then((res: any) => dispatch(res));
    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleChange = (e: any) => {
        const value = e.target.value;
        const re = /^[0-9]+$/;
        const error = re.test(value);

        if (!error) {
            setOtpData((prevState) => {
                return {
                    ...prevState,
                    numberFlag: true,
                };
            });
        } else {
            setOtpData((prevState) => {
                return {
                    ...prevState,
                    numberFlag: false,
                };
            });
        }
    };

    const handlePaste = (e: any) => {
        const value = isNaN(e.clipboardData.getData('Text'))

        if (value) {
            setOtpData((prevState) => {
                return {
                    ...prevState,
                    numberFlag: true,
                };
            });
        } else {
            setOtpData((prevState) => {
                return {
                    ...prevState,
                    numberFlag: false,
                };
            });
        }
    };

    const enableResend = () => {
        counter.current = 60;
        setOtpData((prevState) => {
            return {
                ...prevState,
                counter: counter.current,
            };
        });
        countdown();
    }

    const surveyRedirect = () => {
        let queryParams = {
            app_code: "",
            app_id: "",
        }
        navigate({
            pathname: `/survey/manage-survey/`,
            search: qs.stringify(queryParams)
        })
        window.location.reload();
    }

    const countdown = () => {
        setTimeout(() => {
            counter.current--;
            setOtpData((prevState) => {
                return {
                    ...prevState,
                    counter: counter.current,
                };
            });

            if (counter.current > 0) {
                countdown();
            } else {
                setOtpData((prevState) => {
                    return {
                        ...prevState,
                        resendEnable: true,
                    };
                });
            }
        }, 1000);
    }

    const getDashboardRedirectData = (apiErrorFlag?: string) => {
        const params = {};
        getCall(params, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
            if (data.result === 'success') {
                let defaultDash = '/dashboard/';
                for (let item of data.data) {
                    if (item['isDefault']) {
                        defaultDash = '/custom-dashboard/' + item['dashboardNumber'] + '/';
                        break;
                    }
                }

                const queryParams = {
                    app_id: "",
                };
                navigate({
                    pathname: defaultDash,
                    search: qs.stringify(queryParams),
                });

                window.location.reload();
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    getDashboardRedirectData('retry')
                }, RetryApi.TIMEOUT)
            }
        }
        );
    }


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // On otp data redirect to landing page
    useEffect(() => {
        if (fetched_details?.otpData?.data !== undefined && fetched_details?.otpData?.result === 'success') {
            localStorage.removeItem('OTP_RELAY');
            localStorage.setItem('IS_AUTHENTICATED', 'true');

            localStorage.setItem('EMAIL_ID', fetched_auth_details.userName);
            CommonUtils.storeAuthData(fetched_details?.otpData.data.user);
            CommonUtils.routeOnLogin();

            if (localStorage.getItem('USER_ROLE') !== 'creator') {
                getDashboardRedirectData()
            } else {
                surveyRedirect();
            }

        } else if (fetched_details?.otpData?.result === "error") {
            setOtpData((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: fetched_details.otpData.data.error ?? fetched_details.otpData?.data?.data?.message[0],
                    errorFlag: true,
                    loader: false
                }
            })
        }
    }, [fetched_details.otpData])

    // Resend otp data
    useEffect(() => {
        if (fetched_details?.otpResendData?.data !== undefined && fetched_details?.otpResendData?.result === 'success') {
            localStorage.setItem('OTP_RELAY', fetched_details?.otpResendData?.data?.data.otp_relay);
        } else if (fetched_details?.otpResendData?.result === "error") {
            setOtpData((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: fetched_details.otpResendData?.data?.data?.message[0] ?? fetched_details.otpResendData.data.error,
                    errorFlag: true,
                    loader: false
                }
            })
        }
    }, [fetched_details.otpResendData])


    return (
        <React.Fragment>
            <section className="otp" ref={componentRef}>
                <div className="loginContainer">
                    <div className="loginContainerParts">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 loginForm">
                            <React.Fragment>
                                <div className="loginLogo">
                                    <img className="mainLogo" alt="" src={process.env.REACT_APP_ENV === 'prod' ? mainLogo_1 : mainLogo_2} />
                                </div>

                                {otpData.loader
                                    ? (
                                        <div className="login-loaderHeight width100 displayFlex height100-percent">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <>
                                            <div className="w-60">
                                                <h3 className="loginHead">{insightsLbls['verifyAccount']}</h3>



                                                {otpData.errorFlag && (
                                                    <div className="alert alert-danger pointer errorMsg padding10" data-dismiss="alert">
                                                        {otpData.errorMsg}
                                                    </div>
                                                )}

                                                <p className="otpInfoText">{insightsLbls['otpSent']}</p>

                                                <div>
                                                    <div
                                                        className="font14"
                                                    >
                                                        <FormGroup controlId="formEmail">
                                                            <div className="font700 marginBottom5">
                                                                {insightsLbls['otp']}
                                                            </div>
                                                            <FormControl
                                                                id='otpInput'
                                                                name="OTP"
                                                                placeholder="Enter your OTP"
                                                                maxLength={6}
                                                                onInput={handleChange}
                                                                onPaste={handlePaste}
                                                                autoComplete="off"
                                                            />
                                                        </FormGroup>
                                                        <div className="errorContainer">
                                                            <div className="requiredErrorMsg">
                                                                {otpData.numberFlag === true && (
                                                                    <span className="error">{insightsLbls['enterNumber']}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <FormGroup>
                                                            <button
                                                                className="btn btn-fla modal-save-btn loginBtn width-100-precent"
                                                                onClick={(e) => verifyOtp(e)} disabled={otpData.numberFlag} type="button">{insightsLbls['verifyAccount']}</button>
                                                        </FormGroup>

                                                    </div>
                                                    <div className="loginRemember marginTop-10">
                                                        <p className="otpNotRecieved">{insightsLbls['didntReceiveOTP']}
                                                            {otpData.resendEnable ?
                                                                (
                                                                    <span className="otpEnable" onClick={resendOtp}> {insightsLbls['clicktoResendOTP']}</span>
                                                                )
                                                                :
                                                                (
                                                                    <React.Fragment>
                                                                        <span className="otpNotEnable" onClick={resendOtp}> {insightsLbls['clicktoResendOTP']}: </span>
                                                                        {
                                                                            counter.current > 0 ?
                                                                                (
                                                                                    <span className="counterColor">{counter.current}s</span>
                                                                                ) :
                                                                                (null)
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </p>
                                                        <div className="pull-left d-flex">
                                                            <Link to={"/login"} className="passwordLink">{insightsLbls['backToLogin']}</Link>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </>
                                    )}

                                {/* [className.d-none]="!calling" */}
                                <small className="loginFooter">
                                    {insightsLbls.supportLinkText} <a href={insightsLbls.supportLink} className="supportLink">{insightsLbls.displaySupportLink}</a><br />
                                    {insightsLbls['copyright']} {currentYear} - {insightsLbls.brandName} <br /> {insightsLbls.version}
                                </small>
                            </React.Fragment>
                        </div>
                        <div
                            className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-none d-sm-none d-md-none d-lg-flex d-xl-flex align-items-center justify-content-center bg-login">
                            <img height="100%" width="100%" alt="" src={require(`${process.env.REACT_APP_SECTION_IMAGE}`)} />
                        </div>
                    </div>
                </div>
            </section>


        </React.Fragment>
    )
}
export default withRouter(withReducer("otp", otpReducer)(Otp));

