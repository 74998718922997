import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { useErrorBoundary } from 'react-error-boundary';



const Heatmap = (props) => {
    let fetched_details = useSelector((state) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState({})

    useEffect(() => {
        if (Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details])

    setTimeout(() => {
        try {
            let max = 0;
            for (let i = 0; i < props.chartData.length; i++) {
                if (props.chartData[i].value > max) {
                    max = props.chartData[i].value;
                }
            }

            let number = max;
            // bucket size is 5
            do {
                number++;
            } while (number % 5 !== 0);

            let BoundarySet = number / 5;
            let boudaryValues = [];

            let itrx = 0;
            while (boudaryValues.length < 6) {
                if (Math.round(BoundarySet * itrx) !== 1) {
                    boudaryValues.push(Math.round(BoundarySet * itrx));
                }
                itrx++;
            }

            var margin = { top: 50, right: 0, bottom: 50, left: 30 },
                width = 1000 - margin.left - margin.right,
                height = 430 - margin.top - margin.bottom,
                gridSize = Math.floor(width / 24),
                legendElementWidth = gridSize * 2,
                // buckets = 5,
                // colors = ['#EEEEEE', '#9ECAE1', '#86ADDE', '#3182BD'], // alternatively colorbrewer.YlGnBu[9]
                // colors = ['#fdeec7', '#e79796', '#cd8094', '#3fb5a0'], // alternatively colorbrewer.YlGnBu[9]
                // colors = ['#b9f3e9', '#91e8d9', '#64d4c0', '#38b6a0'], // alternatively colorbrewer.YlGnBu[9]
                colors = ["#ffeb3b", "#cddc39", "#8bc34a", "#5fb104", "#388e3c"], // alternatively colorbrewer.YlGnBu[9]
                times = props.chartTimes.map(function (chart) {
                    return chart + "m";
                }),
                days = props.days;

            const datasets = props.chartData;

            const svg = d3
                .select("#Heatmap")
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            svg
                .selectAll(".timeLabel")
                .data(times)
                .enter()
                .append("text")
                .html(function (d) {
                    if (d === "1am") {
                        return `12am   ` + `&nbsp` + d[0] + `am`;
                    } else {
                        return d;
                    }
                })
                .attr("x", function (d, i) {
                    if (d === "1am") {
                        return i * gridSize + 0;
                    } else {
                        return i * gridSize + 16;
                    }
                })
                .attr("y", 0)
                .style("text-anchor", "middle")
                .attr("transform", "translate(" + gridSize / 2 + ", -6)")
                .attr("class", function (d, i) {
                    return "timeLabel mono axis axis-worktime";
                });

            svg
                .selectAll(".dayLabel")
                .data(days)
                .enter()
                .append("text")
                .text(function (d) {
                    return d;
                })
                .attr("x", 0)
                .attr("y", function (d, i) {
                    return i * gridSize;
                })
                .style("text-anchor", "end")
                .attr("transform", "translate(-6," + gridSize / 1.5 + ")")
                .attr("class", function () {
                    return "dayLabel mono axis axis-workweek";
                });

            function heatmapChart(data) {
                d3.mean(data, function (d) {
                    return +d.value;
                });

                const colorScale = d3.scaleQuantile()
                    .domain(boudaryValues)
                    .range(colors);

                const cards = svg.selectAll(".hour").data(data, function (d) {
                    return d.day + ":" + d.hour;
                });

                const tooltip = d3
                    .select("body")
                    .append("div")
                    .style("position", "absolute")
                    .style("z-index", "10")
                    .style("display", "none")
                    .style("color", "#222")
                    .style("padding", "8px")
                    .style("background-color", "rgba(255, 255, 255, 0.75)")
                    .style("border-radius", "4px")
                    .style("border", "1px solid #666")
                    .style("font", "14px sans-serif")
                    .text("tooltip")
                    .attr("class", "removeTooltip");

                cards.append("title");

                cards
                    .enter()
                    .append("rect")
                    .attr("x", function (d) {
                        return (d.hour - 1) * gridSize;
                    })
                    .attr("y", function (d) {
                        return (d.day - 1) * gridSize;
                    })
                    .attr("rx", 4)
                    .attr("ry", 4)
                    .attr("class", "hour bordered")
                    .attr("width", gridSize)
                    .attr("height", gridSize)
                    .style("fill", function(d){                        
                        if (d["value"] === 0) {
                            return "#EEEEEE";
                        } else {
                            return colorScale(Math.round(d["value"]));
                        }
                    })
                    .on("mouseover", function (d) {
                        // tooltip.text('Count: ' + d.value);
                        tooltip.html(insightsLbls.count + ": <b>" + d.target.__data__.value + "</b>");

                        tooltip.style("display", "block");
                    })
                    .on("mousemove", function (event) {
                        const pageX = event["pageX"];
                        const pageY = event["pageY"];

                        return tooltip
                            .style("top", pageY + 10 + "px")
                            .style("left", pageX + 10 + "px");
                    })
                    .on("mouseout", function () {
                        return tooltip.style("display", "none");
                    });

                // cards
                //     .transition()
                //     .duration(1000)
                //     .style("fill", function (d) {
                //         if (d["value"] == 0) {
                //             return "#EEEEEE";
                //         } else {
                //             return colorScale(Math.round(d["value"]));
                //         }
                //     });

                cards.select("title").text(function (d) {
                    return d.value;
                });

                cards.exit().remove();

                const legend = svg
                    .selectAll(".legend")
                    .data([0].concat(colorScale.quantiles()), function (d) {
                        return d;
                    });

                legend.enter().append("g").attr("class", "legend");

                legend
                    .append("rect")
                    .attr("x", function (d, i) {
                        return legendElementWidth * i;
                    })
                    .attr("y", height - 10)
                    .attr("width", legendElementWidth)
                    .attr("height", gridSize / 2)
                    .style("fill", function (d, i) {
                        return colors[i];
                    });

                legend
                    .append("text")
                    .attr("class", "mono")
                    .text(function (d) {
                        if (d === 0) {
                            return "≥ 1";
                        } else {
                            return "≥ " + Math.round(d);
                        }
                    })
                    .attr("x", function (d, i) {
                        return legendElementWidth * i;
                    })
                    .attr("y", height + gridSize - 10);

                legend.exit().remove();
            }

            heatmapChart(datasets);

            const datasetpicker = d3
                .select("#dataset-picker")
                .selectAll(".dataset-button")
                .data(datasets);

            datasetpicker
                .enter()
                .append("input")
                .attr("value", function (d) {
                    return "Dataset " + d;
                })
                .attr("type", "button")
                .attr("class", "dataset-button")
                .on("click", function (d) {
                    heatmapChart(d);
                });
        } catch (error) {
            showBoundary(error)
        }


    }, 0);

    return (
        <>
            <div id="Heatmap"></div>
        </>
    );
};
export default React.memo(Heatmap);
