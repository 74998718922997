import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";


import "../../style.scss";


const SectionTooltip = (props: any) => {
    let tooltipText = props.tooltipText;
    let placement = props.placement;
    let tooltip;
    if (tooltipText !== undefined) {
        tooltip = (
            <Tooltip id="tooltip">
                <br></br>
                <div><span className="">{tooltipText.tooltipText}</span></div>
                <br></br>
                <ul>
                    {tooltipText.childern?(tooltipText.childern.map((data:any, key:number) => {
                        return (
                                <li key = {key} >
                                    <div>
                                        <span className="marginRight-5 font600">
                                            {data.key}
                                        </span>
                                        {data.value}
                                    </div>
                                    <br></br>
                                </li>
                            );
                    })):null}
                </ul> 
            </Tooltip>
        );
    }
    return (
        <div className = "displayFlex">
            { 
                tooltipText !== undefined ? (<strong>{tooltipText.subTitle}</strong>) :  (null)
            }
            <OverlayTrigger placement={placement} overlay={tooltip} >
                <i className="fa fa-info-circle info-icon-color marginLeft-10 marginTop-2" aria-hidden="true"> </i>
            </OverlayTrigger>
        </div>
    );    
};
export default SectionTooltip;








